import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface IModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    onClose: () => void;
    onSave: (documentName: string, content: string) => void;
    canEdit: boolean;
    documentName: string;
    content: string;
}

const AddDocumentModal: React.FC<IModalProps> = ({
    open,
    setOpen,
    onClose,
    onSave,
    canEdit,
    documentName: initialDocumentName,
    content: initialContent,
}) => {
    const [documentName, setDocumentName] = useState('');
    const [content, setContent] = useState('');
    const [error, setError] = useState(false);
    const quillRef = useRef<ReactQuill | null>(null);

    useEffect(() => {
        if (canEdit) {
            setDocumentName(initialDocumentName);
            setContent(initialContent);
        } else {
            // Reset fields when opening the modal for creating a new document
            setDocumentName('');
            setContent('');
        }
    }, [canEdit, initialDocumentName, initialContent, open]);

    const handleContentChange = (value: string) => {
        setContent(value);
    };

    const insertTextToEditor = (text: string) => {
        const editor = quillRef.current?.getEditor();
        if (editor) {
            const range = editor.getSelection();
            if (range) {
                const highlightedText = `<span style="background-color: #cce4ff; color: #007bff; padding: 2px 4px; border-radius: 4px;">${text}</span>`;
                editor.clipboard.dangerouslyPasteHTML(range.index, highlightedText);
            }
        }
    };

    const handleSave = () => {
        if (!documentName.trim()) {
            setError(true);
            return;
        }
        setError(false);
        if (documentName && content) {
            onSave(documentName, content);
            setDocumentName('');
            setContent('');
        }
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflowY: 'auto',
            }}
        >
            <Box
                sx={{
                    width: '95%',
                    maxWidth: '1400px',
                    backgroundColor: 'white',
                    padding: '20px',
                    display: 'flex',
                    borderRadius: '10px',
                    flexDirection: 'column',
                    height: { xs: '80vh', md: '85vh', lg: '90vh' },
                    maxHeight: '90vh',
                    overflowY: 'auto',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '20px',
                    }}
                >
                    <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>
                        Nome do {canEdit ? "modelo" : "contrato"}
                    </Typography>
                </Box>
                <TextField
                    fullWidth
                    variant="outlined"
                    value={documentName}
                    onChange={(e) => setDocumentName(e.target.value)}
                    sx={{ marginBottom: '20px' }}
                    error={error}
                    helperText={error ? 'O nome do contrato é obrigatório' : ''}
                    required
                />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        flexGrow: 1,
                    }}
                >
                    <Box
                        sx={{
                            width: { xs: '100%', md: '25%' },
                            marginRight: { md: '20px' },
                            marginBottom: { xs: '20px', md: '0' },
                            padding: '10px',
                            border: '1px solid #e4e4e4',
                            borderRadius: '10px',
                            maxHeight: { xs: '30%', md: '100%' },
                            overflowY: 'auto',
                            flexShrink: 0,
                        }}
                    >
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                            Dados para contrato
                        </Typography>
                        <Box sx={{ marginTop: '10px' }}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Contratante</Typography>
                            <Button 
                                variant="outlined" 
                                fullWidth 
                                sx={{ marginTop: '5px' }} 
                                onClick={() => insertTextToEditor('Nome paciente')}
                            >
                                Nome paciente
                            </Button>
                            <Button 
                                variant="outlined" 
                                fullWidth 
                                sx={{ marginTop: '5px' }} 
                                onClick={() => insertTextToEditor('Nome contratante')}
                            >
                                Nome contratante
                            </Button>
                            <Button 
                                variant="outlined" 
                                fullWidth 
                                sx={{ marginTop: '5px' }} 
                                onClick={() => insertTextToEditor('CPF contratante')}
                            >
                                CPF contratante
                            </Button>
                            <Button 
                                variant="outlined" 
                                fullWidth 
                                sx={{ marginTop: '5px' }} 
                                onClick={() => insertTextToEditor('Cidade contratante')}
                            >
                                Cidade contratante
                            </Button>
                            <Button 
                                variant="outlined" 
                                fullWidth 
                                sx={{ marginTop: '5px' }} 
                                onClick={() => insertTextToEditor('Endereço contratante')}
                            >
                                Endereço contratante
                            </Button>
                            <Button 
                                variant="outlined" 
                                fullWidth 
                                sx={{ marginTop: '5px' }} 
                                onClick={() => insertTextToEditor('Bairro contratante')}
                            >
                                Bairro contratante
                            </Button>
                            <Button 
                                variant="outlined" 
                                fullWidth 
                                sx={{ marginTop: '5px' }} 
                                onClick={() => insertTextToEditor('CEP contratante')}
                            >
                                CEP contratante
                            </Button>

                            <Typography variant="body2" sx={{ fontWeight: 'bold', marginTop: '10px' }}>Contratada</Typography>
                            <Button 
                                variant="outlined" 
                                fullWidth 
                                sx={{ marginTop: '5px' }} 
                                onClick={() => insertTextToEditor('Nome contratada')}
                            >
                                Nome contratada
                            </Button>
                            <Button 
                                variant="outlined" 
                                fullWidth 
                                sx={{ marginTop: '5px' }} 
                                onClick={() => insertTextToEditor('CNPJ/CPF contratada')}
                            >
                                CNPJ/CPF contratada
                            </Button>
                            <Button 
                                variant="outlined" 
                                fullWidth 
                                sx={{ marginTop: '5px' }} 
                                onClick={() => insertTextToEditor('Cidade contratada')}
                            >
                                Cidade contratada
                            </Button>

                            <Typography variant="body2" sx={{ fontWeight: 'bold', marginTop: '10px' }}>Tratamentos e custos</Typography>
                            <Button 
                                variant="outlined" 
                                fullWidth 
                                sx={{ marginTop: '5px' }} 
                                onClick={() => insertTextToEditor('Descrição dos tratamentos')}
                            >
                                Descrição dos tratamentos
                            </Button>
                            <Button 
                                variant="outlined" 
                                fullWidth 
                                sx={{ marginTop: '5px' }} 
                                onClick={() => insertTextToEditor('Valor contrato')}
                            >
                                Valor contrato
                            </Button>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            width: { xs: '100%', md: '75%' },
                            border: '1px solid #e4e4e4',
                            borderRadius: '10px',
                            padding: '10px',
                            overflowY: 'auto',
                            height: { xs: '250px', md: 'auto' },
                            flexGrow: 1,
                        }}
                    >
                        <ReactQuill
                            ref={quillRef}
                            value={content}
                            onChange={handleContentChange}
                            style={{ height: '100%' }}
                        />
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: '20px',
                    }}
                >
                    <Button
                        sx={{
                            textTransform: 'none',
                            color: 'black',
                            marginRight: '10px',
                        }}
                        onClick={onClose}
                    >
                        Fechar
                    </Button>
                    <Button
                        sx={{
                            textTransform: 'none',
                            backgroundColor: '#006af5',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: 'rgba(0, 106, 245, 0.7)',
                            },
                            padding: '10px 20px',
                            borderRadius: '10px',
                        }}
                        onClick={handleSave}
                    >
                        {canEdit ? "Atualizar Modelo" : "Salvar Modelo"}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default AddDocumentModal;
