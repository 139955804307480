import axios from 'axios'; 
import { Box, Button, Typography, IconButton } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import AddAnamnesisModal from '../../../components/modals/AddAnamnesisModal/index.tsx';
import { configBackendConnection, endpoints } from "../../../components/utilsBackend.ts";

const fetchAnamnesis = async (): Promise<{ id: number; name: string; selectedQuestions: any[] }[]> => {
    try {
        const response = await axios.get<{ id: number; name: string; selectedQuestions: any[] }[]>(
            `${configBackendConnection.baseURL}/${endpoints.anamnesisAPI}`, 
            {
                headers: configBackendConnection.headersDefault
            }
        );
        return response.data;
    } catch (error) {
        toast.error("Erro ao buscar os modelos de anamnese");
        return [];
    }
};

const createAnamnesis = async (anamnesisData: { name: string; selectedQuestions: any[] })
: Promise<{ id: number; name: string; selectedQuestions: any[] } | undefined> => {
    try {
        const response = await axios.post<{ id: number; name: string; selectedQuestions: any[] }>(
            `${configBackendConnection.baseURL}/${endpoints.anamnesisAPI}`, 
            anamnesisData,
            {
                headers: configBackendConnection.headersDefault
            }
        );
        toast.success("Modelo de anamnese criado com sucesso!");
        return response.data;
    } catch (error) {
        toast.error("Erro ao criar o modelo de anamnese");
    }
};

const updateAnamnesis = async (id: number, anamnesisData: { name: string; selectedQuestions: any[] })
: Promise<{ id: number; name: string; selectedQuestions: any[] } | undefined> => {
    try {
        const response = await axios.patch<{ id: number; name: string; selectedQuestions: any[] }>(
            `${configBackendConnection.baseURL}/${endpoints.anamnesisAPI}${id}/`, 
            anamnesisData,
            {
                headers: configBackendConnection.headersDefault
            }
        );
        toast.success("Modelo de anamnese atualizado com sucesso!");
        return response.data;
    } catch (error) {
        toast.error("Erro ao atualizar o modelo de anamnese");
    }
};

const deleteAnamnesis = async (id: number): Promise<void> => {
    try {
        await axios.delete(
            `${configBackendConnection.baseURL}/${endpoints.anamnesisAPI}${id}/`, 
            {
                headers: configBackendConnection.headersDefault
            }
        );
        toast.success("Modelo de anamnese excluído com sucesso!");
    } catch (error) {
        toast.error("Erro ao excluir o modelo de anamnese");
    }
};

const AnamnesisSettings: React.FC = () => {
    const [anamnesisList, setAnamnesisList] = useState<{ id: number; name: string; selectedQuestions: any[] }[]>([]);
    const [openAddAnamnesisModal, setOpenAddAnamnesisModal] = useState(false);
    const [step, setStep] = useState(1);
    const [newAnamnesis, setNewAnamnesis] = useState<{ id: number; name: string; selectedQuestions: any[] }>({
        id: -1,
        name: '',
        selectedQuestions: []
    });
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        const loadAnamnesis = async () => {
            const data = await fetchAnamnesis();
            setAnamnesisList(data);
        };
        loadAnamnesis();
    }, []);

    const handleDelete = async (id: number) => {
        await deleteAnamnesis(id);
        setAnamnesisList(prevList => prevList.filter(item => item.id !== id));
    };

    const handleSaveAnamnesis = async (anamnesisData: { id: number; name: string; selectedQuestions: any[] }) => {
        if (isEdit && anamnesisData.id > 0) {
            const updatedAnamnesis = await updateAnamnesis(anamnesisData.id, anamnesisData);
            if (updatedAnamnesis) {
                setAnamnesisList(prevList => prevList.map(item => item.id === updatedAnamnesis.id ? updatedAnamnesis : item));
            }
        } else {
            const createdAnamnesis = await createAnamnesis(anamnesisData);
            if (createdAnamnesis) {
                setAnamnesisList(prevList => [...prevList, createdAnamnesis]);
            }
        }
    };

    const handleEdit = (anamnesis: { id: number; name: string; selectedQuestions: any[] }) => {
        setNewAnamnesis(anamnesis);
        setIsEdit(true);
        setOpenAddAnamnesisModal(true);
        setStep(1);
    };

    return (
        <>
            <AddAnamnesisModal 
                open={openAddAnamnesisModal}
                setOpen={setOpenAddAnamnesisModal}
                onSave={handleSaveAnamnesis}
                step={step}
                setStep={setStep}
                form={newAnamnesis}
                setForm={setNewAnamnesis}
                isEdit={isEdit}
            />
            <Box
                sx={{
                    width: '93%',
                    marginTop: '30px',
                    borderRadius: '10px',
                    padding: '20px',
                    boxShadow: '0px 0px 5px 0px #c0c7d0',
                    backgroundColor: 'white',
                    overflow: 'hidden',
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '20px',
                            fontWeight: 'bold',
                            '@media (max-width: 548px)': {
                                fontSize: '15px'
                            }
                        }}
                    >
                        Anamnese
                    </Typography>
                    <Button
                        sx={{
                            backgroundColor: '#006af5',
                            '&:hover': {
                                backgroundColor: 'rgba(0, 106, 245, 0.7)',
                            },
                            color: 'white',
                            textTransform: 'none',
                            height: '35px',
                        }}
                        onClick={() => {
                            setNewAnamnesis({ id: -1, name: '', selectedQuestions: [] });
                            setIsEdit(false);
                            setOpenAddAnamnesisModal(true);
                            setStep(1);
                        }}
                    >
                        <Typography
                            sx={{
                                '@media (max-width: 548px)': {
                                    fontSize: '12px'
                                }
                            }}
                        >
                            Adicionar modelo de anamnese
                        </Typography>
                    </Button>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        height: '1px',
                        backgroundColor: '#e4e4e4',
                        margin: '20px 0px'
                    }}
                />
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        maxHeight: '500px',
                        overflowY: 'auto', 
                    }}
                >
                    {anamnesisList.map((anamnesis) => (
                        <Box
                            key={anamnesis.id}
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '10px',
                                borderRadius: '5px',
                                backgroundColor: '#f9f9f9',
                                boxShadow: '0px 0px 5px rgba(0,0,0,0.1)',
                                width: '100%',
                                boxSizing: 'border-box',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    color: '#333',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    maxWidth: '70%',
                                }}
                            >
                                {anamnesis.name}
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    gap: '10px',
                                    marginLeft: 'auto', 
                                }}
                            >
                                <IconButton onClick={() => handleDelete(anamnesis.id)} sx={{ color: '#ff4d4d' }}>
                                    <DeleteOutlineOutlinedIcon />
                                </IconButton>
                                <IconButton onClick={() => handleEdit(anamnesis)} sx={{ color: '#006af5' }}>
                                    <EditOutlinedIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    ))}
                </Box>
            </Box>
        </>
    );
};

export default AnamnesisSettings;
