import React, { useContext, useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Box, Button, Typography } from '@mui/material';
import logoIcon from '../../assets/images/logo_footer_landing_page.svg'
import MenuIcon from '@mui/icons-material/Menu';
import MobileSidebar from '../MobileSidebar/index.tsx';
import { AuthContext } from '../../context/AuthProvide.js';

const MobileHeader: React.FC = () => {
    // Logic for user login check
    const [isUserLoggedIn, setIsUserLoggedIn] = React.useState(false);
    const { authenticated } = useContext(AuthContext);
    useEffect(() => {authenticated && setIsUserLoggedIn(true)}, [authenticated]);
    const [openSideBar, setOpenSideBar] = useState(false);
    return (
        <>
            <Box
                sx={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    width: '100vw',
                    height: '100px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0 60px 0 60px',
                    background: 'linear-gradient(to right, #28808a, #0e3e5f 40%)',
                    '@media (min-width: 1410px)': {
                        display: 'none'
                    }
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '160px',
                        alignItems: 'center',
                    }}
                >
                    <img style={{width: '140px'}} src={logoIcon} alt={'logo'}/>
                </Box>
                <MenuIcon
                    sx={{
                        display: isUserLoggedIn ? '' : 'none',
                        color: 'white',
                        fontSize: '40px',
                        cursor: 'pointer'
                    }}
                    onClick={() => setOpenSideBar(!openSideBar)}
                />
            </Box>
            <Box
                sx={{
                    width: '100vw',
                    height: '100vh',
                    display: (isUserLoggedIn && openSideBar) ? 'flex' : 'none',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    backgroundColor: 'rgba(128, 128, 128, 0.5)',
                    zIndex: 9999, // Ensure the overlay is on top of other elements
                    '@media (min-width: 1410px)': {
                        display: 'none'
                    }
                }}
                onClick={() => setOpenSideBar(false)}
            >
                <MobileSidebar />
            </Box>
        </>
    );
};

export default MobileHeader;