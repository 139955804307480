import React, { useEffect, useState } from 'react';
import { Box, Grid, TextField, Typography, MenuItem } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { formatCEP } from '../../../utils/formatValues.tsx';
import axios from 'axios';

interface State {
    id: number;
    nome: string;
    sigla: string;
}

const AddressInfo: React.FC<{ clinicData: any, setClinicData: any }> = ({ clinicData, setClinicData }) => {
    const [states, setStates] = useState<State[]>([]);

    const fetchStates = async () => {
        const response = await axios.get<State[]>('https://servicodados.ibge.gov.br/api/v1/localidades/estados');
        return response.data;
    };

    useEffect(() => {
        const getStates = async () => {
            const statesData = await fetchStates();
            statesData.sort((a, b) => a.nome.localeCompare(b.nome));
            setStates(statesData);
        };
        getStates();
    }, []);

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const charCode = event.which ? event.which : event.keyCode;
        if (charCode < 48 || charCode > 57) {
            event.preventDefault();
        }
    };

    const handleCEPChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const formattedValue = formatCEP(event.target.value);
        setClinicData({ ...clinicData, information_clinic: { ...clinicData.information_clinic, cep: formattedValue } });
    };

    return (
        <Box sx={{ marginTop: '25px' }}>
            <Typography
                sx={{
                    fontSize: '25px',
                    fontWeight: 'bold',
                    fontFamily: 'Lato'
                }}
            >
                Informações da Clínica
            </Typography>
            <Grid container spacing={2} sx={{ marginTop: '25px' }}>
                <Grid item xs={12} md={3}>
                    <Typography sx={{ color: '#7c7c8d' }}>
                        Cep
                    </Typography>
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        placeholder="Digite aqui..."
                        autoComplete="off"
                        value={clinicData?.information_clinic?.cep}
                        onChange={handleCEPChange}
                        onKeyPress={handleKeyPress}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                height: '40px',
                                '& fieldset': {
                                    borderColor: '#e9eaec',
                                    borderRadius: '10px'
                                },
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <Typography sx={{ color: '#7c7c8d' }}>
                        Rua
                    </Typography>
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        placeholder="Digite aqui..."
                        autoComplete="off"
                        value={clinicData?.information_clinic?.street}
                        onChange={(e) => {
                            setClinicData({
                                ...clinicData,
                                information_clinic: {
                                    ...clinicData.information_clinic,
                                    street: e.target.value
                                }
                            });
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                height: '40px',
                                '& fieldset': {
                                    borderColor: '#e9eaec',
                                    borderRadius: '10px'
                                },
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <Typography sx={{ color: '#7c7c8d' }}>
                        Número
                    </Typography>
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        placeholder="Digite aqui..."
                        autoComplete="off"
                        value={clinicData?.information_clinic?.number}
                        onChange={(e) => {
                            setClinicData({
                                ...clinicData,
                                information_clinic: {
                                    ...clinicData.information_clinic,
                                    number: e.target.value
                                }
                            });
                        }}
                        onKeyPress={handleKeyPress}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                height: '40px',
                                '& fieldset': {
                                    borderColor: '#e9eaec',
                                    borderRadius: '10px'
                                },
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography sx={{ color: '#7c7c8d' }}>
                        Complemento
                    </Typography>
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        placeholder="Digite aqui..."
                        autoComplete="off"
                        value={clinicData?.information_clinic?.complement}
                        onChange={(e) => {
                            setClinicData({
                                ...clinicData,
                                information_clinic: {
                                    ...clinicData.information_clinic,
                                    complement: e.target.value
                                }
                            });
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                height: '40px',
                                '& fieldset': {
                                    borderColor: '#e9eaec',
                                    borderRadius: '10px'
                                },
                            },
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ marginTop: '25px' }}>
                <Grid item xs={12} md={4}>
                    <Typography sx={{ color: '#7c7c8d' }}>
                        Bairro
                    </Typography>
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        placeholder="Digite aqui..."
                        autoComplete="off"
                        value={clinicData?.information_clinic?.neighborhood}
                        onChange={(e) => {
                            setClinicData({
                                ...clinicData,
                                information_clinic: {
                                    ...clinicData.information_clinic,
                                    neighborhood: e.target.value
                                }
                            });
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                height: '40px',
                                '& fieldset': {
                                    borderColor: '#e9eaec',
                                    borderRadius: '10px'
                                },
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography sx={{ color: '#7c7c8d' }}>
                        Cidade
                    </Typography>
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        placeholder="Digite aqui..."
                        autoComplete="off"
                        value={clinicData?.information_clinic?.city}
                        onChange={(e) => {
                            setClinicData({
                                ...clinicData,
                                information_clinic: {
                                    ...clinicData.information_clinic,
                                    city: e.target.value
                                }
                            });
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                height: '40px',
                                '& fieldset': {
                                    borderColor: '#e9eaec',
                                    borderRadius: '10px'
                                },
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography sx={{ color: '#7c7c8d' }}>
                        Estado
                    </Typography>
                    <Autocomplete
                        id="estado-autocomplete"
                        options={states}
                        getOptionLabel={(option: State) => option.nome}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Selecione o estado"
                                variant="outlined"
                                size="small"
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        height: '40px',
                                        '& fieldset': {
                                            borderColor: '#e9eaec',
                                            borderRadius: '10px'
                                        },
                                    },
                                }}
                            />
                        )}
                        onChange={(event, newValue) => {
                            setClinicData({
                                ...clinicData,
                                information_clinic: {
                                    ...clinicData.information_clinic,
                                    state: newValue ? newValue.nome : ''
                                }
                            });
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}

export default AddressInfo;
