import React from "react"
import { useNavigate } from "react-router-dom"

interface DropdownProps {
    options: string[],
    path: string[],
    className: string
}

const Dropdown: React.FC<DropdownProps> = ({options, path, className}) => {
    
    const navigate = useNavigate();

    return(
        <>
            <div className={className}>
                {options.map((option, index) => (
                    <div key={index}>
                        <a onClick={() => navigate(path[index])}>{option}</a>
                    </div>
                ))}
            </div>

        </>
    )
}

export default Dropdown;