import React, { useEffect, useState } from 'react';
import { keyframes } from '@emotion/react';
import { Box, Button, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Header from '../../components/header/index.tsx';
import Sidebar from '../../components/sidebar/index.tsx';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardReturnOutlinedIcon from '@mui/icons-material/KeyboardReturnOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import dayjs from 'dayjs';
import TransactionTotalBar from '../../components/TransactionTotalBar/index.tsx';
import TransactionsTable from '../../components/TransactionsTable/index.tsx';
import AddOutcomeModal from '../../components/modals/AddOutcomeModal/index.tsx';
import AddIncomeModal from '../../components/modals/AddIncomeModal/index.tsx';
import PageContentContainer from '../../components/PageContentContainer/index.tsx';
import MobileHeader from '../../components/MobileHeader/index.tsx';

//DROPDOWN ANIMATIONS
const revealDown = keyframes`
  from {
    clip-path: inset(0 0 100% 0);
    opacity: 0;
  }
  to {
    clip-path: inset(0 0 0 0);
    opacity: 1;
  }
`;
const hideUp = keyframes`
  from {
    clip-path: inset(0 0 0 0);
    opacity: 1;
  }
  to {
    clip-path: inset(0 0 100% 0);
    opacity: 0;
  }
`;

const MOCKED_TRANSACTIONS = [
    {
        type: 'receita',
        value: 1000,
        description: 'Salário',
        date: '2021-10-01'
    },
    {
        type: 'receita',
        value: 200,
        description: 'Venda de carro',
        date: '2021-10-03'
    },
    {
        type: 'despesa',
        value: 400,
        description: 'Conta de luz',
        date: '2021-10-05'
    },
    {
        type: 'despesa',
        value: 100,
        description: 'Conta de água',
        date: '2021-10-07'
    },
    {
        type: 'receita',
        value: 300,
        description: 'Venda de bicicleta',
        date: '2021-10-10'
    },
    {
        type: 'despesa',
        value: 200,
        description: 'Conta de internet',
        date: '2021-10-15'
    },
    {
        type: 'receita',
        value: 500,
        description: 'Venda de celular',
        date: '2021-10-20'
    },
    {
        type: 'despesa',
        value: 700,
        description: 'Compra de celular',
        date: '2024-10-25'
    },
    {
        type: 'receita',
        value: 100,
        description: 'Venda de livro',
        date: '2024-10-30'
    },
    {
        type: 'despesa',
        value: 200,
        description: 'Compra de livro',
        date: '2021-11-01'
    },
    {
        type: 'receita',
        value: 400,
        description: 'Venda de computador',
        date: '2024-11-05'
    },
    {
        type: 'despesa',
        value: 100,
        description: 'Compra de computador',
        date: '2021-11-10'
    },
    {
        type: 'receita',
        value: 200,
        description: 'Venda de geladeira',
        date: '2021-11-15'
    },
]

const FinancialPage: React.FC = () => {
    const [dropdownAddButton, setDropdownAddButton] = useState(false);
    const [addModal, setAddModal] = useState(false);
    const [typeOfTransaction, setTypeOfTransaction] = useState('');
    const [animationKey, setAnimationKey] = useState('');
    const [totalValues, setTotalValues] = useState<{
        total: number,
        totalPredicted: number,
        income: {
            total: number,
            incoming: number,
            predicted: number,
        },
        outcome: {
            total: number,
            pendingPayment: number,
            predicted: number,
        },
    }>({
        total: 0,
        totalPredicted: 0,
        income: {
            total: 0,
            incoming: 0,
            predicted: 0,
        },
        outcome: {
            total: 0,
            pendingPayment: 0,
            predicted: 0,
        },
    })
    const today = dayjs();

    useEffect(() => {
        let totalIncome = 0;
        let totalIncomingIncome = 0;
        let totalOutcome = 0;
        let totalPendingPaymentOutcome = 0;

        MOCKED_TRANSACTIONS.forEach(transaction => {
            if (transaction.type === 'receita') {
                if(today.isBefore(dayjs(transaction.date))) {
                    totalIncomingIncome += transaction.value;
                } else {
                    totalIncome += transaction.value;
                }
            } else {
                if(today.isBefore(dayjs(transaction.date))) {
                    totalPendingPaymentOutcome += transaction.value;
                } else {
                    totalOutcome += transaction.value;
                }
            }
        });

        setTotalValues({
            total: totalIncome - totalOutcome,
            totalPredicted: (totalIncome + totalIncomingIncome) - (totalOutcome + totalPendingPaymentOutcome), 
            income: {
                total: totalIncome,
                incoming: totalIncomingIncome,
                predicted: totalIncome + totalIncomingIncome
            },
            outcome: {
                total: totalOutcome,
                pendingPayment: totalPendingPaymentOutcome,
                predicted: totalOutcome + totalPendingPaymentOutcome,
            }
        })
    },[])

    const toggleDropdown = () => {
        if (dropdownAddButton) {
            setAnimationKey(hideUp);
            setTimeout(() => setDropdownAddButton(false), 100); // delay to allow animation to complete
        } else {
            setDropdownAddButton(true);
            setAnimationKey(revealDown);
        }
    };

    return (
        <>
            <AddOutcomeModal
                open={typeOfTransaction === 'despesa' && addModal}
                onClose={() => {
                    setAddModal(false);
                    setTypeOfTransaction('')
                }}
            />
            <AddIncomeModal 
                open={typeOfTransaction === 'receita' && addModal}
                onClose={() => {
                    setAddModal(false);
                    setTypeOfTransaction('')
                }}
            />
            <Header />
            <MobileHeader />
            <Sidebar />
            <PageContentContainer>
                <Box
                    sx={{
                        width: '95%',
                        height: '150px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        rowGap: '20px',
                        paddingBottom: '20px',
                        '@media (max-width: 900px)': {
                            height: '200px',
                            justifyContent: 'center'
                        }
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            rowGap: '20px',
                            marginTop: '20px',
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Lato',
                                fontWeight: 'bold',
                                fontSize: '21px',
                                color: '#717c89',
                                '@media (max-width: 808px)': {
                                    fontSize: '15px'
                                },
                            }}
                        >
                            Exibindo o financeiro de
                        </Typography>
                        <Box
                            sx={{
                                maxWidth: '40%',
                                minWidth: '190px',
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <DatePicker
                                label="De"
                                sx={{
                                    width: '49%',
                                    minWidth: '90px',
                                    marginRight: '3px'
                                }}
                            />
                            <DatePicker
                                label="Até"
                                sx={{
                                    width: '49%',
                                    minWidth: '90px',
                                }}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                width: '20%',
                                maxWidth: '130px',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Button
                                sx={{
                                    backgroundColor: '#006af5',
                                    color: 'white',
                                    width: '50px',
                                    height: '45px',
                                    minWidth: '10px',
                                    '@media (max-width: 764px)': {
                                        width: '30px',
                                        height: '30px',
                                    }
                                }}
                            >
                                <SearchIcon
                                    sx={{
                                        fontSize: '30px',
                                        '@media (max-width: 764px)': {
                                            fontSize: '18px'
                                        }
                                    }}
                                />
                            </Button>
                            <Box
                                sx={{
                                    width: '50px',
                                    height: '45px',
                                    backgroundColor: '#03659a',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    color: 'white',
                                    cursor: 'pointer',
                                    '@media (max-width: 764px)': {
                                        width: '30px',
                                        height: '30px',
                                    }
                                }}
                            >
                                <MenuIcon
                                    sx={{
                                        fontSize: '30px',
                                        '@media (max-width: 764px)': {
                                            fontSize: '18px'
                                        }
                                    }}
                                />
                            </Box>
                        </Box>
                        <Button
                            sx={{
                                textTransform: 'none',
                                color: '#005fdb',
                                fontFamily: 'Lato',
                                fontWeight: 'bold',
                                backgroundColor: '#e0e2e4'
                            }}
                        >Exportar Arquivo</Button>
                    </Box>
                    <Box
                        sx={{
                            backgroundColor: 'green',
                            height: '40px',
                            position: 'relative',
                        }}
                    >
                        <Button
                            sx={{
                                textTransform: 'none',
                                backgroundColor: '#006af5',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 106, 245, 0.7)',
                                },
                                color: 'white',
                                width: '150px',
                                height: '40px',
                                borderRadius: '5px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                            onClick={toggleDropdown}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '23px',
                                        margin: '-5px 10px 0px 0px'
                                    }}
                                >
                                    +
                                </Typography>
                                <Typography>
                                    Adicionar
                                </Typography>
                            </Box>
                            <KeyboardArrowDownIcon />
                        </Button>
                        <Box
                            sx={{
                                width: '150px',
                                height: '80px',
                                backgroundColor: 'white',
                                position: 'absolute',
                                display: dropdownAddButton ? 'flex' : 'none',
                                justifyContent: 'space-between',
                                flexDirection: 'column',
                                boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)',
                                borderRadius: '5px',
                                animation: `${animationKey} 0.1s ease-out`,
                                padding: '5px'
                            }}
                        >
                            <Button
                                sx={{
                                    width: '100%',
                                    height: '49%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    borderRadius: '5px',
                                    textTransform: 'none',
                                    color: '#d00303'
                                }}
                                onClick={() => {
                                    setTypeOfTransaction('despesa');
                                    setAddModal(true)
                                }}
                            >
                                <KeyboardReturnOutlinedIcon
                                    sx={{
                                        transform: 'rotate(270deg)', // Rotate the icon 90 degrees
                                    }}
                                />
                                <Typography>
                                    Despesas
                                </Typography>
                            </Button>
                            <Button
                                sx={{
                                    width: '100%',
                                    height: '49%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    borderRadius: '5px',
                                    textTransform: 'none',
                                    color: '#20a200',
                                }}
                                onClick={() => {
                                    setTypeOfTransaction('receita');
                                    setAddModal(true)
                                }}
                            >
                                <KeyboardReturnOutlinedIcon
                                    sx={{
                                        transform: 'rotate(90deg)', // Rotate the icon 90 degrees
                                    }}
                                />
                                <Typography>
                                    Receitas
                                </Typography>
                            </Button>

                        </Box>
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: '95%',
                        height: '700px',
                        backgroundColor: 'white',
                        boxShadow: '0px 0px 5px 0px #e3e5e8',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '60px 60px 0px 60px',
                            marginBottom: '40px',
                            '@media ( max-width: 658px)': {
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                padding: '20px 20px 0px 20px',
                            }
                        }}
                    >
                        <TransactionTotalBar
                            title="Receitas"
                            incoming={totalValues.income.incoming}
                            incomingText='A receber'
                            totalPredicted={totalValues.income.predicted}
                            value={totalValues.income.total}
                            progress={(totalValues.income.total * 100) / (totalValues.outcome.total + totalValues.income.total)}
                            color={'#20a200'}
                        />
                        <Typography
                            sx={{
                                fontSize: '30px',
                                fontWeight: 'bold',
                                '@media ( max-width: 658px)': {
                                    display: 'none'
                                }
                            }}
                        >-</Typography>
                        <TransactionTotalBar 
                            title="Despesas"
                            incoming={totalValues.outcome.pendingPayment}
                            incomingText='A pagar'
                            totalPredicted={totalValues.outcome.predicted}
                            value={totalValues.outcome.total}
                            progress={(totalValues.outcome.total * 100) / (totalValues.outcome.total + totalValues.income.total)}
                            color={'#d00303'}
                        />
                        <Typography
                            sx={{
                                fontSize: '30px',
                                fontWeight: 'bold',
                                '@media ( max-width: 658px)': {
                                    display: 'none'
                                }
                            }}
                        >=</Typography>
                        <TransactionTotalBar
                            title="Saldos"
                            totalPredicted={totalValues.totalPredicted}
                            value={totalValues.total}
                            progress={((totalValues.income.total - totalValues.outcome.total) * 100) / (totalValues.income.total + totalValues.outcome.total)}
                            color={'#0042a4'}
                        />
                    </Box>
                    <Box>
                        <TransactionsTable
                            rows={MOCKED_TRANSACTIONS.map((transaction, index) => {
                                return {
                                    id: index,
                                    date: dayjs(transaction.date).toDate(),
                                    name: transaction.description,
                                    value: transaction.type === 'despesa' ? (-transaction.value) : (transaction.value),
                                }
                            })}
                        />
                    </Box>
                </Box>
            </PageContentContainer>
        </>
    );
};

export default FinancialPage;
