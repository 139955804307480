import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import SidebarOption from './sidebarOption/index.tsx';
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import PersonIcon from '@mui/icons-material/Person';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import StorageSharpIcon from '@mui/icons-material/StorageSharp';
import { useLocation, useNavigate } from 'react-router-dom';

const coinPilesIcon = require('../../assets/icons/coin-piles.png')
const commentsIcon = require('../../assets/icons/comments.png')
const settingsIcon = require('../../assets/icons/settings.png')

const optionByRoute = {
    '/intelligence': 0,
    '/agenda': 2,
    '/financial': 3,
    '/comunication': 4,
    '/storage': 5,
    '/settings': 6,
}

const Sidebar: React.FC = () => {

    const location = useLocation();
    const navigate = useNavigate();

    const [
        selectedSidebarOption,
        setSelectedSidebarOption
    ] = useState<number>(-1);

    useEffect(() => {
        const path = location.pathname;
        setSelectedSidebarOption(optionByRoute[path]);
    },[location.pathname])

    return (
        <Box
            sx={{
                position: 'absolute',
                left: 0,
                top: '100px',
                width: '250px',
                height: 'calc(100% - 100px)',
                padding: '30px 0px 60px 0px',
                background: 'linear-gradient(to bottom, #0c5c59, #0e6f6b 40%)',
                '@media (max-width: 1410px)': {
                    display: 'none'
                }
            }}
        >
            <SidebarOption 
                selected={selectedSidebarOption === 0}
                onSelect={() => {
                    setSelectedSidebarOption(0)
                    navigate('/intelligence')
                }}
                title={'Inteligência'}
                icon={<BarChartRoundedIcon />}
            />
            <SidebarOption 
                selected={selectedSidebarOption === 1}
                onSelect={() => {
                    setSelectedSidebarOption(1)
                    navigate('/pacientes')
                }}
                title={'Pacientes'}
                icon={<PersonIcon />}
            />
            <SidebarOption 
                selected={selectedSidebarOption === 2}
                onSelect={() => {
                    setSelectedSidebarOption(2)
                    navigate('/agenda')
                }}
                title={'Agenda'}
                icon={<CalendarMonthIcon />}
            />
            <SidebarOption 
                selected={selectedSidebarOption === 3}
                onSelect={() => {
                    setSelectedSidebarOption(3)
                    navigate('/financial')
                }}
                title={'Financeiro'}
                icon={
                    <img
                        style={{
                            width: '26px',
                            height: '26px',
                        }}
                        src={coinPilesIcon} 
                        alt={'coinPiles'} 
                    />
                }
            />
            <SidebarOption 
                selected={selectedSidebarOption === 4}
                onSelect={() => {
                    setSelectedSidebarOption(4)
                    navigate('/comunication')
                }}
                title={'Comunicação'}
                icon={
                    <img
                        style={{
                            width: '26px',
                            height: '26px',
                        }}
                        src={commentsIcon} 
                        alt={'comments'} 
                    />
                }
            />
            <SidebarOption 
                selected={selectedSidebarOption === 5}
                onSelect={() => {
                    setSelectedSidebarOption(5)
                    navigate('/storage')
                }}
                title={'Estoque'}
                icon={<StorageSharpIcon />}
            />
            <SidebarOption 
                selected={selectedSidebarOption === 6}
                onSelect={() => {
                    setSelectedSidebarOption(6)
                    navigate('/settings')
                }}
                title={'Ajustes'}
                icon={
                    <img
                        style={{
                            width: '26px',
                            height: '26px',
                        }}
                        src={settingsIcon} 
                        alt={'settings'}
                    />
                }
            />
        </Box>
    );
};

export default Sidebar;