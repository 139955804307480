import { Box, Button, Checkbox, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import SpeedIcon from '@mui/icons-material/Speed';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import React, { useEffect } from 'react';
import { ICampaignTypes } from '../../../types/comunication';

const cellPhoneImage = require('../../../assets/images/non_licenced_cellphone.png')

const campaignTypeNames = {
    'late-payments': 'Débitos em atraso',
    'postoperative': 'Pós-operatório',
    'payment-due': 'Vencimento de boleto',
    'dental-floss': 'Fio dental',
    'inactive-retrieval': 'Recuperação de inativos',
    'satisfaction': 'Satisfação',
}
const possiblePlanFilters = [ 'Todos', 'Básico', 'Intermediário', 'Avançado']
const possibleGenderFilters = [ 'Todos', 'Masculino', 'Feminino']
const possibleSpecialtyFilters = [ 'Todos', 'Clínico Geral', 'Ortodontia', 'Implantodontia', 'Endodontia', 'Periodontia', 'Odontopediatria', 'Cirurgia', 'Estética', 'Prótese', 'Outros']
const possiblePaymentLateDateFilters = [ '30 dias', '60 dias', '90 dias', '120 dias']

const DefinePublicStep: React.FC<{
    selectedCampaignType: string,
    goToNextStep: () => void;
    goToPreviousStep: () => void;
    campaignTypeNames: ICampaignTypes;
    cellPhoneMessage: ICampaignTypes;
    paymentLateDateFilter: string;
    setPaymentLateDateFilter: (value: string) => void;
    planFilter: string;
    setPlanFilter: (value: string) => void;
    specialtyFilter: string;
    setSpecialtyFilter: (value:string) => void;
    genderFilter: string;
    setGenderFilter: (value: string) => void;
    sendToDebtors: boolean;
    setSendToDebtors: (value:boolean) => void;
    noConsultationsIn: string;
    setNoConsultationsIn: (value:string) => void;
    showDebtorMessage: boolean;
    setShowDebtorMessage: (value: boolean) => void;
    cancel: () => void
}> = ({
    selectedCampaignType,
    goToNextStep,
    goToPreviousStep,
    cellPhoneMessage,
    paymentLateDateFilter,
    setPaymentLateDateFilter,
    planFilter,
    setPlanFilter,
    specialtyFilter,
    setSpecialtyFilter,
    genderFilter,
    setGenderFilter,
    sendToDebtors,
    setSendToDebtors,
    noConsultationsIn,
    setNoConsultationsIn,
    showDebtorMessage,
    setShowDebtorMessage,
    cancel
}) => {

    const [canContinue, setCanContinue] = React.useState(false);
    
    const cleanStates = () => {
        setPaymentLateDateFilter(possiblePaymentLateDateFilters[0])
        setPlanFilter(possiblePlanFilters[0])
        setSpecialtyFilter(possibleSpecialtyFilters[0])
        setGenderFilter(possibleGenderFilters[0])
        setNoConsultationsIn('')
        setSendToDebtors(false)
        setCanContinue(false)
    }

    useEffect(() => {
        if(selectedCampaignType === 'inactive-retrieval'){
            if(noConsultationsIn === ''){
                setCanContinue(false)
            }else{
                setCanContinue(true)
            }
        } else {
            setCanContinue(true)
        }
    },[
        paymentLateDateFilter,
        noConsultationsIn,
        selectedCampaignType
    ])

    return (
        <>
            <Box
                sx={{
                    marginTop: '40px',
                    width: '95%',
                    backgroundColor: 'white',
                    borderRadius: '15px',
                    padding: '40px 0px 20px 50px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    '@media (max-width: 682px)': {
                        padding: '30px 0 0 0',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }
                }}
            >
                <Box 
                    sx={{
                        width: '90%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                    }}
                >
                    <Typography
                        sx={{
                            color: '#03659a',
                            fontSize: 28,
                            fontFamily: 'Lato',
                            maxWidth: '80%',
                            textAlign: 'center',
                            fontWeight: 'bold',
                            transition: 'color 0.3s ease',
                        }}
                    >Definir público</Typography>
                    {   selectedCampaignType !== 'satisfaction' ? (
                            <>
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                        color: '#77828f',
                                        marginTop: '20px',
                                    }}
                                >
                                    Define o público que você deseja alcançar com a
                                    campanha de <span
                                        style={{
                                            fontWeight: 'bold',
                                        }}
                                    >{campaignTypeNames[selectedCampaignType]}</span>.
                                    Você pode segmentar seu público por um ou mais
                                    planos, especialidades de tratamentos ou gênero.
                                </Typography>
                                {selectedCampaignType === 'inactive-retrieval' && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            width: '100%',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            flexWrap: 'wrap',
                                            marginTop: '20px',

                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '16px',
                                                color: '#77828f',
                                            }}
                                        >
                                            Enviar campanha para <span
                                                style={{
                                                    color: '#03659a'
                                                }}
                                            >os pacientes sem consultas marcadas há
                                            </span>
                                        </Typography>
                                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                                            <Checkbox
                                                checked={noConsultationsIn === '6 meses'}
                                                onChange={(e) => {
                                                    if(noConsultationsIn === '6 meses'){
                                                        setNoConsultationsIn('')
                                                    }else{
                                                        setNoConsultationsIn('6 meses')
                                                    }
                                                }}
                                                icon={<RadioButtonUncheckedIcon />}
                                                checkedIcon={
                                                    <RadioButtonCheckedIcon
                                                        sx={{ color:'#03659a'}}
                                                    />
                                                }
                                            />
                                            <Typography>6 meses</Typography>
                                        </Box>
                                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                                            <Checkbox
                                                checked={noConsultationsIn === '12 meses'}
                                                onChange={(e) => {
                                                    if(noConsultationsIn === '12 meses'){
                                                        setNoConsultationsIn('')
                                                    }else{
                                                        setNoConsultationsIn('12 meses')
                                                    }
                                                }}
                                                icon={<RadioButtonUncheckedIcon />}
                                                checkedIcon={
                                                    <RadioButtonCheckedIcon
                                                        sx={{ color:'#03659a'}}
                                                    />
                                                }
                                            />
                                            <Typography>12 meses</Typography>
                                        </Box>
                                    </Box>
                                )}
                                {selectedCampaignType === 'late-payments' && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            width: '100%',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            flexWrap: 'wrap',
                                            marginTop: '20px',

                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '16px',
                                                color: '#77828f',
                                            }}
                                        >
                                            Enviar campanha para <span
                                                style={{
                                                    color: '#03659a'
                                                }}
                                            >os pacientes sem consultas marcadas há
                                            </span>
                                        </Typography>
                                        <FormControl
                                            sx={{
                                                marginLeft: '20px',
                                                width: '130px',
                                            }}
                                        >
                                            <Select
                                                value={paymentLateDateFilter}
                                                onChange={(event) => {
                                                    setPaymentLateDateFilter(event.target.value as string)
                                                }}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                sx={{
                                                    height: '40px',
                                                    borderRadius: '15px'
                                                }}
                                            >
                                                {possiblePaymentLateDateFilters.map((filter) => (
                                                    <MenuItem key={filter} value={filter}>{filter}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                )}
                                <Box
                                    sx={{
                                        marginTop: '30px',
                                        width: '100%',
                                        border: '1px solid #e2e2e2',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        padding: '15px'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            flexWrap: 'wrap',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'flex-start',
                                                width: '30%',
                                                minWidth: '100px',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: '#a5a5a5',
                                                    fontSize: '16px',
                                                    display: 'flex',
                                                }}
                                            >
                                                Plano<Typography color='red'>*</Typography>
                                            </Typography>
                                            <FormControl fullWidth >
                                                <Select
                                                    value={planFilter}
                                                    onChange={(event) => {
                                                        setPlanFilter(event.target.value as string)
                                                    }}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                    sx={{
                                                        height: '50px',
                                                        borderRadius: '10px',
                                                    }}
                                                >
                                                    {possiblePlanFilters.map((filter) => (
                                                        <MenuItem key={filter} value={filter}>{filter}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'flex-start',
                                                width: '30%',
                                                minWidth: '100px',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: '#a5a5a5',
                                                    fontSize: '16px',
                                                    display: 'flex',
                                                }}
                                            >
                                                Especialidade<Typography color='red'>*</Typography>
                                            </Typography>
                                            <FormControl fullWidth>
                                                <Select
                                                    value={specialtyFilter}
                                                    onChange={(event) => {
                                                        setSpecialtyFilter(event.target.value as string)
                                                    }}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                    sx={{
                                                        height: '50px',
                                                        borderRadius: '10px',
                                                    }}
                                                >
                                                    {possibleSpecialtyFilters.map((filter) => (
                                                        <MenuItem key={filter} value={filter}>{filter}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'flex-start',
                                                width: '30%',
                                                minWidth: '100px',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: '#a5a5a5',
                                                    fontSize: '16px',
                                                    display: 'flex',
                                                }}
                                            >
                                                Gênero<Typography color='red'>*</Typography>
                                            </Typography>
                                            <FormControl fullWidth>
                                                <Select
                                                    value={genderFilter}
                                                    onChange={(event) => {
                                                        setGenderFilter(event.target.value as string)
                                                    }}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                    sx={{
                                                        height: '50px',
                                                        borderRadius: '10px',
                                                    }}
                                                >
                                                    {possibleGenderFilters.map((filter) => (
                                                        <MenuItem key={filter} value={filter}>{filter}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginTop: '20px',
                                        }}
                                    >
                                        <Checkbox
                                            checked={sendToDebtors}
                                            onChange={() => setSendToDebtors(!sendToDebtors)}
                                        />
                                        <Typography
                                            sx={{
                                                color: '#5b5b5b'
                                            }}
                                        >
                                            Não enviar para clientes inadimplentes
                                        </Typography>
                                    </Box>
                                </Box>
                            </>
                        ) : (
                            <>
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                        marginTop: '20px',
                                        color: '#77828f',
                                    }}
                                >
                                    Ao ativar a campanha de satisfação, o sistema envia
                                    automaticamente a mensagem para 40% dos pacientes
                                    que forem atendidos
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                        marginTop: '20px',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: '#03659a',
                                            fontSize: 28,
                                            fontFamily: 'Lato',
                                            maxWidth: '80%',
                                            textAlign: 'center',
                                            fontWeight: 'bold',
                                            transition: 'color 0.3s ease',
                                        }}
                                    >Campanha de SATISFAÇÃO</Typography>
                                    <Typography
                                        sx={{
                                            fontSize: '16px',
                                            color: '#77828f',
                                            marginTop: '20px',
                                        }}
                                    >
                                        A campanha de Satisfação é baseada na
                                        metodologia NPS (Net Promoter Score),
                                        essa pesquisa envia WhatsApp para 40%
                                        dos pacientes atendidos, perguntando
                                        qual a nota do seu atendimento de 1 a 10
                                        Caso a nota seja ruim (abaixo de 6) uma
                                        nova mensagem é enviada pedindo
                                        sugestões para melhorar o atendimento
                                    </Typography>
                                    <Box
                                        sx={{
                                            marginTop: '30px',
                                            width: '100%',
                                            border: '1px solid #e2e2e2',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-between',
                                            padding: '15px',
                                            transition: 'height 1s ease',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'flex-end'
                                                }}
                                            >
                                                <SpeedIcon
                                                    sx={{
                                                        color: '#03659a',
                                                        fontSize: '30px',
                                                    }}
                                                />
                                                <Typography
                                                    sx={{
                                                        color: '#656565',
                                                        fontSize: '20px',
                                                        marginLeft: '10px'
                                                    }}
                                                >
                                                    Mensagem para defratores
                                                </Typography>
                                            </Box>
                                            {showDebtorMessage ? (
                                                <KeyboardArrowUpIcon 
                                                    onClick={() => setShowDebtorMessage(!showDebtorMessage)}
                                                />
                                            )
                                            : (
                                                <KeyboardArrowDownIcon
                                                    onClick={() => setShowDebtorMessage(!showDebtorMessage)}
                                                />
                                            )}
                                        </Box>
                                        {showDebtorMessage &&
                                            <Typography>
                                                Que pena, João. O que podemos
                                                fazer diferente para aumentar a
                                                sua nota? Obrigado.
                                            </Typography>
                                        }
                                    </Box>
                                </Box>
                            </>
                        )
                    }
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography>Como seu paciente irá receber 👇</Typography>
                    <Box sx={{ position: 'relative', display: 'inline-block' }}>
                        <img
                            style={{
                                width: '500px',
                            }}
                            src={cellPhoneImage}
                            alt={'celular'}
                        />
                        <Box
                            sx={{
                                position: 'absolute',
                                width: '240px',
                                top: '30%',
                                left: '50%',
                                transform: 'translateX(-50%)',
                                backgroundColor: 'white',
                                padding: '8px 16px',
                                borderRadius: '8px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <Box sx={{display: 'flex'}}>
                                    <EmailOutlinedIcon
                                        sx={{
                                            fontSize: '15px'
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            fontWeight: 'bold',
                                            fontSize: '12px',
                                            marginLeft: '2px'
                                        }}
                                    >Mensagem</Typography>
                                </Box>
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: '12px'
                                    }}
                                >agora</Typography>
                            </Box>
                            <Typography
                                sx={{
                                    fontSize: '12px',
                                    marginTop: '5px'
                                }}
                            >
                                {cellPhoneMessage[selectedCampaignType]}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    width: '95%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '40px',
                    flexWrap: 'wrap'
                }}
            >
                <Box sx={{display: 'flex', alignItems: 'center', minWidth: '120px'}}>
                    <Button
                        sx={{
                            color: '#262626',
                            width: '120px',
                            height: '40px',
                            borderRadius: '5px',
                            marginTop: '50px',
                            textTransform: 'none',
                        }}
                        onClick={() => {
                            cleanStates()
                            goToPreviousStep()
                        }}
                    >
                        Voltar
                    </Button>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center', minWidth: '120px'}}>
                    <Button
                        sx={{
                            color: '#262626',
                            width: '120px',
                            height: '40px',
                            borderRadius: '5px',
                            marginTop: '50px',
                            textTransform: 'none',
                        }}
                        onClick={cancel}
                    >
                        Cancelar
                    </Button>
                    <Button
                        sx={{
                            backgroundColor: canContinue ? '#006af5' : '#d8d8d8',
                            '&:hover': {
                                backgroundColor: canContinue ?
                                'rgba(0, 106, 245, 0.7)' :
                                'rgba(216, 216, 216, 0.7)',
                            },
                            color: canContinue ? 'white' : '#838383',
                            width: '120px',
                            height: '40px',
                            borderRadius: '5px',
                            marginTop: '50px',
                            textTransform: 'none',
                        }}
                        onClick={() => {
                            if(canContinue){
                                goToNextStep()
                            }
                        }}
                    >
                        Continuar
                    </Button>
                </Box>
            </Box>
        </>
    )
}

export default DefinePublicStep;