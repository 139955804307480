import { Box, Button, Checkbox, FormControl, InputLabel, MenuItem, Modal, Select, TextField, Typography, styled } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { IModalProps } from '../../../types/modals';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';

const MOCKED_PLANS = [
    'Unimed',
    'Unimed Odonto',
    'Uniodonto',
    'Bradesco Dental',
    'Amil Dental',
    'OdontoPrev',
    'SulAmérica Odonto',
    'Porto Seguro Odonto',
];

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const AddIncomeModal: React.FC<IModalProps> = ({
    open,
    onClose
}) => {
    const fileInputRef = useRef(null)
    const [form, setForm] = useState<{
        patient: string,
        dueDate: any,
        observation: string,
        receipt: string,
        treatments: {
            plan: string,
            name: string,
            teeth: string,
            value: number,
        }[],
    }>({
        patient: '',
        dueDate: '',
        observation: '',
        receipt: '',
        treatments: [
            {
                plan: '',
                name: '',
                teeth: '',
                value: 0,
            }
        ]
    });
    
    const ResetForm = () => {
        setForm({
            patient: '',
            dueDate: '',
            observation: '',
            receipt: '',
            treatments: [
                {
                    plan: '',
                    name: '',
                    teeth: '',
                    value: 0,
                }
            ]
        })
    }

    const handleFileChange = (event) => {// Este método é chamado ao anexar um arquivo
        const file = event.target.files[0];
        if (file) {
            console.log('Arquivo selecionado:', file);
            // Aqui é onde voce deve mexer com o arquivo. Enviando pra servidor ou algo assim
            setForm({...form, receipt: file})
        }
    };

    useEffect(() => {
        console.log(form)
    },[form])

    return (
        <Modal
            open={open}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box
                sx={{
                    width: '1100px',
                    height: '800px',
                    backgroundColor: 'white',
                    padding: '35px',
                }}
            >
                <Typography
                    sx={{
                        fontSize: '22px',
                        fontWeight: 'bold',
                        fontFamily: 'Lato',
                    }}
                >Novo Recebimento</Typography>
                <Box
                    sx={{
                        height: '620px',
                        overflowY: 'auto',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '30px',
                            marginBottom: '15px',
                            flexWrap: 'wrap',
                            rowGap: '15px'
                        }}
                    >
                        <Box sx={{width: '69%', minWidth: '200px'}}>
                            <Typography
                                sx={{
                                    fontSize: '18px',
                                    fontFamily: 'Lato',
                                    color: '#7c7c8d'
                                }}
                            >Paciente<span style={{color: 'red'}}>*</span></Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                placeholder='Digite aqui...'
                                autoComplete='off'
                                value={form.patient}
                                onChange={(e) => {
                                    setForm({...form, patient: e.target.value});
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#e9eaec',
                                            borderRadius: '10px'
                                        },
                                    },
                                }}
                            />
                        </Box>
                        <Box sx={{width: '30%', minWidth: '200px'}}>
                            <Typography
                                sx={{
                                    fontSize: '18px',
                                    fontFamily: 'Lato',
                                    color: '#7c7c8d',
                                }}
                            >Data de vencimento<span style={{color: 'red'}}>*</span></Typography>
                            <DatePicker
                                label="Selecione..."
                                sx={{
                                    width: '100%'
                                }}
                                onChange={(date) => {
                                    setForm({...form, dueDate: date.format('YYYY-MM-DD')});
                                }}
                            />
                        </Box>
                    </Box>
                    {form.treatments.map((treatment, index) => (
                        <Box
                            sx={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                border: '1px solid #e9eaec',
                                padding: '20px',
                                marginBottom: '15px',
                                flexWrap: 'wrap',
                            }}
                        >
                            <Box sx={{width: '24%', minWidth: '120px'}}>
                                <Typography
                                    sx={{
                                        fontSize: '18px',
                                        fontFamily: 'Lato',
                                        color: '#7c7c8d'
                                    }}
                                >Plano<span style={{color: 'red'}}>*</span></Typography>
                                <Select
                                    sx={{
                                        height: '52px',
                                        color: 'black',
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#e9eaec',
                                        },
                                        border: '10px',
                                    }}
                                    fullWidth
                                    value={treatment.plan}
                                    onChange={(e) => {
                                        setForm({
                                            ...form,
                                            treatments: form.treatments.map((t, i) => {
                                                if(i === index){
                                                    return {...t, plan: e.target.value}
                                                }
                                                return t
                                            })
                                        });
                                    }}
                                >
                                    {MOCKED_PLANS.map(plan =>
                                        <MenuItem key={plan} value={plan}>
                                            {plan}
                                        </MenuItem>
                                    )}
                                </Select>
                            </Box>
                            <Box sx={{width: '24%', minWidth: '120px'}}>
                                <Typography
                                    sx={{
                                        fontSize: '18px',
                                        fontFamily: 'Lato',
                                        color: '#7c7c8d'
                                    }}
                                >Tratamento<span style={{color: 'red'}}>*</span></Typography>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder='Digite aqui...'
                                    autoComplete='off'
                                    value={treatment.name}
                                    onChange={(e) => {
                                        setForm({
                                            ...form,
                                            treatments: form.treatments.map((t, i) => {
                                                if(i === index){
                                                    return {...t, name: e.target.value}
                                                }
                                                return t
                                            })
                                        });
                                    }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: '#e9eaec',
                                                borderRadius: '10px'
                                            },
                                        },
                                    }}
                                />
                            </Box>
                            <Box sx={{width: '24%', minWidth: '120px'}}>
                                <Typography
                                    sx={{
                                        fontSize: '18px',
                                        fontFamily: 'Lato',
                                        color: '#7c7c8d'
                                    }}
                                >Dentes/Região</Typography>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder='Digite aqui...'
                                    autoComplete='off'
                                    value={treatment.teeth}
                                    onChange={(e) => {
                                        setForm({
                                            ...form,
                                            treatments: form.treatments.map((t, i) => {
                                                if(i === index){
                                                    return {...t, teeth: e.target.value}
                                                }
                                                return t
                                            })
                                        });
                                    }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: '#e9eaec',
                                                borderRadius: '10px'
                                            },
                                        },
                                    }}
                                />
                            </Box>
                            <Box sx={{width: '24%', minWidth: '120px'}}>
                                <Typography
                                    sx={{
                                        fontSize: '18px',
                                        fontFamily: 'Lato',
                                        color: '#7c7c8d'
                                    }}
                                >Valor<span style={{color: 'red'}}>*</span></Typography>
                                <TextField
                                    fullWidth
                                    type="number"
                                    variant="outlined"
                                    placeholder='Digite aqui...'
                                    autoComplete='off'
                                    value={treatment.value}
                                    onChange={(e) => {
                                        setForm({
                                            ...form,
                                            treatments: form.treatments.map((t, i) => {
                                                if(i === index){
                                                    return {...t, value: parseFloat(e.target.value)}
                                                }
                                                return t
                                            })
                                        });
                                    }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: '#e9eaec',
                                                borderRadius: '10px'
                                            },
                                        },
                                    }}
                                />
                            </Box>
                        </Box>
                    ))}
                    <Box sx={{display: 'flex', width: '100%', justifyContent: 'center'}}>
                        <Button
                            onClick={() => {
                                setForm({
                                    ...form,
                                    treatments: [
                                        ...form.treatments,
                                        {
                                            plan: '',
                                            name: '',
                                            teeth: '',
                                            value: 0,
                                        }
                                    ]
                                })
                            }}
                        >
                            <Typography
                                sx={{
                                    color: '#005fdb',
                                    fontSize: '20px'
                                }}
                            >Adicionar outro Tratamento</Typography>
                        </Button>
                    </Box>
                    <Typography
                        sx={{
                            color: '#7c7c8d',
                            marginTop: '15px'
                        }}
                    >
                        Observações
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        multiline
                        rows={4}
                        placeholder='Digite aqui...'
                        value={form.observation}
                        onChange={(e) => {
                            if(e.target.value.length <= 500){
                                setForm({ ...form, observation: e.target.value })
                            }
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#e9eaec',
                                    borderRadius: '10px'
                                },
                            },
                        }}
                    />
                    <Box 
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: '5px'
                        }}
                    >
                        <Typography
                            sx={{
                                color: form.observation.length >= 500 ? 'red' : '#7c7c8d'
                            }}
                        >
                            {form.observation.length} / 500
                        </Typography>
                    </Box>
                    <Box sx={{display: 'flex', width: '100%', justifyContent: 'center'}}>
                        <Button
                            onClick={() => {
                                fileInputRef.current!.click()
                            }}
                        >
                            <Typography
                                sx={{
                                    color: '#005fdb',
                                    fontSize: '20px'
                                }}
                            >Anexar Comprovante</Typography>
                            <VisuallyHiddenInput
                                type="file"
                                ref={fileInputRef}
                                onChange={handleFileChange}
                                
                            />
                        </Button>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: '20px',
                        alignItems: 'center'
                    }}
                >
                    <Button
                        sx={{
                            color: '#ca150c',
                            textTransform: 'none',
                            marginRight: '15px'
                        }}
                        onClick={() => {
                            onClose && onClose()
                            ResetForm()
                        }}
                    >Fechar</Button>
                    <Button
                        sx={{
                            color: 'white',
                            backgroundColor: '#006af5',
                            '&:hover': {
                                backgroundColor: 'rgba(0, 106, 245, 0.7)',
                            },
                            textTransform: 'none',
                        }}
                        onClick={() => {
                            alert('não implementado')
                        }}
                    >Salvar</Button>
                </Box>
            </Box>
        </Modal>
    );
}

export default AddIncomeModal;