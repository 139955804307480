import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Box, Button, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, TextField, IconButton } from '@mui/material';
import Header from '../../components/header/index.tsx';
import Sidebar from '../../components/sidebar/index.tsx';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddStorageItem from '../../components/modals/addStorageItem/index.tsx';
import StorageRemovalHistory from '../../components/modals/StorageRemovalHistory/index.tsx';
import RemoveStorageItem from '../../components/modals/RemoveStorageItem/index.tsx';
import PageContentContainer from '../../components/PageContentContainer/index.tsx';
import MobileHeader from '../../components/MobileHeader/index.tsx';
import axios from 'axios';
import { configBackendConnection, endpoints } from '../../components/utilsBackend.ts';

interface Product {
    id: number;
    name: string;
    quantity: number;
    ideal_quantity: number;
}

const StoragePage: React.FC = () => {
    const [openNewStorageItem, setOpenNewStorageItem] = useState(false);
    const [openRemovalHistory, setOpenRemovalHistory] = useState(false);
    const [openRemoveStorageItem, setOpenRemoveStorageItem] = useState(false);
    const [criticalquantitys, setCriticalquantitys] = useState(0);
    const [products, setProducts] = useState<Product[]>([]);
    const [productToRemove, setProductToRemove] = useState<Product>({ id: 0, name: '', quantity: 0, ideal_quantity: 0 });
    const [searchTerm, setSearchTerm] = useState('');

    const fetchProducts = async () => {
        try {
            const response = await axios.get(`${configBackendConnection.baseURL}/${endpoints.stocksAPI}`, {
                headers: configBackendConnection.headersDefault
            });
            const allProducts = response.data.flatMap((stock) => stock.products);
            setProducts(allProducts);

            const critical = allProducts.filter((product) => product.quantity === 0);
            setCriticalquantitys(critical.length);
        } catch (error) {
            toast.error('Erro ao buscar os produtos.');
        }
    };

    const handleDelete = async (id: number) => {
        const deleteUrl = `${configBackendConnection.baseURL}/${endpoints.productsAPI}${id}/`;
    
        try {
            await axios.delete(deleteUrl, {
                headers: configBackendConnection.headersDefault
            });
            toast.success('Produto removido com sucesso!');
            fetchProducts();
        } catch (error) {
            toast.error('Erro ao remover o produto.');
        }
    };

    useEffect(() => {
        fetchProducts();
    }, []);

    const filteredProducts = products.filter((product) =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <>
            <Header />
            <MobileHeader />
            <Sidebar />
            <AddStorageItem
                open={openNewStorageItem}
                onClose={() => {}}
                setOpen={setOpenNewStorageItem}
                onProductAdded={fetchProducts}
            />
            <StorageRemovalHistory
                open={openRemovalHistory}
                onClose={() => setOpenRemovalHistory(false)}
                setOpen={setOpenRemovalHistory}
            />
            <RemoveStorageItem
                open={openRemoveStorageItem}
                onClose={() => setOpenRemoveStorageItem(false)}
                setOpen={setOpenRemoveStorageItem}
                product={productToRemove}
                onProductRemoved={fetchProducts}
            />
            <PageContentContainer>
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        padding: '30px',
                        flexDirection: 'column',
                    }}
                >
                    <Box
                        sx={{
                            height: '40px',
                            '@media (max-width: 524px)': {
                                height: '80px',
                            },
                        }}
                    >
                        <Button
                            sx={{
                                color: 'white',
                                backgroundColor: '#006af5',
                                textTransform: 'none',
                                marginRight: '10px',
                            }}
                            onClick={() => setOpenNewStorageItem(true)}
                        >
                            Fazer entrada no estoque
                        </Button>
                        <Button onClick={() => setOpenRemovalHistory(true)}>
                            Histórico de Retiradas
                        </Button>
                    </Box>
                    {criticalquantitys > 0 && (
                        <Box
                            sx={{
                                width: '100%',
                                height: '60px',
                                backgroundColor: 'white',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '0 20px',
                                marginTop: '20px',
                                borderRadius: '10px',
                                '@media (max-width: 524px)': {
                                    height: '120px',
                                },
                            }}
                        >
                            <AlarmOnIcon sx={{ fontSize: 30, color: '#505050' }} />
                            <Typography
                                color="#a5a5a5"
                                sx={{
                                    '@media (max-width: 524px)': {
                                        fontSize: '14px',
                                    },
                                }}
                            >
                                Você possui {criticalquantitys || 0} produtos com nível crítico no estoque, confira e compre pra não ficar sem
                            </Typography>
                            <Button
                                sx={{
                                    color: 'white',
                                    backgroundColor: '#006af5',
                                    textTransform: 'none',
                                    marginRight: '10px',
                                }}
                            >
                                Ver
                            </Button>
                        </Box>
                    )}
                    <Box
                        sx={{
                            width: '100%',
                            height: { xs: '100%', md: '80%' },
                            backgroundColor: 'white',
                            padding: { xs: '10px', md: '0px 40px' },
                            paddingTop: '20px',
                            marginTop: '20px',
                            borderRadius: '10px',
                            overflowY: 'auto',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                height: '60px',
                                '@media (max-width: 600px)': {
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                },
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: { xs: 18, md: 25 },
                                    fontWeight: '600',
                                    fontFamily: 'Lato',
                                    color: '#505050',
                                }}
                            >
                                Meu estoque
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: { xs: '10px', md: '0px' } }}>
                                <TextField
                                    placeholder="Buscar produto"
                                    variant="outlined"
                                    size="small"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    sx={{ marginRight: '10px' }}
                                />
                            </Box>
                        </Box>
                        <Divider variant="middle" sx={{ marginBottom: '20px' }} />
                        <TableContainer sx={{ maxHeight: '60vh', overflowY: 'auto' }}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography
                                                sx={{
                                                    color: '#5b5b5b',
                                                    fontSize: 18,
                                                    fontFamily: 'DM Sans',
                                                }}
                                            >
                                                Produtos
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left"></TableCell>
                                        <TableCell align="center">
                                            <Typography
                                                sx={{
                                                    color: '#5b5b5b',
                                                    fontSize: 18,
                                                    fontFamily: 'DM Sans',
                                                }}
                                            >
                                                Estoque
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="right"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredProducts.map((product) => (
                                        <TableRow key={product.id}>
                                            <TableCell component="th" scope="row">
                                                <Typography
                                                    sx={{
                                                        color: '#A5A5A5',
                                                        fontFamily: 'DM Sans',
                                                    }}
                                                >
                                                    {product.name}
                                                </Typography>
                                            </TableCell>
                                            {product.quantity > 0 ? (
                                                <TableCell align="left">
                                                    <Button
                                                        onClick={() => {
                                                            setProductToRemove(product);
                                                            setOpenRemoveStorageItem(true);
                                                        }}
                                                    >
                                                        Fazer Retirada
                                                    </Button>
                                                </TableCell>
                                            ) : (
                                                <TableCell align="left"></TableCell>
                                            )}
                                            <TableCell align="center">
                                                <Typography
                                                    sx={{
                                                        color: '#A5A5A5',
                                                        fontFamily: 'DM Sans',
                                                    }}
                                                >
                                                    {product.quantity}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <IconButton onClick={() => handleDelete(product.id)} sx={{ color: '#ff4d4d' }}>
                                                    <DeleteOutlineOutlinedIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            </PageContentContainer>
        </>
    );
};

export default StoragePage;
