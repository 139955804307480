import { Box, Typography } from '@mui/material';
import React from 'react';
import BaseForIntelligenceSquare from '../../../../components/baseForIntelligenceSquare/index.tsx';
import dayjs, { Dayjs } from 'dayjs';
import { BarChart } from '@mui/x-charts/BarChart';

const MOCKED_QUANTITY_DATA: IBarItemData[] = [
    {
        month: 'Jan',
        consultations: 400,
    },
    {
        month: 'Fev',
        consultations: 0,
    },
    {
        month: 'Mar',
        consultations: 200,
    },
    {
        month: 'Abr',
        consultations: 278,
    },
    {
        month: 'Mai',
        consultations: 0,
    },
    {
        month: 'Jun',
        consultations: 239,
    },
    {
        month: 'Jul',
        consultations: 349,
    },
    {
        month: 'Ago',
        consultations: 349,
    },
    {
        month: 'Set',
        consultations: 349,
    },
    {
        month: 'Out',
        consultations: 349,
    },
    {
        month: 'Nov',
        consultations: 349,
    },
    {
        month: 'Dez',
        consultations: 349,
    }
];

interface IBarItemData {
    month: string;
    consultations: number;
}

interface IConsultationData {
    consultationsByPeriod: number;
    canceledConsultationsByPeriod: number;
    consultationGraphData: IBarItemData[];
}

const ConsultationSquare: React.FC = () => {
    const [selectedDate, setSelectedDate] = React.useState<Dayjs | null>(dayjs());
    const [consultationData, setConsultationData] = React.useState<IConsultationData>({
        consultationsByPeriod: MOCKED_QUANTITY_DATA.reduce((acc, item) => acc + item.consultations, 0),
        canceledConsultationsByPeriod: 24,
        consultationGraphData: MOCKED_QUANTITY_DATA,
    });
    
    return (
        <BaseForIntelligenceSquare
            title = "Consultas"
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
        >
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    "@media (max-width: 736px)": {
                        flexDirection: 'column',
                        alignItems: 'center',
                    }
                }}
            >
                <Box
                    sx={{
                        marginTop: '40px',
                        width: '180px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        "@media (max-width: 736px)": {
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%',
                        },
                        "@media (max-width: 668px)": {
                            flexDirection: 'column',
                            rowGap: '20px',
                            alignItems: 'center',
                        }
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                color: '#505050',
                                fontWeight: 'bold',
                            }}
                        variant="h6">{consultationData.consultationsByPeriod}</Typography>
                        <Typography
                            sx={{color: '#77828f'}}
                            variant="body1"
                        >Consultas realizadas por</Typography>
                        <Typography
                            sx={{color: '#77828f'}}
                            variant="body1"
                        >periodo</Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            marginTop: '20px',
                            "@media (max-width: 736px)": {
                                marginTop: '0',
                            }
                        }}
                    >
                        <Typography
                            sx={{
                                color: '#505050',
                                fontWeight: 'bold',
                            }}
                        variant="h6">{consultationData.canceledConsultationsByPeriod}</Typography>
                        <Typography
                            sx={{color: '#77828f'}}
                            variant="body1"
                        >Faltas e cancelamentos</Typography>
                        <Typography
                            sx={{color: '#77828f'}}
                            variant="body1"
                        >no periodo</Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '20px',
                            "@media (max-width: 736px)": {
                                flexDirection: 'row',
                                marginTop: '0',
                                minWidth: '150px',
                            }
                        }}
                    >
                        <Box
                            sx={{
                                width: '95px',
                                height: '95px',
                                borderRadius: '50%',
                                backgroundColor: '#018abe',
                                color: 'white',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                "@media (max-width: 736px)": {
                                    width: '60px',
                                    height: '60px',
                                    marginRight: '10px',
                                }
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '25px',
                                    fontWeight: 'bold',
                                    marginLeft: '10px',
                                    "@media (max-width: 736px)": {
                                        fontSize: '15px',
                                        marginLeft: '0px',
                                    }
                                }}
                            >{
                                consultationData.consultationsByPeriod === 0 ? 0 :
                                (((consultationData.canceledConsultationsByPeriod * -100) / consultationData.consultationsByPeriod)+100).toFixed(2)
                            }</Typography>
                            <Typography
                                sx={{
                                    fontSize: '18px',
                                    fontWeight: 'bold',
                                    "@media (max-width: 736px)": {
                                        fontSize: '12px',
                                    }
                                }}
                            >%</Typography>
                        </Box>
                        <Typography
                            sx={{color: '#77828f'}}
                        >Comparecimento</Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: 'calc(100% - 200px)',
                        height: '400px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        "@media (max-width: 736px)": {
                            width: '100%',
                        },
                        "@media (max-width: 668px)": {
                            height: '300px',
                        }
                    }}
                >
                    <BarChart
                        sx={{
                            width: '100%',
                            height: '100%',
                        }}
                        dataset={consultationData.consultationGraphData as any}
                        series={[
                            { label: 'Consultas', dataKey: 'consultations', color: '#001b48' },
                        ]}
                        xAxis={[{
                            scaleType: 'band',
                            dataKey: 'month',
                            disableLine: true,
                            disableTicks: true,
                        }]}
                        yAxis={[{
                            scaleType: 'linear',
                            disableTicks: true,
                            disableLine: true,
                        }]}
                        borderRadius={40}
                        slotProps={{
                            legend: { hidden: true },
                        }}
                    />
                </Box>
            </Box>
        </BaseForIntelligenceSquare>
    )
}

export default ConsultationSquare;