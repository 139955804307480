import { Box, Button, Modal, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { INewCreditCardForm } from '../../../types/payment';
import CreditCardForm from './CreditCardForm/index.tsx';
import UserInfoForm from './UserInfoForm/index.tsx';
import { toast } from 'react-toastify';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AlterPaydayModal from '../AlterPaydayModal/index.tsx';

interface IModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

interface IAddCreditCardModalProps extends IModalProps {
    form: INewCreditCardForm;
    setForm: (form: INewCreditCardForm) => void;
}

const AddCreditCardModal: React.FC<IAddCreditCardModalProps> = ({
    open,
    setOpen,
    form,
    setForm
}) => {
    const [AddButtonEnabled, setAddButtonEnabled] = useState(false);
    const [openAlterPaydayModal, setOpenAlterPaydayModal] = useState(false);

    useEffect(() => {
        if(
            form.cellphone && form.cpfOrCnpj && form.email && form.fullname &&
            form.cardData.cvv && form.cardData.expiry && form.cardData.name &&
            form.cardData.number && form.address.cep && form.address.city &&
            form.address.neighborhood && form.address.number && form.address.street
        ) {
            setAddButtonEnabled(true);
        }
    },[form])

    return (
        <Modal
            open={open}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box
                sx={{
                    width: '900px',
                    height: '850px',
                    backgroundColor: 'white',
                    padding: '35px',
                }}
            >
                <AlterPaydayModal
                    open={openAlterPaydayModal}
                    setOpen={setOpenAlterPaydayModal}
                    form={form}
                    setForm={setForm}
                />
                <Typography
                    fontFamily={'Lato'}
                    fontSize={'24px'}
                    fontWeight={'bold'}
                >Cartão de Crédito</Typography>
                <Box
                    sx={{
                        overflowY: 'auto',
                        maxHeight: '700px',
                    }}
                >
                    <CreditCardForm form={form} setForm={setForm}/>
                    <Typography
                        fontFamily={'Lato'}
                        fontSize={'24px'}
                        fontWeight={'bold'}
                    >Informações do responsável pela cobrança</Typography>
                    <UserInfoForm form={form} setForm={setForm}/>
                    <Typography
                        fontFamily={'Lato'}
                        fontSize={'24px'}
                        fontWeight={'bold'}
                        marginTop={'20px'}
                    >Dia para a cobrança das próximas faturas</Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginY: '15px'
                        }}
                    >
                        <CalendarMonthIcon sx={{color: '#257985'}}/>
                        <Typography
                            fontFamily={'Lato'}
                            fontSize={'18px'}
                            marginLeft={'20px'}
                            color={'#616d7c'}
                        >Cobrança feita no dia {form.payday} de cada mês</Typography>
                        <Button
                            sx={{
                                textTransform: 'none',
                                marginLeft: '20px'
                            }}
                            onClick={() => setOpenAlterPaydayModal(true)}
                        >Alterar dia de cobrança</Button>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: '20px'
                    }}
                >
                    <Button
                        sx={{
                            textTransform: 'none',
                            color: 'red'
                        }}
                        onClick={() => {
                            setForm({
                                fullname: '',
                                cpfOrCnpj: '',
                                email: '',
                                cellphone: '',
                                payday: 10,
                                address: {
                                    cep: '',
                                    street: '',
                                    number: '',
                                    complement: undefined,
                                    neighborhood: '',
                                    city: '',
                                    state: undefined
                                },
                                cardData: {
                                    cvv: '',
                                    expiry: '',
                                    name: '',
                                    number: ''
                                }
                            });
                            setOpen(false)
                        }}
                    >Fechar</Button>
                    <Button
                        onClick={() => {
                            AddButtonEnabled ?
                                toast.warning('Ainda não implementado') :
                                toast.error('Preencha todos os campos obrigatórios'); 
                        }}
                        sx={{
                            textTransform: 'none',
                            color: 'white',
                            backgroundColor: AddButtonEnabled ? '#0f4161' : '#595959',
                            '&:hover': {
                                backgroundColor: AddButtonEnabled ? '#235579' : '#595959',
                            },
                            height: '35px',
                            width: '190px',
                        }}
                    >Adicionar cartão</Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default AddCreditCardModal;