import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, IconButton } from "@mui/material";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddDocumentModal from '../../../components/modals/AddDocumentModal/index.tsx';
import axios from 'axios';
import { configBackendConnection, endpoints } from "../../../components/utilsBackend.ts";
import { toast } from 'react-toastify';

interface Document {
    id: number;
    contractor_name: string;
    contractor_cpf: string;
    contractor_city: string;
    contractor_address: string;
    contractor_neighborhood: string;
    contractor_zipcode: string;
    contracted_name: string;
    contracted_cnpj_cpf: string;
    contracted_city: string;
    patient_name: string;
    treatment_description: string;
    contract_value: string;
    full_text: string;
}

const fetchDocuments = async (): Promise<Document[]> => {
    try {
        const response = await axios.get<Document[]>(`${configBackendConnection.baseURL}/${endpoints.contractsAPI}`, {
            headers: configBackendConnection.headersDefault
        });
        return response.data;
    } catch (error) {
        toast.error("Erro ao buscar os contratos");
        return [];
    }
};

const saveDocument = async (documentData: Omit<Document, 'id'>): Promise<Document | undefined> => {
    try {
        const response = await axios.post<Document>(`${configBackendConnection.baseURL}/${endpoints.contractsAPI}`, 
        documentData,
        {
            headers: configBackendConnection.headersDefault
        });
        toast.success("Contrato salvo com sucesso!");
        return response.data;
    } catch (error) {
        toast.error("Erro ao salvar o contrato");
    }
};

const updateDocument = async (id: number, documentData: Omit<Document, 'id'>): Promise<Document | undefined> => {
    try {
        const response = await axios.put<Document>(`${configBackendConnection.baseURL}/${endpoints.contractsAPI}/${id}`, 
        documentData,
        {
            headers: configBackendConnection.headersDefault
        });
        toast.success("Contrato atualizado com sucesso!");
        return response.data;
    } catch (error) {
        toast.error("Erro ao atualizar o contrato");
    }
};

const deleteDocument = async (id: number): Promise<void> => {
    try {
        await axios.delete(`${configBackendConnection.baseURL}/${endpoints.contractsAPI}/${id}`, {
            headers: configBackendConnection.headersDefault
        });
        toast.success("Contrato excluído com sucesso!");
    } catch (error) {
        toast.error("Erro ao excluir o contrato");
    }
};

const DocumentSettings: React.FC = () => {
    const [openModal, setOpenModal] = useState(false);
    const [documents, setDocuments] = useState<Document[]>([]);
    const [editDocument, setEditDocument] = useState<Document | null>(null);

    useEffect(() => {
        const loadDocuments = async () => {
            const docs = await fetchDocuments();
            setDocuments(docs || []);
        };
        loadDocuments();
    }, []);

    const handleSaveDocument = async (documentName: string, content: string) => {
        if (editDocument) {
            const updatedDocumentData = {
                user: 'user_placeholder',
                contractor_name: documentName,
                contractor_cpf: editDocument.contractor_cpf,
                contractor_city: editDocument.contractor_city,
                contractor_address: editDocument.contractor_address,
                contractor_neighborhood: editDocument.contractor_neighborhood,
                contractor_zipcode: editDocument.contractor_zipcode,
                contracted_name: editDocument.contracted_name,
                contracted_cnpj_cpf: editDocument.contracted_cnpj_cpf,
                contracted_city: editDocument.contracted_city,
                patient_name: editDocument.patient_name,
                treatment_description: editDocument.treatment_description,
                contract_value: editDocument.contract_value,
                full_text: content,
            };
            const updatedDoc = await updateDocument(editDocument.id, updatedDocumentData);
            if (updatedDoc) {
                setDocuments(documents.map(doc => (doc.id === editDocument.id ? updatedDoc : doc)));
                setEditDocument(null);
            }
        } else {
            const documentData = {
                user: 'user_placeholder',
                contractor_name: documentName,
                contractor_cpf: '',
                contractor_city: '',
                contractor_address: '',
                contractor_neighborhood: '',
                contractor_zipcode: '',
                contracted_name: '',
                contracted_cnpj_cpf: '',
                contracted_city: '',
                patient_name: '',
                treatment_description: '',
                contract_value: '',
                full_text: content,
            };
            const newDoc = await saveDocument(documentData);
            if (newDoc) {
                setDocuments([...documents, newDoc]);
            }
        }
        setOpenModal(false);
    };

    const handleDeleteDocument = async (id: number) => {
        await deleteDocument(id);
        setDocuments(documents.filter(doc => doc.id !== id));
    };

    const handleEditDocument = (doc: Document) => {
        setEditDocument(doc);
        setOpenModal(true);
    };

    return (
        <>
            <AddDocumentModal
                open={openModal}
                setOpen={setOpenModal}
                onClose={() => {
                    setOpenModal(false);
                    setEditDocument(null);
                }}
                onSave={handleSaveDocument}
                canEdit={!!editDocument}
                documentName={editDocument ? editDocument.contractor_name : ''}
                content={editDocument ? editDocument.full_text : ''}
            />
            <Box
                sx={{
                    width: '93%',
                    marginTop: '30px',
                    borderRadius: '10px',
                    padding: '20px',
                    boxShadow: '0px 0px 5px 0px #c0c7d0',
                    backgroundColor: 'white',
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '20px',
                            fontWeight: 'bold',
                            '@media (max-width: 548px)': {
                                fontSize: '15px'
                            }
                        }}
                    >
                        Modelos de contratos
                    </Typography>
                    <Button
                        sx={{
                            backgroundColor: '#006af5',
                            '&:hover': {
                                backgroundColor: 'rgba(0, 106, 245, 0.7)',
                            },
                            color: 'white',
                            textTransform: 'none',
                            height: '35px',
                        }}
                        onClick={() => setOpenModal(true)}
                    >
                        <Typography
                            sx={{
                                '@media (max-width: 548px)': {
                                    fontSize: '12px'
                                }
                            }}
                        >
                            Adicionar contrato ou anexar
                        </Typography>
                    </Button>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        height: '1px',
                        backgroundColor: '#e4e4e4',
                        margin: '20px 0px'
                    }}
                />
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        maxHeight: '500px',
                        overflowY: 'auto',
                    }}
                >
                    {documents.map(doc => (
                        <Box
                            key={doc.id}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                padding: '10px',
                                borderRadius: '5px',
                                backgroundColor: '#f9f9f9',
                                boxShadow: '0px 0px 5px rgba(0,0,0,0.1)'
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    color: '#333',
                                }}
                            >
                                {doc.contractor_name}
                            </Typography>
                            <Box>
                                <IconButton onClick={() => handleDeleteDocument(doc.id)} sx={{ color: '#ff4d4d' }}>
                                    <DeleteOutlineOutlinedIcon />
                                </IconButton>
                                <IconButton onClick={() => handleEditDocument(doc)} sx={{ color: '#006af5' }}>
                                    <EditOutlinedIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    ))}
                </Box>
            </Box>
        </>
    );
};

export default DocumentSettings;
