import { Box, Button, Checkbox, MenuItem, TextField, Typography } from '@mui/material';
import React from 'react';
import AlterPaydayModal from '../../../components/modals/AlterPaydayModal/index.tsx';
import CreditCardForm from '../../../components/modals/AddCreditCardModal/CreditCardForm/index.tsx';
import UserInfoForm from '../../../components/modals/AddCreditCardModal/UserInfoForm/index.tsx';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { INewCreditCardForm } from '../../../types/payment';

const states = [
    'Acre (AC)',
    'Alagoas (AL)',
    'Amapá (AP)',
    'Amazonas (AM)',
    'Bahia (BA)',
    'Ceará (CE)',
    'Distrito Federal (DF)',
    'Espírito Santo (ES)',
    'Goiás (GO)',
    'Maranhão (MA)',
    'Mato Grosso (MT)',
    'Mato Grosso do Sul (MS)',
    'Minas Gerais (MG)',
    'Pará (PA)',
    'Paraíba (PB)',
    'Paraná (PR)',
    'Pernambuco (PE)',
    'Piauí (PI)',
    'Rio de Janeiro (RJ)',
    'Rio Grande do Norte (RN)',
    'Rio Grande do Sul (RS)',
    'Rondônia (RO)',
    'Roraima (RR)',
    'Santa Catarina (SC)',
    'São Paulo (SP)',
    'Sergipe (SE)',
    'Tocantins (TO)',
]

const BasicUserDataForm: React.FC<{
    form: INewCreditCardForm
    setForm: (form: INewCreditCardForm) => void
}> = ({form, setForm}) => {
    return (
        <>
            <Typography
                fontFamily={'Lato'}
                fontSize={'24px'}
                fontWeight={'bold'}
                sx={{marginTop: '30px'}}
            >Dados de cobrança</Typography>
            <Typography sx={{color: '#757f8d'}}>
                Informações do responsável pelo pagamento. Os dados serão utilizados para emissão da nota fiscal.
            </Typography>
            <Box
                sx={{
                    width: '100%',
                    marginTop: '30px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    "@media (max-width: 720px)": {
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        rowGap: '30px'
                    }
                }}
            >
                <Box
                    sx={{
                        width: '53%',
                        "@media (max-width: 720px)": {
                            width: '100%'
                        }
                    }}
                >
                    <Typography
                        sx={{
                        fontSize: '18px',
                        fontFamily: 'Lato',
                        color: '#7c7c8d'
                        }}
                    >
                        Nome Completo<span style={{color: 'red'}}>*</span>
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Digite aqui..."
                        autoComplete="off"
                        value={form.fullname}
                        onChange={(e) => {
                            setForm({
                                ...form,
                                fullname: e.target.value
                            });
                        }}
                        sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                            borderColor: '#e9eaec',
                            borderRadius: '10px'
                            },
                        },
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        width: '45%',
                        "@media (max-width: 720px)": {
                            width: '100%'
                        }
                    }}
                >
                    <Typography
                        sx={{
                        fontSize: '18px',
                        fontFamily: 'Lato',
                        color: '#7c7c8d'
                        }}
                    >
                        CPF/CNPJ<span style={{color: 'red'}}>*</span>
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Digite aqui..."
                        autoComplete="off"
                        value={form.cpfOrCnpj}
                        onChange={(e) => {
                            if(e.target.value.length > 14) return;
                            setForm({
                                ...form,
                                cpfOrCnpj: e.target.value.replace(/\D/g, '')
                            });
                        }}
                        sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                            borderColor: '#e9eaec',
                            borderRadius: '10px'
                            },
                        },
                        }}
                    />
                </Box>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    marginTop: '30px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    "@media (max-width: 720px)": {
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        rowGap: '30px'
                    }
                }}
            >
                <Box
                    sx={{
                        width: '53%',
                        "@media (max-width: 720px)": {
                            width: '100%'
                        }
                    }}
                >
                    <Typography
                        sx={{
                        fontSize: '18px',
                        fontFamily: 'Lato',
                        color: '#7c7c8d'
                        }}
                    >
                        E-mail<span style={{color: 'red'}}>*</span>
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Digite aqui..."
                        autoComplete="off"
                        value={form.email}
                        onChange={(e) => {
                            setForm({
                                ...form,
                                email: e.target.value
                            });
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                borderColor: '#e9eaec',
                                borderRadius: '10px'
                                },
                            },
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        width: '45%',
                        "@media (max-width: 720px)": {
                            width: '100%'
                        }
                    }}
                >
                    <Typography
                        sx={{
                        fontSize: '18px',
                        fontFamily: 'Lato',
                        color: '#7c7c8d'
                        }}
                    >
                        Celular<span style={{color: 'red'}}>*</span>
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Digite aqui..."
                        autoComplete="off"
                        value={form.cellphone}
                        onChange={(e) => {
                            if(e.target.value.length > 14) return;
                            setForm({
                                ...form,
                                cellphone: e.target.value.replace(/\D/g, '')
                            });
                        }}
                        sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                            borderColor: '#e9eaec',
                            borderRadius: '10px'
                            },
                        },
                        }}
                    />
                </Box>
            </Box>
        </>
    )
}

const BasicUserAddressForm: React.FC<{
    form: INewCreditCardForm
    setForm: (form: INewCreditCardForm) => void
}> = ({form, setForm}) => {
    return (
        <>
            <Typography
                fontFamily={'Lato'}
                fontSize={'24px'}
                fontWeight={'bold'}
                sx={{marginTop: '30px'}}
            >Endereço</Typography>
            <Box
                sx={{
                    width: '100%',
                    marginTop: '30px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    "@media (max-width: 720px)": {
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        rowGap: '30px'
                    }
                }}
            >
                <Box
                    sx={{
                        width: '25%',
                        "@media (max-width: 720px)": {
                            width: '100%'
                        }
                    }}>
                    <Typography
                        sx={{
                        fontSize: '18px',
                        fontFamily: 'Lato',
                        color: '#7c7c8d'
                        }}
                    >
                        CEP<span style={{color: 'red'}}>*</span>
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Digite aqui..."
                        autoComplete="off"
                        value={form.address.cep}
                        onChange={(e) => {
                            if(e.target.value.length > 9) return;
                            setForm({
                                ...form,
                                address: {
                                    ...form.address,
                                    cep: e.target.value.replace(/\D/g, '')
                                }
                            });
                        }}
                        sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                            borderColor: '#e9eaec',
                            borderRadius: '10px'
                            },
                        },
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        width: '73%',
                        "@media (max-width: 720px)": {
                            width: '100%'
                        }
                    }}
                >
                    <Typography
                        sx={{
                        fontSize: '18px',
                        fontFamily: 'Lato',
                        color: '#7c7c8d'
                        }}
                    >
                        Rua<span style={{color: 'red'}}>*</span>
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Digite aqui..."
                        autoComplete="off"
                        value={form.address.street}
                        onChange={(e) => {
                            setForm({
                                ...form,
                                address: {
                                    ...form.address,
                                    street: e.target.value
                                }
                            });
                        }}
                        sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                            borderColor: '#e9eaec',
                            borderRadius: '10px'
                            },
                        },
                        }}
                    />
                </Box>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    marginTop: '30px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    "@media (max-width: 720px)": {
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        rowGap: '30px'
                    }
                }}
            >
                <Box
                    sx={{
                        width: '25%',
                        "@media (max-width: 720px)": {
                            width: '100%'
                        }
                    }}
                >
                    <Typography
                        sx={{
                        fontSize: '18px',
                        fontFamily: 'Lato',
                        color: '#7c7c8d'
                        }}
                    >
                        Número<span style={{color: 'red'}}>*</span>
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Digite aqui..."
                        autoComplete="off"
                        value={form.address.number}
                        onChange={(e) => {
                            if(e.target.value.length > 5) return;
                            setForm({
                                ...form,
                                address: {
                                    ...form.address,
                                    number: e.target.value.replace(/\D/g, '')
                                }
                            });
                        }}
                        sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                            borderColor: '#e9eaec',
                            borderRadius: '10px'
                            },
                        },
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        width: '73%',
                        "@media (max-width: 720px)": {
                            width: '100%'
                        }
                    }}
                >
                    <Typography
                        sx={{
                        fontSize: '18px',
                        fontFamily: 'Lato',
                        color: '#7c7c8d'
                        }}
                    >
                        Complemento
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Digite aqui..."
                        autoComplete="off"
                        value={form.address.complement}
                        onChange={(e) => {
                            setForm({
                                ...form,
                                address: {
                                    ...form.address,
                                    complement: e.target.value
                                }
                            });
                        }}
                        sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                            borderColor: '#e9eaec',
                            borderRadius: '10px'
                            },
                        },
                        }}
                    />
                </Box>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    marginTop: '30px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    "@media (max-width: 720px)": {
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        rowGap: '30px'
                    }
                }}
            >
                <Box
                    sx={{
                        width: '32%',
                        "@media (max-width: 720px)": {
                            width: '100%'
                        }
                    }}
                >
                    <Typography
                        sx={{
                        fontSize: '18px',
                        fontFamily: 'Lato',
                        color: '#7c7c8d'
                        }}
                    >
                        Bairro<span style={{color: 'red'}}>*</span>
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Digite aqui..."
                        autoComplete="off"
                        value={form.address.neighborhood}
                        onChange={(e) => {
                            setForm({
                                ...form,
                                address: {
                                    ...form.address,
                                    neighborhood: e.target.value
                                }
                            });
                        }}
                        sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                            borderColor: '#e9eaec',
                            borderRadius: '10px'
                            },
                        },
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        width: '32%',
                        "@media (max-width: 720px)": {
                            width: '100%'
                        }
                    }}
                >
                    <Typography
                        sx={{
                        fontSize: '18px',
                        fontFamily: 'Lato',
                        color: '#7c7c8d'
                        }}
                    >
                        Cidade<span style={{color: 'red'}}>*</span>
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Digite aqui..."
                        autoComplete="off"
                        value={form.address.city}
                        onChange={(e) => {
                            setForm({
                                ...form,
                                address: {
                                    ...form.address,
                                    city: e.target.value
                                }
                            });
                        }}
                        sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                            borderColor: '#e9eaec',
                            borderRadius: '10px'
                            },
                        },
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        width: '32%',
                        "@media (max-width: 720px)": {
                            width: '100%'
                        }
                    }}
                >
                    <Typography
                        sx={{
                        fontSize: '18px',
                        fontFamily: 'Lato',
                        color: '#7c7c8d'
                        }}
                    >
                        Estado
                    </Typography>
                    <TextField
                        fullWidth
                        select
                        variant="outlined"
                        placeholder="Digite aqui..."
                        value={form.address.state}
                        onChange={(e) => {
                            setForm({
                                ...form,
                                address: {
                                    ...form.address,
                                    state: e.target.value
                                }
                            });
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                borderColor: '#e9eaec',
                                borderRadius: '10px'
                                },
                            },
                        }}
                    >
                        {states.map((state) => (
                            <MenuItem key={state} value={state}>
                                {state}
                            </MenuItem>
                        ))}
                    </TextField>
                </Box>
            </Box>
        </>
    )
}

const CreditCardPayment: React.FC<{
    form: INewCreditCardForm
    setForm: (form: INewCreditCardForm) => void
}> = ({form, setForm}) => {
    const [openAlterPaydayModal, setOpenAlterPaydayModal] = React.useState(false);
    return (
        <>
            <AlterPaydayModal
                open={openAlterPaydayModal}
                setOpen={setOpenAlterPaydayModal}
                form={form}
                setForm={setForm}
            />
            <Box>
                <CreditCardForm form={form} setForm={setForm}/>
                <Typography
                    fontFamily={'Lato'}
                    fontSize={'24px'}
                    fontWeight={'bold'}
                >Informações do responsável pela cobrança</Typography>
                <UserInfoForm form={form} setForm={setForm}/>
                <Typography
                    fontFamily={'Lato'}
                    fontSize={'24px'}
                    fontWeight={'bold'}
                    marginTop={'20px'}
                >Dia para a cobrança das próximas faturas</Typography>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginY: '15px'
                    }}
                >   
                    <CalendarMonthIcon sx={{color: '#257985'}}/>
                    <Typography
                        fontFamily={'Lato'}
                        fontSize={'18px'}
                        marginLeft={'20px'}
                        color={'#616d7c'}
                    >Cobrança feita no dia {form.payday} de cada mês</Typography>
                    <Button
                        sx={{
                            textTransform: 'none',
                            marginLeft: '20px'
                        }}
                        onClick={() => setOpenAlterPaydayModal(true)}
                    >Alterar dia de cobrança</Button>
                </Box>
            </Box>
        </>
    )
}

const TicketPayment: React.FC<{
    form: INewCreditCardForm
    setForm: (form: INewCreditCardForm) => void
}> = ({form, setForm}) => {
    const [openAlterPaydayModal, setOpenAlterPaydayModal] = React.useState(false);
    return(
        <>
            <AlterPaydayModal
                open={openAlterPaydayModal}
                setOpen={setOpenAlterPaydayModal}
                form={form}
                setForm={setForm}
            />
            <BasicUserDataForm form={form} setForm={setForm}/>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#e5f6fd',
                    width: '100%',
                    height: 'auto',
                    padding: '10px',
                    borderRadius: '5px',
                    marginY: '20px'
                }}
            >
                <InfoOutlinedIcon
                    sx={{
                        color: '#03659a',
                        fontSize: '35px'
                    }}
                />
                <Typography
                    sx={{
                        color: '#5b5b5b',
                        marginLeft: '10px'
                    }}
                >
                    Após emitido, o boleto será enviado para o e-mail e WhatsApp do responsável
                </Typography>
            </Box>
            <BasicUserAddressForm form={form} setForm={setForm}/>
            <Typography
                fontFamily={'Lato'}
                fontSize={'24px'}
                fontWeight={'bold'}
                sx={{marginTop: '30px'}}
            >Dia para a cobrança das próximas faturas</Typography>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginY: '15px'
                }}
            >
                <CalendarMonthIcon sx={{color: '#257985'}}/>
                <Typography
                    fontFamily={'Lato'}
                    fontSize={'18px'}
                    marginLeft={'20px'}
                    color={'#616d7c'}
                >Cobrança feita no dia {form.payday} de cada mês</Typography>
                <Button
                    sx={{
                        textTransform: 'none',
                        marginLeft: '20px'
                    }}
                    onClick={() => setOpenAlterPaydayModal(true)}
                >Alterar dia de cobrança</Button>
            </Box>
        </>
    )
}

const PixPayment: React.FC<{
    form: INewCreditCardForm
    setForm: (form: INewCreditCardForm) => void
}> = ({form, setForm}) => {
    return(
        <>
            <BasicUserDataForm form={form} setForm={setForm}/>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#e5f6fd',
                    width: '100%',
                    height: 'auto',
                    padding: '10px',
                    borderRadius: '5px',
                    marginY: '20px'
                }}
            >
                <InfoOutlinedIcon
                    sx={{
                        color: '#03659a',
                        fontSize: '35px'
                    }}
                />
                <Typography
                    sx={{
                        color: '#5b5b5b',
                        marginLeft: '10px'
                    }}
                >
                    Após emitido, o boleto será enviado para o e-mail e WhatsApp do responsável
                </Typography>
            </Box>
            <BasicUserAddressForm form={form} setForm={setForm}/>
        </>
    )
}

const PlanPaymentInfo: React.FC<{
    form: INewCreditCardForm
    setForm: (form: INewCreditCardForm) => void
    paymentMethod: 'creditCard' | 'ticket' | 'pix'
    setPaymentMethod: (paymentMethod: 'creditCard' | 'ticket' | 'pix') => void
}> = ({form, setForm, paymentMethod, setPaymentMethod}) => {
    return (
        <Box
            sx={{
                width: '60%',
                backgroundColor: 'white',
                padding: '35px',
                boxShadow: `0px 0px 5px #bcbcbc`, //#f1f1f1
                "@media (max-width: 1086px)": {
                    width: '95%'
                }
            }}
        >
            <Typography
                fontFamily={'Lato'}
                fontSize={'24px'}
                fontWeight={'bold'}
            >Informações de Pagamento</Typography>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '15px'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Checkbox
                        icon={<RadioButtonUncheckedIcon />}
                        checkedIcon={<RadioButtonCheckedIcon/>}
                        checked={paymentMethod === 'creditCard'}
                        onChange={() => setPaymentMethod('creditCard')}
                    />
                    <Typography>Cartão de crédito</Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginX: '5px'
                    }}
                >
                    <Checkbox
                        icon={<RadioButtonUncheckedIcon />}
                        checkedIcon={<RadioButtonCheckedIcon/>}
                        checked={paymentMethod === 'ticket'}
                        onChange={() => setPaymentMethod('ticket')}
                    />
                    <Typography>Boleto Bancário</Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginX: '5px'
                    }}
                >
                    <Checkbox
                        icon={<RadioButtonUncheckedIcon />}
                        checkedIcon={<RadioButtonCheckedIcon/>}
                        checked={paymentMethod === 'pix'}
                        onChange={() => setPaymentMethod('pix')}
                    />
                    <Typography>Pix</Typography>
                </Box>
            </Box>
            {paymentMethod === 'creditCard' && <CreditCardPayment form={form} setForm={setForm}/>}
            {paymentMethod === 'ticket' && <TicketPayment form={form} setForm={setForm}/>}
            {paymentMethod === 'pix' && <PixPayment form={form} setForm={setForm}/>}
        </Box>
    )
}

export default PlanPaymentInfo;