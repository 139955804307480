import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, MenuItem } from '@mui/material';
import Cards, { Focused } from 'react-credit-cards-2';
import GppGoodIcon from '@mui/icons-material/GppGood';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import { INewCreditCardForm } from '../../../../types/payment';

const states = [
    'Acre (AC)',
    'Alagoas (AL)',
    'Amapá (AP)',
    'Amazonas (AM)',
    'Bahia (BA)',
    'Ceará (CE)',
    'Distrito Federal (DF)',
    'Espírito Santo (ES)',
    'Goiás (GO)',
    'Maranhão (MA)',
    'Mato Grosso (MT)',
    'Mato Grosso do Sul (MS)',
    'Minas Gerais (MG)',
    'Pará (PA)',
    'Paraíba (PB)',
    'Paraná (PR)',
    'Pernambuco (PE)',
    'Piauí (PI)',
    'Rio de Janeiro (RJ)',
    'Rio Grande do Norte (RN)',
    'Rio Grande do Sul (RS)',
    'Rondônia (RO)',
    'Roraima (RR)',
    'Santa Catarina (SC)',
    'São Paulo (SP)',
    'Sergipe (SE)',
    'Tocantins (TO)',
]

const UserInfoForm: React.FC<{
  form: INewCreditCardForm,
  setForm: (form: INewCreditCardForm) => void
}> = ({form, setForm}) => {

    useEffect(() => {
        console.log(form)
    }, [form])

    return (
        <Box
        sx={{
            width: '100%',
            marginTop: '30px',
        }}
        >
            <Box
                sx={{
                    width: '100%',
                    marginTop: '30px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    "@media (max-width: 720px)": {
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        rowGap: '30px'
                    }
                }}
            >
                <Box
                    sx={{
                        width: '53%',
                        "@media (max-width: 720px)": {
                            width: '100%',
                        }
                    }}
                >
                    <Typography
                        sx={{
                        fontSize: '18px',
                        fontFamily: 'Lato',
                        color: '#7c7c8d'
                        }}
                    >
                        Nome Completo<span style={{color: 'red'}}>*</span>
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Digite aqui..."
                        autoComplete="off"
                        value={form.fullname}
                        onChange={(e) => {
                            setForm({
                                ...form,
                                fullname: e.target.value
                            });
                        }}
                        sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                            borderColor: '#e9eaec',
                            borderRadius: '10px'
                            },
                        },
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        width: '45%',
                        "@media (max-width: 720px)": {
                            width: '100%',
                        }
                    }}
                >
                    <Typography
                        sx={{
                        fontSize: '18px',
                        fontFamily: 'Lato',
                        color: '#7c7c8d'
                        }}
                    >
                        CPF/CNPJ<span style={{color: 'red'}}>*</span>
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Digite aqui..."
                        autoComplete="off"
                        value={form.cpfOrCnpj}
                        onChange={(e) => {
                            if(e.target.value.length > 14) return;
                            setForm({
                                ...form,
                                cpfOrCnpj: e.target.value.replace(/\D/g, '')
                            });
                        }}
                        sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                            borderColor: '#e9eaec',
                            borderRadius: '10px'
                            },
                        },
                        }}
                    />
                </Box>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    marginTop: '30px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    "@media (max-width: 720px)": {
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        rowGap: '30px'
                    }
                }}
            >
                <Box 
                    sx={{
                        width: '25%',
                        "@media (max-width: 720px)": {
                            width: '100%',
                        }
                    }}
                >
                    <Typography
                        sx={{
                        fontSize: '18px',
                        fontFamily: 'Lato',
                        color: '#7c7c8d'
                        }}
                    >
                        CEP<span style={{color: 'red'}}>*</span>
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Digite aqui..."
                        autoComplete="off"
                        value={form.address.cep}
                        onChange={(e) => {
                            if(e.target.value.length > 9) return;
                            setForm({
                                ...form,
                                address: {
                                    ...form.address,
                                    cep: e.target.value.replace(/\D/g, '')
                                }
                            });
                        }}
                        sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                            borderColor: '#e9eaec',
                            borderRadius: '10px'
                            },
                        },
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        width: '73%',
                        "@media (max-width: 720px)": {
                            width: '100%',
                        }
                    }}
                >
                    <Typography
                        sx={{
                        fontSize: '18px',
                        fontFamily: 'Lato',
                        color: '#7c7c8d'
                        }}
                    >
                        Rua<span style={{color: 'red'}}>*</span>
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Digite aqui..."
                        autoComplete="off"
                        value={form.address.street}
                        onChange={(e) => {
                            setForm({
                                ...form,
                                address: {
                                    ...form.address,
                                    street: e.target.value
                                }
                            });
                        }}
                        sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                            borderColor: '#e9eaec',
                            borderRadius: '10px'
                            },
                        },
                        }}
                    />
                </Box>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    marginTop: '30px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    "@media (max-width: 720px)": {
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        rowGap: '30px'
                    }
                }}
            >
                <Box
                    sx={{
                        width: '25%',
                        "@media (max-width: 720px)": {
                            width: '100%',
                        }
                    }}
                >
                    <Typography
                        sx={{
                        fontSize: '18px',
                        fontFamily: 'Lato',
                        color: '#7c7c8d'
                        }}
                    >
                        Número<span style={{color: 'red'}}>*</span>
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Digite aqui..."
                        autoComplete="off"
                        value={form.address.number}
                        onChange={(e) => {
                            if(e.target.value.length > 5) return;
                            setForm({
                                ...form,
                                address: {
                                    ...form.address,
                                    number: e.target.value.replace(/\D/g, '')
                                }
                            });
                        }}
                        sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                            borderColor: '#e9eaec',
                            borderRadius: '10px'
                            },
                        },
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        width: '73%',
                        "@media (max-width: 720px)": {
                            width: '100%',
                        }
                    }}
                >
                    <Typography
                        sx={{
                        fontSize: '18px',
                        fontFamily: 'Lato',
                        color: '#7c7c8d'
                        }}
                    >
                        Complemento<span style={{color: 'red'}}>*</span>
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Digite aqui..."
                        autoComplete="off"
                        value={form.address.complement}
                        onChange={(e) => {
                            setForm({
                                ...form,
                                address: {
                                    ...form.address,
                                    complement: e.target.value
                                }
                            });
                        }}
                        sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                            borderColor: '#e9eaec',
                            borderRadius: '10px'
                            },
                        },
                        }}
                    />
                </Box>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    marginTop: '30px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    "@media (max-width: 720px)": {
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        rowGap: '30px'
                    }
                }}
            >
                <Box
                    sx={{
                        width: '32%',
                        "@media (max-width: 720px)": {
                            width: '100%',
                        }
                    }}
                >
                    <Typography
                        sx={{
                        fontSize: '18px',
                        fontFamily: 'Lato',
                        color: '#7c7c8d'
                        }}
                    >
                        Bairro<span style={{color: 'red'}}>*</span>
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Digite aqui..."
                        autoComplete="off"
                        value={form.address.neighborhood}
                        onChange={(e) => {
                            setForm({
                                ...form,
                                address: {
                                    ...form.address,
                                    neighborhood: e.target.value
                                }
                            });
                        }}
                        sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                            borderColor: '#e9eaec',
                            borderRadius: '10px'
                            },
                        },
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        width: '32%',
                        "@media (max-width: 720px)": {
                            width: '100%',
                        }
                    }}
                >
                    <Typography
                        sx={{
                        fontSize: '18px',
                        fontFamily: 'Lato',
                        color: '#7c7c8d'
                        }}
                    >
                        Cidade<span style={{color: 'red'}}>*</span>
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Digite aqui..."
                        autoComplete="off"
                        value={form.address.city}
                        onChange={(e) => {
                            setForm({
                                ...form,
                                address: {
                                    ...form.address,
                                    city: e.target.value
                                }
                            });
                        }}
                        sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                            borderColor: '#e9eaec',
                            borderRadius: '10px'
                            },
                        },
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        width: '32%',
                        "@media (max-width: 720px)": {
                            width: '100%',
                        }
                    }}
                >
                    <Typography
                        sx={{
                        fontSize: '18px',
                        fontFamily: 'Lato',
                        color: '#7c7c8d'
                        }}
                    >
                        Estado
                    </Typography>
                    <TextField
                        fullWidth
                        select
                        variant="outlined"
                        placeholder="Digite aqui..."
                        value={form.address.state}
                        onChange={(e) => {
                            setForm({
                                ...form,
                                address: {
                                    ...form.address,
                                    state: e.target.value
                                }
                            });
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                borderColor: '#e9eaec',
                                borderRadius: '10px'
                                },
                            },
                        }}
                    >
                        {states.map((state) => (
                            <MenuItem key={state} value={state}>
                                {state}
                            </MenuItem>
                        ))}
                    </TextField>
                </Box>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    marginTop: '30px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    "@media (max-width: 720px)": {
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        rowGap: '30px'
                    }
                }}
            >
                <Box
                    sx={{
                        width: '56%',
                        "@media (max-width: 720px)": {
                            width: '100%',
                        }
                    }}
                >
                    <Typography
                        sx={{
                        fontSize: '18px',
                        fontFamily: 'Lato',
                        color: '#7c7c8d'
                        }}
                    >
                        E-mail<span style={{color: 'red'}}>*</span>
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Digite aqui..."
                        autoComplete="off"
                        value={form.email}
                        onChange={(e) => {
                            setForm({
                                ...form,
                                email: e.target.value
                            });
                        }}
                        sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                            borderColor: '#e9eaec',
                            borderRadius: '10px'
                            },
                        },
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        width: '42%',
                        "@media (max-width: 720px)": {
                            width: '100%',
                        }
                    }}
                >
                    <Typography
                        sx={{
                        fontSize: '18px',
                        fontFamily: 'Lato',
                        color: '#7c7c8d'
                        }}
                    >
                        Celular<span style={{color: 'red'}}>*</span>
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Digite aqui..."
                        autoComplete="off"
                        value={form.cellphone}
                        onChange={(e) => {
                            if(e.target.value.length > 14) return;
                            setForm({
                                ...form,
                                cellphone: e.target.value.replace(/\D/g, '')
                            });
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                borderColor: '#e9eaec',
                                borderRadius: '10px'
                                },
                            },
                        }}  
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default UserInfoForm;
