import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Box } from '@mui/material';
import Header from '../../components/header/index.tsx';
import Sidebar from '../../components/sidebar/index.tsx';
import ComunicationMainScreen from './ComunicationMainScreen/index.tsx';
import NewCampaignScreen from './NewCampaignScreen/index.tsx';
import ComunicationCampaignSteps from '../../components/comunicationCampaignSteps/index.tsx';
import DefinePublicStep from './DefinePublicStep/index.tsx';
import ResumeAndActivateStep from './ResumeAndActivateStep/index.tsx';
import PageContentContainer from '../../components/PageContentContainer/index.tsx';
import MobileHeader from '../../components/MobileHeader/index.tsx';

const SATISFACTION_MESSAGE = `Oi joão! Aqui quem fala é Julia Laux.\n Você
teve uma consulta com Julia Laux e queremos saber o que você achou do
atendimento. Estamos sempre buscando melhorar e sua opinião é muito importante.
Responda de 1 a 10, qual a sua nota para o atendimento?`

const LATE_PAYMENTS_MESSAGE = `Olá, João! Tudo bem? Gostariamos de avisar
que você está com um débito em atraso no(a) Julía Laux. Mas, fica tranquilo(a)
que você pode resolver isso. O telefone (51) 98012-2409 é pra você nos contatar
e resolver rapidinho! Abraços!`

const POSTOPERATIVE_MESSAGE = `Oi, João!<br/>Esperamos que esteja tudo bem!
Passando aqui para te avisar: não se esqueça dos remédios na hora certa. E
aproveite para seguir todas as nossas orientações certinho, ok?!<br/>Nosso
telefone é (51) 98012-2409 e pode ficar a vontade para ligar se surgir alguma
dúvida. Toda a equipe Julia Laux manda um abraço.`

const PAYMENT_DUE_MESSAGE = `Oi, João! Viemos para te lembrar que amanhã vence
seu boleto do(a) Julia Laux. Aproveite para pagar em dia! Qualquer dúvida sobre
boleto, nosso telefone é (51)9 8012-2409. Um abraço`

const DENTAL_FLOSS_MESSAGE = `Oi João! Temos uma dica do(a) Julia Laux que vai
ajudar a cuidar da sua saúde bucal: o uso regular de fio dental ajuda a prevenir
cáries, gengivite e periodontite. Cuide do seu sorriso. Abraços!`

const DENTAL_FLOSS_RESUME_MESSAGE = `A DOT verifica diariamente quais
pacientes possuem boletos para vencer, e envia uma mensagem 24 horas antes da
data de vencimento do boleto`

const INACTIVE_RETRIEVAL_MESSAGE = `Olá João! Já faz tempo desde sua última
visita no(a) Julia Laux. Não fique tanto tempo sem cuidar do seu sorriso. Você
pode nos contatar pelo telefone (51)9 8012-2409 em caso de qualquer dúvida`

const cellPhoneMessage = {
    'satisfaction': SATISFACTION_MESSAGE,
    'late-payments': LATE_PAYMENTS_MESSAGE,
    'postoperative': POSTOPERATIVE_MESSAGE,
    'payment-due': PAYMENT_DUE_MESSAGE,
    'dental-floss': DENTAL_FLOSS_MESSAGE,
    'inactive-retrieval': INACTIVE_RETRIEVAL_MESSAGE,
}

// TODO: do a message for every type of campaign
const resumeMessage ={
    'satisfaction': DENTAL_FLOSS_RESUME_MESSAGE,
    'late-payments': DENTAL_FLOSS_RESUME_MESSAGE,
    'postoperative': DENTAL_FLOSS_RESUME_MESSAGE,
    'payment-due': DENTAL_FLOSS_RESUME_MESSAGE,
    'dental-floss': DENTAL_FLOSS_RESUME_MESSAGE,
    'inactive-retrieval': DENTAL_FLOSS_RESUME_MESSAGE,
}

const campaignTypeNames = {
    'late-payments': 'Débitos em atraso',
    'postoperative': 'Pós-operatório',
    'payment-due': 'Vencimento de boleto',
    'dental-floss': 'Fio dental',
    'inactive-retrieval': 'Recuperação de inativos',
    'satisfaction': 'Satisfação',
}
const possiblePlanFilters = [ 'Todos', 'Básico', 'Intermediário', 'Avançado']
const possibleGenderFilters = [ 'Todos', 'Masculino', 'Feminino']
const possibleSpecialtyFilters = [ 'Todos', 'Clínico Geral', 'Ortodontia', 'Implantodontia', 'Endodontia', 'Periodontia', 'Odontopediatria', 'Cirurgia', 'Estética', 'Prótese', 'Outros']
const possiblePaymentLateDateFilters = [ '30 dias', '60 dias', '90 dias', '120 dias']

const ComunicationPage: React.FC = () => {
    const query = new URLSearchParams(useLocation().search);
    const campaign = query.get('campaign');
    const [currentStep, setCurrentStep] = useState(1);
    const [selectedCampaignSender, setSelectedCampaignSender] = useState('');
    const [selectedCampaignType, setSelectedCampaignType] = useState('');
    const navigate = useNavigate()

    const [paymentLateDateFilter, setPaymentLateDateFilter] = React.useState(possiblePaymentLateDateFilters[0])
    const [planFilter, setPlanFilter] = React.useState(possiblePlanFilters[0])
    const [specialtyFilter, setSpecialtyFilter] = React.useState(possibleSpecialtyFilters[0])
    const [genderFilter, setGenderFilter] = React.useState(possibleGenderFilters[0])
    const [sendToDebtors, setSendToDebtors] = React.useState(false)
    const [noConsultationsIn, setNoConsultationsIn] = React.useState('')
    const [showDebtorMessage, setShowDebtorMessage] = React.useState(false)

    const [targets, setTargets] = useState('Todos os pacientes')
    const [campaignInit, setCampaignInit] = useState('A partir da data de ativação')

    useEffect(() => {
        if(planFilter !== 'Todos' || specialtyFilter !== 'Todos' || genderFilter !== 'Todos') {
            console.log('res')
            if(selectedCampaignType === 'late-payments'){
                setTargets(paymentLateDateFilter + ' - Personalizado')
            } else if (selectedCampaignType === 'inactive-retrieval') {
                setTargets(noConsultationsIn + ' - Personalizado')
            } else {
                setTargets('Personalizado')
            }
        } else {
            if(selectedCampaignType === 'late-payments'){
                setTargets(paymentLateDateFilter + ' - Todos os pacientes')
            } else if (selectedCampaignType === 'inactive-retrieval') {
                setTargets(noConsultationsIn + ' - Todos os pacientes')
            } else {
                setTargets('Todos os pacientes')
            }
        }
    },[planFilter, genderFilter, specialtyFilter, selectedCampaignType, noConsultationsIn])

    const cancel = () => {
        setCurrentStep(1)
        setSelectedCampaignSender('')
        setSelectedCampaignType('')
        navigate('/comunication')
    }

    return (
        <>
            <Header />
            <MobileHeader />
            <Sidebar />
            <PageContentContainer>
                {
                    campaign ? null : <ComunicationMainScreen />
                }
                {
                    campaign ? 
                        <>
                            <Box
                                sx={{
                                    backgroundColor: 'white',
                                    width: '100%',
                                    height: '120px',
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    alignItems: 'center',
                                    borderRadius: '5px',
                                    boxShadow: '0px 0px 5px 0px #c0c7d0',
                                    '@media (max-width: 632px)': {
                                        padding: '20px'
                                    }
                                }}
                            >
                                <ComunicationCampaignSteps currentStep={currentStep}/>
                            </Box>
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    height: 'calc(100vh - 100px - 120px)',
                                    overflow: 'auto',
                                }}
                            >
                                {currentStep === 1 &&
                                    <NewCampaignScreen
                                        goToNextStep={() => {setCurrentStep(2)}}
                                        selectedCampaignSender={selectedCampaignSender}
                                        setSelectedCampaignSender={setSelectedCampaignSender}
                                        selectedCampaignType={selectedCampaignType}
                                        setSelectedCampaignType={setSelectedCampaignType}
                                    />
                                }
                                {currentStep === 2 &&
                                    <DefinePublicStep
                                        selectedCampaignType={selectedCampaignType}
                                        goToNextStep={() => {setCurrentStep(3)}}
                                        goToPreviousStep={() => {setCurrentStep(1)}}
                                        cellPhoneMessage={cellPhoneMessage}
                                        campaignTypeNames={campaignTypeNames}
                                        paymentLateDateFilter={paymentLateDateFilter}
                                        setPaymentLateDateFilter={setPaymentLateDateFilter}
                                        planFilter={planFilter}
                                        setPlanFilter={setPlanFilter}
                                        specialtyFilter={specialtyFilter}
                                        setSpecialtyFilter={setSpecialtyFilter}
                                        genderFilter={genderFilter}
                                        setGenderFilter={setGenderFilter}
                                        sendToDebtors={sendToDebtors}
                                        setSendToDebtors={setSendToDebtors}
                                        noConsultationsIn={noConsultationsIn}
                                        setNoConsultationsIn={setNoConsultationsIn}
                                        showDebtorMessage={showDebtorMessage}
                                        setShowDebtorMessage={setShowDebtorMessage}
                                        cancel={cancel}
                                    />
                                }
                                {currentStep === 3 &&
                                    <ResumeAndActivateStep
                                        selectedCampaignType={selectedCampaignType}
                                        selectedCampaignSender={selectedCampaignSender}
                                        goToPreviousStep={() => {setCurrentStep(2)}}
                                        cellPhoneMessage={cellPhoneMessage}
                                        campaignTypeNames={campaignTypeNames}
                                        cancel={cancel}
                                        targets={targets}
                                        campaignInit={campaignInit}
                                        resumeMessage={resumeMessage}
                                        activateCampaign={cancel}
                                    />
                                }
                            </Box>
                        </> : null
                }
            </PageContentContainer>
        </>
    );
};

export default ComunicationPage;
