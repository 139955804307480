import { Box, Typography } from '@mui/material';
import React from 'react';
import BaseForIntelligenceSquare from '../../../../components/baseForIntelligenceSquare/index.tsx';
import dayjs, { Dayjs } from 'dayjs';
import { PieChart } from '@mui/x-charts/PieChart';
import { IBlockableIntelligenceSquareProps } from '../../../../types/intelligence.tsx';

const ENTRY_METHODS_DATA = [
    { id: 0, value: 10, label: 'Despesa 1', color: "#001b48"},
    { id: 2, value: 20, label: 'Despesa 2', color: "#02457a"},
    { id: 3, value: 5, label: 'Despesa 3', color: '#018abe'},
    { id: 4, value: 10, label: 'Despesa 4', color: "#97cadb"},
    { id: 5, value: 10, label: 'Despesa 5', color: "#b3f0e8"},
    { id: 1, value: 45, label: 'Despesa 6', color: "#d6e7ee"},
    { id: 6, value: 10, label: 'Despesa 7', color: "#8ee5dc" },
    { id: 9, value: 10, label: 'Despesa 8', color: "#2fc4b1"},
    { id: 10, value: 10, label: 'Despesa 9', color: "#359d9e"},
]

const SubtitleItem: React.FC<{color: string, label: string}> = ({color, label}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <Box
                sx={{
                    width: '30px',
                    height: '18px',
                    backgroundColor: color,
                    borderRadius: '20%',
                    marginRight: '10px',
                }}
            />
            <Typography
                sx={{
                    fontSize: '16px',
                    fontFamily: 'Lato',
                }}
            >
                {label}
            </Typography>
        </Box>
    )
}

const CostsByCategorySquare: React.FC<IBlockableIntelligenceSquareProps> = ({
    enabled
}) => {
    const [total, setTotal] = React.useState<number>(ENTRY_METHODS_DATA.reduce((acc, entryMethod) => acc + entryMethod.value, 0));
    const [selectedDate, setSelectedDate] = React.useState<Dayjs | null>(dayjs());
    const [files, setFiles] = React.useState<File[] | null>(null);
    return (
        <BaseForIntelligenceSquare
            title = "Despesas por categoria"
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            setFiles={setFiles}
        >
            <Box
                sx={{
                    minHeight: '400px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                    "@media (max-width: 684px)": {
                        flexDirection: 'column',
                    }
                }}
            >
                {!enabled && (
                    <Box
                        sx={{
                            height: '100%',
                            width: '100%',
                            position: 'absolute',
                            zIndex: 2,
                            backgroundColor: 'rgba(255, 255, 255, 0.73)',
                            pointerEvents: 'none',
                        }}
                    />
                )}
                <Box
                    sx={{
                        width: '200px',
                        position: 'relative',
                    }}
                >
                    <PieChart
                        series={[
                            {
                                data: ENTRY_METHODS_DATA,
                                innerRadius: 80,
                                highlightScope: { fade: 'global', highlight: 'item' },
                                faded: { innerRadius: 50, additionalRadius: -30, color: 'gray' },
                            },
                            {
                                data: [{ value: total, label: 'Total', color: '#001b48'}],
                                innerRadius: 0,
                                outerRadius: 50,
                            }
                        ]}
                        slotProps={{
                            legend: {
                                hidden: true
                            }
                        }}
                        width={300}
                        height={200}
                    />
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            pointerEvents: 'none',
                            display: 'flex',
                            alignItems: 'center',
                            color: 'white'
                        }}
                    >
                        <Typography
                            sx={{
                                marginRight: '4px',
                                fontSize: '10px',
                            }}
                        >R$</Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',

                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                }}
                            >Total</Typography>
                            <Typography
                                sx={{
                                    fontWeight: 'bold',
                                    fontSize: '15px'
                                }}
                            >{total.toFixed(2)}</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        "@media (max-width: 684px)": {
                            justifyContent: 'space-between',
                            width: '60%'
                        },
                        "@media (max-width: 488px)": {
                            flexDirection: 'column',
                            alignItems: 'center',
                            marginY: '20px',
                        }
                    }}
                >
                    <Box
                        sx={{
                            marginRight: '60px',
                            marginLeft: '90px',
                            "@media (max-width: 684px)": {
                                marginLeft: '0px',
                                marginRight: '0px',
                            },
                        }}
                    >
                        {ENTRY_METHODS_DATA.slice(0,5).map((entryMethod, index) => (
                            <SubtitleItem
                                key={index}
                                color={entryMethod.color}
                                label={entryMethod.label}
                            />
                        ))}
                    </Box>
                    <Box>
                        {ENTRY_METHODS_DATA.slice(5).map((entryMethod, index) => (
                            <SubtitleItem
                                key={index}
                                color={entryMethod.color}
                                label={entryMethod.label}
                            />
                        ))}
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography
                    sx={{
                        fontSize: '20px',
                        fontFamily: 'Lato',
                        fontWeight: 'bold',
                        textAlign: 'center',
                    }}
                >
                    Nenhuma categoria de despesa encontrada no periodo selecionado. Você pode
                </Typography>
                <Typography
                    sx={{
                        fontSize: '20px',
                        fontFamily: 'Lato',
                        fontWeight: 'bold',
                        textAlign: 'center',
                    }}
                >
                    escolher outro período ou fazer lançamentos no financeiro
                </Typography>
            </Box>
        </BaseForIntelligenceSquare>
    )
}

export default CostsByCategorySquare;