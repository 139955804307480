import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoginPage from './pages/login/LoginPage.js';
import RegisterPage from './pages/register/RegisterPage.js';
import LandingPage from './pages/landing-page/LandingPage.tsx';
import AgendaPage from './pages/agenda/index.tsx';
import PatientPage  from './pages/pacient/index.tsx';
import FinancialPage from './pages/financial/index.tsx';
import StoragePage from './pages/storage/index.tsx';
import SettingsPage from './pages/settings/index.tsx';
import ComunicationPage from './pages/comunication/index.tsx';
import { SettingsProvider } from './hooks/settings.tsx';
import { AuthProvider } from './context/AuthProvide.js';
import ProtectedRoute from './context/ProtectedRoute.js';
import PublicRoute from './context/PublicRoute.js';
import LogoutPage from './pages/logout/LogoutPage.js';
import AccountScreen from './pages/account/index.tsx';
import UserPaymentDataPage from './pages/user-payment-data/index.tsx';
import PlansPage from './pages/plans/index.tsx';
import IntelligencePage from './pages/intelligence/index.tsx';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

function App() {
  return (
    <Router>
      <AuthProvider>
        <SettingsProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<PublicRoute element={<LoginPage />} />} />
            <Route path='/register' element={<PublicRoute element={<RegisterPage />} />} />
            <Route path="/logout" element={<LogoutPage />} />
            <Route path='/agenda' element={<ProtectedRoute element={<AgendaPage />} />} />
            <Route path='/pacientes' element={<ProtectedRoute element={<PatientPage />} />} />
            <Route path='/financial' element={<ProtectedRoute element={<FinancialPage />} />} />
            <Route path='/comunication' element={<ProtectedRoute element={<ComunicationPage />} />} />
            <Route path='/storage' element={<ProtectedRoute element={<StoragePage />} />} />
            <Route path='/settings' element={<ProtectedRoute element={<SettingsPage />} />} />
            <Route path='/account' element={<ProtectedRoute element={<AccountScreen />} />} />
            <Route path='/payment-details' element={<ProtectedRoute element={<UserPaymentDataPage />} />} />
            <Route path='/plans' element={<PlansPage />} />
            <Route path='/intelligence' element={<ProtectedRoute element={< IntelligencePage/>} />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
          </LocalizationProvider>
        </SettingsProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
