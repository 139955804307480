import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    TextField,
    IconButton,
    Dialog
} from '@mui/material';
import Header from '../../components/header/index.tsx';
import Sidebar from '../../components/sidebar/index.tsx';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import PageContentContainer from '../../components/PageContentContainer/index.tsx';
import MobileHeader from '../../components/MobileHeader/index.tsx';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { configBackendConnection, endpoints } from '../../components/utilsBackend.ts';
import PatientDetail from '../../components/patientDetail/index.tsx';

interface Plan {
    id: number;
    name: string;
}

interface Patient {
    id: number;
    name: string;
    medicalRecord: string | null;
    age: number;
    cpf: string;
    phone: string;
    sex: string; // Updated from gender to sex
    patientNumber: number;
    plan: Plan; // Updated to reflect that plan is an object with id and name
}

const PatientPage: React.FC = () => {
    const [patients, setPatients] = useState<Patient[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedPatient, setSelectedPatient] = useState<Patient | null>(null);
    const [isDetailModalOpen, setDetailModalOpen] = useState(false);

    const fetchPatients = async () => {
        try {
            const response = await axios.get(`${configBackendConnection.baseURL}/${endpoints.patientAPI}`, {
                headers: configBackendConnection.headersDefault
            });
            setPatients(response.data);
        } catch (error) {
            toast.error('Erro ao buscar os pacientes.');
        }
    };

    const handleDelete = async (id: number) => {
        const deleteUrl = `${configBackendConnection.baseURL}/${endpoints.patientAPI}${id}/`;

        try {
            await axios.delete(deleteUrl, {
                headers: configBackendConnection.headersDefault
            });
            toast.success('Paciente removido com sucesso!');
            fetchPatients();
        } catch (error) {
            toast.error('Erro ao remover o paciente.');
        }
    };

    useEffect(() => {
        fetchPatients();
    }, []);

    const handleOpenDetailModal = (patient: Patient) => {
        setSelectedPatient(patient);
        setDetailModalOpen(true);
    };

    const handleCloseDetailModal = () => {
        setDetailModalOpen(false);
        setSelectedPatient(null);
    };

    const filteredPatients = patients.filter(patient =>
        patient.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <>
            <Header />
            <MobileHeader />
            <Sidebar />
            <PageContentContainer>
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        padding: '30px',
                        flexDirection: 'column',
                    }}
                >
                    <Box
                        sx={{
                            height: '40px',
                            '@media (max-width: 524px)': {
                                height: '80px',
                            },
                        }}
                    >
                        <Button
                            sx={{
                                color: 'white',
                                backgroundColor: '#006af5',
                                textTransform: 'none',
                                marginRight: '10px',
                            }}
                            onClick={() => {
                                toast.info("Funcionalidade de adicionar paciente.");
                            }}
                        >
                            Adicionar Paciente
                        </Button>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            height: { xs: '100%', md: '80%' },
                            backgroundColor: 'white',
                            padding: { xs: '10px', md: '0px 40px' },
                            paddingTop: '20px',
                            marginTop: '20px',
                            borderRadius: '10px',
                            overflowY: 'auto',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                height: '60px',
                                '@media (max-width: 600px)': {
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                },
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: { xs: 18, md: 25 },
                                    fontWeight: '600',
                                    fontFamily: 'Lato',
                                    color: '#505050',
                                }}
                            >
                                Pacientes
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: { xs: '10px', md: '0px' } }}>
                                <TextField
                                    placeholder="Buscar paciente"
                                    variant="outlined"
                                    size="small"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    sx={{ marginRight: '10px' }}
                                />
                            </Box>
                        </Box>
                        <Divider variant="middle" sx={{ marginBottom: '20px' }} />
                        <TableContainer sx={{ maxHeight: '60vh', overflowY: 'auto' }}>
                            <Table sx={{ minWidth: 300 }} aria-label="simple table" stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography
                                                sx={{
                                                    color: '#5b5b5b',
                                                    fontSize: 18,
                                                    fontFamily: 'DM Sans',
                                                }}
                                            >
                                                Nome
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center" sx={{ display: { xs: 'none', md: 'table-cell' } }}>
                                            <Typography
                                                sx={{
                                                    color: '#5b5b5b',
                                                    fontSize: 18,
                                                    fontFamily: 'DM Sans',
                                                }}
                                            >
                                                Prontuário
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center" sx={{ display: { xs: 'none', md: 'table-cell' } }}>
                                            <Typography
                                                sx={{
                                                    color: '#5b5b5b',
                                                    fontSize: 18,
                                                    fontFamily: 'DM Sans',
                                                }}
                                            >
                                                Idade
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center" sx={{ display: { xs: 'none', md: 'table-cell' } }}>
                                            <Typography
                                                sx={{
                                                    color: '#5b5b5b',
                                                    fontSize: 18,
                                                    fontFamily: 'DM Sans',
                                                }}
                                            >
                                                CPF
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center" sx={{ display: { xs: 'none', md: 'table-cell' } }}>
                                            <Typography
                                                sx={{
                                                    color: '#5b5b5b',
                                                    fontSize: 18,
                                                    fontFamily: 'DM Sans',
                                                }}
                                            >
                                                Celular do paciente
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="right"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredPatients.map((patient) => (
                                        <TableRow key={patient.id}>
                                            <TableCell component="th" scope="row">
                                                <Typography
                                                    sx={{
                                                        color: '#A5A5A5',
                                                        fontFamily: 'DM Sans',
                                                        cursor: 'pointer',
                                                        textDecoration: 'none',
                                                        '&:hover': {
                                                            textDecoration: 'underline',
                                                        }
                                                    }}
                                                    onClick={() => handleOpenDetailModal(patient)}
                                                >
                                                    {patient.name}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center" sx={{ display: { xs: 'none', md: 'table-cell' } }}>
                                                <Typography
                                                    sx={{
                                                        color: '#A5A5A5',
                                                        fontFamily: 'DM Sans',
                                                    }}
                                                >
                                                    {patient.medicalRecord || '-'}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center" sx={{ display: { xs: 'none', md: 'table-cell' } }}>
                                                <Typography
                                                    sx={{
                                                        color: '#A5A5A5',
                                                        fontFamily: 'DM Sans',
                                                    }}
                                                >
                                                    {patient.age}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center" sx={{ display: { xs: 'none', md: 'table-cell' } }}>
                                                <Typography
                                                    sx={{
                                                        color: '#A5A5A5',
                                                        fontFamily: 'DM Sans',
                                                    }}
                                                >
                                                    {patient.cpf}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center" sx={{ display: { xs: 'none', md: 'table-cell' } }}>
                                                <Typography
                                                    sx={{
                                                        color: '#A5A5A5',
                                                        fontFamily: 'DM Sans',
                                                    }}
                                                >
                                                    {patient.phone}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <IconButton onClick={() => handleDelete(patient.id)} sx={{ color: '#ff4d4d' }}>
                                                    <DeleteOutlineOutlinedIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            </PageContentContainer>

            <Dialog
                open={isDetailModalOpen}
                onClose={handleCloseDetailModal}
                maxWidth="lg"
                fullWidth
            >
                {selectedPatient && (
                    <PatientDetail
                        open={isDetailModalOpen}
                        onClose={handleCloseDetailModal}
                        patient={selectedPatient}
                    />
                )}
            </Dialog>
        </>
    );
};

export default PatientPage;
