import { Box, Typography } from '@mui/material';
import React from 'react';
import BaseForIntelligenceSquare from '../../../../components/baseForIntelligenceSquare/index.tsx';
import dayjs, { Dayjs } from 'dayjs';
import { BarChart } from '@mui/x-charts/BarChart';

const MOCKED_YELD_DATA: IBarYeldItem[] = [
    {
        month: 'Jan',
        thisYear: 400,
        lastYear: 240,
    },
    {
        month: 'Fev',
        thisYear: 0,
        lastYear: 0,
    },
    {
        month: 'Mar',
        thisYear: 200,
        lastYear: 139,
    },
    {
        month: 'Abr',
        thisYear: 278,
        lastYear: 390,
    },
    {
        month: 'Mai',
        thisYear: 0,
        lastYear: 0,
    },
    {
        month: 'Jun',
        thisYear: 239,
        lastYear: 380,
    },
    {
        month: 'Jul',
        thisYear: 349,
        lastYear: 430,
    },
    {
        month: 'Ago',
        thisYear: 349,
        lastYear: 430,
    },
    {
        month: 'Set',
        thisYear: 349,
        lastYear: 430,
    },
    {
        month: 'Out',
        thisYear: 349,
        lastYear: 430,
    },
    {
        month: 'Nov',
        thisYear: 349,
        lastYear: 430,
    },
    {
        month: 'Dez',
        thisYear: 349,
        lastYear: 430,
    }
]

const MOCKED_INCOME_EXPENSE_DATA: IBarIncomeExpenseItem[] = [
    {
        month: 'Jan',
        incomes: 400,
        expenses: 240,
    },
    {
        month: 'Fev',
        incomes: 0,
        expenses: 0,
    },
    {
        month: 'Mar',
        incomes: 200,
        expenses: 139,
    },
    {
        month: 'Abr',
        incomes: 278,
        expenses: 100,
    },
    {
        month: 'Mai',
        incomes: 0,
        expenses: 0,
    },
    {
        month: 'Jun',
        incomes: 239,
        expenses: 380,
    },
    {
        month: 'Jul',
        incomes: 349,
        expenses: 430,
    },
    {
        month: 'Ago',
        incomes: 349,
        expenses: 430,
    },
    {
        month: 'Set',
        incomes: 349,
        expenses: 430,
    },
    {
        month: 'Out',
        incomes: 349,
        expenses: 430,
    },
    {
        month: 'Nov',
        incomes: 349,
        expenses: 430,
    },
    {
        month: 'Dez',
        incomes: 349,
        expenses: 430,
    }
]

const SubtitleItem: React.FC<{color: string, label: string}> = ({color, label}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                width: '220px',
            }}
        >
            <Box
                sx={{
                    width: '30px',
                    height: '18px',
                    backgroundColor: color,
                    borderRadius: '20%',
                    marginRight: '10px',
                }}
            />
            <Typography
                sx={{
                    fontSize: '16px',
                    fontFamily: 'Lato',
                }}
            >
                {label}
            </Typography>
        </Box>
    )
}

interface IBarYeldItem {
    month: string;
    thisYear: number;
    lastYear: number;
}

interface IBarIncomeExpenseItem {
    month: string;
    incomes: number;
    expenses: number;
}

interface IAverageOrderValueData {
    averageYeldByPatient: number;
    totalYeldByPeriod: number;
    totalIncome: number;
    totalExpenses: number;
    yeldGraphData: IBarYeldItem[];
    totalYeldGraphData: IBarYeldItem[];
    incomeExpenseGraphData: IBarIncomeExpenseItem[];
}

const AverageOrderValueSquare: React.FC = () => {
    const [selectedDate, setSelectedDate] = React.useState<Dayjs | null>(dayjs());
    const [averageOrderValueData, setAverageOrderValueData] = React.useState<IAverageOrderValueData>({
        averageYeldByPatient: 0,
        totalYeldByPeriod: 0,
        totalIncome: 0,
        totalExpenses: 0,
        yeldGraphData: MOCKED_YELD_DATA,
        totalYeldGraphData: MOCKED_YELD_DATA.map((item) => ({
            ...item,
            thisYear: item.thisYear * (Math.random() * 10),
            lastYear: item.lastYear * (Math.random() * 8)
        })),
        incomeExpenseGraphData: MOCKED_INCOME_EXPENSE_DATA,
    });

    // Função para formatar valores
    const formatValue = (value) => {
        if (value >= 1_000_000_000_000) {
            return `${(value / 1_000_000_000_000).toFixed(1)}T`;
        } else if (value >= 1_000_000_000) {
            return `${(value / 1_000_000_000).toFixed(1)}B`;
        } else if (value >= 1_000_000) {
            return `${(value / 1_000_000).toFixed(1)}M`;
        } else if (value >= 1_000) {
            return `${(value / 1_000).toFixed(1)}k`;
        } else {
            return value;
        }
    };

    return (
        <BaseForIntelligenceSquare
            title = "Ticket Médio"
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
        >
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    "@media (max-width: 677px)": {
                        flexDirection: 'column',
                    }
                }}
            >
                <Box
                    sx={{
                        marginTop: '40px',
                        width: '150px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                color: '#505050',
                                fontWeight: 'bold',
                            }}
                        variant="h6">R$ {averageOrderValueData.averageYeldByPatient}</Typography>
                        <Typography
                            sx={{color: '#77828f'}}
                            variant="body1"
                        >Rendimento médio</Typography>
                        <Typography
                            sx={{color: '#77828f'}}
                            variant="body1"
                        >por paciente</Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: 'calc(100% - 200px)',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        "@media (max-width: 677px)": {
                            width: '100%',
                        }
                    }}
                >
                    <Box sx={{width: '100%', height: '300px'}}>
                        <BarChart
                            sx={{
                                width: '100%',
                                height: '100%',
                            }}
                            dataset={averageOrderValueData.yeldGraphData as any}
                            series={[
                                { label: 'Ano Corrente', dataKey: 'thisYear', stack: 'a', color: '#20a200' },
                                { label: 'Ano passado', dataKey: 'lastYear', stack: 'a', color: '#ece9f1' },
                            ]}
                            xAxis={[{
                                scaleType: 'band',
                                dataKey: 'month',
                                disableLine: true,
                                disableTicks: true,
                            }]}
                            yAxis={[{
                                scaleType: 'linear',
                                disableTicks: true,
                                disableLine: true,
                                valueFormatter: (value) => `R$ ${formatValue(value).toLocaleString('pt-BR')}`,
                            }]}
                            borderRadius={40}
                            slotProps={{
                                legend: { hidden: true },
                            }}
                            margin={{left: 60}}
                        />
                    </Box>
                    <Box sx={{display: 'flex', width: '100%', justifyContent: 'flex-end'}}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginTop: '20px',
                                width: '100%',
                                maxWidth: '350px'
                            }}
                        >
                            <SubtitleItem
                                color="#20a200"
                                label="Ano Corrente"
                            />
                            <SubtitleItem
                                color="#ece9f1"
                                label="Ano passado"
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    "@media (max-width: 677px)": {
                        flexDirection: 'column',
                    }
                }}
            >
                <Box
                    sx={{
                        marginTop: '40px',
                        width: '150px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                color: '#505050',
                                fontWeight: 'bold',
                            }}
                        variant="h6">R$ {averageOrderValueData.averageYeldByPatient}</Typography>
                        <Typography
                            sx={{color: '#77828f'}}
                            variant="body1"
                        >Lucratividade total</Typography>
                        <Typography
                            sx={{color: '#77828f'}}
                            variant="body1"
                        >do periodo</Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: 'calc(100% - 200px)',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        "@media (max-width: 677px)": {
                            width: '100%',
                        }
                    }}
                >
                    <Box sx={{width: '100%', height: '300px'}}>
                        <BarChart
                            sx={{
                                width: '100%',
                                height: '100%',
                            }}
                            dataset={averageOrderValueData.totalYeldGraphData as any}
                            series={[
                                { label: 'Ano Corrente', dataKey: 'thisYear', stack: 'a', color: '#20a200' },
                                { label: 'Ano passado', dataKey: 'lastYear', stack: 'a', color: '#ece9f1' },
                            ]}
                            xAxis={[{
                                scaleType: 'band',
                                dataKey: 'month',
                                disableLine: true,
                                disableTicks: true,
                            }]}
                            yAxis={[{
                                scaleType: 'linear',
                                disableTicks: true,
                                disableLine: true,
                                valueFormatter: (value) => `R$ ${formatValue(value).toLocaleString('pt-BR')}`,
                            }]}
                            borderRadius={40}
                            slotProps={{
                                legend: { hidden: true },
                            }}
                            margin={{left: 60}}
                        />
                    </Box>
                    <Box sx={{display: 'flex', width: '100%', justifyContent: 'flex-end'}}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginTop: '20px',
                                width: '100%',
                                maxWidth: '350px'
                            }}
                        >
                            <SubtitleItem
                                color="#20a200"
                                label="Ano Corrente"
                            />
                            <SubtitleItem
                                color="#ece9f1"
                                label="Ano passado"
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    "@media (max-width: 677px)": {
                        flexDirection: 'column',
                    }
                }}
            >
                <Box
                    sx={{
                        marginTop: '40px',
                        width: '150px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                color: '#20a200',
                            }}
                        variant="h6">R$ {averageOrderValueData.totalIncome.toFixed(2)}</Typography>
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                color: '#d00303',
                            }}
                        variant="h6">R$ {averageOrderValueData.totalExpenses.toFixed(2)}</Typography>
                        <Typography
                            sx={{color: '#77828f'}}
                            variant="body1"
                        >Total de receitas e</Typography>
                        <Typography
                            sx={{color: '#77828f'}}
                            variant="body1"
                        >despesas</Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: 'calc(100% - 200px)',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        "@media (max-width: 677px)": {
                            width: '100%',
                        }
                    }}
                >
                    <Box sx={{width: '100%', height: '400px'}}>
                        <BarChart
                            sx={{
                                width: '100%',
                                height: '100%',
                            }}
                            dataset={averageOrderValueData.incomeExpenseGraphData as any}
                            series={[
                                { label: 'Receitas', dataKey: 'incomes', stack: 'a', color: '#20a200' },
                                { label: 'Despesas', dataKey: 'expenses', stack: 'a', color: '#d00303' },
                            ]}
                            xAxis={[{
                                scaleType: 'band',
                                dataKey: 'month',
                                disableLine: true,
                                disableTicks: true,
                            }]}
                            yAxis={[{
                                scaleType: 'linear',
                                disableTicks: true,
                                disableLine: true,
                                valueFormatter: (value) => `R$ ${formatValue(value).toLocaleString('pt-BR')}`,
                            }]}
                            borderRadius={40}
                            slotProps={{
                                legend: { hidden: true },
                            }}
                            margin={{left: 60}}
                        />
                    </Box>
                    <Box sx={{display: 'flex', width: '100%', justifyContent: 'flex-end'}}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginTop: '20px',
                                width: '100%',
                                maxWidth: '350px'
                            }}
                        >
                            <SubtitleItem
                                color="#20a200"
                                label="Receitas"
                            />
                            <SubtitleItem
                                color="#d00303"
                                label="Despesas"
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </BaseForIntelligenceSquare>
    )
}

export default AverageOrderValueSquare;