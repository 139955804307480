import React, { FC, createContext, useState, useContext, useMemo } from "react";
import { IClinicData, ISettingsContext } from "../types/settings";

const SettingsContext = createContext<ISettingsContext>(
    {} as ISettingsContext,
);

export const SettingsProvider: FC<any> = ({ children }) => {
    const [clinicData, setClinicData] = useState<IClinicData>({
        data_clinic: {
            clinic_name: "",
            clinic_registration: "",
            name_used_in_communications: "",
            responsible_clinic: ""
        },
        opening_hour: {
            init_hours: "",
            final_hours: "",
            gmt: "",
        },
        fiscal_name: "",
        information_clinic: {
            email: "",
            telephone: "",
            phone: "",
            cep: "",
            street: "",
            number: "",
            complement: "",
            neighborhood: "",
            city: "",
            state: "",
        },
    });

    return (
        <SettingsContext.Provider value={
            useMemo(() => ({
                clinicData,
                setClinicData,
            }), [
                clinicData,
                setClinicData,
            ])
        }>
            {children}
        </SettingsContext.Provider>
    );
};

export function useSettings() {
    const context = useContext(SettingsContext);

    if (!context) {
        throw new Error(
            "useSettings must be used within an useUserContextProvider",
        );
    }

    return context;
}