const DEFAULT_VALUES = {
    userCollaborator: "C",
    userManager: "M",
    userRoot: "R",
};

export const saveToStorage = (items) => {
    Object.keys(items).forEach(key => {
        localStorage.setItem(key, JSON.stringify(items[key]));
    });
};

export const getFromStorage = (key) => {
    if (key) {
        const value = localStorage.getItem(key);
        try {
            return value ? JSON.parse(value) : null;
        } catch (e) {
            console.error(`Error parsing JSON for key "${key}":`, value, e);
            return null;
        }
    }
    return null;
};


export const removeFromStorage = (...keys) => {
    keys.forEach(key => {
        localStorage.removeItem(key);
    });
};

export const getToken = () => {
    return getFromStorage('token');
};

export const getName = () => {
    return getFromStorage('name');
};

export const getTypeUser = () => {
    return getFromStorage('user_type');
};

export const isManager = () => {
    const userType = getTypeUser();
    return userType === DEFAULT_VALUES.userManager;
};

export const getRestrictedAccess = () => {
    return getFromStorage('restricted_access');
};

export const clearStorage = () => {
    localStorage.clear();
};