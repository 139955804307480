import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IModalProps } from '../../../types/modals';
import axios from 'axios';
import { configBackendConnection, endpoints } from '../../../components/utilsBackend.ts';

interface IRemoveStorageItem extends IModalProps {
    product: {
        id: number;
        name: string;
        quantity: number;
    };
    onProductRemoved: () => void;
}

const RemoveStorageItem: React.FC<IRemoveStorageItem> = ({
    open,
    setOpen,
    onClose,
    product,
    onProductRemoved
}) => {
    const [quantityToRemove, setQuantityToRemove] = useState<number>(1);

    const handleQuantityChange = (value: number) => {
        if (value < 1) {
            setQuantityToRemove(1);
        } else if (value > product.quantity) {
            setQuantityToRemove(product.quantity);
        } else {
            setQuantityToRemove(value);
        }
    };

    const handleRemove = async () => {
        if (quantityToRemove > product.quantity || quantityToRemove < 1) {
            toast.error('Quantidade inválida para retirada.');
            return;
        }

        try {
            await axios.post(`${configBackendConnection.baseURL}/${endpoints.withdrawalHistoryAPI}`, {
                product: product.id,
                quantity_removed: quantityToRemove,
            }, {
                headers: configBackendConnection.headersDefault
            });

            const updatedQuantity = product.quantity - quantityToRemove;
            await axios.patch(`${configBackendConnection.baseURL}/${endpoints.productsAPI}${product.id}/`, {
                quantity: updatedQuantity
            }, {
                headers: configBackendConnection.headersDefault
            });

            toast.success('Produto retirado e estoque atualizado com sucesso.');
            onProductRemoved();

            if (onClose) {
                onClose();
            }
        } catch (error) {
            console.error('Erro ao retirar produto ou atualizar estoque:', error);
            toast.error('Erro ao retirar o produto ou atualizar o estoque.');
        }
    };

    return (
        <Modal
            open={open}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box 
                sx={{
                    width: '600px',
                    backgroundColor: 'white',
                    padding: '35px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    '@media (max-width: 600px)': {
                        width: '100vw'
                    },
                }}
            >
                <Typography
                    sx={{
                        fontSize: '22px',
                        fontWeight: 'bold',
                        color: '#505050'
                    }}
                >
                    Fazer retirada
                </Typography>
                <Box
                    sx={{ 
                        display: 'flex',
                        justifyContent: 'space-between', 
                        alignItems: 'center', 
                        p: 1,
                        flexWrap: 'wrap'
                    }}
                >
                    <Box sx={{ width: '45%', minWidth: '280px' }}>
                        <Typography>
                            Nome do Produto <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <TextField
                            variant="outlined"
                            value={product.name}
                            disabled={true}
                            fullWidth
                            size="small"
                            sx={{ mr: 1 }}
                        />
                    </Box>
                    <Box sx={{ width: '45%', minWidth: '100px' }}>
                        <Typography>Quantidade a ser retirada <span style={{ color: 'red' }}>*</span></Typography>
                        <TextField
                            variant="outlined"
                            type="number"
                            value={quantityToRemove}
                            onChange={(e) => handleQuantityChange(Number(e.target.value))}
                            size="small"
                            fullWidth
                            sx={{ mr: 1 }}
                        />
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        sx={{
                            color: 'black',
                            textTransform: 'none',
                        }}
                        onClick={onClose}
                    >
                        Cancelar
                    </Button>
                    <Button
                        sx={{
                            color: 'white',
                            textTransform: 'none',
                            backgroundColor: '#006af5',
                            '&:hover': {
                                backgroundColor: 'rgba(0, 106, 245, 0.7)',
                            }
                        }}
                        onClick={handleRemove}
                    >
                        Retirar do estoque
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default RemoveStorageItem;
