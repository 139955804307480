import React, { useState, useRef } from 'react';
import {
    Box,
    Avatar,
    Button,
    Tab,
    Tabs,
    Typography,
    Divider,
    Switch,
    Dialog,
    DialogContent,
    DialogActions,
    IconButton,
} from '@mui/material';
import { toast } from 'react-toastify';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import axios from 'axios';
import { configBackendConnection, endpoints } from '../../components/utilsBackend.ts';

interface Plan {
    id: number;
    name: string;
}

interface PatientDetailProps {
    patient: {
        id: number;
        name: string;
        sex: string;
        plan: Plan;
        photo?: string;
    };
    open: boolean;
    onClose: () => void;
}

const PatientDetail: React.FC<PatientDetailProps> = ({ patient, open, onClose }) => {
    const [activeTab, setActiveTab] = useState(0);
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
    const [isCameraOpen, setIsCameraOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [photoUrl, setPhotoUrl] = useState<string | undefined>(patient.photo);
    const [isCaptured, setIsCaptured] = useState(false);
    const videoRef = useRef<HTMLVideoElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [stream, setStream] = useState<MediaStream | null>(null);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
    };

    const handleOpenUploadModal = () => {
        setIsUploadModalOpen(true);
    };

    const handleCloseUploadModal = () => {
        setIsUploadModalOpen(false);
        setSelectedFile(null);
    };

    const uploadPhoto = async (file: File) => {
        const formData = new FormData();
        formData.append('photo', file);

        try {
            const response = await axios.patch(`${configBackendConnection.baseURL}/${endpoints.patientAPI}${patient.id}/`, formData, {
                headers: {
                    ...configBackendConnection.headersFile,
                },
            });
            const updatedPhotoUrl = response.data.photo;
            setPhotoUrl(updatedPhotoUrl);
            toast.success('Imagem enviada com sucesso!');
            setIsCameraOpen(false);
            handleCloseUploadModal();
        } catch (error) {
            toast.error('Erro ao enviar a imagem.');
        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            if (file.size > 10485760) {
                toast.error('O arquivo é muito grande. O limite é de 10MB.');
                return;
            }
            uploadPhoto(file);
        }
    };

    const handleCapture = () => {
        if (videoRef.current && canvasRef.current) {
            const canvas = canvasRef.current;
            const context = canvas.getContext('2d');
            if (context) {
                context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
                canvas.toBlob((blob) => {
                    if (blob) {
                        const file = new File([blob], 'captured-image.jpg', { type: 'image/jpeg' });
                        setSelectedFile(file);
                        setIsCaptured(true);
                        if (videoRef.current) {
                            videoRef.current.pause();
                        }
                    }
                });
            }
        }
    };

    const handleTakePhoto = async () => {
        setIsCaptured(false);
        setIsCameraOpen(true);

        try {
            const cameraStream = await navigator.mediaDevices.getUserMedia({ video: true });
            setStream(cameraStream);
            if (videoRef.current) {
                videoRef.current.srcObject = cameraStream;
                videoRef.current.play();
            }
        } catch (error) {
            toast.error('Erro ao acessar a câmera.');
        }
    };

    const handleRetakePhoto = () => {
        if (stream && videoRef.current) {
            videoRef.current.play();
            setIsCaptured(false);
        }
    };

    const handleFileClick = () => {
        const fileInput = document.getElementById('upload-image');
        if (fileInput) {
            fileInput.click();
        }
    };

    const handleCloseCameraModal = () => {
        if (stream) {
            stream.getTracks().forEach((track) => {
                track.stop();
            });
        }
        setIsCameraOpen(false);
    };

    return (
        <Box
            sx={{
                width: '100%',
                maxWidth: '1200px',
                margin: '0 auto',
                backgroundColor: 'white',
                padding: '20px',
                borderRadius: '8px',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Avatar
                    alt={patient.name}
                    src={photoUrl || '/path/to/default-avatar.jpg'}
                    sx={{ width: 80, height: 80, mr: 2, cursor: 'pointer' }}
                    onClick={handleOpenUploadModal}
                />
                <Box>
                    <Typography variant="h5">{patient.name}</Typography>
                    <Typography variant="body2">Nº paciente: {patient.id}</Typography>
                </Box>
                <Box sx={{ ml: 'auto' }}>
                    <Button variant="contained" color="primary" sx={{ mr: 2 }}>
                        Editar
                    </Button>
                    <Button variant="outlined">Analisar score</Button>
                </Box>
            </Box>

            <Tabs value={activeTab} onChange={handleChange} indicatorColor="primary" textColor="primary">
                <Tab label="Sobre" />
                <Tab label="Orçamentos" />
                <Tab label="Tratamentos" />
                <Tab label="Anamnese" />
                <Tab label="Imagens" />
                <Tab label="Documentos" />
                <Tab label="Débitos" />
            </Tabs>

            <Divider sx={{ my: 2 }} />

            {activeTab === 0 && (
                <Box>
                    <Typography variant="h6">Dados pessoais</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                        <Box>
                            <Typography variant="body2">Número paciente</Typography>
                            <Typography variant="body1">{patient.id}</Typography>
                        </Box>
                        <Box>
                            <Typography variant="body2">Sexo</Typography>
                            <Typography variant="body1">
                                {patient.sex === 'M' ? 'Masculino' : 'Feminino'}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="body2">Plano</Typography>
                            <Typography variant="body1">{patient.plan ? patient.plan.name : '-'}</Typography>
                        </Box>
                    </Box>

                    <Divider sx={{ my: 2 }} />

                    <Box>
                        <Typography variant="h6">Comunicação</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                            <Switch defaultChecked />
                            <Typography variant="body2">
                                Mensagens relacionadas ao serviço prestado
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            )}

            <Dialog open={isUploadModalOpen} onClose={handleCloseUploadModal} maxWidth="sm" fullWidth>
                <DialogContent>
                    <Box sx={{ textAlign: 'center', p: 2 }}>
                        <IconButton sx={{ fontSize: 60 }} color="primary" onClick={handleFileClick}>
                            <CloudUploadIcon fontSize="inherit" />
                        </IconButton>
                        <Typography variant="h6" sx={{ mb: 2 }}>Enviar imagem de perfil</Typography>
                        <Typography variant="body2" sx={{ mb: 2 }}>
                            Escolha uma foto de sua galeria ou arraste uma aqui mesmo
                        </Typography>
                        <input
                            accept="image/*"
                            style={{ display: 'none' }}
                            id="upload-image"
                            type="file"
                            onChange={handleFileChange}
                        />

                        {selectedFile && (
                            <Typography sx={{ mt: 1 }}>{selectedFile.name}</Typography>
                        )}
                        <Typography variant="caption">
                            O tamanho máximo para as imagens é de 10 MB
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center' }}>
                    <Button onClick={handleTakePhoto} variant="contained" color="primary" sx={{ mr: 2 }}>
                        Tirar Foto
                    </Button>
                    <Button onClick={handleCloseUploadModal} variant="contained" sx={{ backgroundColor: 'gray', color: 'white' }}>
                        Fechar
                    </Button>
                </DialogActions>

            </Dialog>

            <Dialog open={isCameraOpen} onClose={handleCloseCameraModal} maxWidth="sm" fullWidth>
                <DialogContent>
                    <Typography variant="h6" gutterBottom>Tirar Foto</Typography>
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <video ref={videoRef} width="100%" height="100%" />
                        <canvas ref={canvasRef} style={{ display: 'none' }} />
                    </Box>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center' }}>
                    {!isCaptured ? (
                        <Button onClick={handleCapture} sx={{ backgroundColor: 'gray', color: 'white' }} variant="contained">
                            Capturar
                        </Button>
                    ) : (
                        <>
                            <Button onClick={handleRetakePhoto} sx={{ backgroundColor: 'gray', color: 'white' }} variant="contained">
                                Alterar
                            </Button>
                            <Button onClick={() => uploadPhoto(selectedFile!)} sx={{ backgroundColor: 'green', color: 'white' }} variant="contained">
                                Salvar
                            </Button>
                        </>
                    )}
                    <Button onClick={handleCloseCameraModal} sx={{ backgroundColor: 'gray', color: 'white' }} variant="contained">
                        Fechar
                    </Button>
                </DialogActions>

            </Dialog>
        </Box>
    );
};

export default PatientDetail;
