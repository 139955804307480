export const MockedQuestionTypes = [
    {
        id: 1,
        type: 'Sim/Não/Não sei'
    },
    {
        id: 2,
        type: 'Somente Texto',
    },
    {
        id: 3,
        type: 'Sim/Não/Não sei e Texto'
    }
];
