import React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

interface IRow {
    id: number;
    date: Date;
    name: string;
    value: number;
}

const TransactionsTable: React.FC<{rows: IRow[]}> = ({
    rows
}) => {
    const columns: GridColDef[] = [
        { field: 'date', headerName: 'Data', type: 'date' , flex: 1 },
        { field: 'name', headerName: 'Nome', flex: 4 },
        {
          field: 'value',
          headerName: 'Valor',
          type: 'number',
          flex: 1,
        },
    ];

    return (
        <DataGrid
            sx={{
                padding: '0px 30px',
                height: '520px',
            }}
            rows={rows}
            columns={columns}
            pageSizeOptions={[9, 10]}
        />
    );
}

export default TransactionsTable;