import { Box, Button, Checkbox, FormControl, InputLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import MenuIcon from '@mui/icons-material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { MockedQuestions } from './Questions.tsx';
import { MockedQuestionTypes } from './questionsTypes.tsx';
import { v4 as uuidv4 } from 'uuid';

interface IModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    onClose?: (result?: any) => void;
}

interface IAddPlanModal extends IModalProps {
    step: number;
    setStep: (step: number) => void;
    form: IForm;
    setForm: (form: IForm) => void;
    onSave: (form: IForm) => void;
    isEdit: boolean;
}

interface IAddQuestionModal extends IModalProps {
    questionTypes: string[];
    isEdit: boolean;
    cameraToEdit?: string;
}

interface IForm {
    id: number;
    name: string;
    questions: {
        id: number;
        uuid: string;
        alert: boolean;
        question: string;
        type_question: string;
        alertText?: string;
    }[]
}

const QuestionModal: React.FC<IAddQuestionModal> = ({
    open,
    setOpen,
    questionTypes,
    isEdit,
    cameraToEdit,
    onClose
}) => {
    const [form, setForm] = useState<{ question: string, type_question: string }>({
        question: '',
        type_question: ''
    });
    const [errors, setErrors] = useState<{ question: boolean, type_question: boolean }>({
        question: false,
        type_question: false,
    });

    useEffect(() => {
        if (!open) {
            setForm({ question: '', type_question: '' });
            setErrors({ question: false, type_question: false });
        }
    }, [open]);

    const generateUniqueId = () => {
        const existingIds = MockedQuestions.map(q => q.id);
        let newId = Math.max(...existingIds) + 1;
        while (existingIds.includes(newId)) {
            newId++;
        }
        return newId;
    };

    const handleSave = () => {
        const newErrors = {
            question: form.question === '',
            type_question: form.type_question === ''
        };
        setErrors(newErrors);

        if (!newErrors.question && !newErrors.type_question) {
            const newQuestion = {
                id: generateUniqueId(),
                uuid: uuidv4(),
                question: form.question,
                type_question: form.type_question,
                alert: false,
                alertText: ''
            };

            MockedQuestions.unshift(newQuestion);
            setOpen(false);
            setForm({ question: '', type_question: '' });
        }
    };

    return (
        <Modal
            open={open}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            onClose={() => {
                setForm({ question: '', type_question: '' });
                setErrors({ question: false, type_question: false });
            }}
        >
            <Box
                sx={{
                    width: '600px',
                    height: isEdit ? '400px' : '360px',
                    backgroundColor: 'white',
                    padding: '35px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}
            >
                <Typography
                    sx={{
                        fontSize: '22px',
                        fontWeight: 'bold',
                        fontFamily: 'Lato',
                    }}
                >{isEdit ? 'Editar pergunta' : 'Nova pergunta'}</Typography>
                {isEdit &&
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '15px',
                            backgroundColor: '#fae8e7',
                            padding: '10px',
                            borderRadius: '10px',
                            width: '100%',
                        }}
                    >
                        <ErrorOutlineIcon
                            sx={{
                                fontSize: '40px',
                                marginRight: '20px',
                                color: '#ca150c'
                            }}
                        />
                        <Typography
                            sx={{
                                fontSize: '13px',
                                color: '#5b5b5b'
                            }}
                        >
                            Atenção: Alterar essa pergunta nesse modelo de anamnese, refletirá EM TODAS AS ANAMNESES que utilizam esse modelo.
                        </Typography>
                    </Box>
                }
                <Box>
                    <Typography sx={{ color: '#7c7c8d' }}>
                        Pergunta <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <TextField
                        fullWidth
                        size={'small'}
                        variant="outlined"
                        placeholder='Digite aqui...'
                        autoComplete='off'
                        value={form.question}
                        onChange={(e) => {
                            setForm({ ...form, question: e.target.value });
                            setErrors({ ...errors, question: false });
                        }}
                        error={errors.question}
                        helperText={errors.question ? "O campo pergunta é obrigatório." : ""}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: errors.question ? 'red' : '#e9eaec',
                                    borderRadius: '10px'
                                },
                            },
                        }}
                    />
                </Box>
                <Box>
                    <Typography sx={{ color: '#7c7c8d', marginBottom: '15px' }}>
                        Tipo de Pergunta <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <FormControl fullWidth error={errors.type_question}>
                        <InputLabel
                            sx={{ marginTop: '-9px', color: '#a5a5a5' }}
                            id="demo-simple-select-label">Selecione...</InputLabel>
                        <Select
                            sx={{
                                height: '40px',
                                marginBottom: '10px',
                                color: 'black',
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: errors.type_question ? 'red' : '#e9eaec',
                                },
                                border: '10px',
                            }}
                            fullWidth
                            value={form.type_question}
                            onChange={(e) => {
                                setForm({ ...form, type_question: e.target.value as string });
                                setErrors({ ...errors, type_question: false });
                            }}
                        >
                            {questionTypes.map((type, index) =>
                                <MenuItem key={`${type}-${index}`} value={type}>
                                    {type}
                                </MenuItem>
                            )}
                        </Select>
                        {errors.type_question && <Typography color="red" variant="caption">O campo tipo de pergunta é obrigatório.</Typography>}
                    </FormControl>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <Button
                        sx={{
                            textTransform: 'none',
                            color: 'black'
                        }}
                        onClick={() => {
                            setForm({ question: '', type_question: '' });
                            setOpen(false);
                        }}
                    >Cancelar</Button>
                    <Button
                        sx={{
                            backgroundColor: '#006af5',
                            '&:hover': {
                                backgroundColor: 'rgba(0, 106, 245, 0.7)',
                            },
                            color: 'white',
                            textTransform: 'none',
                            marginLeft: '10px',
                        }}
                        onClick={handleSave}
                    >Salvar</Button>
                </Box>
            </Box>
        </Modal>
    );
};

const QuestionComponent: React.FC<{
    id: number;
    question: string;
    type_question: string;
    alert: boolean;
    activated: boolean;
    toggleActivateQuestion: (id: number) => void;
    setOpenEditModal: (open: boolean) => void;
    setCameraToEdit: (uuid: string) => void;
}> = ({
    id,
    question,
    type_question,
    alert,
    activated,
    toggleActivateQuestion,
    setOpenEditModal,
    setCameraToEdit
}) => {
    const alertText = alert ? 'Com alerta' : 'Sem alerta';
    
    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '15px',
                paddingRight: '20px',
                flexWrap: 'wrap',
                '@media (max-width: 600px)': {
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                },
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexGrow: 1,
                    '@media (max-width: 600px)': {
                        width: '100%',
                        justifyContent: 'space-between',
                    },
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexShrink: 0,
                        marginRight: '10px',
                        '@media (max-width: 600px)': {
                            marginRight: '5px',
                        },
                    }}
                >
                    <MenuIcon sx={{ backgroundColor: '#03659a', color: 'white', padding: '5px', borderRadius: '50%' }} />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        flexShrink: 1,
                        wordWrap: 'break-word',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '17px',
                            fontWeight: 'bold',
                            fontFamily: 'Lato',
                            color: '#03659a',
                            maxWidth: '560px',
                            '@media (max-width: 600px)': {
                                fontSize: '16px',
                                display: 'inline',
                            },
                        }}
                    >{question}</Typography>
                    <Typography
                        sx={{
                            color: '#77828f',
                            '@media (max-width: 600px)': {
                                fontSize: '14px',
                            },
                        }}
                    >{alertText} - Pergunta {type_question}</Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '48%',
                    '@media (max-width: 600px)': {
                        width: '100%',
                    },
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: '10px',
                        '@media (max-width: 600px)': {
                            marginRight: '0',
                        },
                    }}
                >
                    <Checkbox
                        value={activated}
                        checked={activated}
                        onChange={() => toggleActivateQuestion(id)}
                        sx={{ marginLeft: '-10px' }} 
                    />
                    <Typography sx={{ fontSize: '14px' }}>Pergunta ativa</Typography>
                </Box>
                <MoreVertIcon
                    sx={{
                        cursor: 'pointer',
                        '@media (max-width: 600px)': {
                            marginLeft: '10px',
                        },
                    }}
                    onClick={() => {
                        setOpenEditModal(true);
                        setCameraToEdit(MockedQuestions.find(q => q.id === id)?.uuid ?? '');
                    }}
                />
            </Box>
        </Box>
    );
};


const AddAnamnesisModal: React.FC<IAddPlanModal> = ({
    open,
    setOpen,
    onSave,
    step,
    setStep,
    form,
    setForm,
    isEdit
}) => {
    const [nameError, setNameError] = useState(false);
    const [openNewQuestionModal, setOpenNewQuestionModal] = useState(false);
    const [openEditQuestionModal, setOpenEditQuestionModal] = useState(false);
    const [cameraToEdit, setCameraToEdit] = useState<string>('');
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        if (isEdit && form.questions.length > 0) {
            form.questions.forEach((question) => {
                const existsInMocked = MockedQuestions.some(
                    (mockedQuestion) => mockedQuestion.question === question.question
                );
                if (!existsInMocked) {
                    MockedQuestions.unshift({
                        id: question.id,
                        uuid: question.uuid,
                        question: question.question,
                        type_question: question.type_question,
                        alert: question.alert,
                    });
                }
            });
            setForm({
                ...form,
                questions: form.questions.sort((a, b) => (b.alert ? 1 : -1)),
            });
        }
    }, [isEdit, form.questions]);

    const handleClose = () => {
        setOpen(false);
        setForm({ id: -1, name: '', questions: [] });
        setStep(1);
    };

    const handleSave = () => {
        if (form.name.trim() === '') {
            setNameError(true);
            return;
        }
        setNameError(false);

        onSave(form);
        handleClose();
    };

    const onContinue = () => {
        if (form.name.trim() === '') {
            setNameError(true);
            return;
        }

        setNameError(false);
        setStep(step + 1);
    };

    const toggleActivateQuestion = (id: number) => {
        if (!form.questions) {
            form.questions = [];
        }

        let selectedQuestion = MockedQuestions.find((question) => question.id === id);

        if (!selectedQuestion) {
            selectedQuestion = {
                id,
                uuid: uuidv4(),
                question: "Pergunta desconhecida",
                type_question: "Tipo desconhecido",
                alert: false,
            };
            MockedQuestions.unshift(selectedQuestion);
        }

        const questionExistsInForm = form.questions.some((q) => q.question === selectedQuestion?.question);

        if (questionExistsInForm) {
            setForm({
                ...form,
                questions: form.questions.filter((q) => q.question !== selectedQuestion?.question)
            });
        } else {
            setForm({
                ...form,
                questions: [
                    ...form.questions,
                    {
                        id: selectedQuestion.id,
                        uuid: selectedQuestion.uuid,
                        alert: selectedQuestion.alert,
                        question: selectedQuestion.question,
                        type_question: selectedQuestion.type_question,
                    }
                ]
            });
        }
    };

    // Ordena as perguntas ativas para aparecerem no topo
    const filteredQuestions = MockedQuestions
        .filter((question) =>
            question.question.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .sort((a, b) => {
            const isActiveA = form.questions?.some((selectedQuestion) => selectedQuestion.question === a.question);
            const isActiveB = form.questions?.some((selectedQuestion) => selectedQuestion.question === b.question);
            return isActiveA === isActiveB ? 0 : isActiveA ? -1 : 1;
        });

    return (
        <Modal
            open={open}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            onClose={handleClose}
        >
            {step === 1 ? (
                <Box
                    sx={{
                        width: '600px',
                        height: '300px',
                        backgroundColor: 'white',
                        padding: '35px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '22px',
                            fontWeight: 'bold',
                            fontFamily: 'Lato',
                        }}
                    >Modelo de anamnese</Typography>
                    <Box>
                        <Typography sx={{ color: '#7c7c8d' }}>
                            Nome do modelo de anamnese <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <TextField
                            fullWidth
                            size={'small'}
                            variant="outlined"
                            placeholder='Digite aqui...'
                            autoComplete='off'
                            value={form.name}
                            onChange={(e) => {
                                setForm({ ...form, name: e.target.value });
                                setNameError(false);
                            }}
                            error={nameError}
                            helperText={nameError ? "O nome do modelo é obrigatório." : ""}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: nameError ? 'red' : '#e9eaec',
                                        borderRadius: '10px'
                                    },
                                },
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button
                            sx={{
                                textTransform: 'none',
                                color: 'black'
                            }}
                            onClick={handleClose}
                        >Cancelar</Button>
                        <Button
                            sx={{
                                backgroundColor: '#006af5',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 106, 245, 0.7)',
                                },
                                color: 'white',
                                textTransform: 'none',
                                marginLeft: '10px',
                            }}
                            onClick={onContinue}
                        >Continuar</Button>
                    </Box>
                </Box>
            ) : (
                <Box
                    sx={{
                        width: '1300px',
                        height: '780px',
                        backgroundColor: 'white',
                        padding: '35px',
                    }}
                >
                    <QuestionModal
                        open={openNewQuestionModal || openEditQuestionModal}
                        setOpen={openNewQuestionModal ? setOpenNewQuestionModal : setOpenEditQuestionModal}
                        questionTypes={MockedQuestionTypes.map((type) => type.type)}
                        isEdit={openEditQuestionModal === true && openNewQuestionModal === false}
                        cameraToEdit={cameraToEdit}
                        onClose={() => {
                            if (openNewQuestionModal) {
                                setOpenNewQuestionModal(false);
                            }
                            if (openEditQuestionModal) {
                                setOpenEditQuestionModal(false);
                            }
                        }}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '22px',
                                fontWeight: 'bold',
                                fontFamily: 'Lato',
                            }}
                        >Modelo de anamnese</Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <TextField
                                placeholder="Pesquisar pergunta..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                size="small"
                                variant="outlined"
                            />
                            <Button
                                sx={{
                                    backgroundColor: '#006af5',
                                    '&:hover': {
                                        backgroundColor: 'rgba(0, 106, 245, 0.7)',
                                    },
                                    color: 'white',
                                    width: '45px',
                                    height: '35px',
                                    minWidth: '10px',
                                    marginLeft: '10px',
                                }}
                            >
                                <SearchIcon />
                            </Button>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            height: '500px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            marginTop: '20px',
                            padding: '20px 20px 10px 10px',
                            border: '1px solid #e9eaec',
                            borderRadius: '10px',
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                flexWrap: 'wrap'
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Lato',
                                    fontSize: '28px',
                                }}
                            >Perguntas para o modelo de anamnese</Typography>
                            <Button
                                sx={{
                                    backgroundColor: '#006af5',
                                    '&:hover': {
                                        backgroundColor: 'rgba(0, 106, 245, 0.7)',
                                    },
                                    color: 'white',
                                    textTransform: 'none',
                                    width: '100px',
                                    height: '35px',
                                    minWidth: '10px',
                                    marginRight: '10px',
                                    fontWeight: 'bold',
                                }}
                                onClick={() => setOpenNewQuestionModal(true)}
                            >
                                + Adicionar
                            </Button>
                        </Box>
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                marginTop: '20px',
                                height: '400px',
                                overflowY: 'auto',
                            }}
                        >
                            {filteredQuestions.map((question, index) => (
                                <QuestionComponent
                                    key={`${question.id}-${index}`}
                                    id={question.id}
                                    question={question.question}
                                    type_question={question.type_question}
                                    alert={question.alert}
                                    activated={form.questions?.some((selectedQuestion) => selectedQuestion.question === question.question)}
                                    toggleActivateQuestion={toggleActivateQuestion}
                                    setOpenEditModal={setOpenEditQuestionModal}
                                    setCameraToEdit={setCameraToEdit}
                                />
                            ))}
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: '20px'
                        }}
                    >
                        <Button
                            sx={{
                                textTransform: 'none',
                                color: 'black'
                            }}
                            onClick={handleClose}
                        >Cancelar</Button>
                        <Button
                            sx={{
                                backgroundColor: '#006af5',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 106, 245, 0.7)',
                                },
                                color: 'white',
                                textTransform: 'none',
                                marginLeft: '10px',
                            }}
                            onClick={handleSave}
                        >Salvar</Button>
                    </Box>
                </Box>
            )}
        </Modal>
    );
};

export default AddAnamnesisModal;
