import { Box, Typography } from '@mui/material';
import React from 'react';

const steps = [ 'Definir Público', 'Resumo e Ativação'];

const ComunicationCampaignSteps: React.FC<{
    currentStep: number
}> = ({currentStep}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        width: '35px',
                        height: '35px',
                        backgroundColor:'#03659a',
                        color: 'white',
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        '@media (max-width: 814px)': {
                            width: '25px',
                            height: '25px'
                        },
                        '@media (max-width: 632px)': {
                            width: '15px',
                            minWidth: '15px',
                            height: '15px',
                            minHeight: '15px'
                        }
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Lato',
                            fontWeight: 'bold',
                            fontSize: '20px',
                            '@media (max-width: 814px)': {
                                fontSize: '14px'
                            },
                            '@media (max-width: 632px)': {
                                fontSize: '10px'
                            }
                        }}
                    >1</Typography>
                </Box>
                <Typography
                    sx={{
                        fontFamily: 'Lato',
                        fontWeight: 'bold',
                        fontSize: '18px',
                        color: '#03659a',
                        marginLeft: '10px',
                        '@media (max-width: 814px)': {
                            fontSize: '14px'
                        },
                        '@media (max-width: 632px)': {
                            fontSize: '10px'
                        }
                    }}
                >Definir tipo de Campanha</Typography>
            </Box>
            <Box
                sx={{
                    margin: '0px 20px',
                    width: '50px',
                    height: '2px',
                    backgroundColor: currentStep >= 2 ? '#03659a' : '#757575',
                    '@media (max-width: 814px)': {
                        width: '25px',
                    },
                    '@media (max-width: 632px)': {
                        width: '10px',
                    }
                }}
            />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        width: '35px',
                        height: '35px',
                        backgroundColor: currentStep >= 2 ? '#03659a' : '#757575',
                        color: 'white',
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        '@media (max-width: 814px)': {
                            width: '25px',
                            height: '25px'
                        },
                        '@media (max-width: 632px)': {
                            width: '15px',
                            minWidth: '15px',
                            height: '15px',
                            minHeight: '15px'
                        }
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Lato',
                            fontWeight: 'bold',
                            fontSize: '20px',
                            '@media (max-width: 814px)': {
                                fontSize: '14px'
                            },
                            '@media (max-width: 632px)': {
                                fontSize: '10px'
                            }
                        }}
                    >2</Typography>
                </Box>
                <Typography
                    sx={{
                        fontFamily: 'Lato',
                        fontWeight: 'bold',
                        fontSize: '18px',
                        color: currentStep >= 2 ? '#03659a' : '#757575',
                        marginLeft: '10px',
                        '@media (max-width: 814px)': {
                            fontSize: '14px'
                        },
                        '@media (max-width: 632px)': {
                            fontSize: '10px'
                        }
                    }}
                >Definir Público</Typography>
            </Box>
            <Box
                sx={{
                    margin: '0px 20px',
                    width: '50px',
                    height: '2px',
                    backgroundColor: currentStep >= 3 ? '#03659a' : '#757575',
                    '@media (max-width: 814px)': {
                        width: '25px',
                    },
                    '@media (max-width: 632px)': {
                        width: '10px',
                    }
                }}
            />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        width: '35px',
                        height: '35px',
                        backgroundColor: currentStep >= 3 ? '#03659a' : '#757575',
                        color: 'white',
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        '@media (max-width: 814px)': {
                            width: '25px',
                            height: '25px'
                        },
                        '@media (max-width: 632px)': {
                            width: '15px',
                            minWidth: '15px',
                            height: '15px',
                            minHeight: '15px'
                        }
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Lato',
                            fontWeight: 'bold',
                            fontSize: '20px',
                            '@media (max-width: 814px)': {
                                fontSize: '14px'
                            },
                            '@media (max-width: 632px)': {
                                fontSize: '10px'
                            }
                        }}
                    >3</Typography>
                </Box>
                <Typography
                    sx={{
                        fontFamily: 'Lato',
                        fontWeight: 'bold',
                        fontSize: '18px',
                        color: currentStep === 3 ? '#03659a' : '#757575',
                        marginLeft: '10px',
                        '@media (max-width: 814px)': {
                            fontSize: '14px'
                        },
                        '@media (max-width: 632px)': {
                            fontSize: '10px'
                        }
                    }}
                >Resumo e Ativação</Typography>
            </Box>
        </Box>
    );
}

export default ComunicationCampaignSteps