import { getToken } from '../utils/storageUtils.tsx';

export const configBackendConnection = {
    baseURL: process.env.REACT_APP_BACKEND_URL as string,
    headersDefault: getAuthHeaders(),
    headersFile: getAuthHeadersFile(),
};

const ApiIndex = "api/v1";

export const endpoints = {
    loginToken: `${ApiIndex}/rest-auth/login/`,
    registerAPI: `${ApiIndex}/register/`,
    planAPI: `${ApiIndex}/settings/plans/plan/`,
    generalInformationClinicAPI: `${ApiIndex}/settings/clinic/general-information/`,
    contractsAPI: `${ApiIndex}/settings/contracts/contract/`,
    anamnesisAPI: `${ApiIndex}/settings/anamnesis/anamnesis/`,
    machineAPI: `${ApiIndex}/settings/tax/machine/`,
    stocksAPI: `${ApiIndex}/stock/stocks/`,
    productsAPI: `${ApiIndex}/stock/products/`,
    withdrawalHistoryAPI: `${ApiIndex}/stock/withdrawal-history/`,
    patientAPI: `${ApiIndex}/patients/patient/`,
};

export function getAuthHeaders() {
    const token = getToken();
    if (token) {
        return {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json',
        };
    }
    return {
        'Content-Type': 'application/json',
    };
}

export function getAuthHeadersFile() {
    const token = getToken();
    if (token) {
        return {
            'Authorization': `Token ${token}`,
            'Content-Type': 'multipart/form-data',
        };
    }
    return {
        'Content-Type': 'multipart/form-data',
    };
}