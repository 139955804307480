import React from 'react';
import Header from '../../components/header/index.tsx';
import MobileHeader from '../../components/MobileHeader/index.tsx';
import Sidebar from '../../components/sidebar/index.tsx';
import PageContentContainer from '../../components/PageContentContainer/index.tsx';
 import { Box } from '@mui/material';
import IntelligenceOptionsMenu from './IntelligenceOptionsMenu/index.tsx';
import BasePerformanceSquare from './Performance/BasePerformanceSquare/index.tsx';
import PatientEntryMethodSquare from './Performance/PatientEntryStateSquare/index.tsx';
import CostsByCategorySquare from './Performance/CostsByCategorySquare/index.tsx';
import SatisfactionSquare from './Performance/SatisfactionSquare/index.tsx';
import BudgetSquare from './Performance/BudgetSquare/index.tsx';
import ConsultationSquare from './Performance/ConsultationSquare/index.tsx';
import AverageOrderValueSquare from './Performance/AverageOrderValueSquare/index.tsx';
import SalesTargetSquare from './Performance/SalesTargetSquare/index.tsx';
import BaseTaskSquare from './Tasks/BaseTaskSquare/index.tsx';

const IntelligencePage: React.FC = () => {
    const [optionSelected, setOptionSelected] = React.useState<string>('Performance')
    return (
        <>
            <Header />
            <MobileHeader />
            <Sidebar />
            <PageContentContainer>
                <Box
                    sx={{
                        width: '100%',
                        maxHeight: 'calc( 100vh - 100px)',
                        overflowY: 'auto',
                    }}
                >
                    <IntelligenceOptionsMenu
                        optionSelected={optionSelected}
                        setOptionSelected={setOptionSelected}
                    />
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        {   optionSelected === 'Performance' && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    width: '98%',
                                }}
                            >
                                <BasePerformanceSquare />
                                <SalesTargetSquare />
                                <BudgetSquare />
                                <SatisfactionSquare enabled={false}/>
                                <PatientEntryMethodSquare enabled={false}/>
                                <ConsultationSquare />
                                <AverageOrderValueSquare />
                                <CostsByCategorySquare enabled={false}/>
                            </Box>
                        )}
                        {   optionSelected === 'Tarefas' && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    width: '98%',
                                }}
                            >
                                <BaseTaskSquare />
                            </Box>
                        )}
                    </Box>
                </Box>
            </PageContentContainer>
        </>
    )
}

export default IntelligencePage;