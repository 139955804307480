import { Box, LinearProgress, linearProgressClasses, LinearProgressProps, styled, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import React, { useState } from 'react';

interface BorderLinearProgressProps extends LinearProgressProps {
    colorPrimary?: string;
    backgroundColor?: string;
}
  
const BorderLinearProgress = styled(LinearProgress, {
    shouldForwardProp: (prop) => prop !== 'colorPrimary' && prop !== 'backgroundColor',
})<BorderLinearProgressProps>(({ theme, colorPrimary, backgroundColor }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: backgroundColor || theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: colorPrimary || (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'),
    },
}));

const TransactionBarField: React.FC<{
    title: string;
    incoming?: number;
    incomingText?: 'A pagar' | 'A receber'
    totalPredicted: number;
    value: number;
    progress: number;
    color: string
}> = ({
    title,
    incoming,
    incomingText,
    totalPredicted,
    value,
    progress,
    color
}) => {
    return (
        <Box
            sx={{
                width: '100%',
                minWidth: '150px',
                justifyContent: 'space-between',
                '@media (max-width: 658px)': {
                    width: '100%'
                }
            }}
        >
            <Typography sx={{color: '#77828f', fontSize: '15px'}}
            >{title}</Typography>
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    alignContent: 'center',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                }}
            >
                <Typography
                    sx={{
                        color: color,
                        fontSize: '14px'
                    }}
                >R$ {value.toFixed(2)}</Typography>
                {incomingText && incoming &&
                    <Typography
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            color: '#77828f',
                            fontSize: '14px'
                        }}
                    >
                        {incomingText ? incomingText+ ': ' : ''}
                        <Typography
                            sx={{color: 'inherit', fontSize: 'inherit'}}
                        >R$ {incoming.toFixed(2)}</Typography>
                    </Typography>
                }
            </Box>
            <BorderLinearProgress sx={{height: '5px'}} value={progress} variant="determinate" colorPrimary={color} backgroundColor='#e9ecf0' />
            <Box sx={{display: 'flex', width: '100%', justifyContent: 'flex-end'}}>
                <Typography
                    sx={{
                        color: '#77828f',
                        fontSize: '14px'
                    }}
                >
                    Total previsto: R$ {totalPredicted.toFixed(2)}
                </Typography>
            </Box>
        </Box>
    );
}

const FinancialPerformanceReports: React.FC = () => {
    const [financialDate, setFinancialDate] = useState<Dayjs | null>(dayjs());
    const [totalValues, setTotalValues] = useState<{
        total: number,
        totalPredicted: number,
        income: {
            total: number,
            incoming: number,
            predicted: number,
        },
        outcome: {
            total: number,
            pendingPayment: number,
            predicted: number,
        },
    }>({
        total: 20,
        totalPredicted: 30,
        income: {
            total: 30,
            incoming: 20,
            predicted: 10,
        },
        outcome: {
            total: 10,
            pendingPayment: 10,
            predicted: 0,
        },
    })
    return (
        <Box
            sx={{
                padding: '20px 30px',
                backgroundColor: '#fff',
                boxShadow: '0px 0px 5px 0px #c0c7d0',
                '@media (max-width: 904px)': {
                    width: '100%',
                    marginBottom: '20px',
                }
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <Typography
                    sx={{
                        fontSize: '20px',
                        fontFamily: 'Lato',
                        fontWeight: 'bold',
                    }}
                >Financeiro</Typography>
                <DatePicker
                    format="DD/MM/YYYY"
                    sx={{
                        width: '23%',
                        maxWidth: '170px',
                        minWidth: '150px',
                        '.MuiOutlinedInput-root': {
                            '& fieldset': {
                                border: 'none',
                            },
                            backgroundColor: '#e9ecf0',
                            minHeight: '30px', // Ajuste da altura mínima do componente
                            height: '30px', // Ajuste da altura do input
                            padding: '0 10px', // Ajusta o padding para uma altura reduzida
                            
                        },
                        '.MuiInputBase-input': {
                            backgroundColor: '#e9ecf0',
                            height: '20px', // Ajuste da altura do texto dentro do input
                            padding: '5px 0',
                        },
                    }}
                    value={financialDate}
                    onChange={(date) => {
                        if (date) {
                            setFinancialDate(date);
                        } else {
                            setFinancialDate(null);
                        }
                    }}
                />
            </Box>
            <TransactionBarField
                title="Receitas"
                incoming={totalValues.income.incoming}
                incomingText='A receber'
                totalPredicted={totalValues.income.predicted}
                value={totalValues.income.total}
                progress={(totalValues.income.total * 100) / (totalValues.outcome.total + totalValues.income.total)}
                color={'#20a200'}
            />
            <TransactionBarField
                title="Despesas"
                incoming={totalValues.outcome.pendingPayment}
                incomingText='A pagar'
                totalPredicted={totalValues.outcome.predicted}
                value={totalValues.outcome.total}
                progress={(totalValues.outcome.total * 100) / (totalValues.outcome.total + totalValues.income.total)}
                color={'#d00303'}
            />
            <TransactionBarField
                title="Saldos"
                totalPredicted={totalValues.totalPredicted}
                value={totalValues.total}
                progress={((totalValues.income.total - totalValues.outcome.total) * 100) / (totalValues.income.total + totalValues.outcome.total)}
                color={'#0042a4'}
            />
        </Box>
    )
}

export default FinancialPerformanceReports;