import { Box, Typography } from '@mui/material';
import React from 'react';
import NoCreditCardRegistered from './NoCreditCardRegistered/index.tsx';
import { INewCreditCardForm } from '../../../types/payment.tsx';
import AddCreditCardModal from '../../../components/modals/AddCreditCardModal/index.tsx';

const UserCreditCardScreen: React.FC = () => {
    const [isRegistered, setIsRegistered] = React.useState(false);
    const [newCreditCardForm, setNewCreditCardForm] = React.useState<INewCreditCardForm>({
        fullname: '',
        cpfOrCnpj: '',
        email: '',
        cellphone: '',
        payday: 10,
        address: {
            cep: '',
            street: '',
            number: '',
            complement: undefined,
            neighborhood: '',
            city: '',
            state: undefined
        },
        cardData: {
            cvv: '',
            expiry: '',
            name: '',
            number: ''
        }
    });
    const [openRegisterNewCardModal, setOpenRegisterNewCardModal] = React.useState(false);
    return (
        <Box
            sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                overflowY: 'auto',
                padding: '40px'
            }}
        >
            <AddCreditCardModal
                open={openRegisterNewCardModal}
                setOpen={setOpenRegisterNewCardModal}
                form={newCreditCardForm}
                setForm={setNewCreditCardForm}
            />
            {!isRegistered && 
                <NoCreditCardRegistered
                    onRegister={() => setOpenRegisterNewCardModal(true)}
                />
            }
        </Box>
    )
}

export default UserCreditCardScreen;