import React, { useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Box } from '@mui/material';
import Header from '../../components/header/index.tsx';
import Sidebar from '../../components/sidebar/index.tsx';
import SettingsMenuOption from './SettingsMenuOption/index.tsx';
import ClinicSettings from './ClinicSettings/index.tsx';
import PlanSettings from './PlanSettings/index.tsx';
import DocumentSettings from './DocumentSettings/index.tsx';
import AnamnesisSettings from './AnamnesisSettings/index.tsx';
import PageContentContainer from '../../components/PageContentContainer/index.tsx';
import MobileHeader from '../../components/MobileHeader/index.tsx';
import MachineFee from './MachineFee/index.tsx';

const SettingsPage: React.FC = () => {
    const [selected, setSelected] = useState(0);
    return (
        <>
            <Header />
            <MobileHeader />
            <Sidebar />
            <PageContentContainer>
                <Box
                    sx={{
                        backgroundColor: 'white',
                        width: '100%',
                        height: '90px',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-end',
                        borderRadius: '5px',
                        padding: '0px 50px',
                        boxShadow: '0px 0px 5px 0px #c0c7d0',
                        overflow: 'auto'
                    }}
                >
                    <SettingsMenuOption
                        title={'Clínica'}
                        selected={selected === 0}
                        onClick={() => setSelected(0)}
                    />
                    <SettingsMenuOption
                        title={'Planos'}
                        selected={selected === 1}
                        onClick={() => setSelected(1)}
                    />
                    <SettingsMenuOption
                        title={'Anamnese'}
                        selected={selected === 2}
                        onClick={() => setSelected(2)}
                    />
                    <SettingsMenuOption
                        title={'Contratos'}
                        selected={selected === 3}
                        onClick={() => setSelected(3)}
                    />
                    <SettingsMenuOption
                        title={'Taxas Maquininha'}
                        selected={selected === 4}
                        onClick={() => setSelected(4)}
                    />
                </Box>
                <Box
                    sx={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        overflowY: 'auto',
                    }}
                >
                    {selected === 0 && (
                        <ClinicSettings />
                    )}
                    {selected === 1 && (
                        <PlanSettings />
                    )}
                    {selected === 2 && (
                        <AnamnesisSettings />
                    )}
                    {selected === 3 && (
                        <DocumentSettings />
                    )}
                    {selected === 4 && (
                        <MachineFee />
                    )}
                </Box>
            </PageContentContainer>
        </>
    );
};

export default SettingsPage;
