import { Box, Button, MenuItem, Modal, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { INewCreditCardForm } from '../../../types/payment';
import { toast } from 'react-toastify';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface IModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

interface IAddCreditCardModalProps extends IModalProps {
    form: INewCreditCardForm;
    setForm: (form: INewCreditCardForm) => void;
}

const AlterPaydayModal: React.FC<IAddCreditCardModalProps> = ({
    open,
    setOpen,
    form,
    setForm
}) => {
    const [selectedPayday, setSelectedPayday] = useState(form.payday);

    const possiblePaydays = [5, 10, 14, 20, 25, 30];

    return (
        <Modal
            open={open}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box
                sx={{
                    width: '860px',
                    height: '480px',
                    backgroundColor: 'white',
                    padding: '35px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    "@media (max-width: 860px)": {
                        width: '100%',
                        height: 'auto',
                        rowGap: '15px',
                    }
                }}
            >
                <CreditCardIcon
                    sx={{
                        color: '#0e6f6b',
                        fontSize: '100px'
                    }}
                />
                <Typography
                    sx={{
                        fontSize: '24px',
                        fontFamily: 'Lato',
                        fontWeight: 'bold',
                        textAlign: 'center',
                    }}
                >Escolha um dia para a cobrança</Typography>
                <Typography
                    sx={{
                        textAlign: 'center',
                        maxWidth: '650px',
                        color: '#6b7684',
                    }}
                >
                    A cobrança do plano aparecerá no seu cartão no dia 10 de 
                    cada mês, mas você pode escolher uma data que seja mais 
                    conveniente para o pagamento.
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        sx={{
                            color: '#a5a5a5'
                        }}
                    >Dia de cobrança</Typography>
                    <TextField
                        select
                        variant="outlined"
                        value={selectedPayday}
                        onChange={(e) => setSelectedPayday(Number(e.target.value))}
                        sx={{
                            width: '180px',
                            marginLeft: '20px',
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#e9eaec',
                                    borderRadius: '10px'
                                },
                            },
                        }}
                    >
                        {possiblePaydays.map((payday) => (
                            <MenuItem key={payday} value={payday}>
                                {payday}
                            </MenuItem>
                        ))}
                    </TextField>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#e5f6fd',
                        width: '700px',
                        height: '60px',
                        "@media (max-width: 860px)": {
                            width: '90%',
                            height: 'auto',
                            padding: '10px',
                        },
                    }}
                >
                    <InfoOutlinedIcon
                        sx={{
                            color: '#03659a',
                            fontSize: '35px'
                        }}
                    />
                    <Typography
                        sx={{
                            color: '#5b5b5b',
                            marginLeft: '10px'
                        }}
                    >
                        Essa alteração será aplicada somente para após segunda
                        fatura do Simples Dental.
                    </Typography>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: '20px'
                    }}
                >
                    <Button
                        sx={{
                            textTransform: 'none',
                            color: 'red'
                        }}
                        onClick={() => {
                            setForm({
                                fullname: '',
                                cpfOrCnpj: '',
                                email: '',
                                cellphone: '',
                                payday: 10,
                                address: {
                                    cep: '',
                                    street: '',
                                    number: '',
                                    complement: undefined,
                                    neighborhood: '',
                                    city: '',
                                    state: undefined
                                },
                                cardData: {
                                    cvv: '',
                                    expiry: '',
                                    name: '',
                                    number: ''
                                }
                            });
                            setOpen(false)
                        }}
                    >Fechar</Button>
                    <Button
                        onClick={() => {
                            toast.warning('Ainda não implementado')
                        }}
                        sx={{
                            textTransform: 'none',
                            color: 'white',
                            backgroundColor: '#006af5',
                            '&:hover': {
                                backgroundColor: 'rgba(0, 106, 245, 0.7)',
                            },
                            height: '30px',
                            width: '80px',
                        }}
                    >Salvar</Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default AlterPaydayModal;