import React, { useEffect, useState } from 'react';
import BaseForIntelligenceSquare from '../../../../components/baseForIntelligenceSquare/index.tsx';
import dayjs, { Dayjs } from 'dayjs';
import { Box, Button, Typography, LinearProgress, linearProgressClasses, LinearProgressProps, styled} from '@mui/material';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { LineChart } from '@mui/x-charts';
import SalesTargetModal from '../../../../components/modals/SalesTargetModal/index.tsx';
const MOCKED_CHART_DATA: ISalesChartData[] = [
    { day: 1, thisMonthValue: 1000, lastMonthValue: 500 },
    { day: 2, thisMonthValue: 2000, lastMonthValue: 1000 },
    { day: 3, thisMonthValue: 1500, lastMonthValue: 750 },
    { day: 4, thisMonthValue: 2500, lastMonthValue: 4250 },
    { day: 5, thisMonthValue: 3000, lastMonthValue: 5500 },
    { day: 6, thisMonthValue: 5000, lastMonthValue: 2500 },
    { day: 7, thisMonthValue: 4000, lastMonthValue: 2000 },
    { day: 8, thisMonthValue: 6000, lastMonthValue: 3000 },
    { day: 9, thisMonthValue: 7000, lastMonthValue: 3500 },
    { day: 10, thisMonthValue: 8000, lastMonthValue: 4000 },
    { day: 11, thisMonthValue: 9000, lastMonthValue: 4500 },
    { day: 12, thisMonthValue: 10000, lastMonthValue: 5000 },
    { day: 13, thisMonthValue: 11000, lastMonthValue: 5500 },
    { day: 14, thisMonthValue: 8000, lastMonthValue: 6000 },
    { day: 15, thisMonthValue: 6000, lastMonthValue: 6500 },
    { day: 16, thisMonthValue: 5000, lastMonthValue: 7000 },
    { day: 17, thisMonthValue: 2000, lastMonthValue: 7500 },
    { day: 18, thisMonthValue: 10000, lastMonthValue: 8000 },
    { day: 19, thisMonthValue: 12000, lastMonthValue: 8500 },
    { day: 20, thisMonthValue: 7000, lastMonthValue: 9000 },
    { day: 21, thisMonthValue: 4000, lastMonthValue: 9500 },
    { day: 22, thisMonthValue: 5000, lastMonthValue: 10000 },
    { day: 23, thisMonthValue: Math.floor(Math.random() * (13000 - 9000 + 1)) + 9000, lastMonthValue: Math.floor(Math.random() * (13000 - 9000 + 1)) + 9000 },
    { day: 24, thisMonthValue: Math.floor(Math.random() * (13000 - 9000 + 1)) + 9000, lastMonthValue: Math.floor(Math.random() * (13000 - 9000 + 1)) + 9000 },
    { day: 25, thisMonthValue: Math.floor(Math.random() * (13000 - 9000 + 1)) + 9000, lastMonthValue: Math.floor(Math.random() * (13000 - 9000 + 1)) + 9000 },
    { day: 26, thisMonthValue: Math.floor(Math.random() * (13000 - 9000 + 1)) + 9000, lastMonthValue: Math.floor(Math.random() * (13000 - 9000 + 1)) + 9000 },
    { day: 27, thisMonthValue: Math.floor(Math.random() * (13000 - 9000 + 1)) + 9000, lastMonthValue: Math.floor(Math.random() * (13000 - 9000 + 1)) + 9000 },
    { day: 28, thisMonthValue: Math.floor(Math.random() * (13000 - 9000 + 1)) + 9000, lastMonthValue: Math.floor(Math.random() * (13000 - 9000 + 1)) + 9000 },
    { day: 29, thisMonthValue: Math.floor(Math.random() * (13000 - 9000 + 1)) + 9000, lastMonthValue: Math.floor(Math.random() * (13000 - 9000 + 1)) + 9000 },
    { day: 30, thisMonthValue: Math.floor(Math.random() * (13000 - 9000 + 1)) + 9000, lastMonthValue: Math.floor(Math.random() * (13000 - 9000 + 1)) + 9000 },
];

interface BorderLinearProgressProps extends LinearProgressProps {
    colorPrimary?: string;
    backgroundColor?: string;
}
 
const SubtitleItem: React.FC<{color: string, label: string}> = ({color, label}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                width: '220px',
            }}
        >
            <Box
                sx={{
                    width: '30px',
                    height: '18px',
                    backgroundColor: color,
                    borderRadius: '20%',
                    marginRight: '10px',
                }}
            />
            <Typography
                sx={{
                    fontSize: '16px',
                    fontFamily: 'Lato',
                }}
            >
                {label}
            </Typography>
        </Box>
    )
}

const BorderLinearProgress = styled(LinearProgress, {
    shouldForwardProp: (prop) => prop !== 'colorPrimary' && prop !== 'backgroundColor',
})<BorderLinearProgressProps>(({ theme, colorPrimary, backgroundColor }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: backgroundColor || theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: colorPrimary || (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'),
    },
}));

interface ISalesChartData {
    day: number;
    thisMonthValue: number;
    lastMonthValue: number;
}

interface ISalesData {
    targetValueMonth: number;
    targetValueToday: number;
    soldValueMonth: number;
    soldValueToday: number;
    salesChartData: ISalesChartData[];
}

const SalesTargetSquare: React.FC = () => {
    const [openSalesTargetModal, setOpenSalesTargetModal] = useState<boolean>(false);
    const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs());
    const [salesData, setSalesData] = useState<ISalesData>({
        targetValueMonth: 120000,
        targetValueToday: 2586.21,
        soldValueMonth: 45000,
        soldValueToday: 2200.23,
        salesChartData: MOCKED_CHART_DATA,
    });
    const [daysTillEndOfMonth, setDaysTillEndOfMonth] = useState<number>(
        selectedDate ? selectedDate.daysInMonth() - selectedDate.date() : 0
    );
    useEffect(() => {
        setDaysTillEndOfMonth(selectedDate ? selectedDate.daysInMonth() - selectedDate.date() : 0);
    }, [selectedDate])

    const formatValue = (value) => {
        if (value >= 1_000_000_000_000) {
            return `${(value / 1_000_000_000_000).toFixed(1)}T`;
        } else if (value >= 1_000_000_000) {
            return `${(value / 1_000_000_000).toFixed(1)}B`;
        } else if (value >= 1_000_000) {
            return `${(value / 1_000_000).toFixed(1)}M`;
        } else if (value >= 1_000) {
            return `${(value / 1_000).toFixed(1)}k`;
        } else {
            return value;
        }
    };

    return (
        <BaseForIntelligenceSquare
            title={"Metas de Vendas"}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            infoIcon={true}
        >
            <SalesTargetModal
                open={openSalesTargetModal}
                setOpen={setOpenSalesTargetModal}
            />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: '20px',
                    "@media (max-width: 860px)": {
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                    }
                }}
            >
                <Box
                    sx={{
                        width: '45%',
                        "@media (max-width: 860px)": {
                            width: '100%',
                        }
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            color: '#77828f',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '15px'
                            }}
                        >Objetivos do mês</Typography>
                        <Typography
                            sx={{
                                fontSize: '15px'
                            }}
                        >{(salesData.soldValueMonth * 100 / salesData.targetValueMonth).toFixed(2)}%</Typography>
                    </Box>
                    <BorderLinearProgress sx={{height: '10px'}} value={salesData.soldValueMonth * 100 / salesData.targetValueMonth} variant="determinate" colorPrimary={'#288af1'} backgroundColor='#e9ecf0' />
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            fontSize: '15px',
                            color: '#77828f'
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '15px'
                            }}
                        ><span style={{fontWeight: 'bold'}}>R$ {salesData.soldValueMonth.toFixed(2)}</span> em vendas</Typography>
                        <Button
                            onClick={() => setOpenSalesTargetModal(true)}
                        >Criar meta</Button>
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: '1px',
                        height: '50px',
                        backgroundColor: '#f0f0f0',
                        "@media (max-width: 860px)": {
                            display: 'none'
                        }
                    }}
                />
                <Box
                    sx={{
                        width: '15%',
                        minWidth: '190px',
                        "@media (max-width: 860px)": {
                            marginBottom: '10px'
                        }
                    }}
                >
                    <Typography
                        sx={{
                            color: '#77828f',
                            fontSize: '15px'
                        }}
                    >Necessário vender</Typography>
                    <Typography
                        sx={{
                            color: '#77828f',
                            fontSize: '15px'                        
                        }}
                    ><span
                        style={{fontWeight: 'bold', fontSize: '18px'}}
                    >R$ {((salesData.targetValueMonth - salesData.soldValueMonth) / daysTillEndOfMonth).toFixed(2)}
                    </span> por dia útil</Typography>
                    <Box 
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <RadioButtonCheckedIcon
                            sx={{
                                fontSize: '15px',
                                color: '#b4bdc8'
                            }}
                        />
                        <Typography
                            sx={{
                                color: '#77828f',
                                fontSize: '15px',
                                marginLeft: '5px'
                            }}
                        >{daysTillEndOfMonth} dias restantes</Typography>
                    </Box>
                </Box>
                <Box sx={{width: '15%', minWidth: '190px'}}>
                    <Typography
                        sx={{
                            color: '#77828f',
                            fontSize: '15px'
                        }}
                    >Vendido hoje</Typography>
                    <Typography
                        sx={{
                            color: '#77828f',
                            fontWeight: 'bold',
                            fontSize: '18px'
                        }}
                    >R$ {(salesData.soldValueToday).toFixed(2)}</Typography>
                    <Box 
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <RadioButtonCheckedIcon
                            sx={{
                                fontSize: '15px',
                                color: '#b4bdc8'
                            }}
                        />
                        <Typography
                            sx={{
                                color: '#77828f',
                                fontSize: '15px',
                                marginLeft: '5px'
                            }}
                        >{(salesData.soldValueToday * 100 / salesData.targetValueToday).toFixed(2)}% da meta diária</Typography>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    height: '500px',
                    "@media (max-width: 860px)": {
                        height: "400px"
                    },
                    "@media (max-width: 650px)": {
                        height: "300px"
                    }
                }}
            >
                <LineChart
                    sx={{
                        width: '100%',
                        height: '100%',
                        "& .MuiLineElement-root": { strokeWidth: 6 },
                        "@media (max-width: 650px)": {
                            "& .MuiLineElement-root": { strokeWidth: 3 },
                        }
                    }}
                    dataset={salesData.salesChartData as any}
                    series={[
                        { label: 'Esse mês', dataKey: 'thisMonthValue', color: '#2592f6', showMark: false },
                        { label: 'Mês passado', dataKey: 'lastMonthValue', color: '#ece9f1', showMark: false,},
                    ]}
                    xAxis={[
                      {
                        dataKey: 'day',
                        scaleType: 'band',
                        tickInterval: [1, 5, 10, 15, 20, 25, 30], 
                        disableLine: true,
                        disableTicks: true,
                      },
                    ]}
                    yAxis={[{
                        scaleType: 'linear',
                        disableTicks: true,
                        disableLine: true,
                        valueFormatter: (value) => `R$ ${formatValue(value).toLocaleString('pt-BR')}`,
                    }]}
                    slotProps={{
                        legend: { hidden: true },
                    }}
                    grid={{ horizontal: true }}
                    
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'flex-start'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: '20px',
                        width: '100%',
                        maxWidth: '350px',
                        marginLeft: '30px'
                    }}
                >
                    <SubtitleItem
                        color="#2592f6"
                        label="Mês atual"
                    />
                    <SubtitleItem
                        color="#ece9f1"
                        label="Mês passado"
                    />
                </Box>
            </Box>
        </BaseForIntelligenceSquare>
    )
}

export default SalesTargetSquare;