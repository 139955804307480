import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SidebarOption from '../sidebar/sidebarOption/index.tsx';
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import PersonIcon from '@mui/icons-material/Person';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import StorageSharpIcon from '@mui/icons-material/StorageSharp';
import MenuIcon from '@mui/icons-material/Menu';
import { useLocation, useNavigate } from 'react-router-dom';

const coinPilesIcon = require('../../assets/icons/coin-piles.png')
const commentsIcon = require('../../assets/icons/comments.png')
const settingsIcon = require('../../assets/icons/settings.png')

const MobileSidebar: React.FC = () => {
    const [selectedSidebarOption, setSelectedSidebarOption] = useState<number>(-1);
    const location = useLocation();
    const navigate = useNavigate();
    const name = localStorage.getItem('name') || '';

    useEffect(() => {
        const path = location.pathname;
        path === '/agenda' && setSelectedSidebarOption(2);
        path === '/financial' && setSelectedSidebarOption(3);
        path === '/comunication' && setSelectedSidebarOption(4);
        path === '/storage' && setSelectedSidebarOption(5);
        path === '/settings' && setSelectedSidebarOption(6);
    },[location.pathname])
    
    return (
        <Box
            sx={{
                width: '20vw',
                height: '100vh',
                padding: '30px 0px 30px 0px',
                background: 'linear-gradient(to bottom, #0c5c59, #0e6f6b 40%)',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                '@media (max-width: 888px)': {
                    width: '30vw'
                },
                '@media (max-width: 688px)': {
                    width: '40vw'
                },
                '@media (max-width: 472px)': {
                    width: '60vw'
                }
            }}
            onClick={(e) => e.stopPropagation()}
        >
            <Box sx={{width: '100%'}}>
                <SidebarOption 
                    selected={selectedSidebarOption === 0}
                    onSelect={() => {
                        setSelectedSidebarOption(0)
                        navigate('/intelligence')
                    }}
                    title={'Inteligência'}
                    icon={<BarChartRoundedIcon />}
                />
                <SidebarOption 
                    selected={selectedSidebarOption === 1}
                    onSelect={() => {
                        setSelectedSidebarOption(1)
                    }}
                    title={'Pacientes'}
                    icon={<PersonIcon />}
                />
                <SidebarOption 
                    selected={selectedSidebarOption === 2}
                    onSelect={() => {
                        setSelectedSidebarOption(2)
                        navigate('/agenda')
                    }}
                    title={'Agenda'}
                    icon={<CalendarMonthIcon />}
                />
                <SidebarOption 
                    selected={selectedSidebarOption === 3}
                    onSelect={() => {
                        setSelectedSidebarOption(3)
                        navigate('/financial')
                    }}
                    title={'Financeiro'}
                    icon={
                        <img
                            style={{
                                width: '26px',
                                height: '26px',
                            }}
                            src={coinPilesIcon} 
                            alt={'coinPiles'} 
                        />
                    }
                />
                <SidebarOption 
                    selected={selectedSidebarOption === 4}
                    onSelect={() => {
                        setSelectedSidebarOption(4)
                        navigate('/comunication')
                    }}
                    title={'Comunicação'}
                    icon={
                        <img
                            style={{
                                width: '26px',
                                height: '26px',
                            }}
                            src={commentsIcon} 
                            alt={'comments'} 
                        />
                    }
                />
                <SidebarOption
                    selected={selectedSidebarOption === 5}
                    onSelect={() => {
                        setSelectedSidebarOption(5)
                        navigate('/storage')
                    }}
                    title={'Estoque'}
                    icon={<StorageSharpIcon />}
                />
                <SidebarOption 
                    selected={selectedSidebarOption === 6}
                    onSelect={() => {
                        setSelectedSidebarOption(6)
                        navigate('/settings')
                    }}
                    title={'Ajustes'}
                    icon={
                        <img
                            style={{
                                width: '26px',
                                height: '26px',
                            }}
                            src={settingsIcon} 
                            alt={'settings'}
                        />
                    }
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '170px',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'white',
                        borderRadius: '50%',
                        width: '40px',
                        height: '40px'
                    }}
                >
                    FT
                </Box>
                <Box sx={{color: 'white'}}>
                    <Typography sx={{fontSize: 18}}>
                        {name}
                    </Typography>
                    <Typography sx={{fontSize: 10}}>
                        Dentista Administradora
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}

export default MobileSidebar;