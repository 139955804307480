import { Box, Button, Typography } from '@mui/material';
import UpdateIcon from '@mui/icons-material/Update';
import React, { useEffect } from 'react';

const UserPlanScreen: React.FC = () => {
    const [userPlan, setUserPlan] = React.useState('')
    useEffect(() => {
        //aqui fica a lógica para buscar o plano do usuário
        setUserPlan('Vendas')
    },[])
    return (
        <Box
            sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                overflowY: 'auto',
                padding: '30px'
            }}
        >
            <Box
                sx={{
                    width: '580px',
                    height: '300px',
                    padding: '20px',
                    backgroundColor: 'white'
                }}
            >
                <Typography
                    fontFamily={'Lato'}
                    fontSize={'21px'}
                    fontWeight={'bold'}
                    color={'#505050'}
                >Plano Contratado</Typography>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box
                        sx={{
                            marginY: '20px',
                            width: '190px',
                            height: '190px',
                            backgroundColor: '#277d88',
                            borderRadius: '50%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: 'white',
                        }}
                    >
                        <UpdateIcon sx={{fontSize: '45px'}}/>
                        <Typography
                            fontFamily={'Lato'}
                            fontWeight={'bold'}
                            fontSize={21}
                        >
                            PLANO DE
                        </Typography>
                        <Typography
                            fontFamily={'Lato'}
                            fontWeight={'bold'}
                            fontSize={21}
                        >
                            {userPlan.toUpperCase()}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            height: '200px',
                            marginTop: '70px',
                            width: '50%',
                            color: '#616d7c'
                        }}
                    >
                        <Box>
                            <Typography
                                fontSize={16}
                            >Você está usando o plano de</Typography>
                            <Typography
                                fontSize={16}
                            >{userPlan} do medicare</Typography>
                        </Box>
                        <Button
                            sx={{
                                marginTop: '15px',
                                marginLeft: '-5px',
                            }}
                        >Ver Histórico de Compras</Button>
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginTop: '25px'
                            }}
                        >
                            <Button>Selecionar Plano</Button>
                        </Box>

                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default UserPlanScreen;