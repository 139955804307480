import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField } from '@mui/material';
import Cards, { Focused } from 'react-credit-cards-2';
import GppGoodIcon from '@mui/icons-material/GppGood';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import { INewCreditCardForm } from '../../../../types/payment';

const CreditCardForm: React.FC<{
  form: INewCreditCardForm,
  setForm: (form: INewCreditCardForm) => void
}> = ({form, setForm}) => {
    const [focus, setFocus] = useState<Focused | undefined>(undefined);

    const handleInputFocus = (evt) => {
        setFocus(evt.target.name === 'cvv' ? 'cvc' : evt.target.name);
    };

    const handleChangeNumber = (e) => {
        const rawValue = e.target.value
            .replace(/\s+/g, '')// Remove spaces from input
            .replace(/\D/g, '');// Remove all non-digits 
        if (rawValue.length > 16) return;
        setForm({
        ...form,
        cardData: {
            ...form.cardData,
            number: rawValue.replace(/\D/g, '')
        }
        });
    };

    const handleChangeExpiry = (e) => {
        const rawValue = e.target.value
            .replace(/\//g, '')// Remove all slashes from input
            .replace(/\D/g, '');// Remove all non-digits
        if (rawValue.length > 4) return;
        setForm({
            ...form,
            cardData: {
                ...form.cardData,
                expiry: rawValue.replace(/\D/g, '')
            }
        });
    };

    return (
        <Box
        sx={{
            width: '100%',
            height: 'auto',
            marginTop: '30px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            "@media (max-width: 720px)": {
                flexDirection: 'column-reverse',
                alignItems: 'center'
            }
        }}
        >
            <Box
                sx={{
                    width: '50%',
                    "@media (max-width: 720px)": {
                        width: '100%',
                        marginBottom: '20px'
                    }
                }}
            >
                <Box sx={{width: '100%'}}>
                    <Typography
                        sx={{
                        fontSize: '18px',
                        fontFamily: 'Lato',
                        color: '#7c7c8d'
                        }}
                    >
                        Número do cartão<span style={{color: 'red'}}>*</span>
                    </Typography>
                    <TextField
                        name={'number'}
                        fullWidth
                        variant="outlined"
                        placeholder="Digite aqui..."
                        autoComplete="off"
                        value={form.cardData.number
                            .replace(/(.{4})/g, '$1 ')
                            .trim() // Format the displayed value
                        }
                        onChange={handleChangeNumber}
                        sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                            borderColor: '#e9eaec',
                            borderRadius: '10px'
                            },
                        },
                        }}
                        onFocus={handleInputFocus}
                    />
                </Box>
                <Box sx={{width: '100%', marginTop: '30px'}}>
                    <Typography
                        sx={{
                        fontSize: '18px',
                        fontFamily: 'Lato',
                        color: '#7c7c8d'
                        }}
                    >
                        Nome(igual escrito no seu cartão)<span style={{color: 'red'}}>*</span>
                    </Typography>
                    <TextField
                        name={'name'}
                        fullWidth
                        variant="outlined"
                        placeholder="Digite aqui..."
                        autoComplete="off"
                        value={form.cardData.name}
                        onChange={(e) => {
                            setForm({
                                ...form,
                                cardData: {
                                ...form.cardData,
                                name: e.target.value
                                }
                            });
                        }}
                        sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                            borderColor: '#e9eaec',
                            borderRadius: '10px'
                            },
                        },
                        }}
                        onFocus={handleInputFocus}
                    />
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        marginTop: '30px',
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                >
                    <Box sx={{width: '48%'}}>
                        <Typography
                            sx={{
                            fontSize: '18px',
                            fontFamily: 'Lato',
                            color: '#7c7c8d'
                            }}
                        >
                            Validade<span style={{color: 'red'}}>*</span>
                        </Typography>
                        <TextField
                            name={'expiry'}
                            fullWidth
                            variant="outlined"
                            placeholder="mm/aa"
                            autoComplete="off"
                            value={form.cardData.expiry
                                .replace(/^(.{2})(.*)$/, '$1/$2')
                            }
                            onChange={handleChangeExpiry}
                            sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                borderColor: '#e9eaec',
                                borderRadius: '10px'
                                },
                            },
                            }}
                            onFocus={handleInputFocus}
                        />
                    </Box>
                    <Box sx={{width: '48%'}}>
                        <Typography
                            sx={{
                            fontSize: '18px',
                            fontFamily: 'Lato',
                            color: '#7c7c8d'
                            }}
                        >
                            CVV<span style={{color: 'red'}}>*</span>
                        </Typography>
                        <TextField
                            fullWidth
                            name='cvv'
                            variant="outlined"
                            placeholder="***"
                            autoComplete="off"
                            value={form.cardData.cvv}
                            onChange={(e) => {
                                if(e.target.value.length > 3) return;
                                setForm({
                                    ...form,
                                    cardData: {
                                    ...form.cardData,
                                    cvv: e.target.value.replace(/\D/g, '')
                                    }
                                });
                            }}
                            sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                borderColor: '#e9eaec',
                                borderRadius: '10px'
                                },
                            },
                            }}
                            onFocus={handleInputFocus}
                        />
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    width: '450px',
                    height: '350px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Cards
                    number={form.cardData.number}
                    expiry={form.cardData.expiry}
                    cvc={form.cardData.cvv}
                    name={form.cardData.name}
                    focused={focus}
                />
                <Box sx={{display: 'flex', alignItems: 'center', marginTop: '20px'}}>
                    <GppGoodIcon sx={{ color: '#6ee7b7'}}/>
                    <Typography color={'#e5e7eb'}>
                        Seus Dados estão seguros
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default CreditCardForm;
