import { Box } from '@mui/material';
import React from 'react';

const PageContentContainer: React.FC<React.PropsWithChildren<{
    noSidebar?: boolean;
    backgroundWhite?: boolean;
}>> = ({ children, noSidebar, backgroundWhite}) => {
    return (
        <Box
            sx={{
                width: '100vw',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingTop: '100px',
                paddingLeft: noSidebar ? '' : '250px',
                backgroundColor: backgroundWhite ? '#ffffff' : '#f5f5f5',
                '@media (max-width: 1410px)': {
                    paddingLeft: '0'
                }
            }}
        >
            {children}
        </Box>
    );
}

export default PageContentContainer;