import React, { useRef } from 'react';
import { Box, Grid, TextField, Typography } from '@mui/material';
import { formatCNPJ } from '../../../utils/formatValues.tsx';

const ClinicInfo: React.FC<{ 
    clinicData: any, 
    setClinicData: any, 
    nameError: boolean, 
    communicationNameError: boolean, 
    responsibleError: boolean, 
    cnpjError: boolean, 
    setCnpjError: (error: boolean) => void 
}> = ({ 
    clinicData, 
    setClinicData, 
    nameError, 
    communicationNameError, 
    responsibleError, 
    cnpjError, 
    setCnpjError 
}) => {
    const fileInputRef = useRef(null);

    const handleCNPJChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const formattedValue = formatCNPJ(event.target.value);
        setClinicData({ 
            ...clinicData,
            data_clinic: {
                ...clinicData.data_clinic,
                clinic_registration: formattedValue 
            }
        });

        setCnpjError(formattedValue.length < 18);
    };

    return (
        <Box sx={{ marginTop: '25px' }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Typography sx={{ color: '#7c7c8d' }}>
                        Nome da Clínica <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        placeholder="Digite aqui..."
                        autoComplete="off"
                        value={clinicData?.data_clinic?.clinic_name}
                        onChange={(e) => {
                            setClinicData({ 
                                ...clinicData,
                                data_clinic: {
                                    ...clinicData.data_clinic,
                                    clinic_name: e.target.value 
                                }
                            })
                        }}
                        error={nameError}
                        helperText={nameError ? "Este campo é obrigatório." : ""}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#e9eaec',
                                    borderRadius: '10px'
                                },
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography sx={{ color: '#7c7c8d' }}>
                        CNPJ da Clínica <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        placeholder="Digite aqui..."
                        autoComplete="off"
                        value={clinicData?.data_clinic?.clinic_registration}
                        onChange={handleCNPJChange}
                        error={cnpjError}
                        helperText={cnpjError ? "CNPJ inválido. Deve conter 18 caracteres." : ""}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#e9eaec',
                                    borderRadius: '10px'
                                },
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography sx={{ color: '#7c7c8d' }}>
                        Nome utilizado nas comunicações <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        placeholder="Digite aqui..."
                        autoComplete="off"
                        value={clinicData?.data_clinic?.name_used_in_communications}
                        onChange={(e) => {
                            setClinicData({ 
                                ...clinicData,
                                data_clinic: {
                                    ...clinicData.data_clinic,
                                    name_used_in_communications: e.target.value 
                                }
                            })
                        }}
                        error={communicationNameError}
                        helperText={communicationNameError ? "Este campo é obrigatório." : ""}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#e9eaec',
                                    borderRadius: '10px'
                                },
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography sx={{ color: '#7c7c8d' }}>
                        Responsável pela Clínica <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        placeholder="Digite aqui..."
                        autoComplete="off"
                        value={clinicData?.data_clinic?.responsible_clinic}
                        onChange={(e) => {
                            setClinicData({ 
                                ...clinicData,
                                data_clinic: {
                                    ...clinicData.data_clinic,
                                    responsible_clinic: e.target.value 
                                }
                            })
                        }}
                        error={responsibleError}
                        helperText={responsibleError ? "Este campo é obrigatório." : ""}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#e9eaec',
                                    borderRadius: '10px'
                                },
                            },
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default ClinicInfo;
