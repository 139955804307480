import React, { useEffect } from 'react';
import { Box, Checkbox, TextField, Typography } from "@mui/material";
import { ICommitmentForm } from '../../../../types/addEventModal';


interface IAddEventCommitmentFormProps {
    form: ICommitmentForm;
    setForm: (form: ICommitmentForm) => void;
}

const AddEventCommitmentForm: React.FC<IAddEventCommitmentFormProps> = ({
    form,
    setForm
}) => {

    const {
        name,
        fullDay,
        startDate,
        startTime,
        endDate,
        endTime,
        repeat,
        alert,
        unavailable,
        importantEvent
    } = form;

    return (
        <Box sx={{marginTop: '-30px'}}>
            <Box>
                <Typography sx={{color: '#7c7c8d'}}>
                    Nome do Paciente <span style={{color: 'red'}}>*</span>
                </Typography>
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder='Digite aqui...'
                    autoComplete='off'
                    value={name}
                    onChange={(e) => {
                        setForm({...form, name: e.target.value})
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: '#e9eaec',
                                borderRadius: '10px'
                            },
                        },
                    }}
                />
            </Box>
            <Box 
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '10px'
                }}>
                <Typography sx={{color: '#4087e4'}}>
                    Cadastrar novo paciente
                </Typography>
            </Box>
            <Box sx={{display: 'flex', alignItems: 'center', height: '20px'}}>
                <Checkbox 
                    value={fullDay}
                    checked={fullDay}
                    onChange={(e) => {
                        setForm({...form, fullDay: e.target.checked})
                    }}
                />
                <Typography>Dia inteiro</Typography>
            </Box>
            <Box 
                sx={{
                    display: 'flex',
                    height: '190px',
                    flexDirection: 'column',
                    justifyContent: 'space-between',  
                    marginTop: '20px'
                }}
            >
                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Box sx={{width: '700px'}}>
                        <Typography sx={{color: '#7c7c8d'}}>
                            Começa em <span style={{color: 'red'}}>*</span>
                        </Typography>
                        <TextField
                            fullWidth
                            variant="outlined"
                            type='date'
                            value={startDate}
                            onChange={(e) => {
                                setForm({...form, startDate: e.target.value})
                            }}
                            inputProps={{ max: endDate }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: '#e9eaec',
                                        borderRadius: '10px'
                                    },
                                },
                            }}
                        />
                    </Box>
                    <Box sx={{width: '510px'}}>
                        <Typography sx={{color: '#7c7c8d'}}>
                            Horário início <span style={{color: 'red'}}>*</span>
                        </Typography>
                        <TextField
                            fullWidth
                            variant="outlined"
                            type='time'
                            value={startTime}
                            onChange={(e) => {
                                setForm({...form, startTime: e.target.value})
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: '#e9eaec',
                                        borderRadius: '10px'
                                    },
                                },
                            }}
                        />
                    </Box>
                </Box>
                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Box sx={{width: '700px'}}>
                        <Typography sx={{color: '#7c7c8d'}}>
                            Termina em <span style={{color: 'red'}}>*</span>
                        </Typography>
                        <TextField
                            fullWidth
                            variant="outlined"
                            type='date'
                            value={endDate}
                            onChange={(e) => {
                                setForm({...form, endDate: e.target.value})
                            }}
                            inputProps={{ min: startDate }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: '#e9eaec',
                                        borderRadius: '10px'
                                    },
                                },
                            }}
                        />
                    </Box>
                    <Box sx={{width: '510px'}}>
                        <Typography sx={{color: '#7c7c8d'}}>
                            Horário Final <span style={{color: 'red'}}>*</span>
                        </Typography>
                        <TextField
                            fullWidth
                            variant="outlined"
                            type='time'
                            value={endTime}
                            onChange={(e) => {
                                setForm({...form, endTime: e.target.value})
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: '#e9eaec',
                                        borderRadius: '10px'
                                    },
                                },
                            }}
                        />
                    </Box>
                    
                </Box>
            </Box>
            <Box sx={{marginTop: '30px'}}>
                <Box sx={{display: 'flex', alignItems: 'center', height: '35px'}}>
                    <Checkbox
                        value={repeat}
                        checked={repeat}
                        onChange={(e) => {
                            setForm({...form, repeat: e.target.checked})
                        }}
                    />
                    Repetir Compromisso
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center', height: '35px'}}>
                    <Checkbox
                        value={alert}
                        checked={alert}
                        onChange={(e) => {
                            setForm({...form, alert: e.target.checked})
                        }}
                    />
                    Receber alerta
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center', height: '35px'}}>
                    <Checkbox
                        value={unavailable}
                        checked={unavailable}
                        onChange={(e) => {
                            setForm({...form, unavailable: e.target.checked})
                        }}
                    />
                    Não disponível para atendimento neste período
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center', height: '35px'}}>
                    <Checkbox
                        value={importantEvent}
                        checked={importantEvent}
                        onChange={(e) => {
                            setForm({...form, importantEvent: e.target.checked})
                        }}
                    />
                    Marcar como Evento Importante
                </Box>
            </Box>
        </Box>
    )
}

export default AddEventCommitmentForm;