import './LandingPageNavbar.css';
import { BsCaretDownFill, BsFilterRight, BsXLg } from "react-icons/bs";
import React from 'react';
import { useState } from 'react';
import LogoNavbar from "../../../assets/images/logo_nav_landing_page.svg";
import Dropdown from './dropdown.tsx';
import { useNavigate } from 'react-router-dom';

interface LandingPageNavbarProps {
  windowWidth: number;
}


const LandingPageNavbar: React.FC<LandingPageNavbarProps> = ({ windowWidth }) => {
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const [openDropdown, setOpenDropdown] = useState<string | null>(null);

    const toggleDropdown = (dropdownName: string | null) => {
        setOpenDropdown(prev => (prev === dropdownName ? null : dropdownName));
    }

  return (
    <>
      <header>
      {windowWidth > 1100 ? (
        <nav className="landing-page-menu">
          <div id='options-menu'>
            <div id='menu-buttons'>
              <img src={LogoNavbar} alt="Logo" />
              <div>
                <div className='menu-buttons' onMouseEnter={() => toggleDropdown('funcionalidades')} onMouseLeave={() => toggleDropdown(null)}>
                  <div>
                    <p>Funcionalidades</p>
                    <BsCaretDownFill className='dropdown-icon' />
                  </div>
                  {openDropdown == 'funcionalidades' &&
                    <Dropdown 
                    className={'dropdown'}
                    path={['/login', '/register']}
                    options={['Login', 'Register']}
                  />
                  }
                </div>
                <div className='menu-buttons' onMouseEnter={() => toggleDropdown('novidades')} onMouseLeave={() => toggleDropdown(null)}>
                  <div>
                    <p>Novidades</p>
                    <BsCaretDownFill className='dropdown-icon' />
                  </div>
                  {openDropdown == 'novidades' &&
                    <Dropdown 
                    className={'dropdown'}
                    path={['/login', '/register']}
                    options={['Login', 'Register']}
                  />
                  }
                </div>
                <div className='menu-buttons' onClick={() => navigate('/plans')}>
                  <p>Planos</p>
                </div>
              </div>
            </div>
            <div id='actions'>
              <a onClick={() => navigate('/login')} className='menu-buttons'>Acessar</a>
              <a onClick={() => navigate('/register')} id='trial'>Teste Grátis</a>
            </div>
          </div>
        </nav>
      ) : (
        <nav className='landing-page-menu'>
          <img src={LogoNavbar}></img>
          <div className='toggle-menu' onClick={toggleMenu}>
            <BsFilterRight />
          </div>
          {isOpen && (
            <div id='options-menu'>
              <div>
                <div className='toggle-menu' onClick={toggleMenu}>
                  <BsXLg />
                </div>
                <div id='menu-buttons'>
                  <div>
                    <div className={openDropdown != 'funcionalidades' ? 'menu-buttons' : 'menu-buttons background-white'} onClick={() => toggleDropdown('funcionalidades')}>
                      <div>
                        <p>Funcionalidades</p>
                        <BsCaretDownFill className='dropdown-icon' />
                      </div>
                      {openDropdown == 'funcionalidades' &&
                        <Dropdown 
                        className={'dropdown'}
                        path={['/login', '/register']}
                        options={['Login', 'Register']}
                      />
                      }
                    </div>
                    <div className={openDropdown != 'novidades' ? 'menu-buttons' : 'menu-buttons background-white'} onClick={() => toggleDropdown('novidades')}>
                      <div>
                        <p>Novidades</p>
                        <BsCaretDownFill className='dropdown-icon' />
                      </div>
                      {openDropdown == 'novidades' &&
                        <Dropdown 
                        className={'dropdown'}
                        path={['/login', '/register']}
                        options={['Login', 'Register']}
                      />
                      }
                    </div>
                    <div className='menu-buttons'  onClick={() => navigate('/plans')}>
                      <p>Planos</p>
                    </div>
                  </div>
                </div>
              </div>
              <div id='actions'>
                <a onClick={() => navigate('/login')} className='menu-buttons'>Acessar</a>
                <a onClick={() => navigate('/register')} id='trial'>Teste Grátis</a>
              </div>
            </div>
          )}
        </nav>
      )}
    </header>
    </>
  );
};

export default LandingPageNavbar;