import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [restrictedAccess, setRestrictedAccess] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const restricted = localStorage.getItem('restricted_access') === 'true';
    setAuthenticated(!!token);
    setRestrictedAccess(restricted);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('restricted_access');
    setAuthenticated(false);
    setRestrictedAccess(false);
  };

  return (
    <AuthContext.Provider value={{ authenticated, setAuthenticated, handleLogout, restrictedAccess }}>
      {children}
    </AuthContext.Provider>
  );
};
