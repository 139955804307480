import React from 'react';
import { Box, Checkbox, MenuItem, Select, TextField, Typography } from "@mui/material";
import { IAppointmentForm } from '../../../../types/addEventModal';

const calendarClockIcon = require('../../../../assets/icons/calendar-clock.png') as string;

interface IAddEventAppointmentFormProps {
    form: IAppointmentForm;
    setForm: (form: IAppointmentForm) => void;
}

const mockedReturnalOptions = ["mock1", "mock2","mock3"]

const AddEventAppointmentForm: React.FC<IAddEventAppointmentFormProps> = ({
    form,
    setForm
}) => {

    const {
        patientName,
        duration,
        observation,
        returnal,
        sendAutomaticAppointmentConfirmation,
        importantEvent
    } = form;

    return (
        <Box>
            <Box>
                <Typography sx={{color: '#7c7c8d'}}>
                    Nome do Paciente <span style={{color: 'red'}}>*</span>
                </Typography>
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder='Digite aqui...'
                    autoComplete='off'
                    value={patientName}
                    onChange={(e) => {
                        setForm({...form, patientName: e.target.value})
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: '#e9eaec',
                                borderRadius: '10px'
                            },
                        },
                    }}
                />
            </Box>
            <Box 
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '10px',
                    '@media (max-width: 644px)': {
                        marginBottom: '10px'
                    }
                }}>
                <Typography sx={{color: '#4087e4'}}>
                    Cadastrar novo paciente
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                }}
            >
                <Box
                    sx={{
                        border: '1px solid #e9eaec',
                        color: '#005fdb',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '400px',
                        cursor: 'pointer',
                        borderRadius: '10px',
                        '@media (max-width: 1270px)': {
                            height: '100px'
                        }
                    }}
                >
                    <img src={calendarClockIcon} alt={'calendarClock'} />
                    <Typography>Encontrar horário livre</Typography>
                </Box>
                <Box
                    sx={{
                        width: '800px',
                    }}
                >
                    <Typography sx={{color: '#7c7c8d'}}>
                        Duração da consulta (min) <span style={{color: 'red'}}>
                            *
                        </span>
                    </Typography>
                    <TextField 
                        fullWidth
                        variant="outlined"
                        placeholder='Digite aqui...'
                        value={duration}
                        onChange={(e) => setForm({...form, duration: e.target.value})}
                        autoComplete='off'
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#e9eaec',
                                    borderRadius: '10px'
                                },
                            },
                        }}
                    />
                </Box>
            </Box>
            <Box sx={{marginTop: '20px'}}>
                <Typography sx={{color: '#7c7c8d'}}>
                    Observações
                </Typography>
                <TextField
                    fullWidth
                    variant="outlined"
                    multiline
                    rows={4}
                    placeholder='Digite aqui...'
                    value={observation}
                    onChange={(e) => {
                        if(e.target.value.length <= 500){
                            setForm({ ...form, observation: e.target.value })
                        }
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: '#e9eaec',
                                borderRadius: '10px'
                            },
                        },
                    }}
                />
                <Box 
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: '5px'
                    }}
                >
                    <Typography
                        sx={{
                            color: observation.length >= 500 ? 'red' : '#7c7c8d'
                        }}
                    >
                        {observation.length} / 500
                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    '@media (max-width: 670px)': {
                        marginBottom: '20px'
                    }
                }}
            >
                <Box sx={{width: '50%', minWidth: '300px'}}>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '300px'
                        }}>
                        <Checkbox
                            value={sendAutomaticAppointmentConfirmation}
                            checked={sendAutomaticAppointmentConfirmation}
                            onChange={(e) => setForm({
                                ...form,
                                sendAutomaticAppointmentConfirmation: e.target.checked
                            })}
                        />
                        <Typography>
                            Enviar confirmação e lembrete de consulta automática
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '300px'
                        }}>
                        <Checkbox
                            value={importantEvent}
                            checked={importantEvent}
                            onChange={(e) => setForm({...form, importantEvent: e.target.checked})}
                        />
                        <Typography sx={{marginTop: '5px'}}>
                            Marcar como Evento Importante
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{width: '50%', minWidth: '300px'}}>
                    <Typography sx={{color: '#7c7c8d'}}>
                        Retornar em
                    </Typography>
                    <Select
                        sx={{
                            width: '100%',
                            height: '50px',
                            color: returnal === 'Sem Retorno' ?
                                '#7c7c8d' : 'black',
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#e9eaec',
                                borderWidth: '1px',
                                borderRadius: '10px'
                            }
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={returnal}
                        onChange={(e) => setForm({...form, returnal: e.target.value})}
                    >
                        <MenuItem
                            value={'Sem Retorno'}
                            sx={{
                                color: '#7c7c8d'
                            }}
                        >{'Sem Retorno'}</MenuItem>
                        {mockedReturnalOptions.map(returnalOption =>
                            <MenuItem key={returnalOption} value={returnalOption}>
                                {returnalOption}
                            </MenuItem>
                        )}
                    </Select>
                </Box>
            </Box>
        </Box>
    )
}

export default AddEventAppointmentForm;