import React, { useState } from "react"; 
import Header from "../../components/header/index.tsx";
import Sidebar from "../../components/sidebar/index.tsx";
import { Box, Button, FormControl, FormControlLabel, FormHelperText, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from "@mui/material";
import { formatCEP, formatCPF, formatCellPhone } from "../../utils/formatValues.tsx";
import { toast } from "react-toastify";
import { ISaveForm } from "../../types/AccountSettings/forms.tsx";
import ChangePassword from "../../components/modals/ChangePasswordModal/index.tsx";
import { useNavigate } from "react-router-dom";
import MobileHeader from "../../components/MobileHeader/index.tsx";


const estados = [
    "Acre - AC",
    "Alagoas - AL",
    "Amapá - AP",
    "Amazonas - AM",
    "Bahia - BA",
    "Ceará - CE",
    "Distrito Federal - DF",
    "Espírito Santo - ES",
    "Goiás - GO",
    "Maranhão - MA",
    "Mato Grosso - MT",
    "Mato Grosso do Sul - MS",
    "Minas Gerais - MG",
    "Pará - PA",
    "Paraíba - PB",
    "Paraná - PR",
    "Pernambuco - PE",
    "Piauí - PI",
    "Rio de Janeiro - RJ",
    "Rio Grande do Norte - RN",
    "Rio Grande do Sul - RS",
    "Rondônia - RO",
    "Roraima - RR",
    "Santa Catarina - SC",
    "São Paulo - SP",
    "Sergipe - SE",
    "Tocantins - TO"
  ]

const AccountScreen: React.FC = () => {
    
    const navigate = useNavigate();

    const [form, setForm] = useState<ISaveForm> ({
        nome: '',
        sexo: '',
        cpf: '',
        cno: '',
        email: '',
        celular: '',
        cep: '',
        rua: '',
        numero: '',
        bairro: '',
        cidade: '',
        estado: '',
        clinica: '',
        perfil: ''
    }) 

    const [errors, setErrors] = useState<ISaveForm> ({
        nome: '',
        sexo: '',
        cpf: '',
        cno: '',
        email: '',
        celular: '',
        cep: '',
        rua: '',
        numero: '',
        bairro: '',
        cidade: '',
        estado: '',
        clinica: '',
        perfil: ''
    }) 

    const [openChangePasswordModal, setChangePasswordModal] = useState(false);

    const validateForm = () => {
        const newErrors = { ...errors };
        let isValid = true;
    
        if (!form.nome) {
            newErrors.nome = 'Campo obrigatório. Favor, preencha para continuar.';
            isValid = false;
        } else {
            newErrors.nome = '';
        }

        if (!form.email) {
            newErrors.email = 'Campo obrigatório. Favor, preencha para continuar.';
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(form.email)) {
            newErrors.email = 'Email inválido. Favor, insira um E-mail válido para continuar.';
            isValid = false;
        } else {
            newErrors.email = '';
        }

        setErrors(newErrors);
        return isValid;
    }
    
    const handleSubmit = () => {
        if(validateForm()) {
            toast.success('Informações atualizadas com sucesso.')
        } else {
            toast.error(
                <>
                    Erro ao atualizar as informações.
                    <br/>
                    Verifique os campos obrigatórios e tente novamente.
                </>
            )
        }
    }
    return(
        <>
            <Header />
            <MobileHeader />
            <Sidebar />
            <ChangePassword
                open={openChangePasswordModal}
                setOpen={setChangePasswordModal}
                onClose={() => {}}
            />
            <Box
                sx={{
                    width: 'calc(100vw - 250px)',
                    position: 'absolute',
                    height: 'calc(100vh - 100px)',
                    alignItems: 'center',
                    marginTop: '100px',
                    marginLeft: '250px',
                    padding: '1.5rem',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1rem',
                    overflowY: 'scroll',
                    '@media (max-width: 1410px)': {
                        width: '100vw',
                        marginLeft: '0',
                    }
                }}
            >
                <Box 
                    sx={{
                        width: '100%',
                        padding: '2rem',
                        border: '1px solid rgba(0, 0, 0, 0.1)',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '.5rem',

                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: '2rem',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#004039',
                                borderRadius: '50%',
                                width: '136px',
                                height: '136px',
                                fontSize: '2rem',
                                color: '#FFF',
                            }}
                        >
                            FT
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '.25rem',
                                "@media (max-width: 452px)": {
                                    width: '100%',
                                }
                            }}
                        >
                            <Typography 
                                sx={{
                                    color:'rgba(124, 124, 141, 1)',
                                    fontWeight: '600'
                                }}
                            >
                                Nome
                                <span style={{color: '#FF0000'}}>*</span>
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: '1rem',
                                    "@media (max-width: 682px)": {
                                        flexDirection: 'column',
                                        maxWidth: '350px',
                                    },
                                    "@media (max-width: 452px)": {
                                        maxWidth: '100%',
                                        width: '100%',
                                    }
                                }}    
                            >
                                <FormControl error={!!errors.nome} sx={{width: '100%'}}>
                                    <TextField
                                        required
                                        variant="outlined"
                                        placeholder='Digite aqui...'
                                        size='small'
                                        error={!!errors.nome}
                                        value={form.nome}
                                        onChange={(e) => {
                                            setForm({...form, nome: e.target.value})
                                                setErrors({...errors, nome: ''})
                                        }}
                                        sx={{
                                            "@media (max-width: 452px)": {
                                                width: '100%',   
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderRadius: '10px'
                                                },
                                            },
                                        }}
                                    />
                                    <FormHelperText>{errors.nome}</FormHelperText>
                                </FormControl>
                                <Box
                                    sx={{
                                        paddingBottom: !!errors.nome ? '19.91px' : 'none',
                                        display: 'flex',
                                        gap: '1rem',
                                        alignItems: 'center',
                                    }}                                
                                >
                                    <Typography 
                                        sx={{
                                            color:'rgba(124, 124, 141, 1)',
                                            fontWeight: '600'
                                        }}
                                    >
                                        Sexo
                                    </Typography>
                                    <RadioGroup
                                        name="controlled-radio-buttons-group"
                                        row
                                        value={form.sexo}
                                        onChange={(e) => {
                                            setForm({...form, sexo: e.target.value})
                                    }}
                                        sx={{ width: '280px' }}
                                    >
                                        <FormControlLabel value="Feminino" control={<Radio size="small"/>} style={{position: 'inherit', pointerEvents: 'inherit'}} label="Feminino" />
                                        <FormControlLabel value="Masculino" control={<Radio size="small" />} style={{position: 'inherit', pointerEvents: 'inherit'}} label="Masculino" />
                                    </RadioGroup>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: '1rem',
                                    "@media (max-width: 770px)": {
                                        flexDirection: 'column',
                                        maxWidth: '400px',
                                    },
                                    "@media (max-width: 452px)": {
                                        maxWidth: '100%',
                                        width: '100%',
                                    }
                                }}    
                            >
                                <Box sx={{width: '100%'}}>
                                    <Typography 
                                        sx={{
                                            color:'rgba(124, 124, 141, 1)',
                                            fontWeight: '600'
                                        }}
                                    >
                                        CPF
                                    </Typography>
                                    <TextField
                                    variant="outlined"
                                    placeholder='Digite aqui...'
                                    size='small'
                                    value={formatCPF(form.cpf)}
                                    onChange={(e) => {
                                        setForm({...form, cpf: e.target.value})
                                    }}
                                    sx={{
                                        width: '20rem',
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: '#e9eaec',
                                                borderRadius: '10px'
                                            },
                                        },
                                        "@media (max-width: 452px)": {
                                            width: '100%',
                                        }
                                    }}
                                />
                                </Box>
                                <Box>
                                    <Typography 
                                        sx={{
                                            color:'rgba(124, 124, 141, 1)',
                                            fontWeight: '600'
                                        }}
                                    >
                                        CNO
                                    </Typography>
                                    <TextField
                                    variant="outlined"
                                    placeholder='Digite aqui...'
                                    size='small'
                                    value={form.cno}
                                    onChange={(e) => {
                                        setForm({...form, cno: e.target.value})
                                    }}
                                    sx={{
                                        width: '20rem',
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: '#e9eaec',
                                                borderRadius: '10px'
                                            },
                                        },
                                        "@media (max-width: 452px)": {
                                            width: '100%',
                                        }
                                    }}
                                />
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    <Typography sx={{
                        fontSize: '25px',
                        fontWeight: 'bold',
                        fontFamily: 'Lato'
                    }}>Contato</Typography>
                    <Box
                            sx={{
                                display: 'flex',
                                gap: '1rem',
                                "@media (max-width: 1098px)": {
                                    flexDirection: 'column',
                                    width: '100%',
                                }
                            }}    
                    >
                        <Box sx={{width: '100%'}}>
                            <FormControl error={!!errors.email} sx={{ width: '100%'}}>
                                <Typography 
                                    sx={{
                                        color:'rgba(124, 124, 141, 1)',
                                        fontWeight: '600'
                                    }}
                                >
                                    E-mail
                                </Typography>
                                <TextField
                                    required
                                    variant="outlined"
                                    placeholder='Digite aqui...'
                                    size='small'
                                    error={!!errors.email}
                                    value={form.email}
                                    onChange={(e) => {
                                        setForm({...form, email: e.target.value})
                                        setErrors({...errors, email: ''})
                                    }}
                                    sx={{
                                        width: '41rem',
                                        "@media (max-width: 1098px)": {
                                            width: '100%'    
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderRadius: '10px'
                                            },
                                        },
                                    }}
                                />
                                <FormHelperText>{errors.email}</FormHelperText>
                            </FormControl>
                        </Box>
                        <Box sx={{width: '100%'}}>
                            <Typography 
                                sx={{
                                    color:'rgba(124, 124, 141, 1)',
                                    fontWeight: '600'
                                }}
                            >
                                Celular
                            </Typography>
                            <TextField
                            variant="outlined"
                            placeholder='Digite aqui...'
                            size='small'
                            value={formatCellPhone(form.celular)}
                            onChange={(e) => {
                                setForm({...form, celular: e.target.value})
                            }}
                            sx={{
                                width: '20rem',
                                "@media (max-width: 440px)": {
                                    width: '100%',
                                },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: '#e9eaec',
                                        borderRadius: '10px'
                                    },
                                },
                            }}
                            />
                        </Box>  
                    </Box>
                    <Typography sx ={{
                        fontSize: '25px',
                        fontWeight: 'bold',
                        fontFamily: 'Lato'
                        }}>Senha</Typography>
                    <Button 
                        onClick={(e) => setChangePasswordModal(!openChangePasswordModal)}
                        sx={{
                            fontSize: '20px',
                            fontWeight: 'bold',
                            fontFamily: 'Lato',
                            alignSelf: 'start',
                            padding: 0,
                            '&:hover': {
                                textDecorationLine: 'underline',
                                backgroundColor: 'transparent',
                            }
                        }}
                    >
                        ALTERAR SENHA
                    </Button>
                    <Typography sx={{
                        fontSize: '25px',
                        fontWeight: 'bold',
                        fontFamily: 'Lato'}}>Endereço</Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '.25rem',
                            "@media (max-width: 550px)": {
                                flexDirection: 'column',
                                width: '100%',
                            }
                        }}
                    >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '.25rem',
                                    width: '25%',
                                    minWidth: '100px',
                                    "@media (max-width: 550px)": {
                                        width: '100%',
                                    },
                                }}
                            >
                                <Typography 
                                    sx={{
                                        color:'rgba(124, 124, 141, 1)',
                                        fontWeight: '600'
                                    }}
                                >
                                    CEP
                                </Typography>
                                <TextField
                                    variant="outlined"
                                    placeholder='Digite aqui...'
                                    size='small'
                                    value={formatCEP(form.cep)}
                                    onChange={(e) => {
                                        setForm({...form, cep: e.target.value})
                                    }}
                                    sx={{
                                        "@media (max-width: 550px)": {
                                            width: '100%',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: '#e9eaec',
                                                borderRadius: '10px'
                                            },
                                        },
                                    }}
                                />
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '.25rem',
                                    width: '50%',
                                    minWidth: '210px',
                                    "@media (max-width: 550px)": {
                                        width: '100%',
                                    },
                                }}                                
                            >
                                <Typography 
                                    sx={{
                                        color:'rgba(124, 124, 141, 1)',
                                        fontWeight: '600'
                                    }}
                                >
                                    Rua
                                </Typography>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder='Digite aqui...'
                                    size='small'
                                    value={form.rua}
                                    onChange={(e) => {
                                        setForm({...form, rua: e.target.value})
                                    }}
                                    sx={{
                                        "@media (max-width: 550px)": {
                                            width: '100%',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: '#e9eaec',
                                                borderRadius: '10px'
                                            },
                                        },
                                    }}
                                />
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '.25rem',
                                    width: '25%',
                                    minWidth: '90px',
                                    "@media (max-width: 550px)": {
                                        width: '100%',
                                    },
                                }}
                            >
                                <Typography 
                                    sx={{
                                        color:'rgba(124, 124, 141, 1)',
                                        fontWeight: '600'
                                    }}
                                >
                                    Número
                                </Typography>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder='Digite aqui...'
                                    size='small'
                                    value={form.numero}
                                    onChange={(e) => {
                                        setForm({...form, numero: e.target.value})
                                    }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: '#e9eaec',
                                                borderRadius: '10px'
                                            },
                                        },
                                    }}
                                />
                            </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '1rem',
                            "@media (max-width: 550px)": {
                                flexDirection: 'column',
                                width: '100%',
                            },
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '.25rem',
                                width: '33.3%',
                                "@media (max-width: 550px)": {
                                    width: '100%',
                                },
                            }}                                
                        >
                            <Typography 
                                sx={{
                                    color:'rgba(124, 124, 141, 1)',
                                    fontWeight: '600'
                                }}
                            >
                                Bairro
                            </Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                placeholder='Digite aqui...'
                                size='small'
                                value={form.bairro}
                                onChange={(e) => {
                                    setForm({...form, bairro: e.target.value})
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#e9eaec',
                                            borderRadius: '10px'
                                        },
                                    },
                                }}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '.25rem',
                                width: '33.3%',
                                "@media (max-width: 550px)": {
                                    width: '100%',
                                },
                            }}                                
                        >
                            <Typography 
                                sx={{
                                    color:'rgba(124, 124, 141, 1)',
                                    fontWeight: '600'
                                }}
                            >
                                Cidade
                            </Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                placeholder='Digite aqui...'
                                size='small'
                                value={form.cidade}
                                onChange={(e) => {
                                    setForm({...form, cidade: e.target.value})
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#e9eaec',
                                            borderRadius: '10px'
                                        },
                                    },
                                }}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '.25rem',
                                width: '33.4%',
                                "@media (max-width: 550px)": {
                                    width: '100%',
                                },
                            }}                                
                        >
                            <Typography 
                                sx={{
                                    color:'rgba(124, 124, 141, 1)',
                                    fontWeight: '600'
                                }}
                            >
                                Estado
                            </Typography>
                            <Select
                                placeholder="Selecione um Estado"
                                size='small'
                                value={form.estado}
                                onChange={(e) => {
                                    setForm({...form, estado: e.target.value})
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#e9eaec',
                                        borderWidth: '1px',
                                        borderRadius: '10px'
                                    }
                                }}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            maxHeight: '200px',
                                        }
                                    }
                                }}
                            >
                            <MenuItem
                                selected
                                value={'Selecione um Estado'}
                                sx={{
                                    color: '#7c7c8d',
                                }}
                            >{'Selecione um Estado'}</MenuItem>
                            {estados.map(opcao => 
                                <MenuItem key={opcao} value={opcao}>
                                    {opcao}
                                </MenuItem>
                            )}
                            </Select>
                        </Box>
                    </Box>
                    <Typography sx={{
                        fontSize: '25px',
                        fontWeight: 'bold',
                        fontFamily: 'Lato'
                    }}>Clínicas</Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '1rem',
                            "@media (max-width: 633px)": {
                                flexDirection: 'column',
                                width: '100%',
                            }
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '.25rem',
                                width: '25%',
                                minWidth: '250px',
                                "@media (max-width: 633px)": {
                                    width: '100%',
                                }
                            }}                                
                        >
                            <Typography 
                                sx={{
                                    color:'rgba(124, 124, 141, 1)',
                                    fontWeight: '600'
                                }}
                            >
                                Clínica
                            </Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                placeholder='Digite aqui...'
                                size='small'
                                value={form.clinica}
                                    onChange={(e) => {
                                        setForm({...form, clinica: e.target.value})
                                    }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#e9eaec',
                                            borderRadius: '10px'
                                        },
                                    },
                                }}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '.25rem',
                                width: '25%',
                                minWidth: '250px',
                                "@media (max-width: 633px)": {
                                    width: '100%',
                                }
                            }}                                
                        >
                                <Typography 
                                    sx={{
                                        color:'rgba(124, 124, 141, 1)',
                                        fontWeight: '600'
                                    }}
                                >
                                    Perfil
                                </Typography>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder='Digite aqui...'
                                    size='small'
                                    value={form.perfil}
                                    onChange={(e) => {
                                        setForm({...form, perfil: e.target.value})
                                    }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: '#e9eaec',
                                                borderRadius: '10px'
                                            },
                                        },
                                    }}
                                />
                            </Box>
                    </Box>
                    <Button
                        onClick={(e) => navigate('/settings')}
                        sx={{
                            fontSize: '20px',
                            fontWeight: 'bold',
                            fontFamily: 'Lato',
                            alignSelf: 'start',
                            padding: 0,
                            '&:hover': {
                                textDecorationLine: 'underline',
                                backgroundColor: 'transparent',
                            }
                        }}
                    >
                        CRIAR NOVA CLÍNICA
                    </Button>
                </Box>          
                <Button 
                    onClick={handleSubmit}
                    sx={{
                        backgroundColor: 'rgba(0, 106, 245, 1)',
                        color: '#FFF',
                        display: 'flex',
                        alignSelf: 'end',
                        '&:hover': {
                            backgroundColor: 'rgba(0, 80, 184, 1)'
                        }
                    }}
                >
                    Salvar
                </Button>
            </Box>
        </>
    );
}

export default AccountScreen;