import { Box, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';

const bookIcon = require('../../../../assets/icons/book-icon.png')

interface IConsultation {
    patientFirstName: string;
    date: string;
    status: string;
}

const CanceledConsultationsSquare: React.FC = () => {
    const [date, setDate] = React.useState<Dayjs | null>(dayjs());
    const [isThereCanceledConsultations, setIsThereCanceledConsultations] = React.useState<boolean>(false);
    const [consultations, setConsultations] = React.useState<IConsultation[]>([
        {
            patientFirstName: 'João',
            date: '22/09/2021',
            status: 'Cancelada'
        },
        {
            patientFirstName: 'Maria',
            date: '22/09/2021',
            status: 'Reagendada'
        }
    ]);
    useEffect(() => {
        if(consultations.filter(consultation => consultation.status === 'Cancelada').length){
            setIsThereCanceledConsultations(true);
        } else {
            setIsThereCanceledConsultations(false);
        }
    },[consultations])

    return (
        <Box
            sx={{
                padding: '15px',
                backgroundColor: '#fff',
                boxShadow: '0px 0px 5px 0px #c0c7d0',
                width: '60%',
                height: '351px',
                '@media (max-width: 765px)': {
                    width: '100%',
                    marginY: '20px',
                }
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    height: '50px',      
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                        sx={{
                            fontSize: '20px',
                            fontFamily: 'Lato',
                            fontWeight: 'bold',
                            '@media (max-width: 458px)': {
                                fontSize: '16px'
                            }
                        }}
                    >Consultas Canceladas</Typography>
                    <InfoOutlinedIcon
                        sx={{
                            color: '#627d93',
                            fontSize: '18px',
                            marginLeft: '15px',
                            '@media (max-width: 458px)': {
                                marginLeft: '2px',
                                fontSize: '14px'
                            }
                        }}
                    />
                </Box>
                <DatePicker
                    format="DD/MM/YYYY"
                    sx={{
                        width: '23%',
                        maxWidth: '170px',
                        minWidth: '150px',
                        '.MuiOutlinedInput-root': {
                            '& fieldset': {
                                border: 'none',
                            },
                            backgroundColor: '#e9ecf0',
                            minHeight: '30px', // Ajuste da altura mínima do componente
                            height: '30px', // Ajuste da altura do input
                            padding: '0 10px', // Ajusta o padding para uma altura reduzida
                            
                        },
                        '.MuiInputBase-input': {
                            backgroundColor: '#e9ecf0',
                            height: '20px', // Ajuste da altura do texto dentro do input
                            padding: '5px 0',
                        },
                    }}
                    value={date}
                    onChange={(date) => {
                        if (date) {
                            setDate(date);
                        } else {
                            setDate(null);
                        }
                    }}
                />
            </Box>
            { isThereCanceledConsultations ? (
                <Box
                    sx={{
                        height: 'calc(100% - 90px)',
                        overflowY: 'auto',
                        marginTop: '15px'
                    }}
                >
                    {   consultations.map((consultation, index) => (
                        <>
                            <Box
                                key={index}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    paddingX: '20px',
                                    marginY: '10px',
                                }}
                            >
                                <Box>
                                    <Typography
                                        sx={{color: '#77828f'}}
                                    >{consultation.patientFirstName}</Typography>
                                    <Typography
                                        sx={{
                                            color: '#77828f',
                                            fontSize: '14px',
                                        }}
                                    >{consultation.date}</Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-end',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                color: consultation.status === 'Cancelada' ? '#ca150c' : '#006af5',
                                            }}
                                        >{consultation.status}</Typography>
                                    </Box>
                                    <MoreVertOutlinedIcon sx={{marginLeft: '10px', color: '#77828f'}}/>
                                </Box>
                            </Box>
                            {index !== consultations.length-1 ? (
                                <Box
                                    sx={{
                                        width: '99%',
                                        marginX: 'auto',
                                        height: '1px',
                                        backgroundColor: '#c0c7d0'
                                    }}
                                />
                            ) : (<Box/>)}
                        </>
                    ))}
                </Box>
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        height: 'calc(100% - 90px)',
                        maxHeight: '190px',
                        margin: '35px 0px 15px 0px',
                    }}
                >
                    <img src={bookIcon} alt="Book Icon" />
                    <Typography
                        sx={{
                            fontSize: '20px',
                            fontWeight: 'bold',
                        }}
                    >Parabéns!</Typography>
                    <Typography
                        sx={{
                            color: '#77828f',
                        }}
                    >Todas as consultas canceladas estão reagendadas!</Typography>
                </Box>
            )}
        </Box>
    )
}

export default CanceledConsultationsSquare;