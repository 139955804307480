import { Box, Typography } from '@mui/material';
import React from 'react';
import BaseForIntelligenceSquare from '../../../../components/baseForIntelligenceSquare/index.tsx';
import dayjs, { Dayjs } from 'dayjs';
import { BarChart } from '@mui/x-charts/BarChart';

const MOCKED_QUANTITY_DATA = [
    {
        month: 'Jan',
        aproved: 400,
        disapproved: 240,
        open: 240,
    },
    {
        month: 'Fev',
        aproved: 0,
        disapproved: 0,
        open: 300,
    },
    {
        month: 'Mar',
        aproved: 200,
        disapproved: 139,
        open: 200,
    },
    {
        month: 'Abr',
        aproved: 278,
        disapproved: 390,
        open: 200,
    },
    {
        month: 'Mai',
        aproved: 0,
        disapproved: 0,
        open: 700,
    },
    {
        month: 'Jun',
        aproved: 239,
        disapproved: 380,
        open: 200,
    },
    {
        month: 'Jul',
        aproved: 349,
        disapproved: 430,
        open: 200,
    },
    {
        month: 'Ago',
        aproved: 349,
        disapproved: 430,
        open: 200,
    },
    {
        month: 'Set',
        aproved: 349,
        disapproved: 430,
        open: 200,
    },
    {
        month: 'Out',
        aproved: 349,
        disapproved: 430,
        open: 200,
    },
    {
        month: 'Nov',
        aproved: 349,
        disapproved: 430,
        open: 200,
    },
    {
        month: 'Dez',
        aproved: 349,
        disapproved: 430,
        open: 200,
    }
]

const SubtitleItem: React.FC<{color: string, label: string}> = ({color, label}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                width: '220px',
            }}
        >
            <Box
                sx={{
                    width: '30px',
                    height: '18px',
                    backgroundColor: color,
                    borderRadius: '20%',
                    marginRight: '10px',
                }}
            />
            <Typography
                sx={{
                    fontSize: '16px',
                    fontFamily: 'Lato',
                }}
            >
                {label}
            </Typography>
        </Box>
    )
}

interface IBarItemData {
    month: string;
    aproved: number;
    disapproved: number;
    open: number;
}

interface IBudgetData {
    aprovedBudgets: number;
    disapprovedBudgets: number;
    openBudgets: number;
    aprovalRate: number;
    quantityData: IBarItemData[];
    valueData: IBarItemData[];
}

const BudgetSquare: React.FC = () => {
    const [selectedDate, setSelectedDate] = React.useState<Dayjs | null>(dayjs());
    const [budgetOption, setBudgetOption] = React.useState<1 | 2>(1);
    const [budgetData, setBudgetData] = React.useState<IBudgetData>({
        aprovedBudgets: 0,
        disapprovedBudgets: 0,
        openBudgets: 0,
        aprovalRate: 0,
        quantityData: MOCKED_QUANTITY_DATA,
        valueData: MOCKED_QUANTITY_DATA.map((item) => ({
            month: item.month,
            aproved: item.aproved * (Math.random() * 15),
            disapproved: item.disapproved * (Math.random() * 12),
            open: item.open * (Math.random() * 10),
        })),
    });
    
    return (
        <BaseForIntelligenceSquare
            title = "Orçamento"
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            setBudgetOption={setBudgetOption}
            budgetOption={budgetOption}
        >
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    "@media (max-width: 736px)": {
                        flexDirection: 'column',
                        alignItems: 'center',
                    }
                }}
            >
                <Box
                    sx={{
                        marginTop: '40px',
                        width: '150px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        "@media (max-width: 736px)": {
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%',
                        },
                        "@media (max-width: 512px)": {
                            flexWrap: 'wrap',
                            rowGap: '15px',
                            justifyContent: 'center',
                        }
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            "@media (max-width: 512px)": {
                                marginRight: '20px',
                            }
                        }}
                    >
                        <Typography
                            sx={{
                                color: '#505050',
                                fontWeight: 'bold',
                            }}
                        variant="h6">{budgetData.aprovedBudgets}</Typography>
                        <Typography
                            sx={{
                                color: '#77828f',
                                "@media (max-width: 736px)": {
                                    fontSize: '13px',
                                }
                            }}
                            variant="body1"
                        >Orçamentos</Typography>
                        <Typography
                            sx={{
                                color: '#77828f',
                                "@media (max-width: 736px)": {
                                    fontSize: '13px',
                                }
                            }}
                            variant="body1"
                        >Aprovados</Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            marginTop: '20px',
                            "@media (max-width: 736px)": {
                                marginTop: '0',
                            }
                        }}
                    >
                        <Typography
                            sx={{
                                color: '#505050',
                                fontWeight: 'bold',
                            }}
                        variant="h6">{budgetData.disapprovedBudgets}</Typography>
                        <Typography
                            sx={{
                                color: '#77828f',
                                "@media (max-width: 736px)": {
                                    fontSize: '13px',
                                }
                            }}
                            variant="body1"
                        >Orçamentos</Typography>
                        <Typography
                            sx={{
                                color: '#77828f',
                                "@media (max-width: 736px)": {
                                    fontSize: '13px',
                                }
                            }}
                            variant="body1"
                        >Reprovados</Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            marginTop: '20px',
                            "@media (max-width: 736px)": {
                                marginTop: '0',
                            },
                            "@media (max-width: 512px)": {
                                marginLeft: '20px',
                            }
                        }}
                    >
                        <Typography
                            sx={{
                                color: '#505050',
                                fontWeight: 'bold',
                            }}
                        variant="h6">{budgetData.openBudgets}</Typography>
                        <Typography
                            sx={{
                                color: '#77828f',
                                "@media (max-width: 736px)": {
                                    fontSize: '13px',
                                }
                            }}
                            variant="body1"
                        >Orçamentos em</Typography>
                        <Typography
                            sx={{
                                color: '#77828f',
                                "@media (max-width: 736px)": {
                                    fontSize: '13px',
                                }
                            }}
                            variant="body1"
                        >Aberto</Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '20px',
                            "@media (max-width: 736px)": {
                                flexDirection: 'row',
                                marginTop: '0',
                                minWidth: '150px',
                            }
                        }}
                    >
                        <Box
                            sx={{
                                width: '95px',
                                height: '95px',
                                borderRadius: '50%',
                                backgroundColor: '#018abe',
                                color: 'white',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                "@media (max-width: 736px)": {
                                    width: '60px',
                                    height: '60px',
                                }
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '40px',
                                    fontWeight: 'bold',
                                    marginLeft: '10px',
                                    "@media (max-width: 736px)": {
                                        fontSize: '20px',
                                        marginLeft: '0px',
                                    }
                                }}
                            >{budgetData.aprovalRate}</Typography>
                            <Typography
                                sx={{
                                    fontSize: '18px',
                                    fontWeight: 'bold',
                                    "@media (max-width: 736px)": {
                                        fontSize: '12px',
                                    }
                                }}
                            >%</Typography>
                        </Box>
                        <Typography
                            sx={{color: '#77828f'}}
                        >Aprovação</Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: 'calc(100% - 200px)',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        "@media (max-width: 736px)": {
                            width: '100%',
                        }
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            height: '400px',
                            "@media (max-width: 736px)": {
                                height: '300px',
                            },
                            "@media (max-width: 510px)": {
                                height: '200px',
                            }
                        }}
                    >
                        <BarChart
                            sx={{
                                width: '100%',
                                height: '100%',
                            }}
                            dataset={budgetOption === 1 ? budgetData.quantityData as any : budgetData.valueData as any}
                            series={[
                                { label: 'Aprovados', dataKey: 'aproved', stack: 'a', color: '#20a200' },
                                { label: 'Reprovados', dataKey: 'disapproved', stack: 'a', color: '#d00303' },
                                { label: 'Em Aberto', dataKey: 'open', stack: 'a', color: '#ece9f1' },
                            ]}
                            xAxis={[{
                                scaleType: 'band',
                                dataKey: 'month',
                                disableLine: true,
                                disableTicks: true,
                            }]}
                            yAxis={[{
                                scaleType: 'linear',
                                disableTicks: true,
                                disableLine: true,
                            }]}
                            borderRadius={40}
                            slotProps={{
                                legend: { hidden: true },
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginTop: '20px',
                            width: '100%',
                            maxWidth: '800px',
                            flexWrap: 'wrap',
                            rowGap: '10px',
                        }}
                    >
                        <SubtitleItem
                            color="#20a200"
                            label="Orçamentos Aprovados"
                        />
                        <SubtitleItem
                            color="#d00303"
                            label="Orçamentos Reprovados"
                        />
                        <SubtitleItem
                            color="#ece9f1"
                            label="Orçamentos em Aberto"
                        />
                    </Box>
                </Box>
            </Box>
        </BaseForIntelligenceSquare>
    )
}

export default BudgetSquare;