import React, { useState, useEffect } from 'react';
import './RegisterPage.css';
import registerImg from '../../assets/images/register-img.png';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { configBackendConnection, endpoints } from "../../components/utilsBackend.ts";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const RegisterPage = () => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [cellphone, setCellphone] = useState('');
    const [formattedCellphone, setFormattedCellphone] = useState('');
    const [senha, setSenha] = useState('');
    const [confirmSenha, setConfirmSenha] = useState('');
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => {
            const loginCard = document.getElementById('login-card');
            const imageSection = document.getElementById('image-section');
            if (window.innerWidth <= 400) {
                loginCard.style.width = '100%';
                imageSection.style.width = '0';
                imageSection.style.display = 'none';
            } else if (window.innerWidth <= 576) {
                loginCard.style.width = '80%';
                imageSection.style.width = '20%';
                imageSection.style.display = 'flex';
            } else if (window.innerWidth <= 768) {
                loginCard.style.width = '70%';
                imageSection.style.width = '30%';
            } else if (window.innerWidth <= 992) {
                loginCard.style.width = '60%';
                imageSection.style.width = '40%';
            } else if (window.innerWidth <= 1200) {
                loginCard.style.width = '50%';
                imageSection.style.width = '50%';
            } else {
                loginCard.style.width = '40%';
                imageSection.style.width = '60%';
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };

    const toggleConfirmPasswordVisibility = () => {
        setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
    };

    const validatePassword = (password) => {
        const minLength = password.length >= 8;
        const hasUppercase = /[A-Z]/.test(password);
        const hasLowercase = /[a-z]/.test(password);
        const hasNumber = /\d/.test(password);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
        return minLength && hasUppercase && hasLowercase && hasNumber && hasSpecialChar;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validatePassword(senha)) {
            toast.error('A senha deve ter no mínimo 8 caracteres, incluindo uma letra maiúscula, uma letra minúscula, um número e um caractere especial.');
            return;
        }
        if (senha !== confirmSenha) {
            toast.error('As senhas não coincidem.');
            return;
        }

        setIsLoading(true);

        try {
            const response = await fetch(`${configBackendConnection.baseURL}/${endpoints.registerAPI}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email.toLowerCase(),
                    first_name: name,
                    telephone: cellphone,
                    password: senha,
                })
            });
            
            if (response.ok) {
                const data = await response.json();
                const token = data.token;
                localStorage.setItem('token', token);
                toast.success('Registro realizado com sucesso! Efetue o login...');
                navigate('/login');
            } else {
                const errorData = await response.json();
                const errorMessage = errorData.detail || "Não foi possível realizar o cadastro do usuário.";
                toast.error(errorMessage);
            }
        } catch (error) {
            toast.error('Ocorreu um erro ao processar sua solicitação. Por favor, tente novamente mais tarde.');
        } finally {
            setIsLoading(false);
        }
    };

    const formatPhoneNumber = (phoneNumber) => {
        const cleaned = phoneNumber.replace(/\D/g, '');
        const match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/);
        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
        return phoneNumber;
    };

    const handlePhoneNumberChange = (e) => {
        const cleanedPhoneNumber = e.target.value.replace(/\D/g, '');
        setCellphone(cleanedPhoneNumber);
        setFormattedCellphone(formatPhoneNumber(cleanedPhoneNumber));
    };

    return (
        <main id="register-main" className="position-fixed w-100 h-100 d-flex justify-content-center align-items-center">
            <section id="login-card" className="card rounded-4 login-container">
                <div className="card-body rounded-0 d-flex flex-column justify-content-center align-items-center">
                    <h1 className="title_login ml-0">Cadastre-se na DOT</h1>
                    <div className="entry_login_text">
                        <p className="text_login ml-0">Já tem uma conta? </p>
                        <a href="/login">Fazer login</a>
                    </div>

                    <form className="w-100" onSubmit={handleSubmit}>
                        <div className="form-group">
                            <input 
                                type="email" 
                                className="form-control" 
                                id="email" 
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Seu e-mail" 
                                required
                            />
                        </div>

                        <div className="form-group">
                            <input 
                                type="text" 
                                className="form-control" 
                                id="name" 
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Seu nome" 
                                required
                            />
                        </div>

                        <div className="form-group">
                            <input 
                                type="tel" 
                                className="form-control" 
                                id="cellphone" 
                                value={formattedCellphone}
                                onChange={handlePhoneNumberChange}
                                placeholder="WhatsApp ou celular *" 
                                required
                            />
                        </div>

                        <div className="form-group position-relative">
                            <input
                                type={isPasswordVisible ? "text" : "password"}
                                className="form-control"
                                id="senha"
                                value={senha}
                                onChange={(e) => setSenha(e.target.value)}
                                placeholder="Senha"
                                required
                            />
                            {isPasswordVisible ? (
                                <FaEyeSlash className="eye-icon" onClick={togglePasswordVisibility} />
                            ) : (
                                <FaEye className="eye-icon" onClick={togglePasswordVisibility} />
                            )}
                        </div>

                        <div className="form-group position-relative">
                            <input
                                type={isConfirmPasswordVisible ? "text" : "password"}
                                className="form-control"
                                id="confirmSenha"
                                value={confirmSenha}
                                onChange={(e) => setConfirmSenha(e.target.value)}
                                placeholder="Confirmar Senha"
                                required
                            />
                            {isConfirmPasswordVisible ? (
                                <FaEyeSlash className="eye-icon" onClick={toggleConfirmPasswordVisibility} />
                            ) : (
                                <FaEye className="eye-icon" onClick={toggleConfirmPasswordVisibility} />
                            )}
                        </div>

                        <p className="registration-text">Se ainda não tiver uma conta na DOT, <a href="/#">Termos de uso</a> e <a href="/#">Política de privacidade</a></p>
                        <button type="submit" className="btn btn-primary" disabled={isLoading}>
                            {isLoading ? 'Registrando...' : 'Testar grátis por 7 dias'}
                        </button>
                    </form>
                </div>
            </section>
            <section id="image-section" className="image-container">
                <img src={registerImg} alt="IMG Dentista registro" />
            </section>
        </main>
    );
};

export default RegisterPage;
