import React from 'react';
import PageContentContainer from '../../components/PageContentContainer/index.tsx';
import Sidebar from '../../components/sidebar/index.tsx';
import MobileHeader from '../../components/MobileHeader/index.tsx';
import Header from '../../components/header/index.tsx';
import { Box } from '@mui/material';
import PaymentDataOptionsMenu from './PaymentDataOptionsMenu/index.tsx';
import UserPlanScreen from './UserPlanScreen/index.tsx';
import UserCreditCardScreen from './UserCreditCardScreen/index.tsx';

export const UserPaymentDataPage: React.FC = () => {

    const [optionSelected, setOptionSelected] = React.useState('plan')
    return (
        <>
            <Header />
            <MobileHeader />
            <Sidebar />
            <PageContentContainer>
                <PaymentDataOptionsMenu
                    optionSelected={optionSelected}
                    setOptionSelected={setOptionSelected}
                />
                {
                    (optionSelected === 'plan' && <UserPlanScreen/>) ||
                    (optionSelected === 'credit' && <UserCreditCardScreen/>)
                }
            </PageContentContainer>
        </>
    )
}

export default UserPaymentDataPage;