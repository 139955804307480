import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './AuthProvide';
import { toast } from 'react-toastify';

const ProtectedRoute = ({ element }) => {
  const { authenticated, restrictedAccess } = useContext(AuthContext);

  if (!authenticated) {
    return <Navigate to="/login" />;
  }

  if (restrictedAccess) {
    toast.info('Pagamento pendente. Por favor, regularize seu pagamento.');
    return <Navigate to="/plans" />;
  }

  return element;
};

export default ProtectedRoute;
