import React, { useContext, useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Box, Button, Typography } from '@mui/material';
import logoIcon from '../../assets/images/logo_footer_landing_page.svg';
import StarIcon from '@mui/icons-material/Star';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import PersonModal from '../modals/PersonModal/index.tsx';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthProvide.js';

const Header: React.FC = () => {
    // Logic for user login check
    const [isUserLoggedIn, setIsUserLoggedIn] = React.useState(false);
    const { authenticated } = useContext(AuthContext);
    useEffect(() => {authenticated && setIsUserLoggedIn(true)}, [authenticated]);
    // vars
    const name = localStorage.getItem('name') || '';
    const navigate = useNavigate();
    const [openPersonModal, setOpenPersonModal] = useState<boolean>(false)

    return (
        <Box
            sx={{
                position: 'absolute',
                left: 0,
                top: 0,
                width: '100vw',
                height: '100px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0 60px 0 60px',
                background: 'linear-gradient(to right, #28808a, #0e3e5f 40%)',
                '@media (max-width: 1410px)': {
                    display: 'none'
                }
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '160px',
                    alignItems: 'center',
                    cursor: 'pointer'
                }}
                onClick={() => navigate('/intelligence')}
            >
                <img style={{width: '140px'}} src={logoIcon} alt={'logo'}/>
            </Box>
            <Box sx={{
                color: 'white',
                display: isUserLoggedIn ? 'flex' : 'none',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '450px',
            }}>
                <Button
                    sx={{
                        border: '1px solid white',
                        color: 'white',
                        height: '35px',
                        textTransform: 'none',
                        borderRadius: '10px',
                        '&:hover': {
                            backgroundColor: '#0a2e47',
                        }
                    }}
                    onClick={() => navigate('/plans')}
                >
                    Ver Planos
                </Button>
                <Box
                    sx={{
                        display: 'flex',
                        width: '100px',
                        justifyContent: 'space-between'
                    }}
                >
                    <StarIcon sx={{cursor: 'pointer'}}/>
                    <EmailRoundedIcon sx={{cursor: 'pointer'}}/>
                    <NotificationsRoundedIcon sx={{cursor: 'pointer'}}/>
                </Box>
                <Box
                    onClick={() => setOpenPersonModal(!openPersonModal)}
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                        width: '225px',
                        borderRadius: '10px',
                        cursor: 'pointer',
                        border: '1px solid none',
                        '&:hover': {
                            backgroundColor: '#0a2e47',
                        }
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: '#004039',
                            borderRadius: '50%',
                            width: '40px',
                            height: '40px',
                            color: '#FFF'
                        }}
                    >
                        FT
                    </Box>
                    <Box
                    >
                        <Typography sx={{fontSize: 16}}>
                            {name}
                        </Typography>
                        <Typography sx={{fontSize: 10}}>
                            Dentista Administradora
                        </Typography>
                    </Box>
                    <ArrowDropDownRoundedIcon sx={{fontSize: 45}}/>
                </Box>
            </Box>
                <PersonModal 
                    visible={openPersonModal}
                />
        </Box>
    );
};

export default Header;