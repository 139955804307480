import { Box, Button, Checkbox, Link, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { IPlanBoxData } from '../../../types/Plans/PlanTypes';
import { INewCreditCardForm, IPaymentData } from '../../../types/payment';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import ReCAPTCHA from 'react-google-recaptcha'
import PixPaymentConfirmationModal from '../../../components/modals/PaymentConfirmationModal/Pix/index.tsx';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';

const PlanPaymentSummary: React.FC<{
    paymentMethod: 'creditCard' | 'ticket' | 'pix';
    selectedPlan: IPlanBoxData;
    form: INewCreditCardForm;
}> = ({paymentMethod, selectedPlan, form}) => {
    const [agreed, setAgreed] = React.useState(false);
    const [enableConfirmButton, setEnableConfirmButton] = React.useState(false);
    const [capturedReCAPTCHAVal , setCapturedReCAPTCHAVal] = React.useState(null);
    const [paymentData, setPaymentData] = React.useState<IPaymentData>({
        paymentMethod: paymentMethod,
        email: form.email,
        status: 'pending',
        date: dayjs().format('DD/MM/YYYY'),
        discount: 'R$ 0,00',
        total: selectedPlan.price,
        code: Math.random().toString(36).substring(7),
    }); //this is just a mock, the real data will be set when the payment method is implemented
    const [openPixPaymentConfirmationModal, setOpenPixPaymentConfirmationModal] = React.useState(false);

    useEffect(() => {
        setPaymentData({
            ...paymentData,
            paymentMethod: paymentMethod,
            email: form.email,
            total: selectedPlan.price,
        });
    },[selectedPlan, form, paymentMethod]);

    useEffect(() => {
        if(paymentMethod === 'creditCard') {
            if(
                form.cellphone && form.cpfOrCnpj && form.email && form.fullname &&
                form.cardData.cvv && form.cardData.expiry && form.cardData.name &&
                form.cardData.number && form.address.cep && form.address.city &&
                form.address.neighborhood && form.address.number && form.address.street &&
                agreed && capturedReCAPTCHAVal
            ) {
                setEnableConfirmButton(true);
            } else {
                setEnableConfirmButton(false);
            }
        } else if (paymentMethod === 'ticket' || paymentMethod === 'pix') {
            if(
                form.cellphone && form.cpfOrCnpj && form.email && form.fullname &&
                form.address.cep && form.address.city &&
                form.address.neighborhood && form.address.number && form.address.street &&
                agreed && capturedReCAPTCHAVal
            ) {
                setEnableConfirmButton(true);
            } else {
                setEnableConfirmButton(false);
            }
        }
    }, [form, paymentMethod, agreed, capturedReCAPTCHAVal]);

    return (
        <Box
            sx={{
                width: '35%',
                "@media (max-width: 1086px)": {
                    width: '70%',
                },
                "@media (max-width: 840px)": {
                    width: '80%',
                },
                "@media (max-width: 416px)": {
                    width: '90%'
                }
            }}
        >   
            <PixPaymentConfirmationModal
                open={openPixPaymentConfirmationModal}
                setOpen={setOpenPixPaymentConfirmationModal}
                paymentData={paymentData}
            />
            <Typography
                fontFamily={'Lato'}
                fontSize={'24px'}
                fontWeight={'bold'}
                marginBottom={'20px'}
            >Resumo</Typography>
            <Box sx={{display: 'flex'}}>
                <VerifiedOutlinedIcon sx={{color: '#247885'}}/>
                <Typography
                    fontFamily={'Lato'}
                    fontSize={'16px'}
                    marginLeft={'5px'}
                    marginBottom={'10px'}
                    color={'#616d7c'}
                >Plano Gestão</Typography>
            </Box>
            <Box sx={{display: 'flex'}}>
                <AttachMoneyOutlinedIcon sx={{color: '#247885'}} />
                <Typography
                    fontFamily={'Lato'}
                    fontSize={'16px'}
                    marginLeft={'5px'}
                    color={'#616d7c'}
                >Valor do Plano: {selectedPlan.price}</Typography>
            </Box>
            <Box sx={{display: 'flex', marginY: '10px', marginLeft: '-7px'}}>
                <Checkbox checked={agreed} onChange={() => setAgreed(!agreed)}/>
                <Typography
                    fontFamily={'Lato'}
                    fontSize={'16px'}
                    marginLeft={'5px'}
                    color={'#616d7c'}
                >Concordo com os <Link sx={{cursor: 'pointer'}}>Termos de uso</Link> e <Link sx={{cursor: 'pointer'}}>Políticas de privacidade</Link> do Medicare {selectedPlan.price}</Typography>
            </Box>
            <ReCAPTCHA
                onChange={(val) => {setCapturedReCAPTCHAVal(val)}}
                sitekey={process.env.REACT_APP_SITE_KEY}
            />
            <Button
                sx={{
                    textTransform: 'none',
                    marginTop: '20px',
                    color: 'white',
                    backgroundColor: enableConfirmButton ? '#275b85' : '#bcbcbc',
                    '&:hover': {
                        backgroundColor: enableConfirmButton ? '#275b85' : '#bcbcbc',
                    },
                    width: '200px',
                    height: '50px'
                }}
                onClick={() => {
                    if(enableConfirmButton) {
                        if(paymentMethod === 'pix') {
                            setOpenPixPaymentConfirmationModal(true);
                        }
                    } else {
                        if(!capturedReCAPTCHAVal){
                            toast.error('Preencha o ReCAPTCHA');
                        } else if(!agreed){ 
                            toast.error('Aceite os termos de uso e políticas de privacidade');
                        } else {
                            toast.error('Preencha todos os campos obrigatórios');
                        }
                    }
                }}
            >
                Confirmar contratação
            </Button>
            <Typography
                    fontFamily={'Lato'}
                    fontSize={'16px'}
                    marginLeft={'5px'}
                    color={'#616d7c'}
                    marginY={'15px'}
            >
                * Para verificar o seu cartão será efetuado uma transação no 
                valor de R$1,27 que será estornada imediatamente após a verificação.
            </Typography>
            <Typography
                    fontFamily={'Lato'}
                    fontSize={'16px'}
                    marginLeft={'5px'}
                    color={'#616d7c'}
                    marginY={'15px'}
            >
                * A cobrança virá na sua fatura de Cartão de Crédito identificado 
                como Software Medicare.
            </Typography>
            <Typography
                    fontFamily={'Lato'}
                    fontSize={'16px'}
                    marginLeft={'5px'}
                    color={'#616d7c'}
                    marginY={'15px'}
            >
                * Sem fidelidade, cancelamento a qualquer hora em apenas um clique.
            </Typography>
        </Box>
    );
}

export default PlanPaymentSummary;