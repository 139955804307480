import React from 'react';
import { Box, Grid, TextField, Typography } from '@mui/material';

const FiscalInfo: React.FC<{ clinicData: any, setClinicData: any }> = ({ clinicData, setClinicData }) => {
    return (
        <Box sx={{ marginTop: '25px' }}>
            <Typography
                sx={{
                    fontSize: '25px',
                    fontWeight: 'bold',
                    fontFamily: 'Lato'
                }}
            >
                Fiscal
            </Typography>
            <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                <Grid item xs={12} md={4}>
                    <Typography sx={{ color: '#7c7c8d' }}>
                        Emitir recibos em nome de
                    </Typography>
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        placeholder="Digite aqui..."
                        value={clinicData?.fiscal_name || ''}
                        onChange={(e) => setClinicData({
                            ...clinicData,
                            fiscal_name: e.target.value
                        })}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                height: '40px',
                                '& fieldset': {
                                    borderColor: '#e9eaec',
                                    borderRadius: '10px'
                                },
                            },
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}

export default FiscalInfo;
