import { Box, Typography } from '@mui/material'
import React from 'react'

interface IPaymentDataOptionsMenuProps {
    optionSelected: string,
    setOptionSelected: (option: string) => void
}

const PaymentDataOptionsMenu: React.FC<IPaymentDataOptionsMenuProps> = ({optionSelected, setOptionSelected}) => {
    return (
        <Box
            sx={{
                backgroundColor: 'white',
                width: '100%',
                height: '70px',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-end',
                borderRadius: '5px',
                padding: '0px 50px',
                boxShadow: '0px 0px 5px 0px #c0c7d0',
                overflow: 'auto'
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    cursor: 'pointer',
                    marginRight: '30px'
                }}
                onClick={() => setOptionSelected('plan')}
            >
                <Typography
                    sx={{
                        color: optionSelected === 'plan' ? '#005fdb' : '#88929d',
                    }}
                >Plano</Typography>
                <Box
                    sx={{
                        width: '50%',
                        height: '3px',
                        backgroundColor: optionSelected === 'plan' ? '#005fdb' : 'white',
                        marginTop: '5px',
                        borderRadius: '20px'
                    }}
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    cursor: 'pointer',
                    marginRight: '30px'
                }}
                onClick={() => setOptionSelected('credit')}
            >
                <Typography
                    sx={{
                        color: optionSelected === 'credit' ? '#005fdb' : '#88929d',
                    }}
                >Cartão de crédito</Typography>
                <Box
                    sx={{
                        width: '50%',
                        height: '3px',
                        backgroundColor: optionSelected === 'credit' ? '#005fdb' : 'white',
                        marginTop: '5px',
                        borderRadius: '20px'
                    }}
                />
            </Box>
        </Box>
    )
}

export default PaymentDataOptionsMenu;