import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import Header from '../../components/header/index.tsx';
import Sidebar from '../../components/sidebar/index.tsx';
import AddEventModal from '../../components/modals/AddEventModal/index.tsx';
import MonthCalendar from '../../components/calendar/monthCalendar/index.tsx';
import DayEvents from '../../components/calendar/dayEvents/index.tsx';
import dayjs from 'dayjs';
import ImportantEvents from '../../components/calendar/importantEvents/index.tsx';
import PageContentContainer from '../../components/PageContentContainer/index.tsx';
import MobileHeader from '../../components/MobileHeader/index.tsx';

const AgendaPage: React.FC = () => {
    const [openAddEventModal, setOpenAddEventModal] = useState(false);
    const [chosenDate, setChosenDate] = useState(dayjs());
    return (
        <>
            <Header />
            <MobileHeader />
            <Sidebar />
            <AddEventModal
                open={openAddEventModal}
                setOpen={setOpenAddEventModal}
                onClose={() => {}}
            />
            <PageContentContainer>
                <Box
                    sx={{
                        display: 'flex',
                        position: 'relative',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        width: '100%',
                        backgroundColor: 'white',
                        height: '80px',
                        padding: '0px 50px 0px 0px',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)'
                    }}
                >
                    <Button
                        onClick={() => setOpenAddEventModal(true)}
                        sx={{
                            textTransform: 'none',
                            color: 'white',
                            backgroundColor: '#006af5',
                            '&:hover': {
                                backgroundColor: 'rgba(0, 106, 245, 0.7)',
                            },
                            height: '30px',
                            width: '140px',
                        }}
                    >
                        Adicionar Evento
                    </Button>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        height: 'calc(100vh - 180px)',
                        display: 'flex',
                        justifyContent: 'space-between',
                        backgroundColor: '#f5f5f5',
                        padding: '25px 40px',
                        overflow: 'auto',
                        flexWrap: 'wrap',
                        '@media (max-width: 488px)': {
                            padding: '10px'
                        },
                        '@media (max-width: 1172px)': {
                            justifyContent: 'center'
                        }
                    }}
                >
                    <Box
                        sx={{
                            width: '30%',
                            padding: '10px',
                            minWidth: '350px',
                        }}
                    >
                        <MonthCalendar
                            chosenDate={chosenDate}
                            setChosenDate={setChosenDate}
                        />
                        <ImportantEvents chosenDate={chosenDate}/>
                    </Box>
                    <Box sx={{width: '68%', minWidth: '350px'}}>
                        <DayEvents
                            chosenDate={chosenDate}
                            setChosenDate={setChosenDate}
                        />
                    </Box>
                </Box>
            </PageContentContainer>
        </>
    );
};


export default AgendaPage;