import React from 'react';
import { Box, Grid, TextField, Typography, Select, MenuItem, FormControl, FormHelperText } from '@mui/material';

const MockedTimeZones = [
    'America/Sao_Paulo',
    'America/Argentina/Buenos_Aires',
    'America/Argentina/Cordoba',
    'America/Argentina/Salta',
    'America/Argentina/Jujuy',
    'America/Argentina/Tucuman'
];

const ClinicHours: React.FC<{ 
    clinicData: any, 
    setClinicData: any, 
    initHoursError: boolean, 
    finalHoursError: boolean, 
    gmtError: boolean 
}> = ({ clinicData, setClinicData, initHoursError, finalHoursError, gmtError }) => {
    return (
        <Box sx={{ marginTop: '25px' }}>
            <Typography
                sx={{
                    fontSize: '25px',
                    fontWeight: 'bold',
                    fontFamily: 'Lato'
                }}
            >
                Horário de funcionamento
            </Typography>
            <Grid container spacing={2} sx={{ marginTop: '25px' }}>
                <Grid item xs={12} md={4}>
                    <Typography sx={{ color: '#7c7c8d' }}>
                        Horário inicial da clínica <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        type="time"
                        value={clinicData?.opening_hour?.init_hours}
                        onChange={(e) => {
                            setClinicData({
                                ...clinicData,
                                opening_hour: {
                                    ...clinicData.opening_hour,
                                    init_hours: e.target.value
                                }
                            });
                        }}
                        error={initHoursError}
                        helperText={initHoursError ? "Este campo é obrigatório." : ""}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#e9eaec',
                                    borderRadius: '10px'
                                },
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography sx={{ color: '#7c7c8d' }}>
                        Horário final da clínica <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        type="time"
                        value={clinicData?.opening_hour?.final_hours}
                        onChange={(e) => {
                            setClinicData({
                                ...clinicData,
                                opening_hour: {
                                    ...clinicData.opening_hour,
                                    final_hours: e.target.value
                                }
                            });
                        }}
                        error={finalHoursError}
                        helperText={finalHoursError ? "Este campo é obrigatório." : ""}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#e9eaec',
                                    borderRadius: '10px'
                                },
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography sx={{ color: '#7c7c8d' }}>
                        Fuso horário <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <FormControl fullWidth error={gmtError}>
                        <Select
                            size="small"
                            value={clinicData?.opening_hour?.gmt}
                            onChange={(e) => setClinicData({
                                ...clinicData,
                                opening_hour: {
                                    ...clinicData.opening_hour,
                                    gmt: e.target.value
                                }
                            })}
                            sx={{
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#e9eaec',
                                    borderWidth: '1px',
                                    borderRadius: '10px'
                                }
                            }}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: 200
                                    }
                                }
                            }}
                        >
                            {MockedTimeZones.map((timezone) => (
                                <MenuItem key={timezone} value={timezone}>
                                    {timezone}
                                </MenuItem>
                            ))}
                        </Select>
                        {gmtError && <FormHelperText>Este campo é obrigatório.</FormHelperText>}
                    </FormControl>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ClinicHours;