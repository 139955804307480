import { Box, Typography } from '@mui/material';
import React from 'react';
import BaseForIntelligenceSquare from '../../../../components/baseForIntelligenceSquare/index.tsx';
import dayjs, { Dayjs } from 'dayjs';
import { PieChart } from '@mui/x-charts/PieChart';
import { IBlockableIntelligenceSquareProps } from '../../../../types/intelligence.tsx';

const ENTRY_METHODS_DATA = [
    { id: 0, value: 10, label: 'Facebook', color: "#001b48"},
    { id: 2, value: 20, label: 'Google', color: "#02457a"},
    { id: 3, value: 5, label: 'Indicação de amigo', color: '#018abe'},
    { id: 4, value: 10, label: 'Indicação de outro dentista', color: "#97cadb"},
    { id: 5, value: 10, label: 'Jornal', color: "#b3f0e8"},
    { id: 1, value: 15, label: 'Instagram', color: "#d6e7ee"},
    { id: 6, value: 10, label: 'Outdoor', color: "#8ee5dc" },
    { id: 9, value: 10, label: 'TV', color: "#2fc4b1"},
    { id: 10, value: 10, label: 'Outro', color: "#359d9e"},
]

const SubtitleItem: React.FC<{color: string, label: string}> = ({color, label}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <Box
                sx={{
                    width: '30px',
                    height: '18px',
                    backgroundColor: color,
                    borderRadius: '20%',
                    marginRight: '10px',
                }}
            />
            <Typography
                sx={{
                    fontSize: '16px',
                    fontFamily: 'Lato',
                }}
            >
                {label}
            </Typography>
        </Box>
    )
}

const PatientEntryMethodSquare: React.FC<IBlockableIntelligenceSquareProps> = ({
    enabled
}) => {
    const [selectedDate, setSelectedDate] = React.useState<Dayjs | null>(dayjs());
    return (
        <BaseForIntelligenceSquare
            title = "Como o paciente chegou na clínica"
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
        >
            <Box
                sx={{
                    height: 'auto',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                    marginY: '40px',
                    "@media (max-width: 772px)": {
                        flexDirection: 'column',
                        gap: '20px',
                    }
                }}
            >

                { !enabled && (
                    <Box
                        sx={{
                            height: '100%',
                            width: '100%',
                            position: 'absolute',
                            zIndex: 2,
                            backgroundColor: 'rgba(255, 255, 255, 0.73)',
                            pointerEvents: 'none'
                        }}
                    />
                )}
                <Box
                    sx={{
                        width: '200px'
                    }}
                >
                    <PieChart
                        series={[
                            {
                                data: ENTRY_METHODS_DATA,
                                highlightScope: { fade: 'global', highlight: 'item' },
                                faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                            },
                        ]}
                        slotProps={{
                            legend: {
                                hidden: true
                            }
                        }}
                        width={300}
                        height={200}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        "@media (max-width: 561px)": {
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        }
                    }}
                >
                    <Box
                        sx={{
                            marginX: '60px',
                            "@media (max-width: 561px)": {
                                marginX: '0px',
                            }
                        }}
                    >
                        {ENTRY_METHODS_DATA.slice(0,5).map((entryMethod, index) => (
                            <SubtitleItem
                                key={index}
                                color={entryMethod.color}
                                label={entryMethod.label}
                            />
                        ))}
                    </Box>
                    <Box>
                        {ENTRY_METHODS_DATA.slice(5).map((entryMethod, index) => (
                            <SubtitleItem
                                key={index}
                                color={entryMethod.color}
                                label={entryMethod.label}
                            />
                        ))}
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography
                    sx={{
                        fontSize: '20px',
                        fontFamily: 'Lato',
                        fontWeight: 'bold',
                        textAlign: 'center',
                    }}
                >
                    Você sabe como seus pacientes chegaram até você?
                </Typography>
                <Typography
                    sx={{
                        fontSize: '20px',
                        fontFamily: 'Lato',
                        fontWeight: 'bold',
                        textAlign: 'center',
                    }}
                >
                    Preencha essa informação ao cadastrar o paciente e descubra!
                </Typography>
            </Box>
        </BaseForIntelligenceSquare>
    )
}

export default PatientEntryMethodSquare;