import { Box, Typography } from '@mui/material';
import React from 'react';

const SettingsMenuOption: React.FC<{
    title: string;
    selected: boolean;
    onClick: () => void;
}> = ({title, selected, onClick}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                cursor: 'pointer',
                marginRight: '30px'
            }}
            onClick={onClick}
        >
            <Typography
                sx={{
                    color: selected ? '#005fdb' : '#88929d',
                }}
            >{title}</Typography>
            <Box
                sx={{
                    width: '50%',
                    height: '3px',
                    backgroundColor: selected ? '#005fdb' : 'white',
                    marginTop: '5px',
                    borderRadius: '20px'
                }}
            />
        </Box>
    )
};

export default SettingsMenuOption