import React, { useState, useContext, useEffect } from 'react';
import dentistaPNG from '../../assets/images/dentista_png_login.png';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { configBackendConnection, endpoints } from "../../components/utilsBackend.ts";
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthProvide.js';
import { saveToStorage } from '../../utils/storageUtils.tsx';

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const navigate = useNavigate();
    const { setAuthenticated } = useContext(AuthContext);

    useEffect(() => {
        const handleResize = () => {
            const loginCard = document.getElementById('login-card');
            const imageSection = document.getElementById('image-section');
            if (window.innerWidth <= 400) {
                loginCard.style.width = '100%';
                imageSection.style.width = '0';
                imageSection.style.display = 'none';
            } else if (window.innerWidth <= 576) {
                loginCard.style.width = '80%';
                imageSection.style.width = '20%';
                imageSection.style.display = 'flex';
            } else if (window.innerWidth <= 768) {
                loginCard.style.width = '70%';
                imageSection.style.width = '30%';
            } else if (window.innerWidth <= 992) {
                loginCard.style.width = '60%';
                imageSection.style.width = '40%';
            } else if (window.innerWidth <= 1200) {
                loginCard.style.width = '50%';
                imageSection.style.width = '50%';
            } else {
                loginCard.style.width = '40%';
                imageSection.style.width = '60%';
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };

    const handleChangeEmail = (e) => {
        setEmail(e.target.value);
        setFormSubmitted(false);
    };

    const handleChangePassword = (e) => {
        setPassword(e.target.value);
        setFormSubmitted(false);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setFormSubmitted(true);
        setIsLoading(true);

        try {
            const response = await axios.post(
                `${configBackendConnection.baseURL}/${endpoints.loginToken}`,
                {
                    username: email,
                    password: password
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        ...configBackendConnection.headersLogin
                    }
                }
            );
            
            if (response.status === 200) {
                const { token, name, restricted_access } = response.data;
                saveToStorage({ token, name, restricted_access });
                setAuthenticated(true);
                toast.success('Login bem sucedido!');
                navigate('/agenda');
            } else {
                toast.error("Credenciais inválidas");
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                toast.error("Credenciais inválidas");
            } else {
                toast.error('Ocorreu um erro ao processar sua solicitação. Por favor, tente novamente mais tarde.');
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <main id="login-main" className="position-fixed w-100 h-100 d-flex justify-content-center align-items-center">
            <section id="login-card" className="card rounded-4 login-container">
                <div className="card-body rounded-0 d-flex flex-column justify-content-center align-items-center">
                    <h1 className="title_login ml-0">Entre em sua conta</h1>
                    <p className="text_login ml-0">Por favor, preencha as informações abaixo</p>
                    <form className="w-100" onSubmit={handleSubmit}>
                        <div className="form-group">
                            <input 
                                type="email" 
                                className="form-control" 
                                id="email" 
                                value={email}
                                onChange={handleChangeEmail}
                                placeholder="E-mail" 
                                required
                            />
                            {formSubmitted && email === '' && <div className="text-danger">Email é obrigatório</div>}
                        </div>

                        <div className="form-group position-relative">
                            <input 
                                type={isPasswordVisible ? "text" : "password"} 
                                className="form-control" 
                                id="senha" 
                                value={password}
                                onChange={handleChangePassword}
                                placeholder="Senha" 
                                required
                            />
                            <i 
                                className={`fa eye-icon ${isPasswordVisible ? 'fa-eye' : 'fa-eye-slash'}`} 
                                onClick={togglePasswordVisibility}
                            ></i>
                            {formSubmitted && password === '' && <div className="text-danger">Senha é obrigatória</div>}
                        </div>

                        <a 
                            href="/reset-password" 
                            className="ml-0" 
                            style={{ display: 'block', textAlign: 'right', width: '100%', marginBottom: '15px', marginTop: '-10px' }}
                        >
                            Esqueci minha senha
                        </a>

                        <button type="submit" className="btn btn-primary" disabled={isLoading}>
                            {isLoading ? (
                                <>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    <span className="sr-only">  Entrando...</span>
                                </>
                            ) : (
                                'Entrar no sistema'
                            )}
                        </button>

                        <hr className="horizontal-line" />
                        <p className="registration-text">Novo usuário? <a href="/register">Cadastre-se agora</a></p>
                        <hr className="dotted-line" />
                        
                        <a href="#" className="ml-0">Política de cookies</a>
                    </form>
                </div>
            </section>
            <section id="image-section" className="image-container">
                <img src={dentistaPNG} alt="IMG Dentista login" />
            </section>
        </main>
    );
};

export default LoginPage;
