import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { IPaymentData } from '../../../../types/payment.tsx';
import { toast } from 'react-toastify';
import QRCode from "react-qr-code";
import dayjs from 'dayjs';

interface IModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

interface IPaymentDataProps extends IModalProps {
    paymentData: IPaymentData;
}

const PixPaymentConfirmationModal: React.FC<IPaymentDataProps> = ({
    open,
    setOpen,
    paymentData
}) => {
    const statusMessage = {
        'pending': 'Aguardando Pagamento',
        'confirmed': 'Pagamento Confirmado',
        'refused': 'Pagamento Recusado',
    }
    const paymentMethodMessage = {
        'creditCard': 'Cartão de Crédito',
        'ticket': 'Boleto',
        'pix': 'Pix',
    }
    const [expiryDate, setExpiryDate] = useState(dayjs().add(30, 'minute').format('DD/MM/YYYY'));
    const [expiryTime, setExpiryTime] = useState(dayjs().add(30, 'minute').format('HH:mm'));
    

    const handleCopyClick = () => {
        navigator.clipboard.writeText(paymentData.code)
            .then(() => {
                toast.success('Código copiado para a área de transferência!');
            })
            .catch(err => {
                toast.error('Erro ao copiar o código: ', err);
            });
    };

    return (
        <Modal
            open={open}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box
                sx={{
                    width: '900px',
                    height: '900px',
                    backgroundColor: 'white',
                    padding: '35px',
                }}
            >
                <Typography
                    fontFamily={'Lato'}
                    fontSize={'24px'}
                    fontWeight={'bold'}
                    marginBottom={'20px'}
                >Compra realizada com sucesso</Typography>
                <Typography
                    fontFamily={'Lato'}
                    fontSize={'20px'}
                    marginBottom={'20px'}
                >Dados da compra</Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        fontFamily={'Lato'}
                        fontSize={'16px'}
                        color={'#616d7c'}
                    >A confirmação foi enviada para o e-mail</Typography>
                    <Typography
                        fontFamily={'Lato'}
                        fontSize={'16px'}
                        fontWeight={'bold'}
                        color={'black'}
                    >{paymentData.email}</Typography>
                    <Box
                        sx={{
                            width: '75%',
                            maxWidth: '650px',
                            height: '80px',
                            borderRadius: '10px',
                            backgroundColor: '#275b85',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginY: '20px'
                        }}
                    >
                        <Typography
                            width={'85%'}
                            textAlign={'center'}
                            fontFamily={'Lato'}
                            fontSize={'16px'}
                            color={'white'}
                        >Olá! Agradecemos pela compra, Você poderá visualizar seu código pix logo abaixo.</Typography>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            border: '1px solid grey',
                            padding: '10px'
                        }}
                    >
                        <Box
                            sx={{
                                backgroundColor: '#efefef',
                                display: 'flex',
                                flexDirection: 'column',
                                padding: '10px'
                            }}
                        >
                            <Typography
                                fontFamily={'Lato'}
                                fontSize={'16px'}
                                color={'black'}
                            >Status: <span
                                style={{
                                    color: paymentData.status === 'pending' ? 'green' :
                                    paymentData.status === 'confirmed' ? '#275b85' :  'red'
                                }}
                            >{statusMessage[paymentData.status]}</span></Typography>
                            <Typography
                                fontFamily={'Lato'}
                                fontSize={'16px'}
                                color={'black'}
                            >Data do pedido: {paymentData.date}</Typography>
                            <Typography
                                fontFamily={'Lato'}
                                fontSize={'16px'}
                                color={'black'}
                            >Forma Pagamento: {paymentMethodMessage[paymentData.paymentMethod]}</Typography>
                            <Typography
                                fontFamily={'Lato'}
                                fontSize={'16px'}
                                color={'black'}
                            >Valor Desconto: {paymentData.discount}</Typography>
                            <Typography
                                fontFamily={'Lato'}
                                fontSize={'16px'}
                                color={'black'}
                            >Valor total: {paymentData.total}</Typography>
                            <Box
                                sx={{
                                    width: '100%',
                                    height: '60px',
                                    backgroundColor: '#275b85',
                                    marginY: '5px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    padding: '10px',
                                }}
                            >
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    autoComplete="off"
                                    value={paymentData.code}
                                    sx={{
                                        backgroundColor: 'white',
                                        
                                    }}
                                    size='small'
                                />
                                <Box
                                    sx={{
                                        width: '35%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        cursor: 'pointer'
                                    }}
                                    onClick={handleCopyClick}
                                >
                                    <Typography
                                        fontFamily={'Lato'}
                                        fontWeight={'bold'}
                                        fontSize={'16px'}
                                        color={'white'}
                                    >CLIQUE PARA COPIAR</Typography>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Box
                                    sx={{
                                        padding: '15px',
                                        backgroundColor: 'white',
                                        marginY: '20px',
                                    }}
                                >
                                    <QRCode
                                        size={256}
                                        style={{ height: "auto", maxWidth: "200px", width: "200px" }}
                                        value={paymentData.code}
                                        viewBox={`0 0 256 256`}
                                    />
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Typography
                                    fontFamily={'Lato'}
                                    fontSize={'16px'}
                                    color={'black'}
                                >Válido até: {expiryDate} às {expiryTime} (Horário de Brasília)</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}
                >
                    <Button
                        sx={{
                            textTransform: 'none',
                            color: 'red'
                        }}
                        onClick={() => {
                            setOpen(false)
                        }}
                    >Fechar</Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default PixPaymentConfirmationModal;