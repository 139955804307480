import { Box } from '@mui/material';
import React from 'react';
import PatientsPerformanceReports from '../../Performance/BasePerformanceSquare/PatientsPerformanceReports/index.tsx';
import CanceledConsultationsSquare from '../CanceledConsultationsSquare/index.tsx';

const BaseTaskSquare: React.FC = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                width: '96%',
                maxHeight: '650px',
                marginY: '20px',
            }}
        >
            <CanceledConsultationsSquare />
            <Box
                sx={{
                    width: '37%',
                    minWidth: '242px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    '@media (max-width: 765px)': {
                        width: '100%',
                        marginY: '20px',
                    }
                }}
            >
                <PatientsPerformanceReports />
            </Box>
        </Box>
    )
}

export default BaseTaskSquare;