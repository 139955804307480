import React, { useEffect, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthProvide.js';
import { clearStorage } from '../../utils/storageUtils.tsx';

const LogoutPage = () => {
  const { handleLogout } = useContext(AuthContext);

  useEffect(() => {
    handleLogout();
    clearStorage();
  }, [handleLogout]);

  return <Navigate to="/login" />;
};

export default LogoutPage;
