import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { BsGear } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const settingsAlert = require('../../../assets/icons/settings_alert.png');

type PersonModalProps = {
    visible: boolean
}

const PersonModal: React.FC<PersonModalProps> = ( { visible} ) => {
    const name = localStorage.getItem('name') || '';

    const navigate = useNavigate();
    
    return (
        <Box 
            sx={{
                display: visible ? 'flex' : 'none',
                alignItems: 'center',
                justifyContent: 'space-between',
                position: 'absolute',
                top: '6.25rem',
                right: '3.75rem',
                zIndex: '10000',
            }}
        >
            <Box>
                <Box
                    sx={{
                        padding: '1.25rem',
                        backgroundColor: '#008B7D',
                        display: 'flex',
                        gap: '.75rem',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: '#004039',
                            borderRadius: '50%',
                            width: '40px',
                            height: '40px',
                            color: '#FFF',
                        }}
                    >
                        FT
                    </Box>
                    <Box>
                        <Typography 
                            sx={{
                                fontSize: 16,
                                fontWeight: 'bold',
                                color: "white"
                            }}
                        >
                            Nome Teste
                        </Typography>
                        <Typography 
                            sx={{
                                fontSize: 10,
                                fontWeight: 'bold',
                                color: "white"
                            }}
                        >
                            Dentista Administradora
                        </Typography>
                    </Box>
                    <Box
                        component="img"
                        src={settingsAlert}
                        sx={{
                            width: 32,
                            height: 32,
                            cursor: 'pointer'
                        }}
                        onClick={() => {navigate('/payment-details')}}
                    />
                </Box>
                <Box 
                    sx={{
                        display: 'flex',
                        backgroundColor: '#FEFEFE',
                        justifyContent: 'space-between',
                        padding: '.5rem',
                        borderRadius: '0 0 10px 10px',
                        border: '1px solid #cfcfcf',
                        borderTop: '0',
                    }}
                >
                    <Button 
                        onClick={() => navigate('/account')}
                        sx={{
                            color: '#777777',
                            fontWeight: 'bold',
                            border: '1px solid #FEFEFE',
                            '&:hover': {
                                backgroundColor: '#efefef',
                                borderColor: '#CFCFCF'
                            }
                        }}
                    >
                        Minha Conta
                    </Button>
                    <Button 
                        onClick={() => navigate('/logout')}
                        color='error'
                        sx={{
                            fontWeight: 'bolder'
                        }}
                    >
                        Sair
                    </Button>
                </Box>
            </Box>
        </Box>   
    )
}

export default PersonModal;