import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from "@mui/material";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { useNavigate } from 'react-router-dom';
import { ICampaignTypes } from '../../../types/comunication';
import MobileScreenShareIcon from '@mui/icons-material/MobileScreenShare';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';

const cellPhoneImage = require('../../../assets/images/non_licenced_cellphone.png')

const DENTAL_FLOSS_MESSAGE = `Após a ativação da campanha, a DOT irá
disparar as mensagens dentro de uma hora para seus pacientes`

const ResumeAndActivateStep: React.FC<{
    selectedCampaignSender: string;
    selectedCampaignType: string,
    goToPreviousStep: () => void;
    cellPhoneMessage: ICampaignTypes;
    campaignTypeNames: ICampaignTypes;
    cancel: () => void;
    resumeMessage: ICampaignTypes;
    targets: string;
    campaignInit: string;
    activateCampaign: () => void;
}> = ({
    selectedCampaignSender,
    selectedCampaignType,
    goToPreviousStep,
    cellPhoneMessage,
    campaignTypeNames,
    cancel,
    resumeMessage,
    targets,
    campaignInit,
    activateCampaign
}) => {
    const navigate = useNavigate()

    return (
        <>
            <Box
                sx={{
                    marginTop: '40px',
                    width: '95%',
                    backgroundColor: 'white',
                    borderRadius: '15px',
                    padding: '40px 0px 20px 50px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    '@media (max-width: 742px)': {
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: '20px'
                    }
                }}
            >
                <Box 
                    sx={{
                        width: '70%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                    }}
                >
                    <Typography
                        sx={{
                            color: '#03659a',
                            fontSize: 28,
                            fontFamily: 'Lato',
                            maxWidth: '80%',
                            fontWeight: 'bold',
                            transition: 'color 0.3s ease',
                        }}
                    >
                        Resumo e ativação da campanha de {
                            campaignTypeNames[selectedCampaignType]
                        }
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '16px',
                            color: '#77828f',
                            marginTop: '20px',
                        }}
                    >
                        {`Estamos quase lá! Confira se as informações estão de
                        acordo antes de ativar a campanha. Caso precise fazer
                        alguma alteração, você pode retornar aos passos
                        anteriores. ;)`}
                    </Typography>
                    <Box
                        sx={{
                            marginTop: '40px',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexWrap: 'wrap',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                width: '49%',
                                minWidth: '250px'
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                }}
                            >
                                <Box
                                    sx={{
                                        backgroundColor: '#f5f5f5',
                                        borderRadius: '50%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '45px',
                                        height: '45px'
                                    }}
                                >
                                    <MobileScreenShareIcon
                                        sx={{
                                            fontSize: '25px',
                                            color: '#03659a'
                                        }}
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        marginLeft: '10px'
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: '#5b5b5b',
                                            fontFamily: 'lato'
                                        }}
                                    >
                                        Plataforma
                                    </Typography>
                                    <Typography
                                        sx={{
                                            color: '#a5a5a5',
                                            fontFamily: 'lato'
                                        }}
                                    >
                                        {selectedCampaignSender === 'sms' ? 'SMS' : 'WhatsApp'}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                }}
                            >
                                <Box
                                    sx={{
                                        backgroundColor: '#f5f5f5',
                                        borderRadius: '50%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '45px',
                                        height: '45px'
                                    }}
                                >
                                    <PeopleOutlineOutlinedIcon
                                        sx={{
                                            fontSize: '25px',
                                            color: '#03659a'
                                        }}
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        marginLeft: '10px'
                                    }}
                                >
                                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                                        <Typography
                                            sx={{
                                                color: '#5b5b5b',
                                                fontFamily: 'lato'
                                            }}
                                        >
                                            Público
                                        </Typography>
                                        <ErrorOutlineRoundedIcon
                                            sx={{
                                                fontSize: '20px',
                                                marginLeft: '5px',
                                                color: '#02457a'
                                            }}
                                        />
                                    </Box>
                                    <Typography
                                        sx={{
                                            color: '#a5a5a5',
                                            fontFamily: 'lato'
                                        }}
                                    >
                                        {targets}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                width: '49%',
                                minWidth: '250px'
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                }}
                            >
                                <Box
                                    sx={{
                                        backgroundColor: '#f5f5f5',
                                        borderRadius: '50%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '45px',
                                        height: '45px'
                                    }}
                                >
                                    <MessageOutlinedIcon
                                        sx={{
                                            fontSize: '25px',
                                            color: '#03659a'
                                        }}
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        marginLeft: '10px'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                color: '#5b5b5b',
                                                fontFamily: 'lato'
                                            }}
                                        >
                                            Campanha
                                        </Typography>
                                        <Box
                                            sx={{
                                                backgroundColor: '#f5f5f5',
                                                heigth: '50px',
                                                width: '60px',
                                                borderRadius: '15px',
                                                marginLeft: '5px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: '10px',
                                                    color: '#717c89'
                                                }}
                                            >
                                                Marketing
                                            </Typography>
                                        </Box>
                                        <ErrorOutlineRoundedIcon
                                            sx={{
                                                fontSize: '20px',
                                                marginLeft: '5px',
                                                color: '#02457a'
                                            }}
                                        />
                                    </Box>
                                    <Typography
                                        sx={{
                                            color: '#a5a5a5',
                                            fontFamily: 'lato'
                                        }}
                                    >
                                        {campaignTypeNames[selectedCampaignType]}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                }}
                            >
                                <Box
                                    sx={{
                                        backgroundColor: '#f5f5f5',
                                        borderRadius: '50%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '45px',
                                        height: '45px'
                                    }}
                                >
                                    <CalendarTodayOutlinedIcon
                                        sx={{
                                            fontSize: '25px',
                                            color: '#03659a'
                                        }}
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        marginLeft: '10px',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: '#5b5b5b',
                                            fontFamily: 'lato'
                                        }}
                                    >
                                        Envio da campanha
                                    </Typography>
                                    <Typography
                                        sx={{
                                            color: '#a5a5a5',
                                            fontFamily: 'lato'
                                        }}
                                    >
                                        {campaignInit}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '20px 10px',
                            width: '100%',
                            backgroundColor: '#e5f6fd',
                            marginTop: '50px'
                        }}
                    >
                        <ErrorOutlineRoundedIcon
                            sx={{
                                fontSize: '45px',
                                marginLeft: '5px',
                                color: '#02457a'
                            }}
                        />
                        <Typography
                            sx={{
                                marginLeft: '10px',
                                color: '#5b5b5b'
                            }}
                        >{resumeMessage[selectedCampaignType]}</Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography>Como seu paciente irá receber 👇</Typography>
                    <Box sx={{ position: 'relative', display: 'inline-block' }}>
                        <img
                            style={{
                                width: '500px',
                            }}
                            src={cellPhoneImage}
                            alt={'celular'}
                        />
                        <Box
                            sx={{
                                position: 'absolute',
                                width: '240px',
                                top: '30%',
                                left: '50%',
                                transform: 'translateX(-50%)',
                                backgroundColor: 'white',
                                padding: '8px 16px',
                                borderRadius: '8px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <Box sx={{display: 'flex'}}>
                                    <EmailOutlinedIcon
                                        sx={{
                                            fontSize: '15px'
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            fontWeight: 'bold',
                                            fontSize: '12px',
                                            marginLeft: '2px'
                                        }}
                                    >Mensagem</Typography>
                                </Box>
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: '12px'
                                    }}
                                >agora</Typography>
                            </Box>
                            <Typography
                                sx={{
                                    fontSize: '12px',
                                    marginTop: '5px'
                                }}
                            >
                                {cellPhoneMessage[selectedCampaignType]}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    width: '95%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '40px'
                }}
            >
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <Button
                        sx={{
                            color: '#262626',
                            width: '120px',
                            height: '40px',
                            borderRadius: '5px',
                            marginTop: '50px',
                            textTransform: 'none',
                        }}
                        onClick={goToPreviousStep}
                    >
                        Voltar
                    </Button>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <Button
                        sx={{
                            color: '#262626',
                            width: '120px',
                            height: '40px',
                            borderRadius: '5px',
                            marginTop: '50px',
                            textTransform: 'none',
                        }}
                        onClick={cancel}
                    >
                        Cancelar
                    </Button>
                    <Button
                        sx={{
                            backgroundColor: '#006af5',
                            '&:hover': {
                                backgroundColor: 'rgba(0, 106, 245, 0.7)',
                            },
                            color: 'white',
                            width: '150px',
                            height: '40px',
                            borderRadius: '5px',
                            marginTop: '50px',
                            textTransform: 'none',
                        }}
                        onClick={() => {
                        
                            activateCampaign()
                            navigate('/comunication')
                        }}
                    >
                        Ativar Campanha
                    </Button>
                </Box>
            </Box>
        </>
    )
}

export default ResumeAndActivateStep;