import React, { useEffect, useState } from 'react'
import { Box, Button, Checkbox, Typography } from "@mui/material"
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';

const assignmentReturnIcon = require('../../../assets/icons/assignment_return.png')
const dentistryIcon = require('../../../assets/icons/dentistry.png')
const barCodeIcon = require('../../../assets/icons/barcode_scanner.png')
const electroCardioIcon = require('../../../assets/icons/ecg.png')
const localAtmIcon = require('../../../assets/icons/local_atm.png')
const happyFaceIcon = require('../../../assets/icons/sentiment_satisfied.png')

const NewCampaignOption: React.FC<{
    icon: React.ReactNode;
    title: string;
    description: string;
    onClick: () => void;
    checked: boolean;
}> = ({icon, title, description, onClick, checked}) => {
    const [backgroundColor, setBackgroundColor] = useState('')
    const [color, setColor] = useState('')

    const handleHover = () => {
        setBackgroundColor('#0e6f6b')
        setColor('white')
    }

    const handleHoverExit = () => {
        setBackgroundColor('')
        setColor('')
    }

    return (
        <Box
            sx={{
                width: '48%',
                minWidth: '330px',
                backgroundColor: backgroundColor,
                height: '280px',
                padding: '10px',
                border: '1px solid #eeeeee',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                cursor: 'pointer',
                transition: 'background-color 0.3s ease',
                '@media (max-width: 1118px)': {
                    height: '300px'
                },
                '@media (max-width: 896px)': {
                    minHeight: '340px'
                }
            }}
            onMouseEnter={handleHover}
            onMouseLeave={handleHoverExit}
            onClick={onClick}
        >
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <Box sx={{ width: '50px' }} />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        height: '100%',
                        width: '50px',
                    }}
                >
                    <Checkbox
                        checked={checked}
                        sx={{color: color !== '#03659a' ? color : 'white'}}
                        icon={<RadioButtonUncheckedIcon />}
                        checkedIcon={
                            <RadioButtonCheckedIcon
                                sx={{ color: color !== '' ? color : '#03659a'}}
                            />
                        }
                    />
                </Box>
            </Box>
            <Box
                sx={{
                    backgroundColor: '#f5f5f5',
                    width: '60px',
                    height: '60px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '50%',
                }}
            >
                {icon}
            </Box>
            <Typography
                sx={{
                    color: color !== '' ? color : '#03659a',
                    fontSize: 28,
                    fontFamily: 'Lato',
                    maxWidth: '80%',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    transition: 'color 0.3s ease',
                }}
            >
                {title}
            </Typography>
            <Typography
                sx={{
                    color: color !== '' ? color : '#77828f',
                    maxWidth: '90%',
                    textAlign: 'center',
                    fontSize: 20,
                    marginTop: '15px',
                    transition: 'color 0.3s ease',
                }}
            >
                {description}
            </Typography>
        </Box>
    )
}

const WhatsAppCampaignType: React.FC<{
    checked: boolean;
    onClick: () => void;
    iconUrl: string;
    title: string;
    description: string;
}> = ({checked, onClick, iconUrl, title, description}) => {
    
    const [backgroundColor, setBackgroundColor] = useState('white')
    const [hover, setHover] = useState(false)

    const handleHover = () => {
        setHover(true)
        setBackgroundColor('#0e6f6b')
    }

    const handleHoverExit = () => {
        setHover(false)
        setBackgroundColor('')
    }

    return (
        <Box
            sx={{
                backgroundColor: backgroundColor,
                width: '30%',
                minWidth: '330px',
                height: '190px',
                padding: '10px',
                border: '1px solid #eeeeee',
                display: 'flex',
                flexDirection: 'column',
                alignItems: hover ? 'flex-start' : 'center',
                cursor: 'pointer',
                transition: 'background-color 0.3s ease',
                marginBottom: '40px'
            }}
            onClick={onClick}
            onMouseEnter={handleHover}
            onMouseLeave={handleHoverExit}
        >
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >

                {   (
                    hover &&
                        <Typography
                            sx={{
                                color: 'white',
                                fontSize: 28,
                                fontFamily: 'Lato',
                                maxWidth: '80%',
                                textAlign: 'center',
                                fontWeight: 'bold',
                                transition: 'color 0.3s ease',
                            }}
                        >
                            {title}
                        </Typography>
                    ) || 
                        <Box sx={{ width: '50px' }} />
                }
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: hover ? 'space-between' : 'flex-start',
                        height: '100%',
                        width: '50px',
                    }}
                >
                    <Checkbox
                        checked={checked}
                        sx={{color: hover ? 'white' : '#03659a'}}
                        icon={<RadioButtonUncheckedIcon />}
                        checkedIcon={
                            <RadioButtonCheckedIcon
                                sx={{ color: hover ? 'white' : '#03659a'}}
                            />
                        }
                    />
                </Box>
            </Box>
            <img
                style={{display: hover ? 'none' : ''}}
                src={iconUrl}
                alt={'icon'}
            />
            <Typography
                sx={{
                    display: hover ? 'none' : '',
                    color: '#03659a',
                    fontSize: 28,
                    fontFamily: 'Lato',
                    maxWidth: '80%',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    transition: 'color 0.3s ease',
                }}
            >
                {title}
            </Typography>
            {   hover &&
                <Typography
                    sx={{
                        color: 'white',
                        fontFamily: 'inter',
                        fontSize: '13px',
                        marginTop: '15px'
                    }}
                >
                    {description}
                </Typography>
            }
        </Box>
    )
}

const NewCampaignScreen: React.FC<{
    goToNextStep: () => void;
    selectedCampaignSender: string;
    selectedCampaignType: string;
    setSelectedCampaignSender: (value: string) => void;
    setSelectedCampaignType: (value: string) => void;
}> = ({
    goToNextStep,
    selectedCampaignSender,
    selectedCampaignType,
    setSelectedCampaignSender,
    setSelectedCampaignType
}) => {
    const [canContinue, setCanContinue] = useState<boolean>(false)


    useEffect(() => {
        if(selectedCampaignSender !== '' && selectedCampaignType !== ''){
            setCanContinue(true)
        } else {
            setCanContinue(false)
        }
    },[selectedCampaignSender, selectedCampaignType])

    return (
        <>
            <Box
                sx={{
                    width: '95%',
                    height: selectedCampaignSender !== '' ? '2000px' : '500px',
                    backgroundColor: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '40px',
                    borderRadius: '15px',
                    padding: '50px 70px',
                    boxShadow: '0px 0px 5px 0px #c0c7d0',
                    transition: 'height 0.5s ease',
                    '@media (max-width: 848px)': {
                        height: selectedCampaignSender !== '' ? '2000px' : '1000px',
                        padding: '20px'
                    },
                    '@media (max-width: 738px)': {
                        height: selectedCampaignSender !== '' ? '2500px' : '1000px',
                    },
                }}
            >
                <Typography
                    sx={{
                        color: '#03659a',
                        fontSize: '28px',
                        fontFamily: 'Lato',
                        fontWeight: 'bold',
                    }}
                >
                    Criar nova campanha
                </Typography>
                <Typography
                    sx={{
                        color: '#77828f',
                        fontSize: '20px',
                        margin: '10px 0',
                    }}
                >
                    Selecione a plataforma que você deseja enviar sua campanha
                    de marketing.
                </Typography>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: '20px',
                        flexWrap: 'wrap',
                    }}
                >
                    <NewCampaignOption
                        icon={<WhatsAppIcon sx={{color: '#03659a', fontSize: 50}}/>}
                        title='Campanha por WhatsApp'
                        description='Crie campanhas automatizadas de um jeito
                        simples e barato para fidelizar seus pacientes.'
                        onClick={() => {
                            selectedCampaignSender === 'whatsapp' ?
                                setSelectedCampaignSender('') :
                                setSelectedCampaignSender('whatsapp')
                        }}
                        checked={selectedCampaignSender === 'whatsapp'}
                    />
                    <NewCampaignOption
                        icon={<TextsmsOutlinedIcon sx={{color: '#03659a', fontSize: 50}}/>}
                        title='Campanha por SMS'
                        description='Crie campanhas automatizadas de um jeito\
                        simples e barato para fidelizar seus pacientes.'
                        onClick={() => {
                            selectedCampaignSender === 'sms' ?
                                setSelectedCampaignSender('') :
                                setSelectedCampaignSender('sms')
                        }}
                        checked={selectedCampaignSender === 'sms'}
                    />
                </Box>
                {   selectedCampaignSender !== '' &&
                    <>
                        <Typography
                            sx={{
                                color: '#03659a',
                                fontSize: '28px',
                                fontFamily: 'Lato',
                                fontWeight: 'bold',
                                marginTop: '20px'
                            }}
                        >
                            Tipo de campanha
                        </Typography>
                        <Typography
                            sx={{
                                color: '#77828f',
                                fontSize: '20px',
                                margin: '10px 0',
                            }}
                        >
                            Selecione o modelo de campanha de marketing que você
                            deseja enviar para seus pacientes.
                        </Typography>
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                flexWrap: 'wrap',
                                marginTop: '20px'
                            }}
                        >
                            <WhatsAppCampaignType 
                                checked={selectedCampaignType === 'inactive-retrieval'}
                                onClick={() => {
                                    selectedCampaignType === 'inactive-retrieval' ?
                                        setSelectedCampaignType('') :
                                        setSelectedCampaignType('inactive-retrieval')
                                }}
                                iconUrl={assignmentReturnIcon}
                                title={'Recuperação de inativos'}
                                description='Não deixe que seus pacientes se
                                esqueçam de você. Envie mensagens para eles
                                fazerem um check-up assim que completarem 6
                                ou 12 meses sem consultas marcadas.'
                            />
                            <WhatsAppCampaignType 
                                checked={selectedCampaignType === 'dental-floss'}
                                onClick={() => {
                                    selectedCampaignType === 'dental-floss' ?
                                        setSelectedCampaignType('') :
                                        setSelectedCampaignType('dental-floss')
                                }}
                                iconUrl={dentistryIcon}
                                title={'Fio dental'}
                                description='Esteja presente nos momentos
                                cotidianos de seus pacientes e aproveite
                                para espalhar a boa prática que é o uso do
                                fio dental. A DOT envia a mensagem
                                automática para seus pacientes reforçando a
                                importância do uso.'
                            />
                            <WhatsAppCampaignType 
                                checked={selectedCampaignType === 'payment-due'}
                                onClick={() => {
                                    selectedCampaignType === 'payment-due' ?
                                        setSelectedCampaignType('') :
                                        setSelectedCampaignType('payment-due')
                                }}
                                iconUrl={barCodeIcon}
                                title={'Vencimento de Boleto'}
                                description='Enviar mensagem para os
                                pacientes 24 horas antes do vencimento de
                                seus boletos é uma ótima maneira para
                                lembrá-los de pagar e diminuir a sua
                                inadimplência. Ao ativar a campanha o
                                sistema envia a mensagem automaticamente.'
                            />
                            <WhatsAppCampaignType 
                                checked={selectedCampaignType === 'postoperative'}
                                onClick={() => {
                                    selectedCampaignType === 'postoperative' ?
                                        setSelectedCampaignType('') :
                                        setSelectedCampaignType('postoperative')
                                }}
                                iconUrl={electroCardioIcon}
                                title={'Pós operatório'}
                                description='Envie mensagens após um
                                procedimento cirúrgico e mostre que você se
                                importa de verdade! A DOT envia a
                                mensagem para seu paciente automaticamente,
                                um dia depois do tratamento finalizado no
                                sistema.'
                            />
                            <WhatsAppCampaignType 
                                checked={selectedCampaignType === 'late-payments'}
                                onClick={() => {
                                    selectedCampaignType === 'late-payments' ?
                                        setSelectedCampaignType('') :
                                        setSelectedCampaignType('late-payments')
                                }}
                                iconUrl={localAtmIcon}
                                title={'Debitos em Atraso'}
                                description='Com esta campanha da DOT,
                                cobrar os pacientes que estão te devendo é
                                muito mais fácil! Ao ativá-la, o sistema
                                envia automaticamente uma mensagem para seu
                                paciente lembrando do débito que está
                                atrasado.'
                            />
                            <WhatsAppCampaignType 
                                checked={selectedCampaignType === 'satisfaction'}
                                onClick={() => {
                                    selectedCampaignType === 'satisfaction' ?
                                        setSelectedCampaignType('') :
                                        setSelectedCampaignType('satisfaction')
                                }}
                                iconUrl={happyFaceIcon}
                                title={'Satisfação'}
                                description='Saiba o que seus pacientes
                                estão achando do seu atendimento com a nossa
                                Campanha de Satisfação. Ao ativar ela, o
                                sistema envia automaticamente a mensagem
                                para 40% dos pacientes que foram atendidos.'
                            />
                        </Box>
                    </>
                }
            </Box>
            <Box
                sx={{
                    width: '95%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    marginBottom: '40px'
                }}
            >
                <Button
                    sx={{
                        color: '#262626',
                        width: '120px',
                        height: '40px',
                        borderRadius: '5px',
                        marginTop: '50px',
                        textTransform: 'none',
                    }}
                >
                    Cancelar
                </Button>
                <Button
                    sx={{
                        backgroundColor: canContinue ? '#006af5' : '#d8d8d8',
                        '&:hover': {
                            backgroundColor: canContinue ?
                            'rgba(0, 106, 245, 0.7)' :
                            'rgba(216, 216, 216, 0.7)',
                        },
                        color: canContinue ? 'white' : '#838383',
                        width: '120px',
                        height: '40px',
                        borderRadius: '5px',
                        marginTop: '50px',
                        textTransform: 'none',
                    }}
                    onClick={() => {
                        if(canContinue){
                            goToNextStep()
                        }
                    }}
                >
                    Continuar
                </Button>
            </Box>
        </>
    )
}

export default NewCampaignScreen;