import { Box } from '@mui/material';
import React from 'react';
import ReceivalPerformanceReports from './ReceivalPerformanceReports/index.tsx';
import FinancialPerformanceReports from './FinancialPerformanceReports/index.tsx';
import PatientsPerformanceReports from './PatientsPerformanceReports/index.tsx';

const BasePerformanceSquare: React.FC = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '96%',
                height: '650px',
                marginY: '20px',
                minHeight: '650px',
                '@media (max-width: 904px)': {
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 'auto',
                    minHeight: 'auto',
                }
            }}
        >
            <ReceivalPerformanceReports />
            <Box
                sx={{
                    width: '37%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    '@media (max-width: 904px)': {
                        width: '100%',
                        marginBottom: '20px',
                    }
                }}
            >
                <FinancialPerformanceReports />
                <PatientsPerformanceReports />
            </Box>
        </Box>
    )
}

export default BasePerformanceSquare;