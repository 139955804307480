import React from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import { IPlanBoxData } from '../../../types/Plans/PlanTypes';

interface IPlanBoxProps {
    data: IPlanBoxData;
    selectPlan: () => void;
}

const PlanBox: React.FC<IPlanBoxProps> = ({
    data, selectPlan
}) => {
    const { title, name, description, price, features, promotion} = data;
    const { beforePriceMessage, afterPriceMessage, previousPrice } = promotion || {};
    const [isHovering, setIsHovering] = React.useState(false);
    return (
        <Box
            sx={{
                width: '30%',
                maxWidth: '390px',
                minWidth: '300px',
                height: 'auto',
                marginY: '30px',
                marginX: '10px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                background: isHovering ? 'linear-gradient(to bottom, #104261, #277d89)' : 'white',
                boxShadow: `0px 0px 5px ${isHovering ? '#277d89' : '#bcbcbc'}`, //#f1f1f1
                padding: '20px',
                borderRadius: '10px',
            }}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
        >
            <Typography
                sx={{
                    fontSize: '33px',
                    fontWeight: 'bold',
                    marginBottom: '10px',
                    color: isHovering ? 'white': '#275B85'
                }}
            >{title}</Typography>
            <Typography
                sx={{
                    marginBottom: '10px',
                    color: isHovering ? '#b1ddff' : '#496377'
                }}
            >{description}</Typography>
            <Box>
                {beforePriceMessage && previousPrice && afterPriceMessage &&
                    <Typography
                        sx={{
                            color: isHovering ? '#b1ddff' : '#496377'
                        }}
                    >
                        {beforePriceMessage + ' '}
                        <span style={{textDecoration: 'line-through'}}>{previousPrice}</span> 
                        {' ' + afterPriceMessage}
                    </Typography>
                }
                <Typography
                    sx={{
                        fontSize: '33px',
                        fontWeight: 'bold',
                        marginBottom: '10px',
                        color:  isHovering ? 'white': '#275B85'
                    }}
                >{price}<span style={{fontSize: '20px', fontWeight: 'normal',  color: isHovering ? '#b1ddff' : '#496377'}}>/ mês</span></Typography>
            </Box>
            <Button
                sx={{
                    width: '100%',
                    height: '70px',
                    marginY: '10px',
                    textTransform: 'none',
                    fontWeight: 'bold',
                    fontSize: '20px',
                    color: isHovering ? 'white' : '#275B85',
                    border: `2px solid ${isHovering ? '#3699a5' : '#275B85'}`,
                    backgroundColor: isHovering ? '#277e89' : ''
                }}
                onClick={selectPlan}
            >
                Comprar Plano
            </Button>
            <Typography
                sx={{
                    marginBottom: '10px',
                    color: isHovering ? '#b1ddff' : '#496377'
                }}
            >Não é necessário cartão de crédito</Typography>
            <Box
                sx={{
                    border: '1px solid #e9e9e9',
                    width: '98%',
                    heigth: '1px',
                    marginY: '10px'
                }}
            />
            <Typography
                sx={{
                    color: isHovering ? '#b1ddff' : '#2994e9',
                    fontWeight: 'bold',
                    fontSize: '17px',
                }}
            ><span style={{fontWeight: 'normal'}}>Tudo do Plano</span> {name ? name : title}</Typography>
            <Box>
                {features.map((feature, index) => (
                    <Box
                        key={index}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                            justifyContent: 'space-between',
                            marginY: '10px'
                        }}
                    >
                        <Box >
                            <CheckCircleIcon
                                sx={{
                                    color:  isHovering ? 'white': '#275b85',
                                    marginRight: '8px'
                                }}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'flex-start',
                                width: '100%'
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start',
                                    maxWidth: '300px',
                                }}
                            >
                                <Typography
                                    sx={{
                                        color:  isHovering ? 'white': '#496377',
                                        fontSize: '16px',
                                    }}
                                >{feature.title}</Typography>
                                <Typography
                                    sx={{
                                        color:  isHovering ? 'white': '#627d93',
                                        fontSize: feature.detailSize === 'small' ? '11px' : '16px',
                                    }}
                                >{feature.detail}</Typography>
                            </Box>
                            {feature.detail &&
                                <InfoIcon sx={{color: isHovering ? '#b1ddff' :'#627d93', fontSize: '18px', marginX: '5px'}}/>
                            }
                            {feature.isNew &&
                                <Box
                                    sx={{
                                        backgroundColor: '#277e89',
                                        width: '45px',
                                        marginX: '5px',
                                        height: '20px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius: '5px',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: 'white',
                                            fontSize: '12px',
                                            fontWeight: 'bold'
                                        }}
                                    >Novo</Typography>
                                </Box>
                            }
                        </Box>
                    </Box>
                ))}
            </Box>
        </Box>
    )
}

export default PlanBox;