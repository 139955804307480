import { Box, Typography } from '@mui/material'
import React from 'react'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import CloseIcon from '@mui/icons-material/Close';
import SimpleMenu from '../../../components/simpleMenu/index.tsx';

const MenuItemWithIcon: React.FC<{
    icon: React.ReactNode,
    title: string,
    message: string
}> = ({icon, title, message}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                height: '90px',
                width: '370px',
                '@media (max-width: 1174px)': {
                    width: '250px'
                }
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '40%',
                    height: '100%',
                    backgroundImage: 'linear-gradient(180deg, #104161 0%, #29828b 100%)',
                    borderRadius: '5px',
                    color: 'white',
                }}
            >
                {icon}
            </Box>
            <Box
                sx={{
                    width: '60%',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '15px',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    boxShadow: '0px 0px 5px 0px #c0c7d0',
                }}
            >
                <Typography
                    sx={{
                        fontSize: '15px',
                        fontWeight: 'bold',
                        fontFamily: 'Lato',
                        color: '#03659A',
                    }}
                >{title}</Typography>
                <Typography
                    sx={{
                        fontSize: '13px',
                        fontFamily: 'Lato',
                        color: '#0094E5',
                    }}
                >{message}</Typography>
            </Box>
        </Box>
    )
}

interface IIntelligenceOptionsMenuProps {
    optionSelected: string,
    setOptionSelected: (option: string) => void
}

const IntelligenceOptionsMenu: React.FC<IIntelligenceOptionsMenuProps> = ({optionSelected, setOptionSelected}) => {
    const [debts, setDebts] = React.useState(0)
    const [budgets, setBudgets] = React.useState(0)
    const [reprovedBudgets, setReprovedBudgets] = React.useState(0)
    return (
        <Box
            sx={{
                width: '100%',
            }}
        >
            <Box
                sx={{
                    backgroundColor: 'white',
                    width: '100%',
                    display: 'flex',
                    height: '140px',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    borderRadius: '5px',
                    flexWrap: 'wrap',
                    '@media (max-width: 772px)': {
                        height: '300px',
                    },
                    '@media (max-width: 516px)': {
                        height: '450px',
                    }

                }}
            >
                <MenuItemWithIcon
                    icon={<AttachMoneyIcon sx={{fontSize: '50px'}}/>}
                    title={`R$ ${debts.toFixed(2)}`}
                    message={'Débito em atraso'}
                />
                <MenuItemWithIcon
                    icon={<RequestQuoteIcon sx={{fontSize: '50px'}}/>}
                    title={`R$ ${budgets.toFixed(2)}`}
                    message={'Orçamentos em Aberto'}
                />
                <MenuItemWithIcon
                    icon={<CloseIcon sx={{fontSize: '50px'}}/>}
                    title={`R$ ${reprovedBudgets.toFixed(2)}`}
                    message={'Orçamentos Reprovados'}
                />
            </Box>
            <SimpleMenu
                options={['Performance', 'Tarefas', 'Ortodontia']}
                optionSelected={optionSelected}
                setOptionSelected={setOptionSelected}
            />
        </Box>
    )
}

export default IntelligenceOptionsMenu;