import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import RegisteredPatientsModal from '../../../../../components/modals/RegisteredPatientsModal/index.tsx';

const Divider: React.FC = () => {
    return <Box
        sx={{
            width: '99%',
            marginX: 'auto',
            height: '1px',
            backgroundColor: '#c0c7d0',
            margin: '10px 0px 15px 0px'
        }}
    />
}

const PatientsPerformanceReports: React.FC = () => {
    const [patientsData, setPatientsData] = React.useState<{
        total: number,
        lastSixMonths: number,
        indebted: number,
        newThisMonth: number,
    }>({
        total: 40,
        lastSixMonths: 30,
        indebted: 10,
        newThisMonth: 10,
    });
    const [openRegisteredPatientsModal, setOpenRegisteredPatientsModal] = React.useState<boolean>(false);
    return (
        <Box
            sx={{
                padding: '20px 30px',
                backgroundColor: '#fff',
                boxShadow: '0px 0px 5px 0px #c0c7d0',
            }}
        >
            <RegisteredPatientsModal
                open={openRegisteredPatientsModal}
                setOpen={setOpenRegisteredPatientsModal}
            />
            <Typography
                sx={{
                    fontSize: '20px',
                    fontFamily: 'Lato',
                    fontWeight: 'bold',
                }}
            >Pacientes</Typography>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '20px',
                }}
            >
                <Box>
                    <Typography
                        sx={{
                            fontSize: '16px',
                            color: '#005fdb',
                        }}
                    >{patientsData?.total}</Typography>
                    <Typography
                        sx={{
                            fontSize: '12px',
                            color: '#88929d',
                        }}
                    >Total de Pacientes Cadastrados</Typography>
                </Box>
                <Button
                    onClick={() => setOpenRegisteredPatientsModal(true)}
                >Ver</Button>
            </Box>
            <Divider />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '20px',
                }}
            >
                <Box>
                    <Typography
                        sx={{
                            fontSize: '16px',
                            color: '#005fdb',
                        }}
                    >{patientsData?.total}</Typography>
                    <Typography
                        sx={{
                            fontSize: '12px',
                            color: '#88929d',
                        }}
                    >Pacientes atendidos nos últimos 6 meses</Typography>
                </Box>
                <Button
                    onClick={() => setOpenRegisteredPatientsModal(true)}
                >Ver</Button>
            </Box>
            <Divider />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '20px',
                }}
            >
                <Box>
                    <Typography
                        sx={{
                            fontSize: '16px',
                            color: '#005fdb',
                        }}
                    >{patientsData?.total}</Typography>
                    <Typography
                        sx={{
                            fontSize: '12px',
                            color: '#88929d',
                        }}
                    >Pacientes com débitos em atraso</Typography>
                </Box>
                <Button
                    onClick={() => setOpenRegisteredPatientsModal(true)}
                >Ver</Button>
            </Box>
            <Divider />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '20px',
                }}
            >
                <Box>
                    <Typography
                        sx={{
                            fontSize: '16px',
                            color: '#005fdb',
                        }}
                    >{patientsData?.total}</Typography>
                    <Typography
                        sx={{
                            fontSize: '12px',
                            color: '#88929d',
                        }}
                    >Novos pacientes atendidos no mês</Typography>
                </Box>
                <Button
                    onClick={() => setOpenRegisteredPatientsModal(true)}
                >Ver</Button>
            </Box>
        </Box>
    )
}

export default PatientsPerformanceReports;