import React, { useState, useEffect } from 'react';
import { Box, Button, Modal, TextField, Typography, IconButton, Checkbox } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { IAddPlanModal, INewProcedureForm, Plan, Procedure } from '../../../types/Plans/PlanTypes';
import axios from 'axios';
import { toast } from 'react-toastify';
import { configBackendConnection, endpoints } from "../../../components/utilsBackend.ts";

const MockedSpecialties = [
    { id: 1, name: 'Cirurgia', proceduresIds: [1] },
    { id: 2, name: 'Dentística', proceduresIds: [] as number[] },
    { id: 3, name: 'Endodontia', proceduresIds: [] as number[] },
    { id: 4, name: 'Harmonização facial', proceduresIds: [] as number[] },
    { id: 5, name: 'Implantodontia', proceduresIds: [] as number[] },
    { id: 6, name: 'Ortodontia', proceduresIds: [] as number[] },
    { id: 7, name: 'Outros', proceduresIds: [] as number[] },
    { id: 8, name: 'Periodontia', proceduresIds: [] as number[] },
    { id: 9, name: 'Prevenção', proceduresIds: [] as number[] },
    { id: 10, name: 'Prótese', proceduresIds: [] as number[] },
    { id: 11, name: 'Radiologia', proceduresIds: [] as number[] },
    { id: 12, name: 'Testes e exames laboratoriais', proceduresIds: [] as number[] },
    { id: 13, name: 'Urgência', proceduresIds: [] as number[] }
];

const AddPlanModal: React.FC<IAddPlanModal> = ({
    open,
    setOpen,
    onClose,
    step,
    setStep,
    form,
    setForm,
    onPlanAdded,
    canEdit,
    editPlanId
}) => {
    const [selectedSpecialty, setSelectedSpecialty] = useState(MockedSpecialties[0]);
    const [selectedSpecialtyProcedures, setSelectedSpecialtyProcedures] = useState<Procedure[]>([]);
    const [showAddNewProcedureForm, setShowAddNewProcedureForm] = useState<boolean>(false);
    const [newProcedureForm, setNewProcedureForm] = useState<INewProcedureForm>({
        name: '',
        treatmentValue: '0,00',
        treatmentCost: '0,00',
        acceptFaces: false
    });
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [showSearchInput, setShowSearchInput] = useState<boolean>(false);
    const [errorMessages, setErrorMessages] = useState({
        name: '',
        treatmentValue: '',
        treatmentCost: ''
    });
    const [planNameError, setPlanNameError] = useState<string>('');

    useEffect(() => {
        if (canEdit && editPlanId !== null) {
            const fetchPlanToEdit = async () => {
                try {
                    const response = await axios.get<Plan>(`${configBackendConnection.baseURL}/${endpoints.planAPI}${editPlanId}/`, {
                        headers: configBackendConnection.headersDefault
                    });

                    const planToEdit = response.data;
                    setForm({
                        name: planToEdit.name,
                        isPaydByInsurance: planToEdit.is_payd_by_insurance,
                        isDefaultPlan: planToEdit.is_default_plan,
                        selectedProcedures: planToEdit.specialty.procedures.map(p => ({ id: p.id }))
                    });

                    const specialty = MockedSpecialties.find(s => s.name === planToEdit.specialty.name);
                    if (specialty) {
                        setSelectedSpecialty(specialty);
                        setSelectedSpecialtyProcedures(planToEdit.specialty.procedures);
                    }
                } catch (error) {
                    toast.error("Erro ao carregar o plano para edição.");
                    console.error(error);
                }
            };

            fetchPlanToEdit();
        }
    }, [canEdit, editPlanId, setForm]);

    const onContinue = () => {
        if (form.name === '') {
            setPlanNameError('O campo é obrigatório.');
            return;
        }
        setPlanNameError('');
        setStep(step + 1);
    };

    const validateFields = (): boolean => {
        const errors = {
            name: '',
            treatmentValue: '',
            treatmentCost: ''
        };
        let isValid = true;

        if (!newProcedureForm.name) {
            errors.name = 'O campo é obrigatório.';
            isValid = false;
        }

        setErrorMessages(errors);
        return isValid;
    };

    const addProcedure = () => {
        if (!validateFields()) {
            return;
        }

        const newProcedure: Procedure = {
            id: selectedSpecialtyProcedures.length + 1,
            name: newProcedureForm.name,
            treatment_value: (parseFloat(newProcedureForm.treatmentValue.replace(/[^0-9]/g, '')) / 100).toFixed(2),
            treatment_cost: (parseFloat(newProcedureForm.treatmentCost.replace(/[^0-9]/g, '')) / 100).toFixed(2),
        };

        setSelectedSpecialtyProcedures(prevProcedures => [
            ...prevProcedures,
            newProcedure
        ]);

        setNewProcedureForm({ name: '', treatmentValue: '0,00', treatmentCost: '0,00', acceptFaces: false });
        setShowAddNewProcedureForm(false);
        setErrorMessages({ name: '', treatmentValue: '', treatmentCost: '' });
    };

    const removeProcedure = (procedureId: number) => {
        setSelectedSpecialtyProcedures(
            selectedSpecialtyProcedures.filter(procedure => procedure.id !== procedureId)
        );
    };

    const addOrUpdatePlan = async () => {
        const newPlan = {
            name: form.name,
            is_payd_by_insurance: form.isPaydByInsurance,
            is_default_plan: form.isDefaultPlan,
            specialty: {
                id: selectedSpecialty.id,
                name: selectedSpecialty.name,
                procedures: selectedSpecialtyProcedures.map(p => ({
                    id: p.id,
                    name: p.name,
                    treatment_value: p.treatment_value,
                    treatment_cost: p.treatment_cost
                }))
            }
        };
    
        try {
            if (canEdit && editPlanId !== null) {
                await axios.patch(`${configBackendConnection.baseURL}/${endpoints.planAPI}${editPlanId}/`, newPlan, {
                    headers: configBackendConnection.headersDefault
                });
                toast.success("Plano atualizado com sucesso!");
            } else {
                await axios.post(`${configBackendConnection.baseURL}/${endpoints.planAPI}`, newPlan, {
                    headers: configBackendConnection.headersDefault
                });
                
                toast.success("Plano adicionado com sucesso!");
            }
            if (onPlanAdded) onPlanAdded();
            handleClose();
        } catch (error) {
            console.error("Erro ao salvar o plano:", error);
            toast.error("Erro ao salvar o plano.");
        }
    };

    const formatCurrency = (value: string): string => {
        const num = parseFloat(value.replace(/[^0-9]/g, '')) / 100;
        return isNaN(num) ? 'R$ 0,00' : `R$ ${num.toFixed(2).replace('.', ',')}`;
    };

    const handleCurrencyChange = (field: keyof INewProcedureForm) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace(/[^0-9]/g, '');
        setNewProcedureForm({ ...newProcedureForm, [field]: formatCurrency(value) });
    };

    const filteredProcedures = selectedSpecialtyProcedures.filter(procedure => 
        procedure.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleClose = () => {
        setForm({ name: '', isPaydByInsurance: false, isDefaultPlan: false, selectedProcedures: [] });
        setSelectedSpecialty(MockedSpecialties[0]);
        setSelectedSpecialtyProcedures([]);
        setSearchQuery('');
        setStep(1);
        setOpen(false);
        if (onClose) onClose();
    };

    return (
        <Modal
            open={open}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            onClose={handleClose}
        >
            {step === 1 ? (
                <Box
                    sx={{
                        width: '600px',
                        height: '300px',
                        backgroundColor: 'white',
                        padding: '35px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '22px',
                            fontWeight: 'bold',
                            fontFamily: 'Lato',
                        }}
                    >
                        {canEdit ? "Editar plano" : "Novo plano"}
                    </Typography>
                    <Box>
                        <Typography sx={{ color: '#7c7c8d' }}>
                            Plano <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <TextField
                            fullWidth
                            size={'small'}
                            variant="outlined"
                            placeholder="Digite aqui..."
                            autoComplete="off"
                            value={form.name}
                            onChange={(e) => {
                                setForm({ ...form, name: e.target.value });
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: '#e9eaec',
                                        borderRadius: '10px',
                                    },
                                },
                            }}
                            error={!!planNameError}
                            helperText={planNameError}
                        />
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button
                            sx={{
                                textTransform: 'none',
                                color: 'black',
                            }}
                            onClick={handleClose}
                        >
                            Cancelar
                        </Button>
                        <Button
                            sx={{
                                backgroundColor: '#006af5',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 106, 245, 0.7)',
                                },
                                color: 'white',
                                textTransform: 'none',
                                marginLeft: '10px',
                            }}
                            onClick={onContinue}
                        >
                            Continuar
                        </Button>
                    </Box>
                </Box>
            ) : (
                <Box
                    sx={{
                        width: '1300px',
                        height: '750px',
                        backgroundColor: 'white',
                        padding: '35px',
                        '@media (max-width: 1300px)': {
                            width: '100vw',
                            overflowY: 'auto',
                        },
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '22px',
                                fontWeight: 'bold',
                                fontFamily: 'Lato',
                            }}
                        >
                            {canEdit ? "Editar plano" : "Novo plano"}
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Button
                                sx={{
                                    backgroundColor: '#006af5',
                                    '&:hover': {
                                        backgroundColor: 'rgba(0, 106, 245, 0.7)',
                                    },
                                    color: 'white',
                                    width: '45px',
                                    height: '35px',
                                    minWidth: '10px',
                                    marginRight: '10px',
                                }}
                                onClick={() => setShowSearchInput(!showSearchInput)}
                            >
                                <SearchIcon />
                            </Button>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '20px',
                            flexWrap: 'wrap',
                            rowGap: '10px'
                        }}
                    >
                        <Box sx={{ width: '35%', minWidth: '300px' }}>
                            <Typography sx={{ color: '#7c7c8d' }}>
                                Nome do Plano <span style={{ color: 'red' }}>*</span>
                            </Typography>
                            <TextField
                                fullWidth
                                size={'small'}
                                variant="outlined"
                                placeholder="Digite aqui..."
                                autoComplete="off"
                                value={form.name}
                                onChange={(e) => {
                                    setForm({ ...form, name: e.target.value });
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#e9eaec',
                                            borderRadius: '10px'
                                        },
                                    },
                                }}
                                error={!!planNameError}
                                helperText={planNameError} 
                            />
                        </Box>
                        <Box
                            sx={{
                                width: '400px',
                                height: '40px',
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Checkbox
                                    value={form.isPaydByInsurance}
                                    checked={form.isPaydByInsurance}
                                    onChange={() => setForm({ ...form, isPaydByInsurance: !form.isPaydByInsurance })}
                                />
                                <Typography>
                                    Pago pelo plano/convênio
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Checkbox
                                    value={form.isDefaultPlan}
                                    checked={form.isDefaultPlan}
                                    onChange={() => setForm({ ...form, isDefaultPlan: !form.isDefaultPlan })}
                                />
                                <Typography>
                                    Plano padrão
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '20px',
                            flexWrap: 'wrap',
                            rowGap: '20px',
                            '@media (max-width: 1042px)': {
                                justifyContent: 'center'
                            }
                        }}
                    >
                        <Box
                            sx={{
                                width: '29%',
                                minWidth: '300px',
                                height: '500px',
                                border: '1px solid #e9eaec',
                                borderRadius: '10px'
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '25px',
                                    fontWeight: 'bold',
                                    fontFamily: 'Lato',
                                    marginTop: '20px',
                                    marginLeft: '20px'
                                }}
                            >
                                Especialidades
                            </Typography>
                            <Box
                                sx={{
                                    marginTop: '10px',
                                    maxHeight: '430px',
                                    overflow: 'auto',
                                }}
                            >
                                {MockedSpecialties.map((specialty) => (
                                    <Box
                                        key={specialty.id}
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            height: '40px',
                                            backgroundColor: selectedSpecialty.name === specialty.name ? '#edf5f8' : ''
                                        }}
                                        onClick={() => setSelectedSpecialty(specialty)}
                                    >
                                        <Box
                                            sx={{
                                                width: '10px',
                                                height: '100%',
                                                backgroundColor: selectedSpecialty.name === specialty.name ? '#d6e7ee' : '',
                                                borderRadius: '0px 7px 7px 0px',
                                                marginRight: '10px'
                                            }}
                                        />
                                        <Typography>{specialty.name}</Typography>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                width: '69%',
                                height: '500px',
                                minWidth: '300px',
                                border: '1px solid #e9eaec',
                                borderRadius: '10px'
                            }}
                        >
                            {selectedSpecialty && (
                                <Box
                                    sx={{
                                        '@media (max-width: 1038px)': {
                                            maxHeight: '500px',
                                            overflowY: 'auto',
                                        }
                                    }}
                                >
                                    <Box
                                        sx={{
                                            padding: '20px',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '25px',
                                                fontFamily: 'Lato',
                                            }}
                                        >
                                            {selectedSpecialty.name}
                                        </Typography>
                                        <Box>
                                            <Button
                                                sx={{
                                                    backgroundColor: '#006af5',
                                                    '&:hover': {
                                                        backgroundColor: 'rgba(0, 106, 245, 0.7)',
                                                    },
                                                    color: 'white',
                                                    textTransform: 'none',
                                                    marginRight: '10px',
                                                    fontWeight: 'bold',
                                                }}
                                                onClick={() => setShowAddNewProcedureForm(!showAddNewProcedureForm)}
                                            >
                                                + Adicionar
                                            </Button>
                                            <Button
                                                sx={{
                                                    backgroundColor: '#006af5',
                                                    '&:hover': {
                                                        backgroundColor: 'rgba(0, 106, 245, 0.7)',
                                                    },
                                                    color: 'white',
                                                    width: '45px',
                                                    height: '35px',
                                                    minWidth: '10px',
                                                }}
                                                onClick={() => setShowSearchInput(!showSearchInput)}
                                            >
                                                <SearchIcon />
                                            </Button>
                                        </Box>
                                    </Box>
                                    {showSearchInput && (
                                        <Box
                                            sx={{
                                                width: '100%',
                                                height: 'auto',
                                                padding: '20px',
                                            }}
                                        >
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                placeholder="Pesquisar..."
                                                autoComplete="off"
                                                value={searchQuery}
                                                onChange={(e) => setSearchQuery(e.target.value)}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            borderColor: '#e9eaec',
                                                            borderRadius: '10px'
                                                        },
                                                    },
                                                }}
                                            />
                                        </Box>
                                    )}
                                    {showAddNewProcedureForm && (
                                        <Box
                                            sx={{
                                                width: '100%',
                                                height: 'auto',
                                                padding: '20px',
                                            }}
                                        >
                                            <Box sx={{ marginBottom: '20px' }}>
                                                <Typography sx={{ color: '#7c7c8d' }}>
                                                    Nome do Tratamento <span style={{ color: 'red' }}>*</span>
                                                </Typography>
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    placeholder="Digite aqui..."
                                                    autoComplete="off"
                                                    value={newProcedureForm.name}
                                                    onChange={(e) => setNewProcedureForm({ ...newProcedureForm, name: e.target.value })}
                                                    sx={{
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                borderColor: '#e9eaec',
                                                                borderRadius: '10px'
                                                            },
                                                        },
                                                    }}
                                                />
                                                {errorMessages.name && (
                                                    <Typography sx={{ color: 'red', fontSize: '12px' }}>
                                                        {errorMessages.name}
                                                    </Typography>
                                                )}
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    flexWrap: 'wrap',
                                                }}
                                            >
                                                <Box sx={{ width: '30%', minWidth: '170px' }}>
                                                    <Typography sx={{ color: '#7c7c8d' }}>
                                                        Valor do Tratamento <span style={{ color: 'red' }}>*</span>
                                                    </Typography>
                                                    <TextField
                                                        fullWidth
                                                        variant="outlined"
                                                        placeholder="Digite aqui..."
                                                        autoComplete="off"
                                                        type="text"
                                                        value={newProcedureForm.treatmentValue}
                                                        onChange={handleCurrencyChange('treatmentValue')}
                                                        sx={{
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    borderColor: '#e9eaec',
                                                                    borderRadius: '10px'
                                                                },
                                                            },
                                                        }}
                                                    />
                                                    {errorMessages.treatmentValue && (
                                                        <Typography sx={{ color: 'red', fontSize: '12px' }}>
                                                            {errorMessages.treatmentValue}
                                                        </Typography>
                                                    )}
                                                </Box>
                                                <Box sx={{ width: '30%', minWidth: '170px' }}>
                                                    <Typography sx={{ color: '#7c7c8d' }}>
                                                        Custo do Tratamento <span style={{ color: 'red' }}>*</span>
                                                    </Typography>
                                                    <TextField
                                                        fullWidth
                                                        variant="outlined"
                                                        placeholder="Digite aqui..."
                                                        autoComplete="off"
                                                        type="text"
                                                        value={newProcedureForm.treatmentCost}
                                                        onChange={handleCurrencyChange('treatmentCost')}
                                                        sx={{
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    borderColor: '#e9eaec',
                                                                    borderRadius: '10px'
                                                                },
                                                            },
                                                        }}
                                                    />
                                                    {errorMessages.treatmentCost && (
                                                        <Typography sx={{ color: 'red', fontSize: '12px' }}>
                                                            {errorMessages.treatmentCost}
                                                        </Typography>
                                                    )}
                                                </Box>
                                                <Box
                                                    sx={{
                                                        width: '30%',
                                                        height: '60px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'flex-end',
                                                        paddingTop: '28px',
                                                        minWidth: '170px'
                                                    }}
                                                >
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={addProcedure}
                                                        sx={{
                                                            textTransform: 'none',
                                                            backgroundColor: '#006af5',
                                                            '&:hover': {
                                                                backgroundColor: 'rgba(0, 106, 245, 0.7)',
                                                            },
                                                        }}
                                                    >
                                                        Adicionar
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Box>
                                    )}
                                    <Box
                                        sx={{
                                            width: '100%',
                                            height: showAddNewProcedureForm ? 'calc(420px - 280px)' : '420px',
                                            overflow: 'auto',
                                            padding: '0px 20px 0px 20px',
                                        }}
                                    >
                                        {filteredProcedures.map((procedure) => (
                                            <Box key={procedure.id} sx={{ marginBottom: '20px' }}>
                                                
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: '22px',
                                                            fontFamily: 'Lato',
                                                            fontWeight: 'bold',
                                                            marginBottom: '10px'
                                                        }}
                                                    >
                                                        {procedure.name}
                                                    </Typography>
                                                    <Box
                                                        sx={{
                                                            "@media (min-width: 870px)": {
                                                                display: 'none',
                                                            },
                                                            "@media (max-width: 869px)": {
                                                                width: '30%',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'flex-end',
                                                            }
                                                        }}
                                                    >
                                                        <IconButton onClick={() => removeProcedure(procedure.id)}>
                                                            <DeleteOutlinedIcon />
                                                        </IconButton>
                                                    </Box>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        flexWrap: 'wrap',
                                                    }}
                                                >
                                                    <Box sx={{ width: '30%', minWidth: '170px' }}>
                                                        <Typography sx={{ color: '#7c7c8d' }}>
                                                            Valor do Tratamento <span style={{ color: 'red' }}>*</span>
                                                        </Typography>
                                                        <TextField
                                                            fullWidth
                                                            variant="outlined"
                                                            placeholder="Digite aqui..."
                                                            autoComplete="off"
                                                            value={`R$ ${procedure.treatment_value}`}
                                                            disabled={true}
                                                            sx={{
                                                                '& .MuiOutlinedInput-root': {
                                                                    '& fieldset': {
                                                                        borderColor: '#e9eaec',
                                                                        borderRadius: '10px'
                                                                    },
                                                                },
                                                            }}
                                                        />
                                                    </Box>
                                                    <Box sx={{ width: '30%', minWidth: '170px' }}>
                                                        <Typography sx={{ color: '#7c7c8d' }}>
                                                            Custo do Tratamento <span style={{ color: 'red' }}>*</span>
                                                        </Typography>
                                                        <TextField
                                                            fullWidth
                                                            variant="outlined"
                                                            placeholder="Digite aqui..."
                                                            autoComplete="off"
                                                            value={`R$ ${procedure.treatment_cost}`}
                                                            disabled={true}
                                                            sx={{
                                                                '& .MuiOutlinedInput-root': {
                                                                    '& fieldset': {
                                                                        borderColor: '#e9eaec',
                                                                        borderRadius: '10px'
                                                                    },
                                                                },
                                                            }}
                                                        />
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            width: '30%',
                                                            height: '60px',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'flex-end',
                                                            paddingTop: '28px',
                                                            minWidth: '170px',
                                                            "@media (max-width: 869px)": {
                                                                display: 'none',
                                                            }
                                                        }}
                                                    >
                                                        <IconButton onClick={() => removeProcedure(procedure.id)}>
                                                            <DeleteOutlinedIcon />
                                                        </IconButton>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: '20px'
                        }}
                    >
                        <Button
                            sx={{
                                textTransform: 'none',
                                color: 'black'
                            }}
                            onClick={handleClose}
                        >
                            Cancelar
                        </Button>
                        <Button
                            sx={{
                                backgroundColor: '#006af5',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 106, 245, 0.7)',
                                },
                                color: 'white',
                                textTransform: 'none',
                                marginLeft: '10px',
                            }}
                            onClick={addOrUpdatePlan}
                        >
                            {canEdit ? "Atualizar Plano" : "Adicionar Plano"}
                        </Button>
                    </Box>
                </Box>
            )}
        </Modal>
    );
};

export default AddPlanModal;
