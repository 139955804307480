import React from 'react';
import { Box, LinearProgress, LinearProgressProps, Typography, linearProgressClasses, styled } from '@mui/material';

interface BorderLinearProgressProps extends LinearProgressProps {
    colorPrimary?: string;
    backgroundColor?: string;
}
  
const BorderLinearProgress = styled(LinearProgress, {
    shouldForwardProp: (prop) => prop !== 'colorPrimary' && prop !== 'backgroundColor',
})<BorderLinearProgressProps>(({ theme, colorPrimary, backgroundColor }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: backgroundColor || theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: colorPrimary || (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'),
    },
}));

const TransactionTotalBar: React.FC<{
    title: string;
    incoming?: number;
    incomingText?: 'A pagar' | 'A receber'
    totalPredicted: number;
    value: number;
    progress: number;
    color: string
}> = ({
    title,
    incoming,
    incomingText,
    totalPredicted,
    value,
    progress,
    color
}) => {
    return (
        <Box
            sx={{
                width: '30%',
                minWidth: '150px',
                justifyContent: 'space-between',
                '@media (max-width: 658px)': {
                    width: '100%'
                }
            }}
        >
            <Typography sx={{color: '#77828f'}}
            >{title}</Typography>
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    alignContent: 'center',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                }}
            >
                <Typography
                    sx={{
                        color: color,
                        '@media (max-width: 1000px)': {
                            fontSize: '12px'
                        },
                        '@media (max-width: 786px)': {
                            fontSize: '10px'
                        },
                        '@media (max-width: 658px)': {
                            fontSize: '15px'
                        }
                    }}
                >R$ {value.toFixed(2)}</Typography>
                {incomingText && incoming &&
                    <Typography
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            color: '#77828f',
                            '@media (max-width: 1000px)': {
                                fontSize: '12px'
                            },
                            '@media (max-width: 786px)': {
                                fontSize: '10px'
                            },
                            '@media (max-width: 658px)': {
                                fontSize: '15px'
                            }
                        }}
                    >
                        {incomingText ? incomingText+ ': ' : ''}
                        <Typography
                            sx={{color: 'inherit', fontSize: 'inherit'}}
                        >R$ {incoming.toFixed(2)}</Typography>
                    </Typography>
                }
            </Box>
            <BorderLinearProgress value={progress} variant="determinate" colorPrimary={color} backgroundColor='#e9ecf0' />
            <Box sx={{display: 'flex', width: '100%', justifyContent: 'flex-end'}}>
                <Typography
                    sx={{
                        color: '#77828f',
                        '@media (max-width: 1000px)': {
                            fontSize: '12px'
                        },
                        '@media (max-width: 786px)': {
                            fontSize: '10px'
                        },
                        '@media (max-width: 658px)': {
                            fontSize: '15px'
                        }
                    }}
                >
                    Total previsto: R$ {totalPredicted.toFixed(2)}
                </Typography>
            </Box>
        </Box>
    );
}

export default TransactionTotalBar;