import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { IModalProps } from '../../../types/modals';
import { toast } from 'react-toastify';

const SalesTargetModal: React.FC<IModalProps> = ({
    open,
    setOpen,
    onClose
}) => {
    const [salesTarget, setSalesTarget] = useState<string>('');
    return (
        <Modal
            open={open}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box 
                sx={{
                    width: '600px',
                    height: '280px',
                    backgroundColor: 'white',
                    padding: '35px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    '@media (max-width: 600px)': {
                        width: '100vw',
                        overflowY: 'auto'
                    }
                }}
            >
                <Typography
                    sx={{
                        fontSize: '25px',
                        fontWeight: 'bold',
                        fontFamily: 'Lato'
                    }}
                >Definir Meta</Typography>
                <Box sx={{width: '100%'}}>
                    <Typography
                        sx={{
                        fontSize: '18px',
                        fontFamily: 'Lato',
                        color: '#7c7c8d'
                        }}
                    >
                        Definir o valor da meta<span style={{color: 'red'}}>*</span>
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Digite aqui..."
                        autoComplete="off"
                        value={salesTarget}
                        onChange={(e) => {
                            setSalesTarget(e.target.value);
                        }}
                        sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                            borderColor: '#e9eaec',
                            borderRadius: '10px'
                            },
                        },
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        width: '100%',
                    }}
                >
                    <Box>
                        <Button
                            sx={{
                                color: '#ca150c',
                                textTransform: 'none'
                            }}
                            onClick={() => setOpen && setOpen(false)}
                        >
                            Fechar
                        </Button>
                        <Button
                            sx={{
                                textTransform: 'none',
                                color: 'white',
                                width: '80px',
                                backgroundColor: '#006af5',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 106, 245, 0.7)',
                                },
                            }}
                            onClick={() => {
                                salesTarget ? toast.error('Não implementado') :
                                    toast.warning('Preencha o campo obrigatório')
                            }}
                        >
                            Salvar
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

export default SalesTargetModal;