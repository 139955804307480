import { v4 as uuidv4 } from 'uuid';

export const MockedQuestions = [
    {
        id: 1,
        uuid: uuidv4(),
        alert: false,
        question: 'Queixa principal?',
        type_question: 'Somente Texto',
    },
    {
        id: 2,
        uuid: uuidv4(),
        alert: false,
        question: 'Quando foi sua última vez que veio ao dentista? Como foi o atendimento?',
        type_question: 'Somente Texto',
    },
    {
        id: 3,
        uuid: uuidv4(),
        alert: false,
        question: 'Quantas vezes por dia escova os dentes?',
        type_question: 'Somente Texto',
    },
    {
        id: 4,
        uuid: uuidv4(),
        alert: false,
        question: 'Usa creme dental?',
        type_question: 'Sim/Não/Não Sei',
    },
    {
        id: 5,
        uuid: uuidv4(),
        alert: false,
        question: 'Faz uso de antisséptico bucal?',
        type_question: 'Sim/Não/Não sei e Texto',
    },
    {
        id: 6,
        uuid: uuidv4(),
        alert: false,
        question: 'Usa fio dental?',
        type_question: 'Sim/Não/Não sei e Texto',
    },
    {
        id: 7,
        uuid: uuidv4(),
        alert: false,
        question: 'Está em tratamento médico?',
        type_question: 'Sim/Não/Não sei e Texto',
    },
    {
        id: 8,
        uuid: uuidv4(),
        alert: true,
        question: 'Está usando medicação?',
        type_question: 'Sim/Não/Não sei e Texto',
        alertText: 'Toma',
    },
    {
        id: 9,
        uuid: uuidv4(),
        alert: true,
        question: 'Possui alguma alergia? (Como penicilinas, AAS ou outra)',
        type_question: 'Sim/Não/Não sei e Texto',
        alertText: 'Alérgico a',
    },
    {
        id: 10,
        uuid: uuidv4(),
        alert: false,
        question: 'Já esteve internado?',
        type_question: 'Sim/Não/Não sei e Texto',
    },
    {
        id: 11,
        uuid: uuidv4(),
        alert: false,
        question: 'Informações adicionais:',
        type_question: 'Somente Texto',
    },
    {
        id: 12,
        uuid: uuidv4(),
        alert: true,
        question: 'Já teve hemorragia diagnosticada?',
        type_question: 'Sim/Não/Não Sei',
        alertText: 'Risco de hemorragia',
    },
    {
        id: 13,
        uuid: uuidv4(),
        alert: true,
        question: 'Possui alguma alteração sanguínea?',
        type_question: 'Sim/Não/Não sei e Texto',
        alertText: 'Alteração sanguínea',
    },
    {
        id: 14,
        uuid: uuidv4(),
        alert: true,
        question: 'Possui alguma alteração cardiovascular?',
        type_question: 'Sim/Não/Não sei e Texto',
        alertText: 'Alteração cardíaca',
    },
    {
        id: 15,
        uuid: uuidv4(),
        alert: true,
        question: 'Tem pressão alta?',
        type_question: 'Sim/Não/Não sei e Texto',
        alertText: 'Hipertenso',
    },
    {
        id: 16,
        uuid: uuidv4(),
        alert: true,
        question: 'Possui diabetes?',
        type_question: 'Sim/Não/Não sei e Texto',
        alertText: 'Diabético',
    },
    {
        id: 17,
        uuid: uuidv4(),
        alert: true,
        question: 'Possui asma?',
        type_question: 'Sim/Não/Não Sei',
        alertText: 'Asmático',
    },
    {
        id: 18,
        uuid: uuidv4(),
        alert: true,
        question: 'Possui anemia?',
        type_question: 'Sim/Não/Não Sei',
        alertText: 'Anêmico',
    },
    {
        id: 19,
        uuid: uuidv4(),
        alert: true,
        question: 'Possui alguma disfunção hepática?',
        type_question: 'Sim/Não/Não sei e Texto',
        alertText: 'Disfunção hepática',
    },
    {
        id: 20,
        uuid: uuidv4(),
        alert: true,
        question: 'Apresenta alguma disfunção renal?',
        type_question: 'Sim/Não/Não sei e Texto',
        alertText: 'Problema renal',
    },
    {
        id: 21,
        uuid: uuidv4(),
        alert: true,
        question: 'Possui alguma disfunção respiratória?',
        type_question: 'Sim/Não/Não sei e Texto',
        alertText: 'Problema respiratório',
    },
    {
        id: 22,
        uuid: uuidv4(),
        alert: true,
        question: 'Possui alguma alteração óssea?',
        type_question: 'Sim/Não/Não sei e Texto',
        alertText: 'Alteração óssea',
    },
    {
        id: 23,
        uuid: uuidv4(),
        alert: true,
        question: 'Possui alguma doença transmissível? (HIV, Hepatite, outra)',
        type_question: 'Sim/Não/Não sei e Texto',
        alertText: 'Doença transmissível',
    },
    {
        id: 24,
        uuid: uuidv4(),
        alert: true,
        question: 'Possui câncer?',
        type_question: 'Sim/Não/Não sei e Texto',
        alertText: 'Câncer',
    },
    {
        id: 25,
        uuid: uuidv4(),
        alert: true,
        question: 'Possui alguma outra doença/síndrome não mencionada?',
        type_question: 'Sim/Não/Não sei e Texto',
        alertText: 'undefined',
    },
    {
        id: 26,
        uuid: uuidv4(),
        alert: true,
        question: 'Já sofreu alguma reação alérgica ao receber anestesia?',
        type_question: 'Sim/Não/Não sei e Texto',
        alertText: 'Alérgico ao anestésico',
    },
    {
        id: 27,
        uuid: uuidv4(),
        alert: false,
        question: 'Possui mau hálito?',
        type_question: 'Sim/Não/Não sei e Texto',
    },
    {
        id: 28,
        uuid: uuidv4(),
        alert: false,
        question: 'Possui azia, má digestão, refluxo, úlcera ou gastrite?',
        type_question: 'Sim/Não/Não Sei',
    },
    {
        id: 29,
        uuid: uuidv4(),
        alert: false,
        question: 'Apresenta sangramento a escovação?',
        type_question: 'Sim/Não/Não Sei',
    },
    {
        id: 30,
        uuid: uuidv4(),
        alert: false,
        question: 'Sente seus dentes moles?',
        type_question: 'Sim/Não/Não Sei',
    },
    {
        id: 31,
        uuid: uuidv4(),
        alert: false,
        question: 'Fumante?',
        type_question: 'Sim/Não/Não sei e Texto',
    },
    {
        id: 32,
        uuid: uuidv4(),
        alert: false,
        question: 'Range os dentes?',
        type_question: 'Sim/Não/Não sei e Texto',
    },
    {
        id: 33,
        uuid: uuidv4(),
        alert: false,
        question: 'Tem o hábito de roer unha ou morder objetos (lápis, caneta, etc.)?',
        type_question: 'Sim/Não/Não Sei',
    },
    {
        id: 34,
        uuid: uuidv4(),
        alert: false,
        question: 'Tem hábito de tomar café ou refrigerantes?',
        type_question: 'Sim/Não/Não sei e Texto',
    },
    {
        id: 35,
        uuid: uuidv4(),
        alert: false,
        question: 'Ingere bebidas alcoólicas?',
        type_question: 'Sim/Não/Não sei e Texto',
    },
    {
        id: 36,
        uuid: uuidv4(),
        alert: false,
        question: 'Possui depressão?',
        type_question: 'Sim/Não/Não Sei',
    },
    {
        id: 37,
        uuid: uuidv4(),
        alert: false,
        question: 'Está ou esteve em tratamento psicológico?',
        type_question: 'Sim/Não/Não Sei',
    },
    {
        id: 38,
        uuid: uuidv4(),
        alert: false,
        question: 'Escuta algum estalido ao abrir a boca?',
        type_question: 'Sim/Não/Não Sei',
    },
    {
        id: 39,
        uuid: uuidv4(),
        alert: false,
        question: 'Tem dificuldade de abrir a boca?',
        type_question: 'Sim/Não/Não Sei',
    },
    {
        id: 40,
        uuid: uuidv4(),
        alert: false,
        question: 'Sente dores no ouvido, cabeça, face, nuca ou pescoço?',
        type_question: 'Sim/Não/Não Sei',
    },
    {
        id: 41,
        uuid: uuidv4(),
        alert: false,
        question: 'Possui algum antecedente de febre reumática?',
        type_question: 'Sim/Não/Não Sei',
    },
    {
        id: 42,
        uuid: uuidv4(),
        alert: true,
        question: 'Possui algum antecedente de endocardite bacteriana?',
        type_question: 'Sim/Não/Não Sei',
        alertText: 'Antecedente de endocardite bacteriana',
    },
    {
        id: 43,
        uuid: uuidv4(),
        alert: false,
        question: 'Já se submeteu à Periodontia (tratamento gengival)?',
        type_question: 'Sim/Não/Não sei e Texto',
    },
    {
        id: 44,
        uuid: uuidv4(),
        alert: false,
        question: 'Já se submeteu à Ortodontia (aparelhos e correção)?',
        type_question: 'Sim/Não/Não sei e Texto',
    },
    {
        id: 45,
        uuid: uuidv4(),
        alert: false,
        question: 'Já se submeteu à Cirurgia Oral (exodontia, freio labial, etc.)?',
        type_question: 'Sim/Não/Não sei e Texto',
    },
    {
        id: 46,
        uuid: uuidv4(),
        alert: false,
        question: 'Já se submeteu à Endodontia (tratamento de canal)?',
        type_question: 'Sim/Não/Não sei e Texto',
    },
    {
        id: 47,
        uuid: uuidv4(),
        alert: false,
        question: 'Já se submeteu à Profilaxia / Prevenção (limpeza, flúor, selante oclusal, etc.)?',
        type_question: 'Sim/Não/Não Sei',
    },
    {
        id: 48,
        uuid: uuidv4(),
        alert: false,
        question: 'Seus dentes são sensíveis a mudança de temperatura ou a alimentos doces?',
        type_question: 'Sim/Não/Não Sei',
    },
    {
        id: 49,
        uuid: uuidv4(),
        alert: false,
        question: 'Apresenta alguma alteração de língua, lábio e/ou palato?',
        type_question: 'Sim/Não/Não sei e Texto',
    },
    {
        id: 50,
        uuid: uuidv4(),
        alert: true,
        question: 'Está grávida?',
        type_question: 'Sim/Não/Não sei e Texto',
        alertText: 'Grávida',
    },
    {
        id: 51,
        uuid: uuidv4(),
        alert: false,
        question: 'Está amamentando?',
        type_question: 'Sim/Não/Não Sei',
    },
    {
        id: 52,
        uuid: uuidv4(),
        alert: false,
        question: 'Toma anticoncepcional?',
        type_question: 'Sim/Não/Não sei e Texto',
    },
    {
        id: 53,
        uuid: uuidv4(),
        alert: false,
        question: 'Realizou pré-natal?',
        type_question: 'Sim/Não/Não Sei',
    },
    {
        id: 54,
        uuid: uuidv4(),
        alert: false,
        question: 'A criança nasceu com parto normal ou cesariana?',
        type_question: 'Somente Texto',
    },
    {
        id: 55,
        uuid: uuidv4(),
        alert: false,
        question: 'Peso ao nascer?',
        type_question: 'Somente Texto',
    },
    {
        id: 56,
        uuid: uuidv4(),
        alert: false,
        question: 'Realiza(ou) aleitamento materno?',
        type_question: 'Sim/Não/Não Sei',
    },
    {
        id: 57,
        uuid: uuidv4(),
        alert: false,
        question: 'Realiza(ou) o uso de mamadeira ou chupeta?',
        type_question: 'Sim/Não/Não sei e Texto',
    },
    {
        id: 58,
        uuid: uuidv4(),
        alert: false,
        question: 'Realiza(ou) sucção de dedo ou lábio?',
        type_question: 'Sim/Não/Não sei e Texto',
    },
    {
        id: 59,
        uuid: uuidv4(),
        alert: false,
        question: 'Qual o perfil? (côncavo, convexo ou reto)',
        type_question: 'Somente Texto',
    },
    {
        id: 60,
        uuid: uuidv4(),
        alert: false,
        question: 'Qual a sobremordida? (aumentada, normal ou aberta)',
        type_question: 'Somente Texto',
    },
    {
        id: 61,
        uuid: uuidv4(),
        alert: false,
        question: 'Qual o trespasse horizontal? (aumentado, normal ou negativo)',
        type_question: 'Somente Texto',
    },
    {
        id: 62,
        uuid: uuidv4(),
        alert: false,
        question: 'Possui mordida cruzada?',
        type_question: 'Sim/Não/Não sei e Texto',
    },
    {
        id: 63,
        uuid: uuidv4(),
        alert: false,
        question: 'Possui desvio de linha média?',
        type_question: 'Sim/Não/Não sei e Texto',
    },
    {
        id: 64,
        uuid: uuidv4(),
        alert: false,
        question: 'O canino direito apresenta relação de: classe I, classe II, classe III ou topo?',
        type_question: 'Somente Texto',
    },
    {
        id: 65,
        uuid: uuidv4(),
        alert: false,
        question: 'O canino esquerdo apresenta relação de: classe I, classe II, classe III ou topo?',
        type_question: 'Somente Texto',
    },
    {
        id: 66,
        uuid: uuidv4(),
        alert: false,
        question: 'Qual a classe do paciente? (Classe I, II ou III)',
        type_question: 'Somente Texto',
    },
    {
        id: 67,
        uuid: uuidv4(),
        alert: false,
        question: 'Qual a pressão arterial do paciente?',
        type_question: 'Somente Texto',
    },
    {
        id: 68,
        uuid: uuidv4(),
        alert: false,
        question: 'Qual frequência respiratória por minuto do paciente?',
        type_question: 'Somente Texto',
    },
    {
        id: 69,
        uuid: uuidv4(),
        alert: false,
        question: 'Qual a frequência cardíaca por minuto(bpm) do paciente?',
        type_question: 'Somente Texto',
    },
    {
        id: 70,
        uuid: uuidv4(),
        alert: false,
        question: 'O paciente possui alguma alteração ganglionar?',
        type_question: 'Sim/Não/Não sei e Texto',
    },
    {
        id: 71,
        uuid: uuidv4(),
        alert: false,
        question: 'Apresenta outra alteração na face não mencionada?',
        type_question: 'Sim/Não/Não sei e Texto',
    },
    {
        id: 72,
        uuid: uuidv4(),
        alert: false,
        question: 'Usa drogas?',
        type_question: 'Sim/Não/Não sei e Texto',
    },
    {
        id: 73,
        uuid: uuidv4(),
        alert: false,
        question: 'Come muitos doces, balas entre outros?',
        type_question: 'Sim/Não/Não sei e Texto',
    },
    {
        id: 74,
        uuid: uuidv4(),
        alert: false,
        question: 'Sente alguma dor nos dentes ou na boca?',
        type_question: 'Sim/Não/Não sei e Texto',
    },
    {
        id: 75,
        uuid: uuidv4(),
        alert: false,
        question: 'Possui o hábito de tabagismo, alcoolismo ou uso de drogas?',
        type_question: 'Sim/Não/Não Sei',
    },
    {
        id: 76,
        uuid: uuidv4(),
        alert: false,
        question: 'Você está com febre ou teve febre nos últimos 14 dias?',
        type_question: 'Sim/Não/Não Sei',
    },
    {
        id: 77,
        uuid: uuidv4(),
        alert: false,
        question: 'Você teve alguma experiência de início de problemas respiratórios, como tosse ou dificuldade de respirar nos últimos 14 dias?',
        type_question: 'Sim/Não/Não Sei',
    },
    {
        id: 78,
        uuid: uuidv4(),
        alert: false,
        question: 'Você teve algum tipo de contato com alguma pessoa confirmadamente infectada pelo 2019-nCoV nos últimos 14 dias?',
        type_question: 'Sim/Não/Não Sei',
    },
    {
        id: 79,
        uuid: uuidv4(),
        alert: false,
        question: 'Você teve contato com um grande número de pessoas diferentes no últimos 14 dias?',
        type_question: 'Sim/Não/Não Sei',
    },
    {
        id: 80,
        uuid: uuidv4(),
        alert: false,
        question: 'Você está usando máscara, álcool em gel ou outras medidas de biossegurança no seu dia a dia?',
        type_question: 'Sim/Não/Não Sei',
    },
    {
        id: 81,
        uuid: uuidv4(),
        alert: false,
        question: 'Já se submeteu à Cirurgia?',
        type_question: 'Sim/Não/Não sei e Texto',
    },
    {
        id: 82,
        uuid: uuidv4(),
        alert: false,
        question: 'Realizou cirurgia bariátrica?',
        type_question: 'Sim/Não/Não sei e Texto',
    },
    {
        id: 83,
        uuid: uuidv4(),
        alert: false,
        question: 'Tem muita exposição solar?',
        type_question: 'Sim/Não/Não sei e Texto',
    },
    {
        id: 84,
        uuid: uuidv4(),
        alert: false,
        question: 'Apresenta manchas na pele?',
        type_question: 'Sim/Não/Não sei e Texto',
    },
    {
        id: 85,
        uuid: uuidv4(),
        alert: false,
        question: 'Apresenta flacidez na pele?',
        type_question: 'Sim/Não/Não sei e Texto',
    },
    {
        id: 86,
        uuid: uuidv4(),
        alert: false,
        question: 'Apresenta lesões de acne ativas?',
        type_question: 'Sim/Não/Não sei e Texto',
    },
    {
        id: 87,
        uuid: uuidv4(),
        alert: false,
        question: 'Já realizou algum tratamento estético facial ou de pele?',
        type_question: 'Sim/Não/Não sei e Texto',
    },
    {
        id: 88,
        uuid: uuidv4(),
        alert: false,
        question: 'Já se submeteu a tratamentos com preenchedores não reabsorvíveis (PMMA)?',
        type_question: 'Sim/Não/Não Sei',
    },
    {
        id: 89,
        uuid: uuidv4(),
        alert: false,
        question: 'Fez uso de ácido ou algum peeling químico?',
        type_question: 'Sim/Não/Não sei e Texto',
    },
    {
        id: 90,
        uuid: uuidv4(),
        alert: false,
        question: 'Usa filtro solar regularmente?',
        type_question: 'Sim/Não/Não Sei',
    },
    {
        id: 91,
        uuid: uuidv4(),
        alert: false,
        question: 'Faz uso de produtos de cuidado diário da pele?',
        type_question: 'Sim/Não/Não sei e Texto',
    },
    {
        id: 92,
        uuid: uuidv4(),
        alert: false,
        question: 'O que você sente quando se olha no espelho?',
        type_question: 'Somente Texto',
    },
    {
        id: 93,
        uuid: uuidv4(),
        alert: false,
        question: 'O que você gostaria de melhorar no seu rosto?',
        type_question: 'Somente Texto',
    },
    {
        id: 94,
        uuid: uuidv4(),
        alert: false,
        question: 'Você prefere resultados mais rápidos e mais caros ou mais lentos e mais baratos?',
        type_question: 'Somente Texto',
    },
];
