import React from 'react';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { Box, Button, Typography } from '@mui/material';

const creditCardFlags = require('../../../../assets/images/credit_card_flags.png');

const NoCreditCardRegistered: React.FC<{onRegister: () => void}> = ({onRegister}) => {
    return (
        <Box
            sx={{
                width: '100%',
                height: '500px',
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingY: '30px'
            }}
        >
            <CreditCardIcon
                sx={{
                    color: '#d5d5d5',
                    fontSize: '100px'
                }}
            />
            <Typography
                fontFamily={'Lato'}
                color={'#505050'}
                fontWeight={'bold'}
                fontSize={'25px'}
            >Sem cartão de crédito cadastrado</Typography>
            <Typography
                sx={{
                    width: '620px',
                    color: '#747f8c',
                    textAlign: 'center'
                }}
            >
                O cartão cadastrado poderá ser usado para pagamentos 
                das mensalidades e compras na nossa loja (pacotes de mensagens 
                e pacotes de assinatura eletrônica).
            </Typography>
            <Typography color={'#747f8c'}>Bandeiras aceitas:</Typography>
            <Box
                component={'img'}
                src={creditCardFlags}
                alt={'bandeiras disponiveis'}
            />
            <Button
                onClick={onRegister}
                sx={{
                    textTransform: 'none',
                    color: 'white',
                    backgroundColor: '#006af5',
                    '&:hover': {
                        backgroundColor: 'rgba(0, 106, 245, 0.7)',
                    },
                    height: '30px',
                    width: '140px',
                }}
            >Cadastrar Cartão</Button>
        </Box>
    )
}

export default NoCreditCardRegistered;