import { Box, Button, Menu, MenuItem, Modal, styled, Typography } from '@mui/material';
import React, { useRef } from 'react';
import { IModalProps } from '../../../types/modals';
import { toast } from 'react-toastify';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckIcon from '@mui/icons-material/Check';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const MOCKED_PROFILE_IMAGE = require('../../../assets/images/mulher_landing_page.png');

const MOCKED_USERS: IPatientData[] = [
    {
        name: "John Doe",
        phone: "(123) 456-7890",
        email: "john.doe@example.com",
    },
    {
        name: "Jane Smith",
        phone: "(987) 654-3210",
        email: "jane.smith@example.com",
        profileImage: MOCKED_PROFILE_IMAGE,
    },
    {
        name: "Alex Johnson",
        phone: "(555) 123-4567",
        email: "alex.johnson@example.com",
    },
    {
        name: "Emily Davis",
        phone: "(111) 222-3333",
        email: "emily.davis@example.com",
        profileImage: MOCKED_PROFILE_IMAGE,
    },
    {
        name: "Michael Wilson",
        phone: "(444) 555-6666",
        email: "michael.wilson@example.com",
        profileImage: MOCKED_PROFILE_IMAGE,
    },
    {
        name: "Sophia Brown",
        phone: "(777) 888-9999",
        email: "sophia.brown@example.com",
    },
    {
        name: "William Taylor",
        phone: "(222) 333-4444",
        email: "william.taylor@example.com",
    },
    {
        name: "Olivia Anderson",
        phone: "(666) 777-8888",
        email: "olivia.anderson@example.com",
    },
    {
        name: "James Martinez",
        phone: "(999) 000-1111",
        email: "james.martinez@example.com",
    },
    {
        name: "Ava Thompson",
        phone: "(444) 555-6666",
        email: "ava.thompson@example.com",
    },
    {
        name: "Noah Clark",
        phone: "(888) 999-0000",
        email: "noah.clark@example.com",
    },
    {
        name: "Isabella Rodriguez",
        phone: "(222) 333-4444",
        email: "isabella.rodriguez@example.com",
    },
    {
        name: "Liam Lewis",
        phone: "(555) 666-7777",
        email: "liam.lewis@example.com",
    },
    {
        name: "Mia Hall",
        phone: "(999) 000-1111",
        email: "mia.hall@example.com",
    },
    {
        name: "Benjamin Young",
        phone: "(333) 444-5555",
        email: "benjamin.young@example.com",
    },
];

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

interface IPossibleFilters {
    inactive: boolean;
    dead: boolean;
    cirurgy: boolean;
    hof: boolean;
    ortho: boolean;
    prothesis: boolean;
}

interface IPatientData {
    name: string;
    phone: string;
    email: string;
    profileImage?: string;
}

const RegisteredPatientsModal: React.FC<IModalProps> = ({
    open,
    setOpen,
    onClose
}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [menuOpen, setMenuOpen] = React.useState<boolean>(false);
    const fileInputRef = useRef(null)
    const handleCloseMenu = () => {
        setAnchorEl(null);
        setMenuOpen(false);
    };
    const [patientFilters, setPatientFilters] = React.useState<IPossibleFilters>({
        inactive: false,
        dead: false,
        cirurgy: false,
        hof: false,
        ortho: false,
        prothesis: false,
    });
    const [patientsList, setPatientsList] = React.useState<IPatientData[]>(MOCKED_USERS);
    const handleFileChange = (event) => {// Este método é chamado ao anexar um arquivo
        const file = event.target.files[0];
        if (file) {
            console.log('Arquivo selecionado:', file);
            // Aqui é onde voce deve mexer com o arquivo. Enviando pra servidor ou algo assim
        }
    };

    return (
        <Modal
            open={open}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box 
                sx={{
                    width: '1300px',
                    height: '750px',
                    backgroundColor: 'white',
                    padding: '35px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    '@media (max-width: 1300px)': {
                        width: '100vw',
                        overflowY: 'auto'
                    }
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        '@media (max-width: 700px)': {
                            justifyContent: 'center'            
                        }
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '25px',
                            fontFamily: 'Lato',
                            fontWeight: 'bold',
                            minWidth: '300px',
                            '@media (max-width: 700px)': {
                                fontSize: '22px',
                                marginBottom: '10px'
                                
                            }
                        }}
                    >Total de pacientes cadastrados</Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '280px',
                            alignItems: 'center',
                        }}
                    >
                        <Button
                            sx={{
                                backgroundColor: '#006af5',
                                color: 'white',
                                width: '50px',
                                height: '45px',
                                minWidth: '10px',
                                '@media (max-width: 764px)': {
                                    width: '30px',
                                    height: '30px',
                                }
                            }}
                        >
                            <SearchIcon
                                sx={{
                                    fontSize: '30px',
                                    '@media (max-width: 764px)': {
                                        fontSize: '18px'
                                    }
                                }}
                            />
                        </Button>
                        <Box
                            sx={{
                                width: '50px',
                                height: '45px',
                                backgroundColor: '#03659a',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: 'white',
                                cursor: 'pointer',
                                '@media (max-width: 764px)': {
                                    width: '30px',
                                    height: '30px',
                                }
                            }}
                        >
                            <MenuIcon
                                aria-controls={menuOpen ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={menuOpen ? true : false}
                                sx={{
                                    fontSize: '30px',
                                    '@media (max-width: 764px)': {
                                        fontSize: '18px'
                                    }
                                }}
                                onClick={(e) => {
                                    setAnchorEl(e.currentTarget as any);
                                    setMenuOpen(!menuOpen);
                                }}
                            />
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={menuOpen}
                                onClose={handleCloseMenu}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-icon', // Ajuste para corresponder ao ID do ícone
                                }}
                            >
                                <MenuItem
                                    onClick={() => {
                                        setPatientFilters({
                                            ...patientFilters,
                                            inactive: !patientFilters.inactive
                                        });
                                    }}
                                    selected={patientFilters.inactive}
                                >
                                    {   patientFilters.inactive ? (
                                            <CheckIcon
                                                sx={{ color: '#006af5', fontSize: '18px' }}
                                            />
                                        ) : (
                                            <CheckBoxOutlineBlankIcon
                                                sx={{ color: '#006af5', fontSize: '18px' }}
                                            />
                                        )
                                    }
                                    <Typography
                                        sx={{marginLeft: '5px'}}
                                    >Inativo</Typography>
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        setPatientFilters({
                                            ...patientFilters,
                                            dead: !patientFilters.dead
                                        });
                                    }}
                                    selected={patientFilters.dead}
                                >
                                    {   patientFilters.dead ? (
                                            <CheckIcon
                                                sx={{ color: '#006af5', fontSize: '18px' }}
                                            />
                                        ) : (
                                            <CheckBoxOutlineBlankIcon
                                                sx={{ color: '#006af5', fontSize: '18px' }}
                                            />
                                        )
                                    }
                                    <Typography
                                        sx={{marginLeft: '5px'}}
                                    >Falecido</Typography>
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        setPatientFilters({
                                            ...patientFilters,
                                            cirurgy: !patientFilters.cirurgy
                                        });
                                    }}
                                    selected={patientFilters.cirurgy}
                                >
                                    {   patientFilters.cirurgy ? (
                                            <CheckIcon
                                                sx={{ color: '#006af5', fontSize: '18px' }}
                                            />
                                        ) : (
                                            <CheckBoxOutlineBlankIcon
                                                sx={{ color: '#006af5', fontSize: '18px' }}
                                            />
                                        )
                                    }
                                    <Typography
                                        sx={{marginLeft: '5px'}}
                                    >Cirurgia</Typography>
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        setPatientFilters({
                                            ...patientFilters,
                                            hof: !patientFilters.hof
                                        });
                                    }}
                                    selected={patientFilters.hof}
                                >
                                    {   patientFilters.hof ? (
                                            <CheckIcon
                                                sx={{ color: '#006af5', fontSize: '18px' }}
                                            />
                                        ) : (
                                            <CheckBoxOutlineBlankIcon
                                                sx={{ color: '#006af5', fontSize: '18px' }}
                                            />
                                        )
                                    }
                                    <Typography
                                        sx={{marginLeft: '5px'}}
                                    >HOF</Typography>
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        setPatientFilters({
                                            ...patientFilters,
                                            ortho: !patientFilters.ortho
                                        });
                                    }}
                                    selected={patientFilters.ortho}
                                >
                                    {   patientFilters.ortho ? (
                                            <CheckIcon
                                                sx={{ color: '#006af5', fontSize: '18px' }}
                                            />
                                        ) : (
                                            <CheckBoxOutlineBlankIcon
                                                sx={{ color: '#006af5', fontSize: '18px' }}
                                            />
                                        )
                                    }
                                    <Typography
                                        sx={{marginLeft: '5px'}}
                                    >Ortopedia</Typography>
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        setPatientFilters({
                                            ...patientFilters,
                                            prothesis: !patientFilters.prothesis
                                        });
                                    }}
                                    selected={patientFilters.prothesis}
                                >
                                    {   patientFilters.prothesis ? (
                                            <CheckIcon
                                                sx={{ color: '#006af5', fontSize: '18px' }}
                                            />
                                        ) : (
                                            <CheckBoxOutlineBlankIcon
                                                sx={{ color: '#006af5', fontSize: '18px' }}
                                            />
                                        )
                                    }
                                    <Typography
                                        sx={{marginLeft: '5px'}}
                                    >Prótese</Typography>
                                </MenuItem>
                            </Menu>
                        </Box>
                        <Button
                            sx={{
                                textTransform: 'none',
                                color: '#005fdb',
                                fontFamily: 'Lato',
                                fontWeight: 'bold',
                                backgroundColor: '#e0e2e4'
                            }}
                            onClick={() => {
                                fileInputRef.current!.click()
                            }}
                        >
                            Exportar Arquivo
                            <VisuallyHiddenInput
                                type="file"
                                ref={fileInputRef}
                                onChange={handleFileChange}
                                
                            />
                        </Button>
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        height: '550px',
                        overflowY: 'auto',
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            height: '3px',
                            backgroundColor: '#f0f0f0'
                        }}
                    />
                    {   patientsList.map((patient, index) => (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                marginY: '15px'
                            }}
                        >
                            <Box
                                sx={{display: 'flex', alignItems: 'center'}}
                            >
                                {   patient.profileImage ? (
                                    <Box
                                        component={'img'}
                                        sx={{
                                            width: '60px',
                                            height: '60px',
                                            borderRadius: '50%',
                                            "@media (max-width: 606px)": {
                                                width: '40px',
                                                height: '40px',
                                            }
                                        }}
                                        src={patient.profileImage}
                                        alt={'profile'}
                                    />
                                ) : (
                                    <Box
                                        sx={{
                                            width: '60px',
                                            height: '60px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            backgroundColor: '#02457a',
                                            borderRadius: '50%',
                                            "@media (max-width: 606px)": {
                                                width: '40px',
                                                minWidth: '40px',
                                                height: '40px',
                                                minHeight: '40px',
                                            }
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                color: 'white',
                                                fontSize: '30px',
                                                fontFamily: 'Lato',
                                                fontWeight: 'bold',
                                                "@media (max-width: 606px)": {
                                                    fontSize: '20px'
                                                }
                                            }}
                                        >{patient.name[0].toUpperCase()}</Typography>
                                    </Box>
                                )}
                                <Box sx={{marginLeft: '20px'}}>
                                    <Typography
                                        sx={{
                                            fontSize: '20px',
                                            fontFamily: 'Lato',
                                            fontWeight: 'bold',
                                            color: '#77828f',
                                            "@media (max-width: 606px)": {
                                                fontSize: '15px'
                                            }
                                        }}
                                    >{patient.name}</Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-start',
                                            flexWrap: 'wrap',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '15px',
                                                fontFamily: 'Lato',
                                                color: '#77828f',
                                                minWidth: '130px',
                                                "@media (max-width: 606px)": {
                                                    fontSize: '12px'
                                                }
                                            }}
                                        >{patient.phone}</Typography>
                                        <Typography
                                            sx={{
                                                fontSize: '15px',
                                                fontFamily: 'Lato',
                                                marginLeft: '10px',
                                                color: '#77828f',
                                                minWidth: '130px',
                                                "@media (max-width: 606px)": {
                                                    fontSize: '12px'
                                                }
                                            }}
                                        >{patient.email}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    backgroundColor: '#d5f8d6',
                                    width: '130px',
                                    height: '40px',
                                    paddingX: '10px',
                                    borderRadius: '7px',
                                    cursor: 'pointer',
                                    "@media (max-width: 606px)": {
                                        width: '110px',
                                        height: '35px'
                                    },
                                    "@media (max-width: 514px)": {
                                        width: '35px',
                                        borderRadius: '50%',
                                        justifyContent: 'center'
                                    }
                                }}
                                onClick={() => toast.error('Não implementado')}
                            >
                                <Typography
                                    sx={{
                                        color: '#356434',
                                        fontWeight: 'bold',
                                        "@media (max-width: 606px)": {
                                            fontSize: '13px'
                                        },
                                        "@media (max-width: 514px)": {
                                            display: 'none'
                                        }
                                    }}
                                >Conversar</Typography>
                                <WhatsAppIcon
                                    sx={{
                                        color: '#356434',
                                        "@media (max-width: 606px)": {
                                            fontSize: '17px'
                                        }
                                    }}
                                />
                            </Box>
                        </Box>
                    ))}
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        width: '100%',
                    }}
                >
                    <Box>
                        <Button
                            sx={{
                                color: '#ca150c',
                                textTransform: 'none'
                            }}
                            onClick={() => setOpen && setOpen(false)}
                        >
                            Fechar
                        </Button>
                        <Button
                            sx={{
                                textTransform: 'none',
                                color: 'white',
                                width: '80px',
                                backgroundColor: '#006af5',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 106, 245, 0.7)',
                                },
                            }}
                            onClick={() => toast.error('Não implementado')}
                        >
                            Salvar
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

export default RegisteredPatientsModal;