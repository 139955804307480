import { Box, Button, Checkbox, IconButton, InputAdornment, Modal, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import axios from 'axios';
import { configBackendConnection, endpoints } from '../../../components/utilsBackend.ts';

const trolleyIcon = require('../../../assets/icons/trolley.png');
const cottageIcon = require('../../../assets/icons/cottage.png');

interface IModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    onClose: () => void;
}

const AddStorageItem: React.FC<IModalProps & { onProductAdded: () => void }> = ({ open, setOpen, onClose, onProductAdded }) => {
    const [registerType, setRegisterType] = useState<'' | 'not-registered' | 'registered'>('');
    const [showProductForm, setShowProductForm] = useState<boolean>(false);
    const [productsList, setProductsList] = useState<{ id: number; name: string; quantity: number; ideal_quantity: number; }[]>([]);
    const [userId, setUserId] = useState<number>(1);

    const cancelOperation = () => {
        setRegisterType('');
        setOpen && setOpen(false);
        setShowProductForm(false);
        setProductsList([]);
    };

    const handleAddNewProduct = async () => {
        try {
            const payload = {
                id: 1,
                user: userId,
                products: productsList.map(product => ({
                    id: product.id,
                    name: product.name,
                    quantity: product.quantity,
                    ideal_quantity: product.ideal_quantity
                }))
            };

            const response = await axios.post(`${configBackendConnection.baseURL}/${endpoints.stocksAPI}`, payload, {
                headers: configBackendConnection.headersDefault
            });

            if (response.status === 201) {
                toast.success('Produtos adicionados com sucesso');
                cancelOperation();
                onProductAdded();
            }
        } catch (error) {
            toast.error('Erro ao adicionar os produtos.');
        }
    };

    const handlePatchProduct = async (productId: number, updatedProduct: { name: string; quantity: number; ideal_quantity: number }) => {
        try {
            await axios.patch(`${configBackendConnection.baseURL}/${endpoints.productsAPI}${productId}/`, {
                name: updatedProduct.name,
                quantity: updatedProduct.quantity,
                ideal_quantity: updatedProduct.ideal_quantity
            }, {
                headers: configBackendConnection.headersDefault
            });

            toast.success('Produto atualizado com sucesso');
        } catch (error) {
            toast.error('Erro ao atualizar o produto.');
        }
    };

    const handleUpdateProducts = async () => {
        try {
            for (const product of productsList) {
                await handlePatchProduct(product.id, {
                    name: product.name,
                    quantity: product.quantity,
                    ideal_quantity: product.ideal_quantity
                });
            }
            toast.success('Estoque atualizado com sucesso.');
            cancelOperation();
            onProductAdded();
        } catch (error) {
            toast.error('Erro ao atualizar o estoque.');
        }
    };
    
    const continueOperation = () => {
        if (!showProductForm) {
            if (registerType === '') {
                toast.error('Selecione uma opção para continuar');
            } else {
                setShowProductForm(true);
            }
        } else {
            if (registerType === 'not-registered') {
                handleAddNewProduct();
            } else if (registerType === 'registered') {
                handleUpdateProducts();
            } else {
                toast.warn('Operação não implementada ainda');
            }
        }
    };

    useEffect(() => {
        if (registerType === 'registered' && showProductForm) {
            const fetchProducts = async () => {
                try {
                    const response = await axios.get(`${configBackendConnection.baseURL}/${endpoints.stocksAPI}`, {
                        headers: configBackendConnection.headersDefault
                    });
                    const productsFromAPI = response.data.flatMap(stock => stock.products);
                    setProductsList(productsFromAPI);
                } catch (error) {
                    toast.error('Erro ao buscar os produtos.');
                }
            };

            fetchProducts();
        } else if (registerType === 'not-registered' && showProductForm) {
            setProductsList([{
                id: 0,
                name: '',
                quantity: 0,
                ideal_quantity: 0,
            }]);
        }
    }, [showProductForm, registerType]);

    return (
        <Modal
            open={open}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box 
                sx={{
                    width: { xs: '90%', sm: '85%', md: '70%', lg: '60%' },
                    maxHeight: '90vh',
                    backgroundColor: 'white',
                    padding: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    overflowY: 'auto',
                    borderRadius: '8px',
                }}
            >
                <Typography
                    sx={{
                        fontSize: '22px',
                        fontWeight: 'bold',
                        color: '#505050'
                    }}
                >
                    Você está fazendo uma nova entrada no estoque
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        gap: '20px',
                        '@media (max-width: 670px)': {
                            flexDirection: 'column',
                        }
                    }}
                >
                    <Box
                        sx={{
                            width: { xs: '100%', sm: '48%' },
                            height: '340px',
                            padding: '10px',
                            border: '1px solid #eeeeee',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            cursor: 'pointer'
                        }}
                        onClick={() => {
                            if (registerType === 'not-registered') {
                                setRegisterType('');
                            } else {
                                setRegisterType('not-registered');
                            }
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Box sx={{ width: '50px' }} />
                            <img
                                src={trolleyIcon}
                                alt="Trolley Icon"
                                style={{ marginTop: '20px', width: '65px' }}
                            />
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start',
                                    height: '100%',
                                    width: '50px'
                                }}
                            >
                                <Checkbox
                                    checked={registerType === 'not-registered'}
                                    icon={<RadioButtonUncheckedIcon />}
                                    checkedIcon={<RadioButtonCheckedIcon />}
                                />
                            </Box>
                        </Box>
                        <Typography
                            sx={{
                                color: '#03659a',
                                fontSize: 28,
                                fontFamily: 'Lato',
                                maxWidth: '80%',
                                textAlign: 'center',
                                fontWeight: 'bold',
                                wordWrap: 'break-word',
                                overflowWrap: 'break-word',
                            }}
                        >
                            É um produto que eu não tenho cadastrado
                        </Typography>
                        <Typography
                            sx={{
                                color: '#77828f',
                                maxWidth: '90%',
                                textAlign: 'center',
                                fontSize: 20,
                                marginTop: '15px',
                                wordWrap: 'break-word',
                                overflowWrap: 'break-word',
                            }}
                        >
                            Eu ainda não tenho esse produto cadastrado no
                            meu estoque
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            width: { xs: '100%', sm: '48%' },
                            height: '340px',
                            padding: '10px',
                            border: '1px solid #eeeeee',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            if (registerType === 'registered') {
                                setRegisterType('');
                            } else {
                                setRegisterType('registered');
                            }
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Box sx={{ width: '50px' }} />
                            <img
                                src={cottageIcon}
                                alt="Cottage Icon"
                                style={{ marginTop: '20px', width: '65px' }}
                            />
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start',
                                    height: '100%',
                                    width: '50px'
                                }}
                            >
                                <Checkbox
                                    checked={registerType === 'registered'}
                                    icon={<RadioButtonUncheckedIcon />}
                                    checkedIcon={<RadioButtonCheckedIcon />}
                                />
                            </Box>
                        </Box>
                        <Typography
                            sx={{
                                color: '#03659a',
                                fontSize: 28,
                                fontFamily: 'Lato',
                                maxWidth: '80%',
                                textAlign: 'center',
                                fontWeight: 'bold',
                                wordWrap: 'break-word',
                                overflowWrap: 'break-word',
                            }}
                        >
                            É um produto que eu já tenho cadastrado
                        </Typography>
                        <Typography
                            sx={{
                                color: '#77828f',
                                maxWidth: '90%',
                                textAlign: 'center',
                                fontSize: 20,
                                marginTop: '15px',
                                wordWrap: 'break-word',
                                overflowWrap: 'break-word',
                            }}
                        >
                            Eu preciso aumentar a quantidade desse produto no
                            meu estoque
                        </Typography>
                    </Box>
                </Box>
                {showProductForm && (
                    <>
                        <Typography
                            sx={{
                                fontSize: '22px',
                                fontWeight: 'bold',
                                color: '#505050'
                            }}
                        >
                            {registerType === 'registered' ? 'Atualizando produtos no estoque' : 'Adicionando produto ao estoque'}
                        </Typography>
                        <Box
                            sx={{
                                maxHeight: '50vh',
                                overflowY: 'auto',
                                mt: 2,
                                '@media (max-width: 670px)': {
                                    minHeight: '200px',
                                    marginBottom: '20px',
                                }
                            }}
                        >
                            {productsList.map((product, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        p: 1,
                                        flexWrap: 'wrap',
                                    }}
                                >
                                    <Box sx={{ width: { xs: '100%', md: '60%' }, minWidth: '200px' }}>
                                        <Typography>Nome do Produto <span style={{ color: 'red' }}>*</span></Typography>
                                        <TextField
                                            variant="outlined"
                                            value={product.name}
                                            onChange={(e) => {
                                                setProductsList(
                                                    productsList.map((item) =>
                                                        item.id === product.id ?
                                                            { ...item, name: e.target.value } :
                                                            item
                                                    )
                                                );
                                            }}
                                            onBlur={() => handlePatchProduct(product.id, product)}
                                            fullWidth
                                            size="small"
                                            sx={{ mr: 1 }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="clear"
                                                            onClick={() => {
                                                                setProductsList(
                                                                    productsList.map((item) =>
                                                                        item.id === product.id ?
                                                                            { ...item, name: '' } :
                                                                            item
                                                                    )
                                                                );
                                                            }}
                                                        >
                                                            <HighlightOffIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Box>
                                    <Box sx={{ width: { xs: '48%', md: '20%' }, minWidth: '100px' }}>
                                        <Typography>Quantidade <span style={{ color: 'red' }}>*</span></Typography>
                                        <TextField
                                            variant="outlined"
                                            value={product.quantity}
                                            onChange={(e) => {
                                                setProductsList(
                                                    productsList.map((item) =>
                                                        item.id === product.id ?
                                                            { ...item, quantity: Number(e.target.value) } :
                                                            item
                                                    )
                                                );
                                            }}
                                            onBlur={() => handlePatchProduct(product.id, product)}
                                            size="small"
                                            fullWidth
                                            sx={{ mr: 1 }}
                                        />
                                    </Box>
                                    <Box sx={{ width: { xs: '48%', md: '20%' }, minWidth: '90px' }}>
                                        <Typography>Qtd ideal <span style={{ color: 'red' }}>*</span></Typography>
                                        <TextField
                                            variant="outlined"
                                            value={product.ideal_quantity}
                                            onChange={(e) => {
                                                setProductsList(
                                                    productsList.map((item) =>
                                                        item.id === product.id ?
                                                            { ...item, ideal_quantity: Number(e.target.value) } :
                                                            item
                                                    )
                                                );
                                            }}
                                            onBlur={() => handlePatchProduct(product.id, product)}
                                            size="small"
                                            fullWidth
                                            sx={{ mr: 1 }}
                                        />
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    </>
                )}
                {showProductForm && registerType === 'not-registered' && (
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            '@media (max-width: 670px)': {
                                marginBottom: '20px',
                            }
                        }}
                        onClick={() => {
                            setProductsList([...productsList, {
                                id: productsList.length,
                                name: '',
                                quantity: 0,
                                ideal_quantity: 0,
                            }]);
                        }}
                    >
                        <Button>Adicionar outro produto</Button>
                    </Box>
                )}
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <Button
                        sx={{
                            color: '#262626',
                            textTransform: 'none',
                        }}
                        onClick={cancelOperation}
                    >Cancelar</Button>
                    <Button
                        sx={{
                            backgroundColor: registerType ? '#006af5' : '#a6a6a6',
                            '&:hover': {
                                backgroundColor: registerType ?
                                    'rgba(0, 106, 245, 0.7)' :
                                    'rgba(166, 166, 166, 0.7)',
                            },
                            color: 'white',
                            textTransform: 'none',
                            width: showProductForm ? '160px' : '90px',
                            marginTop: '10px'
                        }}
                        onClick={continueOperation}
                    >{
                        showProductForm && registerType === 'not-registered' ?
                            'Adicionar ao estoque' :
                            (showProductForm && registerType === 'registered' ?
                                'Atualizar estoque' : 'Continuar'
                            )
                    }</Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default AddStorageItem;
