import React from 'react'
import { Box, Button, Grid, Typography } from "@mui/material"
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SendToMobileIcon from '@mui/icons-material/SendToMobile';
import ForumIcon from '@mui/icons-material/Forum';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LocalAtmRoundedIcon from '@mui/icons-material/LocalAtmRounded';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import { useNavigate } from 'react-router-dom';

const cellPhoneImage = require('../../../assets/images/cellphone_comunication.png')
const assignmentReturnIcon = require('../../../assets/icons/assignment_return.png')
const dentistryIcon = require('../../../assets/icons/dentistry.png')
const barCodeIcon = require('../../../assets/icons/barcode_scanner.png')
const electroCardioIcon = require('../../../assets/icons/ecg.png')
const localAtmIcon = require('../../../assets/icons/local_atm.png')
const happyFaceIcon = require('../../../assets/icons/sentiment_satisfied.png')

const campaignTypeIcons = {
    'late-payments': <img
        src={localAtmIcon}
        style={{
            fontSize: '35px',
        }}
    />,
    'postoperative': <img
        src={electroCardioIcon}
        style={{
            fontSize: '35px',
        }}
    />,
    'payment-due': <img
        src={barCodeIcon}
        style={{
            fontSize: '35px',
        }}
    />,
    'dental-floss': <img
        src={dentistryIcon}
        style={{
            fontSize: '35px',
        }}
    />,
    'inactive-retrieval': <img
        src={assignmentReturnIcon}
        style={{
            fontSize: '35px',
        }}
    />,
    'satisfaction': <img
        src={happyFaceIcon}
        style={{
            fontSize: '35px',
        }}
    />,
}

const campaignTypeNames = {
    'late-payments': 'Débitos em atraso',
    'postoperative': 'Pós-operatório',
    'payment-due': 'Vencimento de boleto',
    'dental-floss': 'Fio dental',
    'inactive-retrieval': 'Recuperação de inativos',
    'satisfaction': 'Satisfação',
}


const CampaignItem: React.FC<{
    campaignSender: string,
    campaignType: string,
    targets: string,
    receivedBy: number,
    viewRate: string,
    answers: number,
    isActive: boolean
}> = ({
    campaignSender,
    campaignType,
    targets,
    receivedBy,
    viewRate,
    answers,
    isActive
}) => {
    return (
        <Grid
            container
            spacing={2}
            wrap='wrap'
        >
            <Grid item xs={1} sx={{minWidth: '60px'}}>  
                <Box
                    sx={{
                        width: '50px',
                        height: '50px',
                        backgroundColor: '#f5f5f5',
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    {campaignTypeIcons[campaignType]}
                </Box>
            </Grid>
            <Grid item xs={2} sx={{minWidth: '190px'}}>  
                <Typography
                    sx={{
                        color: '#5b5b5b'
                    }}
                >
                    {campaignTypeNames[campaignType]}
                </Typography>
                <Typography
                    sx={{
                        color: '#a5a5a5',
                        fontSize: '13px'
                    }}
                >
                    {targets}
                </Typography>
            </Grid>
            <Grid item xs={2} sx={{minWidth: '140px'}}>  
                <Typography
                    sx={{
                        color: '#5b5b5b'
                    }}
                >
                    {receivedBy}
                </Typography>
                <Typography
                    sx={{
                        color: '#a5a5a5',
                        fontSize: '13px'
                    }}
                >
                    Paciente atingido
                </Typography>
            </Grid>
            <Grid item xs={2} sx={{minWidth: '140px'}}>  
                <Typography
                    sx={{
                        color: '#5b5b5b'
                    }}
                >
                    {viewRate}
                </Typography>
                <Typography
                    sx={{
                        color: '#a5a5a5',
                        fontSize: '13px'
                    }}
                >
                    Taxa de visualização
                </Typography>
            </Grid>
            <Grid item xs={1} sx={{minWidth: '90px'}}>
                <Typography
                    sx={{
                        color: '#5b5b5b'
                    }}
                >
                    {answers}
                </Typography>
                <Typography
                    sx={{
                        color: '#a5a5a5',
                        fontSize: '13px'
                    }}
                >
                    Resposta
                </Typography>
            </Grid>
            <Grid item xs={1} sx={{minWidth: '130px'}}>  
                <Box
                    sx={{
                        width: '100px',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <Box
                        sx={{
                            width: '90px',
                            height: '30px',
                            borderRadius: '5px',
                            backgroundColor: '#ddf3e4',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                color:'#46b19f',
                                fontWeight: 'bold',
                                fontSize: '13px'
                            }}
                        >
                            {campaignSender}
                        </Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={2} sx={{display: 'flex', justifyContent: 'center', minWidth: '60px'}}> 
                 
                <Box
                    sx={{
                        width: '100px',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <Box
                        sx={{
                            width: isActive ? '65px' : '80px',
                            height: '30px',
                            borderRadius: '20px',
                            border: `1px solid ${isActive ? '#50ae54' : 'red'}`,
                            display: 'flex',
                            padding: '0px 7px',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <FiberManualRecordRoundedIcon
                            sx={{
                                color: isActive ? '#50ae54' : 'red',
                                marginTop: '-3px',
                                fontSize: '15px'
                            }}
                        />
                        <Typography>
                            {isActive ? 'Ativa' : 'Inativa'}
                        </Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={1} sx={{minWidth: '60px'}}>   
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <MoreVertRoundedIcon
                        sx={{
                            color: '#77828f',
                        }}
                    />
                </Box>
            </Grid>
        </Grid>
    )
}

const mockedCampaignItems = [
    {
        campaignSender: 'WhatsApp',
        campaignType: 'late-payments',
        viewRate: '100%',
        answers: 0,
        targets: '30 dias - Todos os pacientes',
        receivedBy: 1,
        isActive: true,
    },{
        campaignSender: 'SMS',
        campaignType: 'postoperative',
        viewRate: '100%',
        answers: 0,
        targets: '30 dias - Todos os pacientes',
        receivedBy: 1,
        isActive: false,
    },{
        campaignSender: 'WhatsApp',
        campaignType: 'payment-due',
        viewRate: '37%',
        answers: 2,
        targets: '6 meses - Personalizado',
        receivedBy: 12,
        isActive: true,
    },{
        campaignSender: 'SMS',
        campaignType: 'dental-floss',
        viewRate: '20%',
        answers: 4,
        targets: '30 dias - Todos os pacientes',
        receivedBy: 15,
        isActive: true,
    },{
        campaignSender: 'SMS',
        campaignType: 'inactive-retrieval',
        viewRate: '100%',
        answers: 0,
        targets: '30 dias - Todos os pacientes',
        receivedBy: 1,
        isActive: false,
    },{
        campaignSender: 'SMS',
        campaignType: 'satisfaction',
        viewRate: '100%',
        answers: 0,
        targets: '30 dias - Todos os pacientes',
        receivedBy: 1,
        isActive: false,
    }
]

const ComunicationMainScreen: React.FC = () => {
    const navigate = useNavigate();

    return (
        <>
            <Box
                sx={{
                    backgroundColor: 'white',
                    width: '98%',
                    height: '160px',
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    borderRadius: '5px',
                    boxShadow: '0px 0px 5px 0px #c0c7d0',
                    flexWrap: 'wrap',
                    '@media (max-width: 772px)': {
                        height: '300px',
                    },
                    '@media (max-width: 516px)': {
                        height: '450px',
                    }

                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        height: '90px',
                        width: '370px',
                        '@media (max-width: 1174px)': {
                            width: '250px'
                        }
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '40%',
                            height: '100%',
                            backgroundImage: 'linear-gradient(180deg, #104161 0%, #29828b 100%)',
                            borderRadius: '5px',
                            color: 'white',
                        }}
                    >
                        <WhatsAppIcon sx={{fontSize: '50px'}}/>
                    </Box>
                    <Box
                        sx={{
                            width: '60%',
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '15px',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            boxShadow: '0px 0px 5px 0px #c0c7d0',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '15px',
                                fontWeight: 'bold',
                                fontFamily: 'Lato',
                                color: '#03659A',
                            }}
                        >
                            98 Saldos de WhatsApp
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: '13px',
                                fontFamily: 'Lato',
                                color: '#0094E5',
                            }}
                        >
                            ver extrato
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        height: '90px',
                        width: '370px',
                        '@media (max-width: 1174px)': {
                            width: '250px'
                        }
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '40%',
                            height: '100%',
                            backgroundImage: 'linear-gradient(180deg, #104161 0%, #29828b 100%)',
                            borderRadius: '5px',
                            color: 'white',
                        }}
                    >
                        <SendToMobileIcon sx={{fontSize: '50px'}}/>
                    </Box>
                    <Box
                        sx={{
                            width: '60%',
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '15px',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            boxShadow: '0px 0px 5px 0px #c0c7d0',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '15px',
                                fontWeight: 'bold',
                                fontFamily: 'Lato',
                                color: '#03659A',
                            }}
                        >
                            0 Saldos de SMS
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: '13px',
                                fontFamily: 'Lato',
                                color: '#0094E5',
                            }}
                        >
                            ver extrato
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        height: '90px',
                        width: '370px',
                        '@media (max-width: 1174px)': {
                            width: '250px'
                        }
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '40%',
                            height: '100%',
                            backgroundImage: 'linear-gradient(180deg, #104161 0%, #29828b 100%)',
                            borderRadius: '5px',
                            color: 'white',
                        }}
                    >
                        <ForumIcon sx={{fontSize: '50px'}}/>
                    </Box>
                    <Box
                        sx={{
                            width: '60%',
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '15px',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            boxShadow: '0px 0px 5px 0px #c0c7d0',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '15px',
                                fontWeight: 'bold',
                                fontFamily: 'Lato',
                                color: '#03659A',
                            }}
                        >
                            0 Mensagens não lidas
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: '13px',
                                fontFamily: 'Lato',
                                color: '#0094E5',
                            }}
                        >
                            ver mensagens
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    width: 'calc(100vw - 250px)',
                    height: 'calc(100vh - 160px - 100px)',
                    overflow: 'auto',
                    '@media (max-width: 1412px)': {
                        width: '100vw'
                    }
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '70px',
                        padding: '0 70px',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '18px',
                            color: '#737e8b',
                            fontFamily: 'Inter'
                        }}
                    >Exibir</Typography>
                    <Button
                        sx={{
                            textTransform: 'none',
                            color: '#737e8b',
                            border: '1px solid #e9eaec',
                            borderRadius: '10px',
                            marginLeft: '20px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '200px',
                        }}
                    >
                        <Typography sx={{fontSize: '14px'}}>Campanhas Ativas</Typography>
                        <KeyboardArrowDownIcon />
                    </Button>
                </Box>
                <Box sx={{padding: '0px 40px'}}>
                    {mockedCampaignItems.length > 0 &&
                        <Box
                            sx={{
                                width: '100%',
                                overflowY: 'auto',
                                height: '400px',
                                backgroundColor: 'white',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                padding: '20px 50px',
                                boxShadow: '0px 0px 5px 0px #c0c7d0',
                            }}
                        >
                            {mockedCampaignItems.map((campaignItem, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        width: '100%',
                                    }}
                                >
                                    <CampaignItem
                                        campaignSender={campaignItem.campaignSender}
                                        campaignType={campaignItem.campaignType}
                                        viewRate={campaignItem.viewRate}
                                        answers={campaignItem.answers}
                                        targets={campaignItem.targets}
                                        receivedBy={campaignItem.receivedBy}
                                        isActive={campaignItem.isActive}
                                    />
                                    {index !== mockedCampaignItems.length - 1 &&
                                        <Box
                                            sx={{
                                                width: '100%',
                                                height: '1px',
                                                backgroundColor: '#e4e4e4',
                                                margin: '15px 0px'
                                            }}
                                        />
                                    }
                                </Box>
                            ))}
                        </Box>
                    }
                    <Box
                        sx={{
                            width: '100%',
                            height: '400px',
                            maxHeight: '400px',
                            backgroundColor: 'white',
                            marginTop: '50px',
                            marginBottom: '50px',
                            display: 'flex',
                            boxShadow: '0px 0px 5px 0px #c0c7d0',
                            '@media (max-width: 1048px)': {
                                alignItems: 'flex-end',
                                height: '300px',
                            },
                            '@media (max-width: 898px)': {
                                flexDirection: 'column',
                                alignItems: 'center',
                                minHeight: '600px',
                            }
                        }}
                    >
                        <Box
                            component="img"
                            src={cellPhoneImage}
                            alt={'imagem celular'}
                            sx={{
                                '@media (max-width: 1048px)': {
                                    width: '300px',
                                    height: '270px'
                                }
                            }}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                padding: '40px 30px',
                                minWidth: '380px',
                                '@media (max-width: 510px)': {
                                    minWidth: '200px',
                                }
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Inter',
                                    fontSize: '20px',
                                    color: '#77828f',
                                    '@media (max-width: 510px)': {
                                        fontSize: '16px'
                                    }
                                }}
                            >
                                Envio de SMS ou WhatsApp
                            </Typography>
                            <Typography
                                sx={{
                                    color: '#03659a',
                                    fontSize: '29px',
                                    fontWeight: 'bold',
                                    fontFamily: 'Lato',
                                    '@media (max-width: 510px)': {
                                        fontSize: '23px'
                                    }
                                }}
                            >
                                Aumente o poder de comunicação com seus pacientes!
                            </Typography>
                            <Typography
                                sx={{
                                    color: '#77828f',
                                    fontSize: '20px',
                                    '@media (max-width: 510px)': {
                                        fontSize: '16px'
                                    }
                                }}
                            >
                                Além de confirmar consultas automaticamente
                                você também pode enviar campanhas de
                                marketing para seus pacientes!
                            </Typography>
                            <Button
                                sx={{
                                    textTransform: 'none',
                                    backgroundColor: '#006af5',
                                    '&:hover': {
                                        backgroundColor: 'rgba(0, 106, 245, 0.7)',
                                    },
                                    color: 'white',
                                    fontSize: '17px',
                                    width: '200px'
                                }}
                                onClick={() => {
                                    navigate('/comunication?campaign=true')
                                }}
                            >
                                Nova Campanha
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default ComunicationMainScreen;