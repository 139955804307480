import React from 'react';
import { Box, Typography } from '@mui/material';

interface ISidebarOption {
    selected: boolean;
    onSelect: () => void;
    title: string;
    icon: any;
}

const SidebarOption: React.FC<ISidebarOption> = ({selected, onSelect, title, icon}) => {

    return (
        <Box
            onClick={onSelect}
            sx={{
                width: '100%',
                height: '50px',
                color: 'white',
                backgroundColor: selected ? '#0e3e5f' : 'transparent',
                borderRadius: '0 10px 10px 0',
                display: 'flex',
                alignItems: 'center',
                marginTop: '20px',
                cursor: 'pointer',
            }}
        >
            <Box 
                sx={{
                    width: '15px',
                    height: '100%',
                    marginRight: '10px',
                    backgroundColor: selected ? '#d6e7ee' : 'transparent',
                    borderRadius: '0 10px 10px 0',
                }}
            />
            {icon}
            <Typography
                sx={{
                    marginLeft: '10px',
                }}
            >{title}</Typography>
        </Box>
    );
};

export default SidebarOption;