import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import axios from 'axios';
import { useSettings } from '../../../hooks/settings.tsx';
import ClinicInfo from '../ClinicInfo/index.tsx';
import ClinicHours from '../ClinicHours/index.tsx';
import FiscalInfo from '../FiscalInfo/index.tsx';
import ContactInfo from '../ContactInfo/index.tsx';
import AddressInfo from '../AddressInfo/index.tsx';
import { configBackendConnection, endpoints } from '../../../components/utilsBackend.ts';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { sanitizePhone, sanitizeCellPhone, sanitizeCNPJ } from '../../../utils/formatValues.tsx';
import { IClinicData } from '../../../types/settings.tsx';

const initialClinicData: IClinicData = {
    data_clinic: {
        clinic_name: '',
        clinic_registration: '',
        name_used_in_communications: '',
        responsible_clinic: ''
    },
    opening_hour: {
        init_hours: '',
        final_hours: '',
        gmt: ''
    },
    fiscal_name: '',
    information_clinic: {
        email: '',
        telephone: '',
        phone: '',
        cep: '',
        street: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: '',
        state: ''
    }
};

interface IErrorResponse {
    [key: string]: any;
}

const ClinicSettings: React.FC = () => {
    const { clinicData, setClinicData } = useSettings();
    const [formHasErrors, setFormHasErrors] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [cellphoneError, setCellphoneError] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [communicationNameError, setCommunicationNameError] = useState(false);
    const [responsibleError, setResponsibleError] = useState(false);
    const [cnpjError, setCnpjError] = useState(false);
    const [initHoursError, setInitHoursError] = useState(false);
    const [finalHoursError, setFinalHoursError] = useState(false);
    const [gmtError, setGmtError] = useState(false);

    const validateForm = () => {
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const emailIsValid = emailPattern.test(clinicData.information_clinic.email);
        const phoneIsValid = clinicData.information_clinic.telephone.length >= 14;
        const cellphoneIsValid = clinicData.information_clinic.phone.length >= 15;

        setEmailError(!emailIsValid);
        setPhoneError(!phoneIsValid);
        setCellphoneError(!cellphoneIsValid);

        const nameIsValid = clinicData.data_clinic.clinic_name.trim() !== '';
        const communicationNameIsValid = clinicData.data_clinic.name_used_in_communications.trim() !== '';
        const responsibleIsValid = clinicData.data_clinic.responsible_clinic.trim() !== '';
        const cnpjIsValid = clinicData.data_clinic.clinic_registration.trim() !== '';
        const initHoursIsValid = clinicData.opening_hour.init_hours.trim() !== '';
        const finalHoursIsValid = clinicData.opening_hour.final_hours.trim() !== '';
        const gmtIsValid = clinicData.opening_hour.gmt.trim() !== '';

        setNameError(!nameIsValid);
        setCommunicationNameError(!communicationNameIsValid);
        setResponsibleError(!responsibleIsValid);
        setCnpjError(!cnpjIsValid);
        setInitHoursError(!initHoursIsValid);
        setFinalHoursError(!finalHoursIsValid);
        setGmtError(!gmtIsValid);

        setFormHasErrors(
            !emailIsValid || 
            !phoneIsValid || 
            !cellphoneIsValid || 
            !nameIsValid || 
            !communicationNameIsValid || 
            !responsibleIsValid || 
            !cnpjIsValid || 
            !initHoursIsValid || 
            !finalHoursIsValid || 
            !gmtIsValid
        );

        return emailIsValid && phoneIsValid && cellphoneIsValid && nameIsValid && communicationNameIsValid && responsibleIsValid && cnpjIsValid && initHoursIsValid && finalHoursIsValid && gmtIsValid;
    };

    const handleSave = async () => {
        if (!validateForm()) {
            toast.error("Por favor, corrija os erros antes de salvar.");
            return;
        }

        const sanitizedData = {
            ...clinicData,
            data_clinic: {
                ...clinicData.data_clinic,
                clinic_registration: sanitizeCNPJ(clinicData.data_clinic.clinic_registration)
            },
            information_clinic: {
                ...clinicData.information_clinic,
                telephone: sanitizePhone(clinicData.information_clinic.telephone),
                phone: sanitizeCellPhone(clinicData.information_clinic.phone)
            }
        };

        console.log(JSON.stringify(sanitizedData, null, 2));

        const token = configBackendConnection.headersDefault.Authorization;
        console.log('Token utilizado na requisição:', token);

        try {
            const response = await axios.post(
                `${configBackendConnection.baseURL}/${endpoints.generalInformationClinicAPI}`,
                sanitizedData,
                {
                    headers: configBackendConnection.headersDefault
                }
            );
            toast.success("Clinica criada com sucesso!");
            setClinicData(initialClinicData);
        } catch (error) {
            if (error.response && error.response.data) {
                const errorResponse: IErrorResponse = error.response.data;
                const errorMessages: string[] = [];

                for (const key in errorResponse) {
                    if (errorResponse[key]) {
                        const messages = errorResponse[key];
                        if (Array.isArray(messages)) {
                            messages.forEach((message: string) => {
                                errorMessages.push(message);
                            });
                        } else if (typeof messages === 'object') {
                            for (const subKey in messages) {
                                if (Array.isArray(messages[subKey])) {
                                    messages[subKey].forEach((message: string) => {
                                        errorMessages.push(message);
                                    });
                                }
                            }
                        }
                    }
                }

                toast.error(errorMessages.join(' '));
            } else {
                toast.error("Não foi possível salvar os dados da clínica.");
            }
        }
    };

    return (
        <Box
            sx={{
                margin: '30px 0px',
                width: '94%',
                minHeight: '600px',
                padding: '40px',
                backgroundColor: 'white',
                borderRadius: '15px',
                boxShadow: '0px 0px 5px 0px #c0c7d0',
                overflow: 'auto',
                '@media (max-width: 926px)': {
                    overflowY: 'auto'
                },
            }}
        >
            <Box>
                <Typography
                    sx={{
                        fontSize: '25px',
                        fontWeight: 'bold',
                        fontFamily: 'Lato'
                    }}
                >Dados da Clínica</Typography>
                <ClinicInfo 
                    clinicData={clinicData} 
                    setClinicData={setClinicData} 
                    nameError={nameError} 
                    communicationNameError={communicationNameError} 
                    responsibleError={responsibleError} 
                    cnpjError={cnpjError} 
                    setCnpjError={setCnpjError}
                />
            </Box>
            <ClinicHours clinicData={clinicData} setClinicData={setClinicData} initHoursError={initHoursError} finalHoursError={finalHoursError} gmtError={gmtError} />
            <FiscalInfo clinicData={clinicData} setClinicData={setClinicData} />
            <ContactInfo clinicData={clinicData} setClinicData={setClinicData} emailError={emailError} phoneError={phoneError} cellphoneError={cellphoneError} />
            <AddressInfo clinicData={clinicData} setClinicData={setClinicData} />
            <Box
                sx={{
                    width: '100%',
                    height: '2px',
                    backgroundColor: '#f0f0f0',
                    margin: '25px 0px'
                }}
            />
            <Button
                sx={{
                    textTransform: 'none',
                    backgroundColor: '#006af5',
                    '&:hover': {
                        backgroundColor: 'rgba(0, 106, 245, 0.7)',
                    },
                    color: 'white',
                    borderRadius: '5px'
                }}
                onClick={handleSave}
            >
                Salvar
            </Button>
        </Box>
    );
};

export default ClinicSettings;
