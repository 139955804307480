import { Box, Button, Checkbox, FormControl, InputLabel, MenuItem, Modal, Select, TextField, Typography, styled } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { IModalProps } from '../../../types/modals';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';

const MOCKED_FUNDS = [
    'Caixa 1',
    'Caixa 2',
    'Caixa 3',
    'Caixa 4',
    'Caixa 5',
    'Caixa 6',
    'Caixa 7',
];

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const AddOutcomeModal: React.FC<IModalProps> = ({
    open,
    onClose
}) => {
    const fileInputRef = useRef(null)
    const [form, setForm] = useState<{
        description: string,
        value: number | undefined,
        dueDate: any,
        category: string,
        fund: string,
        observation: string,
        repeat: boolean,
        alreadyPaid: boolean,
        receipt: string,
    }>({
        description: '',
        value: undefined,
        dueDate: '',
        category: '',
        fund: '',
        observation: '',
        repeat: false,
        alreadyPaid: false,
        receipt: '',
    });
    
    const ResetForm = () => {
        setForm({
            description: '',
            value: undefined,
            dueDate: '',
            category: '',
            fund: '',
            observation: '',
            repeat: false,
            alreadyPaid: false,
            receipt: '',
        })
    }

    const handleFileChange = (event) => {// Este método é chamado ao anexar um arquivo
        const file = event.target.files[0];
        if (file) {
            console.log('Arquivo selecionado:', file);
            // Aqui é onde voce deve mexer com o arquivo. Enviando pra servidor ou algo assim
            setForm({...form, receipt: file})
        }
    };

    useEffect(() => {
        console.log(form)
    },[form])

    return (
        <Modal
            open={open}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box
                sx={{
                    width: '1100px',
                    height: '690px',
                    backgroundColor: 'white',
                    padding: '35px',
                }}
            >
                <Typography
                    sx={{
                        fontSize: '22px',
                        fontWeight: 'bold',
                        fontFamily: 'Lato',
                    }}
                >Nova Despesa</Typography>
                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: '30px',
                    }}
                >
                    <Box sx={{width: '30%'}}>
                        <Typography
                            sx={{
                                fontSize: '18px',
                                fontFamily: 'Lato',
                                color: '#7c7c8d'
                            }}
                        >Descrição<span style={{color: 'red'}}>*</span></Typography>
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder='Digite aqui...'
                            autoComplete='off'
                            value={form.description}
                            onChange={(e) => {
                                setForm({...form, description: e.target.value});
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: '#e9eaec',
                                        borderRadius: '10px'
                                    },
                                },
                            }}
                        />
                    </Box>
                    <Box sx={{width: '30%'}}>
                        <Typography
                            sx={{
                                fontSize: '18px',
                                fontFamily: 'Lato',
                                color: '#7c7c8d'
                            }}
                        >Valor<span style={{color: 'red'}}>*</span></Typography>
                        <TextField
                            fullWidth
                            type='number'
                            variant="outlined"
                            placeholder='Digite aqui...'
                            autoComplete='off'
                            value={form.value}
                            onChange={(e) => {
                                setForm({...form, value: parseFloat(e.target.value)});
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: '#e9eaec',
                                        borderRadius: '10px'
                                    },
                                },
                            }}
                        />
                    </Box>
                    <Box sx={{width: '30%'}}>
                        <Typography
                            sx={{
                                fontSize: '18px',
                                fontFamily: 'Lato',
                                color: '#7c7c8d'
                            }}
                        >Data de vencimento<span style={{color: 'red'}}>*</span></Typography>
                        <DatePicker
                            label="Selecione..."
                            sx={{
                                width: '100%'
                            }}
                            onChange={(date) => {
                                setForm({...form, dueDate: date.format('YYYY-MM-DD')});
                            }}
                        />
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: '15px'
                    }}
                >
                    <Box sx={{width: '49%'}}>
                        <Typography
                            sx={{
                                fontSize: '18px',
                                fontFamily: 'Lato',
                                color: '#7c7c8d'
                            }}
                        >Categoria<span style={{color: 'red'}}>*</span></Typography>
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder='Digite aqui...'
                            autoComplete='off'
                            value={form.category}
                            onChange={(e) => {
                                setForm({...form, category: e.target.value});
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: '#e9eaec',
                                        borderRadius: '10px'
                                    },
                                },
                            }}
                        />
                    </Box>
                    <Box sx={{width: '49%'}}>
                        <Typography
                            sx={{
                                fontSize: '18px',
                                fontFamily: 'Lato',
                                color: '#7c7c8d'
                            }}
                        >Caixa<span style={{color: 'red'}}>*</span></Typography>
                        <FormControl fullWidth>
                            <Select
                                sx={{
                                    height: '52px',
                                    color: 'black',
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#e9eaec',
                                    },
                                    border: '10px',
                                }}
                                fullWidth
                                value={form.fund}
                                onChange={(e) => {
                                    setForm({...form, fund: e.target.value as string});
                                }}
                            >
                                {MOCKED_FUNDS.map(type =>
                                    <MenuItem key={type} value={type}>
                                        {type}
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
                <Typography
                    sx={{
                        color: '#7c7c8d',
                        marginTop: '15px'
                    }}
                >
                    Observações
                </Typography>
                <TextField
                    fullWidth
                    variant="outlined"
                    multiline
                    rows={4}
                    placeholder='Digite aqui...'
                    value={form.observation}
                    onChange={(e) => {
                        if(e.target.value.length <= 500){
                            setForm({ ...form, observation: e.target.value })
                        }
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: '#e9eaec',
                                borderRadius: '10px'
                            },
                        },
                    }}
                />
                <Box 
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: '5px'
                    }}
                >
                    <Typography
                        sx={{
                            color: form.observation.length >= 500 ? 'red' : '#7c7c8d'
                        }}
                    >
                        {form.observation.length} / 500
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Checkbox
                        checked={form.repeat}
                        onChange={(e) => {
                            setForm({...form, repeat: e.target.checked});
                        }}
                    />
                    <Typography>Essa despesa se repete</Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Checkbox
                        checked={form.alreadyPaid}
                        onChange={(e) => {
                            setForm({...form, alreadyPaid: e.target.checked});
                        }}
                    />
                    <Typography>Essa despesa já foi paga</Typography>
                </Box>
                <Box sx={{display: 'flex', width: '100%', justifyContent: 'center'}}>
                    <Button
                        onClick={() => {
                            fileInputRef.current!.click()
                        }}
                    >
                        <Typography
                            sx={{
                                color: '#005fdb',
                                fontSize: '20px'
                            }}
                        >Anexar Comprovante</Typography>
                        <VisuallyHiddenInput
                            type="file"
                            ref={fileInputRef}
                            onChange={handleFileChange}
                            
                        />
                    </Button>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: '20px',
                        alignItems: 'center'
                    }}
                >
                    <Button
                        sx={{
                            color: '#ca150c',
                            textTransform: 'none',
                            marginRight: '15px'
                        }}
                        onClick={() => {
                            onClose && onClose();
                            ResetForm();
                        }}
                    >Fechar</Button>
                    <Button
                        sx={{
                            color: 'white',
                            backgroundColor: '#006af5',
                            '&:hover': {
                                backgroundColor: 'rgba(0, 106, 245, 0.7)',
                            },
                            textTransform: 'none',
                        }}
                        onClick={() => {
                            alert('não implementado')
                        }}
                    >Salvar</Button>
                </Box>
            </Box>
        </Modal>
    );
}

export default AddOutcomeModal;