import {
    Box,
    Button,
    Modal,
    TextField,
    Typography,
    FormControl,
    FormHelperText,
    InputAdornment,
    IconButton } from "@mui/material";
import React, { useState } from "react";
import { IModalProps } from "../../../types/modals";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IChangePassword } from "../../../types/AccountSettings/forms";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";




const ChangePassword: React.FC<IModalProps> = ({
    open,
    setOpen,
    onClose
}) => {

    const navigate = useNavigate();

    const [form, setForm] = useState<IChangePassword> ({
        password: '',
        newPassword: '',
        confirmPassword: ''
    }) 

    const [errors, setErrors] = useState<IChangePassword> ({
        password: '',
        newPassword: '',
        confirmPassword: '' 
    }) 

    const validateForm = () => {
        const newErrors = { ...errors };
        let isValid = true;
    
        if (!form.password) {
            newErrors.password = 'Campo obrigatório. Favor, preencha para continuar.';
            isValid = false;
        } else {
            newErrors.password = '';
        }

        if (!form.newPassword) {
            newErrors.newPassword = 'Campo obrigatório. Favor, preencha para continuar.';
            isValid = false;
        } else if (form.newPassword.length < 8 || form.newPassword.length > 16) {
            newErrors.newPassword = 'A senha deve ter entre 8 e 16 caracteres.';
            isValid = false;
        } else if (!/[a-z]/.test(form.newPassword)) {
            newErrors.newPassword = 'A senha deve conter pelo menos uma letra minúscula.';
            isValid = false;
        } else if (!/[A-Z]/.test(form.newPassword)) {
            newErrors.newPassword = 'A senha deve conter pelo menos uma letra maiúscula.';
            isValid = false;
        } else if (!/[0-9]/.test(form.newPassword)) {
            newErrors.newPassword = 'A senha deve conter pelo menos um numeral.';
            isValid = false;
        } else if (!/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(form.newPassword)) {
            newErrors.newPassword = 'A senha deve conter pelo menos um caractere especial.';
            isValid = false;
        } else {
            newErrors.newPassword = '';
        }
        
        if(!form.confirmPassword) {
            newErrors.confirmPassword = 'Campo obrigatório. Favor, preencha para continuar.';
            isValid = false;
        } else if(form.confirmPassword !== form.newPassword){
            newErrors.confirmPassword = 'As senhas não coincidem. Favor, verifique e tente novamente.';
            isValid = false;
        } else {
            newErrors.password = '';
        }

        setErrors(newErrors);
        return isValid;
    }

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const [showNewPassword, setShowNewPassword] = useState(false);

    const handleClickShowNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    };

    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleSubmit = () => {
        if(validateForm()) {
            toast.success(
                <>
                    Senha alterada com sucesso.
                    <br/>
                    Faça login novamente para acessar.
                </>
            )
            navigate('/logout')
        } else {
            toast.error(
                <>
                    Erro ao atualizar as informações.
                    <br/>
                    Verifique os campos obrigatórios e tente novamente.
                </>
            )
        }
    }

    return (
        <>
            <Modal
                open={open}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Box
                    sx={{
                        padding: '2rem',
                        backgroundColor: '#FEFEFE',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '1rem'
                    }}
                >
                    <Typography 
                        variant="h1"
                        sx={{
                            color: 'rgba(4, 44, 90, 1)',
                            fontSize: '2rem',
                            fontWeight: 'bold'
                        }}
                    >
                        Alterar Senha
                    </Typography>
                    
                    <FormControl error={!!errors.password}>
                        <Typography 
                            sx={{
                                color:'rgba(124, 124, 141, 1)',
                                fontWeight: '600'
                            }}
                        >
                            Senha Atual
                            <span style={{color: '#FF0000'}}>*</span>
                        </Typography>
                        <TextField
                            error={!!errors.password}
                            size="small"
                            type={showPassword ? 'text' : 'password'}
                            value={form.password}
                            onChange={(e) =>
                                {setForm({...form, password: e.target.value})
                                    setErrors({...errors, password: ''})
                                }}
                            sx={{
                                width: '25rem',
                                fontSize: '1rem',
                                '& .MuiInputBase-input': {
                                    height: '23px',
                                },
                                '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderRadius: '10px',
                                },
                                },
                            }}
                            InputProps={{
                                endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    sx={{
                                        padding: '.25rem'
                                    }}
                                    >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                                ),
                            }}
                        />
                      <FormHelperText>{errors.password}</FormHelperText>
                    </FormControl>
                    <FormControl error={!!errors.newPassword}>
                        <Typography 
                            sx={{
                                color:'rgba(124, 124, 141, 1)',
                                fontWeight: '600'
                            }}
                        >
                            Cadastre Nova Senha
                            <span style={{color: '#FF0000'}}>*</span>
                        </Typography>
                        <TextField
                            error={!!errors.newPassword}
                            size="small"
                            type={showNewPassword ? 'text' : 'password'}
                            value={form.newPassword}
                            onChange={(e) => 
                                {setForm({...form, newPassword: e.target.value})
                                    setErrors({...errors, newPassword: ''})
                            }}
                            sx={{
                                width: '25rem',
                                fontSize: '1rem',
                                '& .MuiInputBase-input': {
                                    height: '23px',
                                },
                                '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderRadius: '10px',
                                },
                                },
                            }}
                            InputProps={{
                                endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowNewPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    sx={{
                                        padding: '.25rem'
                                    }}
                                    >
                                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                                ),
                            }}
                        />
                      <FormHelperText>{errors.newPassword}</FormHelperText>
                    </FormControl>
                    <FormControl error={!!errors.confirmPassword}>
                        <Typography 
                            sx={{
                                color:'rgba(124, 124, 141, 1)',
                                fontWeight: '600'
                            }}
                        >
                            Confirme Nova Senha
                            <span style={{color: '#FF0000'}}>*</span>
                        </Typography>
                        <TextField
                            error={!!errors.confirmPassword}
                            size="small"
                            type={showConfirmPassword ? 'text' : 'password'}
                            value={form.confirmPassword}
                            onChange={(e) => 
                                {setForm({...form, confirmPassword: e.target.value})
                                    setErrors({...errors, confirmPassword: ''})
                            }}
                            sx={{
                                width: '25rem',
                                fontSize: '1rem',
                                '& .MuiInputBase-input': {
                                    height: '23px',
                                },
                                '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderRadius: '10px',
                                },
                                },
                            }}
                            InputProps={{
                                endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowConfirmPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    sx={{
                                        padding: '.25rem'
                                    }}
                                    >
                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                                ),
                            }}
                        />
                      <FormHelperText>{errors.confirmPassword}</FormHelperText>
                    </FormControl>
                    <Box
                        sx={{
                            alignSelf: 'end',
                            display: 'flex',
                            gap:'.5rem'
                        }}>
                        <Button 
                            onClick={(e) => setOpen(false)}
                            color="error"
                            sx={{
                                fontWeight: 'bolder',
                                border: '1px solid #FEFEFE',
                            }}
                        >
                            Voltar
                        </Button>
                        <Button 
                            onClick={handleSubmit}
                            sx={{
                                backgroundColor: 'rgba(0, 106, 245, 1)',
                                color: '#FFF',
                                display: 'flex',
                                alignSelf: 'end',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 80, 184, 1)'
                                }
                            }}
                        >
                            Alterar
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    )

}
export default ChangePassword;