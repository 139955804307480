import React, { ReactNode, useEffect, useState } from 'react';
import { styled } from '@mui/system';
import { Box, Button, Typography } from '@mui/material';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import Carousel from 'react-spring-3d-carousel';
import { config } from "react-spring";

const mulherLandingPage = require('../../assets/images/mulher_landing_page.png');
const homemLandingPage = require('../../assets/images/homem_landing_page.png');

const CustomNavButtonLeft = styled('div')({
    position: 'absolute',
    left: '10px',
    top: 'calc(50% - 20px)',
  });
  
  const CustomNavButtonRight = styled('div')({
    position: 'absolute',
    right: '10px',
    top: 'calc(50% - 20px)',
  });

type CarouselValues = {
    name: string;
    content: string;
    image: string;
    role: string;
};
  
const CarouselItem: React.FC<CarouselValues> = ({name, content, image, role}) => {
    return (
        <div className='info'>
                <div>
                    <svg width="40" height="25" viewBox="0 0 40 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.78049 9.09091C13.6083 9.09091 17.522 12.6523 17.522 17.0455C17.522 21.4386 13.6083 25 8.78049 25C3.9527 25 0.0390244 21.4386 0.0390244 17.0455L0 15.9091C0 7.12273 7.82736 0 17.4829 0V4.54545C14.1474 4.54545 11.0114 5.72749 8.65272 7.87379C8.19871 8.287 7.78435 8.72663 7.41058 9.18842C7.85694 9.1245 8.31438 9.09091 8.78049 9.09091ZM31.2585 9.09091C36.0862 9.09091 40 12.6523 40 17.0455C40 21.4386 36.0862 25 31.2585 25C26.4308 25 22.5171 21.4386 22.5171 17.0455L22.478 15.9091C22.478 7.12273 30.3053 0 39.961 0V4.54545C36.6255 4.54545 33.4893 5.72749 31.1308 7.87379C30.6767 8.287 30.2623 8.72663 29.8885 9.18842C30.3349 9.1245 30.7924 9.09091 31.2585 9.09091Z" fill="#02457A"/>
                    </svg>
                    <h2>Lorem Ipsum</h2>
                    <p>{content}</p>
                    <a href=''>Saiba Mais</a>
                </div>
                <div>
                    <img src={image} alt={name} />
                    <h3>{name}</h3>
                    <p>{role}</p>
                </div>
        </div>
    );
};

const carouselValues: {key: number, content: ReactNode}[] = [
    {
        key: 1,
        content: <CarouselItem
            name='Vanessa Machado'
            content='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tristique auctos, um viton aeeget massa. Felis scelerisque dolor phasellus'
            image={mulherLandingPage}
            role='Dentista'
        />
    },
    {
        key: 2,
        content: <CarouselItem
            name='Rodrigo Paiva'
            content='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tristique auctos, um viton aeeget massa. Felis scelerisque dolor phasellus'
            image={homemLandingPage}
            role='Podólogo'
        />
    },
    {
        key: 3,
        content: <CarouselItem
            name='Vanessa Machado'
            content='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tristique auctos, um viton aeeget massa. Felis scelerisque dolor phasellus'
            image={mulherLandingPage}
            role='Dentista'
        />
    },
];

const CarouselLanding = () => {
    const [state, setState] = useState({
        goToSlide: 0,
        offsetRadius: 2,
        showNavigation: window.innerWidth > 1100,
        enableSwipe: true,
        config: config.gentle,
        xDown: null,
        yDown: null,
    });

    useEffect(() => {
        const handleResize = () => {
            setState((prevState) => ({
                ...prevState,
                showNavigation: window.innerWidth > 1100
            }));
        };
    
        window.addEventListener("resize", handleResize);
    
        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    
    const getTouches = (evt) => {
        return (
          evt.touches || evt.originalEvent.touches
        );
    };
    
    const handleTouchStart = (evt) => {
        if (!state.enableSwipe) {
          return;
        }
    
        const firstTouch = getTouches(evt)[0];
        setState({
          ...state,
          xDown: firstTouch.clientX,
          yDown: firstTouch.clientY
        });
    };

    const handleTouchMove = (evt) => {
        if (!state.enableSwipe || (!state.xDown && !state.yDown)) {
            return;
        }
    
        let xUp = evt.touches[0].clientX;
        let yUp = evt.touches[0].clientY;
    
        let xDiff = state.xDown !== null ? state.xDown - xUp : 0;
        let yDiff = state.yDown !== null ? state.yDown - yUp : 0;
        if (Math.abs(xDiff) > Math.abs(yDiff)) {
            if (xDiff > 0) {
                /* left swipe */
                setState({
                    ...state,
                    goToSlide: state.goToSlide + 1,
                    xDown: null,
                    yDown: null
                });
            } else {
                /* right swipe */
                setState({
                    ...state,
                    goToSlide: state.goToSlide - 1,
                    xDown: null,
                    yDown: null
                });
            }
        }
    };
    
    return (
        <Box
            style={{ width: "80%", height: "500px", margin: "0 auto" }}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
        >
            <Carousel
                slides={carouselValues}       
                goToSlide={state.goToSlide}
                offsetRadius={state.offsetRadius}
                showNavigation={state.showNavigation}
                animationConfig={state.config}
          />
        </Box>
    );
};

export default CarouselLanding;