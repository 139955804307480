import { Box, Button, MenuItem, Modal, Select, Typography } from '@mui/material';
import React, { useState } from 'react';
import { IAppointmentForm, ICommitmentForm } from '../../../types/addEventModal';
import AddEventAppointmentForm from './AddEventAppoinmentForm/index.tsx';
import AddEventCommitmentForm from './AddEventCommitmentForm/index.tsx';
import { IModalProps } from '../../../types/modals.tsx';

const mockedTagOptions = ["mock1", "mock2","mock3"]

const AddEventModal: React.FC<IModalProps> = ({
    open,
    setOpen,
    onClose
}) => {
    const [appointmentChosen, setAppointmentChosen] = useState(true);
    const [appointmentForm, setAppointmentForm] = useState<IAppointmentForm>({
        patientName: '',
        duration: '',
        observation: '',
        returnal: 'Sem Retorno',
        tag: 'Selecionar um Rótulo',
        sendAutomaticAppointmentConfirmation: false,
        importantEvent: false
    });
    const [commitmentForm, setCommitmentForm] = useState<ICommitmentForm>({
        name: '',
        fullDay: false,
        startDate: '',
        startTime: '',
        endDate: '',
        endTime: '',
        repeat: false,
        alert: false,
        tag: 'Selecionar um Rótulo',
        unavailable: false,
        importantEvent: false
    });
    const getTagColor = (form: {tag: string}) => {
        return form.tag === 'Selecionar um Rótulo' ? '#797777' : 'black'
    }

    return (
        <Modal
            open={open}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box 
                sx={{
                    width: '1300px',
                    height: '750px',
                    backgroundColor: 'white',
                    padding: '35px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    '@media (max-width: 1300px)': {
                        width: '100vw',
                        overflowY: 'auto'
                    }
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                    }}
                >
                    <Button
                        sx={{
                            textTransform: 'none',
                            color: 'white',
                            backgroundColor: appointmentChosen ?
                                '#018abe' :
                                '#c1c1c1',
                            '&:hover': {
                                backgroundColor: appointmentChosen ?
                                'rgba(1, 138, 190, 0.7)' :
                                'rgba(193, 193, 193, 0.7)',
                            },
                            marginRight: '15px',
                            height: '35px',
                            width: '100px',
                        }}
                        onClick={() => setAppointmentChosen(true)}
                    >
                        <Typography sx={{fontSize: 17, fontWeight: 600}}>
                            Consulta
                        </Typography>
                    </Button>
                    <Button
                        sx={{
                            textTransform: 'none',
                            color: 'white',
                            backgroundColor: appointmentChosen ?
                                '#c1c1c1' :
                                '#018abe',
                            '&:hover': {
                                backgroundColor: appointmentChosen ?
                                'rgba(1, 138, 190, 0.7)' :
                                'rgba(193, 193, 193, 0.7)',
                            },
                            height: '35px',
                            width: '140px'
                        }}
                        onClick={() => setAppointmentChosen(false)}
                    >
                        <Typography sx={{fontSize: 17, fontWeight: 600}}>
                            Compromisso
                        </Typography>
                    </Button>
                </Box>
                {appointmentChosen ? 
                    <AddEventAppointmentForm
                        form={appointmentForm} 
                        setForm={setAppointmentForm}
                    />
                : 
                    <AddEventCommitmentForm
                        form={commitmentForm}
                        setForm={setCommitmentForm}
                    />
                }
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        rowGap: '10px'
                    }}
                >
                    <Select
                        sx={{
                            width: '300px',
                            height: '50px',
                            color: appointmentChosen ?
                                getTagColor(appointmentForm) :
                                getTagColor(commitmentForm),
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none'
                            },
                            border: '10px',
                            backgroundColor: '#e7e7e7'
                        }}
                        value={appointmentChosen ? appointmentForm.tag : commitmentForm.tag}
                        onChange={(e) => {
                            if(appointmentChosen){
                                setAppointmentForm({...appointmentForm, tag: e.target.value})
                            } else {
                                setCommitmentForm({...commitmentForm, tag: e.target.value})
                            }
                        }}
                    >
                        <MenuItem 
                            value={'Selecionar um Rótulo'}
                            sx={{
                                color: '#7c7c8d'
                            }}
                        >{'Selecionar um Rótulo'}</MenuItem>
                        {mockedTagOptions.map(tag =>
                            <MenuItem key={tag} value={tag}>
                                {tag}
                            </MenuItem>
                        )}
                    </Select>
                    <Box>
                        <Button
                            sx={{
                                color: '#ca150c',
                                textTransform: 'none'
                            }}
                            onClick={() => setOpen(false)}
                        >
                            Fechar
                        </Button>
                        <Button
                            sx={{
                                textTransform: 'none',
                                color: 'white',
                                width: '80px',
                                backgroundColor: '#006af5',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 106, 245, 0.7)',
                                },
                            }}
                            onClick={() => alert('Não implementado')}
                        >
                            Marcar
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

export default AddEventModal;
