import FlagIcon from '@mui/icons-material/Flag';
import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, IconButton } from '@mui/material';
import OutlinedFlagIcon from '@mui/icons-material/OutlinedFlag';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { configBackendConnection, endpoints } from "../../../components/utilsBackend.ts";
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddPlanModal from '../../../components/modals/AddPlanModal/index.tsx';
import { Plan } from '../../../types/Plans/PlanTypes';

const PlanComponent: React.FC<{ title: string; id: number; is_favorite: boolean; onDelete: (id: number) => void; onToggleFavorite: (id: number, is_favorite: boolean) => void; onEdit: () => void }> = ({ title, id, is_favorite, onDelete, onToggleFavorite, onEdit }) => {
  const handleDelete = () => {
    onDelete(id);
  };

  const handleToggleFavorite = () => {
    onToggleFavorite(id, !is_favorite);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px',
        borderRadius: '5px',
        backgroundColor: '#f9f9f9',
        boxShadow: '0px 0px 5px rgba(0,0,0,0.1)',
        marginBottom: '10px',
        width: '100%',
      }}
    >
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 'bold',
          color: '#333',
        }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          gap: '10px',
          marginLeft: 'auto',
          alignItems: 'center',
        }}
      >
        {is_favorite ? (
          <IconButton onClick={handleToggleFavorite}>
            <FlagIcon />
          </IconButton>
        ) : (
          <IconButton onClick={handleToggleFavorite}>
            <OutlinedFlagIcon />
          </IconButton>
        )}
        <IconButton onClick={onEdit} sx={{ color: '#006af5' }}>
          <EditOutlinedIcon />
        </IconButton>
        <IconButton onClick={handleDelete} sx={{ color: '#ff4d4d' }}>
          <DeleteOutlineOutlinedIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

const PlanSettings: React.FC = () => {
  const [plans, setPlans] = useState<Plan[]>([]);
  const [openNewPlanModal, setOpenNewPlanModal] = useState(false);
  const [modalStep, setModalStep] = useState(1);
  const [canEdit, setCanEdit] = useState(false);
  const [editPlanId, setEditPlanId] = useState<number | null>(null);
  const [newPlanForm, setNewPlanForm] = useState<{
    name: string;
    isPaydByInsurance: boolean;
    isDefaultPlan: boolean;
    selectedProcedures: {
      id: number;
    }[];
  }>({
    name: '',
    isPaydByInsurance: false,
    isDefaultPlan: false,
    selectedProcedures: []
  });

  const fetchPlans = async () => {
    try {
      const response = await axios.get<Plan[]>(`${configBackendConnection.baseURL}/${endpoints.planAPI}`, {
        headers: configBackendConnection.headersDefault
      });
      setPlans(response.data);
    } catch (error) {
      console.log("Erro ao buscar os planos:", error);
    }
  };

  useEffect(() => {
    fetchPlans();
  }, []);

  const handleDelete = async (id: number) => {
    try {
      await axios.delete(`${configBackendConnection.baseURL}/${endpoints.planAPI}${id}/`, {
        headers: configBackendConnection.headersDefault
      });
      setPlans(plans.filter(plan => plan.id !== id));
      toast.success("Plano excluído com sucesso!");
    } catch (error) {
      toast.error("Erro ao excluir o plano:");
    }
  };

  const handleToggleFavorite = async (id: number, is_favorite: boolean) => {
    try {
      await axios.patch(`${configBackendConnection.baseURL}/${endpoints.planAPI}${id}/`, { is_favorite }, {
        headers: configBackendConnection.headersDefault
      });
      setPlans(plans.map(plan => plan.id === id ? { ...plan, is_favorite } : plan));
      toast.success("Favorito atualizado com sucesso!");
    } catch (error) {
      console.error("Erro ao atualizar o favorito:", error);
    }
  };

  const handleEdit = (plan: Plan) => {
    setEditPlanId(plan.id);
    setNewPlanForm({
      name: plan.name,
      isPaydByInsurance: plan.is_payd_by_insurance,
      isDefaultPlan: plan.is_default_plan,
      selectedProcedures: plan.specialty.procedures.map(p => ({ id: p.id })),
    });
    setCanEdit(true);
    setOpenNewPlanModal(true);
  };

  const handlePlanAddedOrUpdated = () => {
    setCanEdit(false);
    setEditPlanId(null);
    fetchPlans();
  };

  return (
    <>
      <AddPlanModal
        open={openNewPlanModal}
        setOpen={setOpenNewPlanModal}
        onClose={() => {
          setOpenNewPlanModal(false);
          setCanEdit(false);
        }}
        step={modalStep}
        setStep={setModalStep}
        form={newPlanForm}
        setForm={setNewPlanForm}
        onPlanAdded={handlePlanAddedOrUpdated}
        canEdit={canEdit}
        editPlanId={editPlanId}
      />
      <Box
        sx={{
          width: '93%',
          marginTop: '30px',
          borderRadius: '10px',
          padding: '20px',
          boxShadow: '0px 0px 5px 0px #c0c7d0',
          backgroundColor: 'white',
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            sx={{
              fontSize: '20px',
              fontWeight: 'bold',
              '@media (max-width: 548px)': {
                fontSize: '15px'
              }
            }}
          >
            Planos
          </Typography>
          <Button
            sx={{
              backgroundColor: '#006af5',
              '&:hover': {
                backgroundColor: 'rgba(0, 106, 245, 0.7)',
              },
              color: 'white',
              textTransform: 'none',
              height: '35px',
            }}
            onClick={() => {
              setModalStep(1);
              setNewPlanForm({
                name: '',
                isPaydByInsurance: false,
                isDefaultPlan: false,
                selectedProcedures: []
              });
              setOpenNewPlanModal(true);
            }}
          >
            Novo Plano
          </Button>
        </Box>
        <Box
          sx={{
            width: '100%',
            height: '1px',
            backgroundColor: '#e4e4e4',
            margin: '20px 0px'
          }}
        />
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            maxHeight: '500px',
            overflowY: 'auto',
          }}
        >
          {plans.map((plan) => (
            <PlanComponent
              key={plan.id}
              id={plan.id}
              title={plan.name}
              is_favorite={plan.is_favorite}
              onDelete={handleDelete}
              onToggleFavorite={handleToggleFavorite}
              onEdit={() => handleEdit(plan)}
            />
          ))}
        </Box>
      </Box>
    </>
  );
};

export default PlanSettings;
