import React from 'react';
import { Box, Grid, TextField, Typography } from '@mui/material';
import { formatPhone, formatCellPhone } from '../../../utils/formatValues.tsx';

const ContactInfo: React.FC<{ clinicData: any, setClinicData: any, emailError: boolean, phoneError: boolean, cellphoneError: boolean }> = ({ clinicData, setClinicData, emailError, phoneError, cellphoneError }) => {

    const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const formattedValue = formatPhone(event.target.value);
        setClinicData({ 
            ...clinicData,
            information_clinic: {
                ...clinicData.information_clinic,
                telephone: formattedValue
            } 
        });
    };

    const handleCellphoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const formattedValue = formatCellPhone(event.target.value);
        setClinicData({ 
            ...clinicData,
            information_clinic: { 
                ...clinicData.information_clinic,
                phone: formattedValue 
            }
        });
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const email = event.target.value;
        setClinicData({
            ...clinicData,
            information_clinic: {
                ...clinicData.information_clinic,
                email: email
            }
        });
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const charCode = event.which ? event.which : event.keyCode;
        if (charCode < 48 || charCode > 57) {
            event.preventDefault();
        }
    };

    return (
        <Box sx={{ marginTop: '25px' }}>
            <Typography
                sx={{
                    fontSize: '25px',
                    fontWeight: 'bold',
                    fontFamily: 'Lato'
                }}
            >
                Informações da Clínica
            </Typography>
            <Grid container spacing={2} sx={{ marginTop: '25px' }}>
                <Grid item xs={12} md={4}>
                    <Typography sx={{ color: '#7c7c8d' }}>
                        Email <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        placeholder="Digite aqui..."
                        autoComplete="off"
                        type="email"
                        value={clinicData?.information_clinic?.email}
                        onChange={handleEmailChange}
                        error={emailError}
                        helperText={emailError ? "Por favor, insira um email válido." : ""}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                height: '40px',
                                '& fieldset': {
                                    borderColor: '#e9eaec',
                                    borderRadius: '10px'
                                },
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography sx={{ color: '#7c7c8d' }}>
                        Telefone <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        placeholder="Digite aqui..."
                        autoComplete="off"
                        value={clinicData?.information_clinic?.telephone}
                        onChange={handlePhoneChange}
                        onKeyPress={handleKeyPress}
                        error={phoneError}
                        helperText={phoneError ? "Número de telefone inválido. Deve conter pelo menos 10 dígitos." : ""}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                height: '40px',
                                '& fieldset': {
                                    borderColor: '#e9eaec',
                                    borderRadius: '10px'
                                },
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography sx={{ color: '#7c7c8d' }}>
                        Celular <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        placeholder="Digite aqui..."
                        autoComplete="off"
                        value={clinicData?.information_clinic?.phone}
                        onChange={handleCellphoneChange}
                        onKeyPress={handleKeyPress}
                        error={cellphoneError}
                        helperText={cellphoneError ? "Número de celular inválido. Deve conter pelo menos 11 dígitos." : ""}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                height: '40px',
                                '& fieldset': {
                                    borderColor: '#e9eaec',
                                    borderRadius: '10px'
                                },
                            },
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default ContactInfo;
