import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import BaseForIntelligenceSquare from '../../../../components/baseForIntelligenceSquare/index.tsx';
import dayjs, { Dayjs } from 'dayjs';
import { PieChart } from '@mui/x-charts/PieChart';
import { Gauge, gaugeClasses } from '@mui/x-charts';
import { IBlockableIntelligenceSquareProps } from '../../../../types/intelligence.tsx';

const topScoreIcon = require('../../../../assets/images/top_score_icon.png');

interface ITopScorer {
    name: string;
    answers: number;
}

interface IScoreData {
    totalAnswers: number;
    detractors: number;
}

const SatisfactionSquare: React.FC<IBlockableIntelligenceSquareProps> = ({
    enabled
}) => {
    const [selectedDate, setSelectedDate] = React.useState<Dayjs | null>(dayjs());
    const [topScorers, setTopScorers] = React.useState<ITopScorer[]>([
        {name: 'Fulaninho de Tal', answers: 100},
        {name: 'Fulaninho de Tal', answers: 100},
        {name: 'Fulaninho de Tal', answers: 100}
    ]);
    const [scoreData, setScoreData] = React.useState<IScoreData>({
        totalAnswers: 20,
        detractors: 6
    });
    const gradientId = 'myGradient';

    const GradientDefs = () => (
        <defs>
            <linearGradient id={gradientId} x1="0%" y1="0%" x2="100%" y2="100%">
                <stop offset="0%" stopColor="#0e3e5f" />
                <stop offset="100%" stopColor="#28808a" /> {/* Adicione a segunda cor aqui */}
            </linearGradient>
        </defs>
    );
    
    return (
        <BaseForIntelligenceSquare
            title = "Pesquisa de Satisfação"
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
        >
            <Box
                sx={{
                    minHeight: '400px',
                    width: '80%',
                    marginX: 'auto',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    position: 'relative',
                    flexWrap: 'wrap',
                    "@media (max-width: 814px)": {
                        marginY: '20px',
                    },
                    "@media (max-width: 587px)": {
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '20px',
                    }
                }}
            >
                { !enabled && (
                    <Box
                        sx={{
                            height: '100%',
                            width: '100%',
                            position: 'absolute',
                            zIndex: 2,
                            backgroundColor: 'rgba(255, 255, 255, 0.73)',
                        }}
                    />
                )}
                <Box
                    sx={{
                        width: '200px',
                    }}
                >
                    <Gauge
                        width={200}
                        height={200}
                        value={((scoreData.totalAnswers - scoreData.detractors) * 100) / scoreData.totalAnswers / 10}
                        valueMin={0}
                        valueMax={10}
                        sx={(theme) => ({
                            [`& .${gaugeClasses.valueText}`]: {
                                fontSize: 50,
                                fontWeight: 'bold',
                            },
                            [`& .${gaugeClasses.valueArc}`]: {
                                fill: `url(#${gradientId})`,
                            },
                            [`& .${gaugeClasses.referenceArc}`]: {
                                fill: theme.palette.text.disabled,
                            },
                        })}
                    ><GradientDefs /></Gauge>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <Box
                        sx={{
                            width: '170px',
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}
                        >
                            <Typography sx={{fontWeight: 'bold', fontSize: '20px'}}
                            >{scoreData.totalAnswers}</Typography>
                            <Typography
                            >Respostas</Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}
                        >
                            <Typography sx={{fontWeight: 'bold', fontSize: '20px'}}
                            >{scoreData.detractors}</Typography>
                            <Typography>Detratores</Typography>
                        </Box>
                    </Box>
                    <Button>Ver Respostas</Button>
                </Box>
                <Box>
                    <Typography
                        sx={{
                            fontSize: '20px',
                            fontFamily: 'Lato',
                        }}
                    >Melhores Pontuações</Typography>
                    <Box>
                        { topScorers.map((topScorer, index) => (
                            <Box sx={{display: 'flex', marginY: '15px'}}>
                                <img
                                    src={topScoreIcon}
                                    alt={"star"}
                                    width={"40px"}
                                />
                                <Box sx={{marginLeft: '5px'}}>
                                    <Typography>
                                        {topScorer.name}
                                    </Typography>
                                    <Typography>
                                        {topScorer.answers} respostas
                                    </Typography>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </Box>
                <Button
                    sx={{
                        fontSize: '20px',
                        width: '200px'
                    }}
                >Ver todas as notas</Button>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography
                    sx={{
                        fontSize: '20px',
                        fontFamily: 'Lato',
                        fontWeight: 'bold',
                        textAlign: 'center',
                    }}
                >
                    Ative a campanha Pesquisa de Satisfação para ter informações sobre o atendimento da
                </Typography>
                <Typography
                    sx={{
                        fontSize: '20px',
                        fontFamily: 'Lato',
                        fontWeight: 'bold',
                    }}
                >
                    clínica e dos profissionais
                </Typography>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100px',
                }}
            >
                <Button
                    sx={{
                        textTransform: 'none',
                        fontSize: '15px',
                        '&:hover': {
                            backgroundColor: '#158443',
                        },
                        backgroundColor: '#158443',
                        color: 'white'
                        
                    }}
                >Ativar Campanha</Button>
            </Box>
        </BaseForIntelligenceSquare>
    )
}

export default SatisfactionSquare;