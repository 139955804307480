import React, { useState } from 'react';
import { Box, Button, Typography } from "@mui/material";
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const MonthCalendar: React.FC<{
    chosenDate: dayjs.Dayjs;
    setChosenDate: React.Dispatch<React.SetStateAction<dayjs.Dayjs>>;
}> = ({chosenDate, setChosenDate}) => {
    const leftVector = require('../../../assets/icons/left-vector.png');
    const rightVector = require('../../../assets/icons/right-vector.png');
    const customMonths = {
        'January': 'Jan',
        'February': 'Fev',
        'March': 'Mar',
        'April': 'Abr',
        'May': 'Mai',
        'June': 'Jun',
        'July': 'Jul',
        'August': 'Ago',
        'September': 'Set',
        'October': 'Out',
        'November': 'Nov',
        'December': 'Dez'
    };
    const customWeekDays = {'Su': 'Dom', 'Mo': 'Seg', 'Tu': 'Ter', 'We': 'Qua', 'Th': 'Qui', 'Fr': 'Sex', 'Sa': 'Sáb'};
    const [monthIsSelected, setMonthIsSelected] = useState(true);
    return (
        <Box
            sx={{
                backgroundColor: 'white',
                borderRadius: '20px',
                width: '100%'
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '20px'
                }}>
                <Typography sx={{fontWeight: 600}}>
                    {customMonths[chosenDate.format('MMMM')]}, {chosenDate.format('YYYY')}
                </Typography>
                <Box sx={{display: 'flex', justifyContent: 'space-between', width: '50px'}}>
                    <Box
                        sx={{cursor: 'pointer'}}
                        onClick={() => {
                            setChosenDate(chosenDate.subtract(1, 'month'));
                        }}
                    >
                        <img
                            style={{height: '12px'}}
                            src={leftVector}
                            alt={'leftVector'}
                        />
                    </Box>
                    <Box
                        sx={{cursor: 'pointer'}}
                        onClick={() => {
                            setChosenDate(chosenDate.add(1, 'month'));
                        }}
                    >
                        <img
                            style={{height: '12px'}}
                            src={rightVector}
                            alt={'rightVector'}
                        />
                    </Box>
                </Box>
            </Box>
            <Box sx={{marginBottom: '10px', padding: '0px 10px'}}>
                <Button
                    sx={{
                        width: '50%',
                        textTransform: 'none',
                        color: monthIsSelected ? '#000000' : 'white',
                        backgroundColor: monthIsSelected ? '#f9f9f9' : '#02457a',
                        '&:hover': {
                            backgroundColor: monthIsSelected ? 'rgba(249, 249, 249, 0.7)' : 'rgba(2, 69, 122, 0.7)',
                        },
                        borderRadius: '50px'
                    }}
                    onClick={() => setMonthIsSelected(false)}
                >Semana</Button>
                <Button
                    sx={{
                        width: '50%',
                        textTransform: 'none',
                        color: monthIsSelected ? 'white' : '#000000',
                        backgroundColor: monthIsSelected ? '#02457a' : '#f9f9f9',
                        '&:hover': {
                            backgroundColor: monthIsSelected ? 'rgba(2, 69, 122, 0.7)' : 'rgba(249, 249, 249, 0.7)',
                        },
                        borderRadius: '50px'
                    }}
                    onClick={() => setMonthIsSelected(true)}
                >Mês</Button>
            </Box>
            {monthIsSelected && (
                    <DateCalendar
                        value={chosenDate}
                        onChange={(newDate) => {
                            setChosenDate(newDate);
                        }}
                        sx={{
                            '.MuiPickersCalendarHeader-root': {
                                display: 'none',
                            },
                            '.Mui-selected': {
                                backgroundColor: '#02457a',
                            },
                            width: '90%',
                            maxHeight: '280px',
                        }}
                        dayOfWeekFormatter={(dayOfWeek) => customWeekDays[dayOfWeek]}
                    />
            )}
        </Box>
    );
}

export default MonthCalendar