import React, { useEffect, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import { Box, Button, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { DateTime } from 'luxon';

const events = [
  { title: 'Fulano de Tal', start: '2024-05-26T09:45:00', end: '2024-05-26T11:30:00', description: 'Consulta' },
  { title: 'Ver contabilidade', start: '2024-05-26T10:00:00', end: '2024-05-26T10:45:00', description: 'Compromisso' },
  { title: 'Ajustar erros do setor', start: '2024-05-26T10:45:00', end: '2024-05-26T11:30:00', description: 'Compromisso' },
  { title: 'Ver agenda', start: '2024-05-26T13:00:00', end: '2024-05-26T14:00:00', description: 'Compromisso' },
  { title: 'Fulanhinho de Talzin', start: '2024-05-26T14:00:00', end: '2024-05-26T15:45:00', description: 'Consulta' },
  { title: 'Checar compromissos', start: '2024-05-26T15:45:00', end: '2024-05-26T16:45:00', description: 'Compromisso' },
];

const COLORS = [ "#359d9e", "#0289bf", "#2884ff", "#70cf97", "#ff764c"]

const coloredEvents = events.map((event, index) => ({
    ...event,
    backgroundColor: COLORS[index % COLORS.length]
}));

const minTime = events.reduce((min, event) => event.start < min ? event.start : min, events[0].start).slice(11, 16);
const maxTime = events.reduce((max, event) => event.end > max ? event.end : max, events[0].end).slice(11, 16);

const renderEventContent = (eventInfo) => {
    return (
        <Box 
            sx={{ 
                backgroundColor: eventInfo.event.extendedProps.backgroundColor, 
                borderRadius: '20px', 
                padding: '5px', 
                color: '#fff',
            }}
        >
            <Typography
                sx={{
                    fontSize: '14px',
                    textWeight: 500
                }}
            >{eventInfo.timeText.split(' - ')[0]}</Typography>
            <Typography
                sx={{
                    fontSize: '14px',
                    textWeight: 500
                }}
            >{eventInfo.event.title}</Typography>
            <Typography 
                sx={{
                    fontSize: '12px',
                    textWeight: 500
                }}
            >{eventInfo.event.extendedProps.description}</Typography>
        </Box>
    );
};

const ImportantEvents: React.FC<{chosenDate: dayjs.Dayjs}> = ({chosenDate}) => {
    const calendarRef = useRef<FullCalendar>(null);
    const [clickedTime, setClickedTime] = useState(null);

    useEffect(() => {
        if (calendarRef.current) {
            let calendarApi = calendarRef.current.getApi();
            calendarApi.gotoDate(chosenDate.format('YYYY-MM-DD'));
        }
    }, [chosenDate]);

    const handleSelect = (info) => {
        console.log('clicou')
        setClickedTime(info.startStr);
    };

    const getTimeTop = (dateStr) => {
        const date = DateTime.fromISO(dateStr);
        const startHour = DateTime.fromISO(minTime);
        const endHour = DateTime.fromISO(maxTime);
        const totalHours = endHour.diff(startHour, 'hours').hours;
        const clickedHour = date.diff(startHour, 'hours').hours;
        const topPercentage = (clickedHour / totalHours) * 100;
        return `${topPercentage}%`;
    };

    return (
        <Box 
            sx={{
                marginTop: '20px',
                maxWidth: '100%',
            }}
        >
            <Box
                sx={{
                    backgroundColor: '#ffffff',
                    borderTopLeftRadius: '20px',
                    borderTopRightRadius: '20px',
                    height: '60px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0px 20px',
                }}
            >
                <Typography sx={{fontSize: '20px', fontWeight: 600}}>
                    Eventos Importantes
                </Typography>
            </Box>
            <Box
                sx={{
                    maxWidth: '100%',
                    backgroundColor: 'white !important',
                    '& .fc-timegrid-slot': {
                        backgroundColor: 'white',
                        height: '50px'
                    },
                    '& fc-event-main-frame': {
                        backgroundColor: 'red'
                    },
                    '& .fc-event': {
                        borderRadius: '20px',
                        padding: '5px',
                        marginLeft: '10px',
                        width: 'calc(100% - 20px)',
                    },
                    '& .fc-timegrid-slot-label-cushion': {
                        fontSize: '14px',
                        width: '70px',
                        textAlign: 'center'
                    }
                }}
            >
                <FullCalendar
                    ref={calendarRef}
                    plugins={[dayGridPlugin, timeGridPlugin]}
                    initialView="timeGridDay"
                    initialDate={dayjs().format('YYYY-MM-DD')}
                    events={coloredEvents}
                    height="auto"
                    slotMinTime={minTime}
                    slotMaxTime={maxTime}
                    slotLabelFormat={{
                        hour: 'numeric',
                        minute: '2-digit',
                        omitZeroMinute: false,
                        hour12: false
                    }}
                    allDaySlot={false}
                    headerToolbar={false}
                    dayHeaderFormat={() => ''}
                    eventTimeFormat={{
                        hour: 'numeric',
                        minute: '2-digit',
                        omitZeroMinute: false,
                        hour12: false
                    }}
                    eventContent={renderEventContent}
                    eventOverlap={false}
                    slotEventOverlap={false}
                />
            </Box>
        </Box>
    );
};

export default ImportantEvents;
