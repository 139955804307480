import { Box, Checkbox, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';
import React from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useDropzone } from 'react-dropzone';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

interface IBaseForIntelligenceSquareProps {
    title: string;
    infoIcon?: boolean;
    selectedDate: Dayjs | null;
    setSelectedDate: React.Dispatch<React.SetStateAction<Dayjs | null>>;
    children: React.ReactNode;
    setFiles?: React.Dispatch<React.SetStateAction<File[] | null>>;
    setBudgetOption?: (option: 1 | 2) => void;
    budgetOption?: 1 | 2;
}

const BaseForIntelligenceSquare: React.FC<IBaseForIntelligenceSquareProps> = ({
    title,
    infoIcon = false,
    selectedDate,
    setSelectedDate,
    children,
    setFiles,
    setBudgetOption,
    budgetOption,
}) => {
    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/*': [], // Aceita qualquer tipo de imagem
            'application/pdf': [], // Aceita arquivos PDF
            'text/*': [] // Aceita qualquer tipo de arquivo de texto
        },
        multiple: true, // Aceita múltiplos arquivos se necessário
        onDrop: (acceptedFiles) => {
            // Aqui você pode lidar com os arquivos aceitos
            console.log(acceptedFiles);
            setFiles !== undefined && setFiles(acceptedFiles);
        }
    });
    return (
        <Box
            sx={{
                padding: '30px',
                backgroundColor: '#fff',
                boxShadow: '0px 0px 5px 0px #c0c7d0',
                width: '96%',
                height: '100%',
                marginY: '15px',
            }}
        >
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: (!infoIcon && budgetOption) ? 'wrap' : '',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '20px',
                            fontFamily: 'Lato',
                            fontWeight: 'bold',
                        }}
                    >{title}</Typography>
                    {infoIcon && (
                        <InfoOutlinedIcon
                            sx={{
                                color: '#627d93',
                                fontSize: '18px',
                                marginLeft: '15px',
                            }}
                        />
                    )}
                    {setBudgetOption !== undefined && budgetOption !== undefined && (
                        <Box
                            sx={{
                                display: 'flex',
                                marginLeft: '20px',
                                flexWrap: 'wrap',
                                '@media (max-width: 680px)': {
                                    marginLeft: '-10px',
                                }
                            }}
                        >
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Checkbox
                                    size={'small'}
                                    checked={budgetOption === 1}
                                    icon={
                                        <RadioButtonUncheckedIcon/>
                                    }
                                    onChange={() => setBudgetOption(1)}
                                    checkedIcon={<RadioButtonCheckedIcon />}
                                />
                                <Typography
                                    sx={{
                                        "@media (max-width: 522px)": {
                                            fontSize: '12px',
                                        }
                                    }}
                                >Por quantidade</Typography>
                            </Box>
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Checkbox
                                    size={'small'}
                                    checked={budgetOption === 2}
                                    icon={
                                        <RadioButtonUncheckedIcon/>
                                    }
                                    onChange={() => setBudgetOption(2)}
                                    checkedIcon={<RadioButtonCheckedIcon />}
                                />
                                <Typography
                                    sx={{
                                        "@media (max-width: 522px)": {
                                            fontSize: '12px',
                                        }
                                    }}
                                >Por Valor <span style={{fontSize: '15px', color: "#a4b1c2"}}>(R$)</span></Typography>
                            </Box>
                        </Box>
                    )}
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        "@media (max-width: 734px)": {
                            flexDirection: 'column',
                            alignItems: 'center',
                            rowGap: '10px',
                        }
                    }}
                >
                    <DatePicker
                        format="DD/MM/YYYY"
                        sx={{
                            width: '23%',
                            maxWidth: '170px',
                            minWidth: '150px',
                            '.MuiOutlinedInput-root': {
                                '& fieldset': {
                                    border: 'none',
                                },
                                backgroundColor: '#e9ecf0',
                                minHeight: '30px', // Ajuste da altura mínima do componente
                                height: '30px', // Ajuste da altura do input
                                padding: '0 10px', // Ajusta o padding para uma altura reduzida
                                
                            },
                            '.MuiInputBase-input': {
                                backgroundColor: '#e9ecf0',
                                height: '20px', // Ajuste da altura do texto dentro do input
                                padding: '5px 0',
                            },
                        }}
                        value={selectedDate}
                        onChange={(date) => {
                            if (date) {
                                setSelectedDate(date);
                            } else {
                                setSelectedDate(null);
                            }
                        }}
                    />
                    {setFiles !== undefined && (
                        <Box
                            {...getRootProps()}
                            sx={{
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                color: '#556476',
                            }}
                        >
                            <input {...getInputProps()} />
                            <FileUploadOutlinedIcon />
                            <Typography
                                sx={{
                                    marginLeft: '10px',
                                }}
                            >Solte os arquivos aqui</Typography>
                        </Box>
                    )}
                </Box>
            </Box>
            {children}
        </Box>
    )
}

export default BaseForIntelligenceSquare;