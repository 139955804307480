import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, IconButton, Modal, TextField, MenuItem, FormControl, InputLabel, Select } from "@mui/material";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { configBackendConnection, endpoints } from "../../../components/utilsBackend.ts";

const MachineFee: React.FC = () => {
    const [openModal, setOpenModal] = useState(false);
    const [machineFees, setMachineFees] = useState<any[]>([]);
    const [selectedFee, setSelectedFee] = useState<any | null>(null);
    const [paymentType, setPaymentType] = useState('Débito');
    const [creditPaymentOption, setCreditPaymentOption] = useState<string>(''); 
    const [description, setDescription] = useState<string>('');
    const [daysToReceive, setDaysToReceive] = useState<string>('');
    const [fee, setFee] = useState<Record<string, string>>({});
    const [error, setError] = useState({
        description: false,
        fee: false,
        feeRange: false,
        feeEmpty: false,
    });
    const [extraInstallments, setExtraInstallments] = useState<number[]>([]);

    const resetForm = () => {
        setDescription('');
        setDaysToReceive('');
        setFee({});
        setCreditPaymentOption('');
        setError({
            description: false,
            fee: false,
            feeRange: false,
            feeEmpty: false,
        });
        setExtraInstallments([]);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        resetForm();
    };

    const fetchMachineFees = async () => {
        try {
            const response = await axios.get(
                `${configBackendConnection.baseURL}/${endpoints.machineAPI}`,
                { headers: configBackendConnection.headersDefault }
            );
            setMachineFees(response.data);
        } catch (error) {
            toast.error("Erro ao buscar taxas de maquininha");
        }
    };

    const handleSave = async () => {
        let valid = true;
        let newErrorState = { ...error };

        if (!description.trim()) {
            newErrorState.description = true;
            toast.error("Descrição da maquininha é obrigatória!");
            valid = false;
        } else {
            newErrorState.description = false;
        }

        newErrorState.fee = false;
        newErrorState.feeRange = false;
        newErrorState.feeEmpty = false;

        Object.entries(fee).forEach(([key, value]) => {
            const feeNumber = parseFloat(value);
            if (!value || value.trim() === "") {
                newErrorState.feeEmpty = true;
                toast.error(`A taxa na parcela ${key} não pode ser vazia!`);
                valid = false;
            } else if (isNaN(feeNumber) || feeNumber < 0 || feeNumber > 100) {
                newErrorState.fee = true;
                newErrorState.feeRange = true;
                toast.error(`A taxa na parcela ${key} deve estar entre 0 e 100!`);
                valid = false;
            }
        });

        setError(newErrorState);

        if (valid) {
            try {
                const data = {
                    description,
                    payment_type: paymentType,
                    credit_payment_option: creditPaymentOption,
                    days_to_receive: daysToReceive,
                    fee,
                    extra_installments: extraInstallments,
                };
                if (selectedFee) {
                    await axios.patch(
                        `${configBackendConnection.baseURL}/${endpoints.machineAPI}${selectedFee.id}/`,
                        data,
                        { headers: configBackendConnection.headersDefault }
                    );
                    toast.success("Taxa de maquininha atualizada com sucesso!");
                } else {
                    await axios.post(
                        `${configBackendConnection.baseURL}/${endpoints.machineAPI}`,
                        data,
                        { headers: configBackendConnection.headersDefault }
                    );
                    toast.success("Taxa de maquininha criada com sucesso!");
                }
                fetchMachineFees();
                handleCloseModal();
            } catch (error) {
                toast.error("Erro ao salvar taxa de maquininha");
            }
        }
    };

    const handleEdit = (fee: any) => {
        setSelectedFee(fee);
        setDescription(fee.description);
        setPaymentType(fee.payment_type);
        setDaysToReceive(fee.days_to_receive);
        setCreditPaymentOption(fee.credit_payment_option || '');
        setFee(fee.fee);
        setExtraInstallments(fee.extra_installments || []);
        setOpenModal(true);
    };

    const handleDelete = async (id: number) => {
        try {
            await axios.delete(
                `${configBackendConnection.baseURL}/${endpoints.machineAPI}${id}/`,
                { headers: configBackendConnection.headersDefault }
            );
            toast.success("Taxa de maquininha excluída com sucesso!");
            fetchMachineFees();
        } catch (error) {
            toast.error("Erro ao excluir taxa de maquininha");
        }
    };

    useEffect(() => {
        fetchMachineFees();
    }, []);

    const generateDaysOptions = () => {
        const options = [<MenuItem key="na_hora" value="Na hora">Na hora</MenuItem>];
        for (let i = 1; i <= 31; i++) {
            options.push(
                <MenuItem key={i} value={`${i} dia${i > 1 ? 's úteis' : ' útil'}`}>
                    {i} dia{i > 1 ? 's úteis' : ' útil'}
                </MenuItem>
            );
        }
        return options;
    };

    const handleFeeChange = (index: string, value: string) => {
        setFee(prevState => ({
            ...prevState,
            [index]: value
        }));
    };

    const addExtraInstallment = () => {
        setExtraInstallments(prevState => [...prevState, prevState.length + 13]);
    };

    const removeExtraInstallment = (installment: number) => {
        setExtraInstallments(prevState => prevState.filter(item => item !== installment));
        setFee(prevState => {
            const newFee = { ...prevState };
            delete newFee[installment];
            return newFee;
        });
    };

    return (
        <>
            <Box
                sx={{
                    width: '93%',
                    marginTop: '30px',
                    borderRadius: '10px',
                    padding: '20px',
                    boxShadow: '0px 0px 5px 0px #c0c7d0',
                    backgroundColor: 'white',
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '20px',
                            fontWeight: 'bold',
                            '@media (max-width: 548px)': {
                                fontSize: '15px'
                            }
                        }}
                    >
                        Taxas Maquininha
                    </Typography>
                    <Button
                        sx={{
                            backgroundColor: '#006af5',
                            '&:hover': {
                                backgroundColor: 'rgba(0, 106, 245, 0.7)',
                            },
                            color: 'white',
                            textTransform: 'none',
                            height: '35px',
                        }}
                        onClick={() => setOpenModal(true)}
                    >
                        <Typography
                            sx={{
                                '@media (max-width: 548px)': {
                                    fontSize: '12px'
                                }
                            }}
                        >
                            Adicionar Taxa
                        </Typography>
                    </Button>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        height: '1px',
                        backgroundColor: '#e4e4e4',
                        margin: '20px 0px'
                    }}
                />
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        maxHeight: '500px', // Limite de altura da lista
                        overflowY: 'auto',  // Ativação do scroll vertical
                    }}
                >
                    {machineFees.map(fee => (
                        <Box
                            key={fee.id}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                padding: '10px',
                                borderRadius: '5px',
                                backgroundColor: '#f9f9f9',
                                boxShadow: '0px 0px 5px rgba(0,0,0,0.1)'
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    color: '#333',
                                }}
                            >
                                {fee.description}
                            </Typography>
                            <Box>
                                <IconButton onClick={() => handleDelete(fee.id)} sx={{ color: '#ff4d4d' }}>
                                    <DeleteOutlineOutlinedIcon />
                                </IconButton>
                                <IconButton onClick={() => handleEdit(fee)} sx={{ color: '#006af5' }}>
                                    <EditOutlinedIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    ))}
                </Box>
            </Box>

            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '90%',
                        maxWidth: '500px',
                        maxHeight: '80vh', // Limitar a altura do modal
                        overflowY: 'auto', // Adicionar rolagem ao modal
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: '8px',
                        '@media (max-width: 600px)': {
                            width: '100%',
                            padding: '16px',
                        },
                    }}
                >
                    <Typography id="modal-title" variant="h6" component="h2" sx={{ marginBottom: '20px' }}>
                        Taxa de Maquininha
                    </Typography>
                    <TextField
                        fullWidth
                        label="Descrição da Maquininha"
                        required
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        error={error.description}
                        helperText={error.description ? "Este campo é obrigatório" : ""}
                        sx={{ marginBottom: '20px' }}
                    />
                    <FormControl fullWidth sx={{ marginBottom: '20px' }}>
                        <InputLabel>Qual tipo de pagamento você deseja configurar?</InputLabel>
                        <Select
                            value={paymentType}
                            onChange={(e) => setPaymentType(e.target.value)}
                            label="Qual tipo de pagamento você deseja configurar?"
                        >
                            <MenuItem value="Débito">Débito</MenuItem>
                            <MenuItem value="Crédito">Crédito</MenuItem>
                            <MenuItem value="Débito e Crédito">Débito e Crédito</MenuItem>
                        </Select>
                    </FormControl>

                    {(paymentType === 'Débito' || paymentType === 'Débito e Crédito') && (
                        <>
                            <FormControl fullWidth sx={{ marginBottom: '20px' }}>
                                <InputLabel>Em quantos dias você recebe o valor da venda?</InputLabel>
                                <Select
                                    value={daysToReceive}
                                    onChange={(e) => setDaysToReceive(e.target.value)}
                                    label="Em quantos dias você recebe o valor da venda?"
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: 300,
                                                marginTop: '8px',
                                            },
                                        },
                                    }}
                                >
                                    {generateDaysOptions()}
                                </Select>
                            </FormControl>
                            {daysToReceive && (
                                <TextField
                                    fullWidth
                                    label="Taxa (%)"
                                    required
                                    type="number"
                                    value={fee['0']}
                                    onChange={(e) => handleFeeChange('0', e.target.value)}
                                    error={error.fee || error.feeRange || error.feeEmpty}
                                    helperText={
                                        error.feeEmpty
                                            ? "Este campo não pode estar vazio"
                                            : error.feeRange
                                            ? "A taxa deve estar entre 0 e 100"
                                            : error.fee
                                            ? "Este campo é obrigatório"
                                            : ""
                                    }
                                    sx={{ marginBottom: '20px' }}
                                    inputProps={{
                                        min: 0,
                                        max: 100,
                                        step: 0.01,
                                    }}
                                />
                            )}
                        </>
                    )}

                    {(paymentType === 'Crédito' || paymentType === 'Débito e Crédito') && (
                        <>
                            <FormControl fullWidth sx={{ marginBottom: '20px' }}>
                                <InputLabel>Como você recebe estes valores do crédito na sua conta?</InputLabel>
                                <Select
                                    value={creditPaymentOption}
                                    onChange={(e) => setCreditPaymentOption(e.target.value)}
                                    label="Como você recebe estes valores do crédito na sua conta?"
                                >
                                    <MenuItem value="Em uma vez">Em uma vez</MenuItem>
                                    <MenuItem value="Em parcelas">Em parcelas</MenuItem>
                                </Select>
                            </FormControl>

                            {creditPaymentOption === 'Em uma vez' && (
                                <>
                                    <FormControl fullWidth sx={{ marginBottom: '20px' }}>
                                        <InputLabel>Em quantos dias você recebe o valor da venda?</InputLabel>
                                        <Select
                                            value={daysToReceive}
                                            onChange={(e) => setDaysToReceive(e.target.value)}
                                            label="Em quantos dias você recebe o valor da venda?"
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: 300,
                                                        marginTop: '8px',
                                                    },
                                                },
                                            }}
                                        >
                                            {generateDaysOptions()}
                                        </Select>
                                    </FormControl>
                                    {daysToReceive && (
                                        <TextField
                                            fullWidth
                                            label="1x - Taxa (%)"
                                            required
                                            type="number"
                                            value={fee['1'] || ''}
                                            onChange={(e) => handleFeeChange('1', e.target.value)}
                                            error={error.fee || error.feeRange || error.feeEmpty}
                                            helperText={
                                                error.feeEmpty
                                                    ? "Este campo não pode estar vazio"
                                                    : error.feeRange
                                                    ? "A taxa deve estar entre 0 e 100"
                                                    : error.fee
                                                    ? "Este campo é obrigatório"
                                                    : ""
                                            }
                                            sx={{ marginBottom: '20px' }}
                                            inputProps={{
                                                min: 0,
                                                max: 100,
                                                step: 0.01,
                                            }}
                                        />
                                    )}
                                </>
                            )}

                            {creditPaymentOption === 'Em parcelas' && (
                                <>
                                    {[...Array(12)].map((_, index) => (
                                        <TextField
                                            key={index + 1}
                                            fullWidth
                                            label={`${index + 1}x - Taxa (%)`}
                                            required
                                            type="number"
                                            value={fee[index + 1] || ''}
                                            onChange={(e) => handleFeeChange((index + 1).toString(), e.target.value)}
                                            error={error.fee || error.feeRange || error.feeEmpty}
                                            helperText={
                                                error.feeEmpty
                                                    ? "Este campo não pode estar vazio"
                                                    : error.feeRange
                                                    ? "A taxa deve estar entre 0 e 100"
                                                    : error.fee
                                                    ? "Este campo é obrigatório"
                                                    : ""
                                            }
                                            sx={{ marginBottom: '20px' }}
                                            inputProps={{
                                                min: 0,
                                                max: 100,
                                                step: 0.01,
                                            }}
                                        />
                                    ))}
                                    {extraInstallments.map(installment => (
                                        <Box key={installment} sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                                            <TextField
                                                fullWidth
                                                label={`${installment}x - Taxa (%)`}
                                                required
                                                type="number"
                                                value={fee[installment] || ''}
                                                onChange={(e) => handleFeeChange(installment.toString(), e.target.value)}
                                                error={error.fee || error.feeRange || error.feeEmpty}
                                                helperText={
                                                    error.feeEmpty
                                                        ? "Este campo não pode estar vazio"
                                                        : error.feeRange
                                                        ? "A taxa deve estar entre 0 e 100"
                                                        : error.fee
                                                        ? "Este campo é obrigatório"
                                                        : ""
                                                }
                                                inputProps={{
                                                    min: 0,
                                                    max: 100,
                                                    step: 0.01,
                                                }}
                                            />
                                            <IconButton onClick={() => removeExtraInstallment(installment)} sx={{ marginLeft: '10px' }}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Box>
                                    ))}
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                                        <Button onClick={addExtraInstallment} variant="outlined">
                                            Adicionar Parcela Extra
                                        </Button>
                                    </Box>
                                </>
                            )}
                        </>
                    )}

                    <Box sx={{ display: 'flex', marginTop: '20px' }}>
                        <Button variant="contained" color="primary" onClick={handleSave}>
                            Salvar
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default MachineFee;
