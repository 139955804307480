import { Box, Button, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IModalProps } from '../../../types/modals';
import axios from 'axios';
import { configBackendConnection, endpoints } from '../../../components/utilsBackend.ts';

const StorageRemovalHistory: React.FC<IModalProps> = ({
    open,
    setOpen,
    onClose
}) => {
    const [historyRows, setHistoryRows] = useState<Array<{ product_name: string; quantity_removed: number; withdrawal_date: string }>>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');

    useEffect(() => {
        if (open) {
            const fetchHistory = async () => {
                try {
                    const response = await axios.get(`${configBackendConnection.baseURL}/${endpoints.withdrawalHistoryAPI}`, {
                        headers: configBackendConnection.headersDefault
                    });
                    setHistoryRows(response.data);
                } catch (error) {
                    console.error('Erro ao buscar histórico de retiradas:', error);
                    toast.error('Erro ao buscar histórico de retiradas.');
                }
            };

            fetchHistory();
        }
    }, [open]);

    const filteredRows = historyRows.filter((row) =>
        row.product_name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <Modal
            open={open}
            onClose={onClose}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box 
                sx={{
                    width: '1100px',
                    height: '600px',
                    backgroundColor: 'white',
                    padding: '35px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    transition: 'height 1s ease',
                    '@media (max-width: 1100px)': {
                        width: '100vw'
                    }
                }}
            >
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography
                        sx={{
                            fontSize: '22px',
                            fontWeight: 'bold',
                            color: '#505050'
                        }}
                    >
                        Histórico de retiradas
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                            placeholder="Buscar produto"
                            variant="outlined"
                            size="small"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            sx={{ marginRight: '10px' }}
                        />
                    </Box>
                </Box>
                <TableContainer sx={{ maxHeight: '80%', overflow: 'auto' }}>
                    <Table
                        sx={{ minWidth: 650 }}
                        aria-label="simple table"
                        stickyHeader
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography
                                        sx={{
                                            color: '#5b5b5b',
                                            fontSize: 18,
                                            fontFamily: 'DM Sans'
                                        }}
                                    >
                                        Produtos
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography
                                        sx={{
                                            color: '#5b5b5b',
                                            fontSize: 18,
                                            fontFamily: 'DM Sans'
                                        }}
                                    >
                                        Qtd retirada
                                    </Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography
                                        sx={{
                                            color: '#5b5b5b',
                                            fontSize: 18,
                                            fontFamily: 'DM Sans'
                                        }}
                                    >
                                        Data da Retirada
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredRows.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row">
                                        <Typography
                                            sx={{
                                                color: '#A5A5A5',
                                                fontFamily: 'DM Sans'
                                            }}
                                        >{row.product_name}</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography
                                            sx={{
                                                color: '#A5A5A5',
                                                fontFamily: 'DM Sans'
                                            }}
                                        >{row.quantity_removed}</Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography
                                            sx={{
                                                color: '#A5A5A5',
                                                fontFamily: 'DM Sans'
                                            }}
                                        >{new Date(row.withdrawal_date).toLocaleDateString()}</Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        sx={{
                            color: 'white',
                            textTransform: 'none',
                            backgroundColor: '#006af5',
                            '&:hover': {
                                backgroundColor: 'rgba(0, 106, 245, 0.7)',
                            },
                        }}
                        onClick={onClose}
                    >
                        Fechar
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default StorageRemovalHistory;
