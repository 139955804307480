import { Box, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SimpleMenu from '../../../../../components/simpleMenu/index.tsx';
import { useDropzone } from 'react-dropzone';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';

interface IPerformanceItem {
    name: string;
    receipts: number;
    receiptsValue: number;
}

const ReportItemList: React.FC<{itemList: IPerformanceItem[]}> = ({itemList}) => {
    return (
        <>
            {   itemList.map((item, index) => (
                    <>
                        <Box
                            key={index}
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                paddingX: '20px',
                                marginY: '10px',
                            }}
                        >
                            <Box>
                                <Typography
                                    sx={{color: '#77828f'}}
                                >{item.name}</Typography>
                                <Typography
                                    sx={{
                                        color: '#77828f',
                                        fontSize: '14px',
                                    }}
                                >{item.receipts} Receitas</Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-end',
                                    }}
                                >
                                    <Typography
                                        sx={{color: '#77828f'}}
                                    >Receitas</Typography>
                                    <Typography
                                        sx={{
                                            color: '#77828f',
                                            fontSize: '14px'
                                        }}
                                    >R$ {item.receiptsValue.toFixed(2)}</Typography>
                                </Box>
                                <MoreVertOutlinedIcon sx={{marginLeft: '10px', color: '#77828f'}}/>
                            </Box>
                        </Box>

                        <Box sx={{width: '99%', marginX: 'auto', height: '1px', backgroundColor: '#c0c7d0'}}/>
                    </>
                ))
            }
        </>
    )
}

const ReceivalPerformanceReports: React.FC = () => {
    const [receivalDate, setReceivalDate] = React.useState<Dayjs | null>(dayjs());
    const [optionSelected, setOptionSelected] = React.useState<string>('Profissionais');
    const [professionals, setProfessionals] = React.useState<IPerformanceItem[]>([
        {
            name: 'Fulano de Tal',
            receipts: 0,
            receiptsValue: 0,
        }
    ]);
    const [plans, setPlans] = React.useState<IPerformanceItem[]>([
        {
            name: 'Particular',
            receipts: 0,
            receiptsValue: 0,
        },
        {
            name: 'Plano A',
            receipts: 2,
            receiptsValue: 67,
        }
    ]);
    const [treatments, setTreatments] = React.useState<IPerformanceItem[]>([
        {
            name: 'Tratamento de Canal',
            receipts: 0,
            receiptsValue: 0,
        }
    ]);
    const [specialties, setSpecialties] = React.useState<IPerformanceItem[]>([
        {
            name: 'Odontopediatria',
            receipts: 0,
            receiptsValue: 0,
        },
        {
            name: 'Odontopediatria',
            receipts: 0,
            receiptsValue: 0,
        },
        {
            name: 'Odontopediatria',
            receipts: 0,
            receiptsValue: 0,
        },
        {
            name: 'Odontopediatria',
            receipts: 0,
            receiptsValue: 0,
        },
        {
            name: 'Odontopediatria',
            receipts: 0,
            receiptsValue: 0,
        },
        {
            name: 'Odontopediatria',
            receipts: 0,
            receiptsValue: 0,
        },
        {
            name: 'Odontopediatria',
            receipts: 0,
            receiptsValue: 0,
        },
        {
            name: 'Odontopediatria',
            receipts: 0,
            receiptsValue: 0,
        },
        {
            name: 'Odontopediatria',
            receipts: 0,
            receiptsValue: 0,
        },
        {
            name: 'Odontopediatria',
            receipts: 0,
            receiptsValue: 0,
        },
        {
            name: 'Odontopediatria',
            receipts: 0,
            receiptsValue: 0,
        }
    ]);
    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/*': [], // Aceita qualquer tipo de imagem
            'application/pdf': [], // Aceita arquivos PDF
            'text/*': [] // Aceita qualquer tipo de arquivo de texto
        },
        multiple: true, // Aceita múltiplos arquivos se necessário
        onDrop: (acceptedFiles) => {
            // Aqui você pode lidar com os arquivos aceitos
            console.log(acceptedFiles);
        }
    });

    return (
        <Box
            sx={{
                padding: '15px',
                backgroundColor: '#fff',
                boxShadow: '0px 0px 5px 0px #c0c7d0',
                width: '60%',
                height: '100%',
                '@media (max-width: 904px)': {
                    width: '100%',
                    marginBottom: '20px',
                }
            }}
        >
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                        sx={{
                            fontSize: '20px',
                            fontFamily: 'Lato',
                            fontWeight: 'bold',
                        }}
                    >Relatórios de Recebimentos</Typography>
                    <InfoOutlinedIcon
                        sx={{
                            color: '#627d93',
                            fontSize: '18px',
                            marginLeft: '15px',
                        }}
                    />
                </Box>
                <DatePicker
                    format="DD/MM/YYYY"
                    sx={{
                        width: '23%',
                        maxWidth: '170px',
                        minWidth: '150px',
                        '.MuiOutlinedInput-root': {
                            '& fieldset': {
                                border: 'none',
                            },
                            backgroundColor: '#e9ecf0',
                            minHeight: '30px',
                            height: '30px',
                            padding: '0 10px',
                            
                        },
                        '.MuiInputBase-input': {
                            backgroundColor: '#e9ecf0',
                            height: '20px',
                            padding: '5px 0',
                        },
                    }}
                    value={receivalDate}
                    onChange={(date) => {
                        if (date) {
                            setReceivalDate(date);
                        } else {
                            setReceivalDate(null);
                        }
                    }}
                />
            </Box>
            <SimpleMenu
                options={['Profissionais', 'Planos', 'Tratamentos', 'Especialidades']}
                optionSelected={optionSelected}
                setOptionSelected={setOptionSelected}
                sx={{
                    border: 'none',
                    boxShadow: '0 5px 4px -4px #c0c7d0',
                    marginY: '20px',
                    paddingX: '20px'
                }}
            />
            <Box
                sx={{
                    width: '100%',
                    paddingX: '20px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '20px',
                }}
            >
                <Typography
                    sx={{
                        fontSize: '23',
                        fontFamily: 'Lato',
                        color: '#005fdb',
                    }}
                >
                    <span style={{fontWeight: 'bold'}}>1</span> {
                    optionSelected === 'Profissional' ?
                        'Profissionais' :
                        optionSelected === 'Planos' ?
                            'Plano' :
                            optionSelected === 'Tratamentos' ?
                            'Tratamento' : 'Especialidade'
                }</Typography>
               <Box
                    {...getRootProps()}
                    sx={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        color: '#556476'
                    }}
                >
                    <input {...getInputProps()} />
                    <FileUploadOutlinedIcon />
                    <Typography
                        sx={{
                            marginLeft: '10px',
                        }}
                    >Solte os arquivos aqui</Typography>
                </Box>
            </Box>
            <Box sx={{width: '99%', marginX: 'auto', height: '1px', backgroundColor: '#c0c7d0'}}/>
            <Box
                sx={{
                    height: '455px',
                    overflowY: 'auto'
                }}
            >
                {   optionSelected === 'Profissionais' &&
                    <ReportItemList itemList={professionals}/>
                }
                {   optionSelected === 'Planos' &&
                    <ReportItemList itemList={plans}/>
                }
                {
                    optionSelected === 'Tratamentos' &&
                    <ReportItemList itemList={treatments}/>
                }
                {
                    optionSelected === 'Especialidades' &&
                    <ReportItemList itemList={specialties}/>
                }
            </Box>
        </Box>
    )
}

export default ReceivalPerformanceReports;