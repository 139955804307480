import React, { useContext, useEffect, useRef } from 'react';
import Header from '../../components/header/index.tsx';
import MobileHeader from '../../components/MobileHeader/index.tsx';
import PageContentContainer from '../../components/PageContentContainer/index.tsx';
import { Box, Typography } from '@mui/material';
import PlanBox from './PlanBox/index.tsx';
import { IPlanBoxData } from '../../types/Plans/PlanTypes.ts';
import Sidebar from '../../components/sidebar/index.tsx';
import { toast } from 'react-toastify';
import InfoIcon from '@mui/icons-material/Info';
import PlanPaymentInfo from './PlanPaymentInfo/index.tsx';
import { INewCreditCardForm } from '../../types/payment.tsx';
import PlanPaymentSummary from './PlanPaymentSummary/index.tsx';
import { AuthContext } from '../../context/AuthProvide.js';

const PLANS_DATA: IPlanBoxData[] = [
    {
        title: 'Agenda',
        description: 'Cuide de seus pacientes e da sua agenda',
        price: 'R$ 139,90',
        features: [
            {
                title: 'Usuários ilimitados',
                isNew: false,
            },
            {
                title: 'Confirmação de consulta',
                detail: '* Mensagens SMS e WhatsaApp adquiridas à parte',
                isNew: false,
                detailSize: 'small'
            },
            {
                title: 'Aplicativo para pacientes',
                detail: '(Meu Doutor)',
                isNew: true
            },
            {
                title: 'Prontuário digital e emissão de documentos',
                isNew: false
            },
            {
                title: 'Odontograma',
                isNew: true
            },
            {
                title: 'Campanhas de marketing*',
                isNew: false
            },
            {
                title: 'Orçamento Parcial',
                isNew: true
            },
            {
                title: 'Site do dentista',
                isNew: true
            },
            {
                title: 'Agenda integrada à Alexa',
                isNew: true
            }
            
        ]
    },
    {
        title: 'Vendas',
        description: 'Mais agilidade no processo de vendas',
        price: 'R$ 199,90',
        promotion: {
            beforePriceMessage: 'Durante 3 meses de',
            previousPrice: 'R$ 279,90',
            afterPriceMessage: 'por'
        },
        features: [
            {
                title: 'Usuários ilimitados',
                isNew: false,
            },
            {
                title: 'Confirmação de consulta',
                detail: '* Mensagens SMS e WhatsaApp adquiridas à parte',
                isNew: false,
                detailSize: 'small'
            },
            {
                title: 'Aplicativo para pacientes',
                detail: '(Meu Doutor)',
                isNew: true
            },
            {
                title: 'Prontuário digital e emissão de documentos',
                isNew: false
            },
            {
                title: 'Odontograma',
                isNew: true
            },
            {
                title: 'Campanhas de marketing*',
                isNew: false
            },
            {
                title: 'Orçamento Parcial',
                isNew: true
            },
            {
                title: 'Site do dentista',
                isNew: true
            },
            {
                title: 'Agenda integrada à Alexa',
                isNew: true
            }
            
        ]
    },
    {
        title: 'Agenda',
        description: 'Cuide de seus pacientes e da sua agenda',
        price: 'R$ 139,90',
        features: [
            {
                title: 'Usuários ilimitados',
                isNew: false,
            },
            {
                title: 'Confirmação de consulta',
                detail: '* Mensagens SMS e WhatsaApp adquiridas à parte',
                isNew: false,
                detailSize: 'small'
            },
            {
                title: 'Aplicativo para pacientes',
                detail: '(Meu Doutor)',
                isNew: true
            },
            {
                title: 'Prontuário digital e emissão de documentos',
                isNew: false
            },
            {
                title: 'Odontograma',
                isNew: true
            },
            {
                title: 'Campanhas de marketing*',
                isNew: false
            },
            {
                title: 'Orçamento Parcial',
                isNew: true
            },
            {
                title: 'Site do dentista',
                isNew: true
            },
            {
                title: 'Agenda integrada à Alexa',
                isNew: true
            }
            
        ]
    },
    {
        title: 'Agenda',
        description: 'Cuide de seus pacientes e da sua agenda',
        price: 'R$ 139,90',
        features: [
            {
                title: 'Usuários ilimitados',
                isNew: false,
            },
            {
                title: 'Confirmação de consulta',
                detail: '* Mensagens SMS e WhatsaApp adquiridas à parte',
                isNew: false,
                detailSize: 'small'
            },
            {
                title: 'Aplicativo para pacientes',
                detail: '(Meu Doutor)',
                isNew: true
            },
            {
                title: 'Prontuário digital e emissão de documentos',
                isNew: false
            },
            {
                title: 'Odontograma',
                isNew: true
            },
            {
                title: 'Campanhas de marketing*',
                isNew: false
            },
            {
                title: 'Orçamento Parcial',
                isNew: true
            },
            {
                title: 'Site do dentista',
                isNew: true
            },
            {
                title: 'Agenda integrada à Alexa',
                isNew: true
            }
            
        ]
    }
]

const PlansPage: React.FC = () => {
    // Logic for user login check
    const [isUserLoggedIn, setIsUserLoggedIn] = React.useState(false);
    const { authenticated } = useContext(AuthContext);
    useEffect(() => {authenticated && setIsUserLoggedIn(true)}, [authenticated]);
    // vars
    const [selectedPlan, setSelectedPlan] = React.useState<number>(-1);
    const [paymentMethod, setPaymentMethod] = React.useState<'creditCard' | 'ticket' | 'pix'>('creditCard');
    const paymentMethodBoxRef = useRef<HTMLDivElement>(null);
    const [paymentInfoForm, setPaymentInfoForm] = React.useState<INewCreditCardForm>({
        fullname: '',
        cpfOrCnpj: '',
        email: '',
        cellphone: '',
        payday: 10,
        address: {
            cep: '',
            street: '',
            number: '',
            complement: undefined,
            neighborhood: '',
            city: '',
            state: undefined
        },
        cardData: {
            cvv: '',
            expiry: '',
            name: '',
            number: ''
        }
    });
    return (
        <>
            <Header />
            <MobileHeader />
            {isUserLoggedIn && <Sidebar />}
            <PageContentContainer noSidebar={!isUserLoggedIn} backgroundWhite>
                <Box 
                    sx={{
                        width: '100%',
                        height: '100%',
                        overflowY: 'auto',
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            height: '70px',
                            background: 'linear-gradient(to bottom, #d4d4d4, #ffffff)'
                        }}
                    />
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            overflowY: 'auto',
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Lato',
                                fontSize: '22px',
                                fontWeight: 'bold',
                                marginBottom: '10px',
                                color: '#505050'
                            }}
                        >
                            {isUserLoggedIn ? "Alterar plano" : "Adquirir um plano"}
                        </Typography>
                        <Typography
                            sx={{
                                color: '#616d7c',
                                textAlign: 'center',
                            }}
                        >
                            Todos os planos possuem agenda, comunicação, ficha do paciente e suporte gratuito
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                        }}
                    >
                        {PLANS_DATA.map((plan, index) => (
                            <PlanBox
                                key={index}
                                data={plan}
                                selectPlan={() => {
                                    setSelectedPlan(index)
                                    paymentMethodBoxRef.current?.scrollIntoView({behavior: 'smooth'})
                                    toast.error('Não implementado ainda')
                                }}
                            />
                        ))}
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                        ref={paymentMethodBoxRef}
                    >
                        <Box
                            sx={{
                                border: '1px solid #e9e9e9',
                                width: '98%',
                                heigth: '1px',
                                marginY: '10px'
                            }}
                        />
                        <Typography
                            sx={{
                                textAlign: 'center',
                            }}
                        >
                            * As mensagens de SMS e WhatsApp são adquiridas à parte <InfoIcon sx={{color: '#627d93', fontSize: '18px', marginX: '5px'}}/>
                        </Typography>
                        <Box
                            sx={{
                                border: '1px solid #e9e9e9',
                                width: '98%',
                                heigth: '1px',
                                marginY: '10px'
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            width: '98%',
                            display: 'flex',
                            justifyContent: 'space-around',
                            marginY: '40px',
                            "@media (max-width: 1086px)": {
                                flexDirection: 'column',
                                alignItems: 'center',
                                width: '100%',
                                rowGap: '30px'
                            }
                        }}
                    >
                        <PlanPaymentInfo
                            form={paymentInfoForm}
                            setForm={setPaymentInfoForm}
                            paymentMethod={paymentMethod}
                            setPaymentMethod={setPaymentMethod}
                        />
                        <PlanPaymentSummary
                            selectedPlan={PLANS_DATA[selectedPlan] ? PLANS_DATA[selectedPlan] : PLANS_DATA[0]}
                            paymentMethod={paymentMethod}
                            form={paymentInfoForm}
                        />
                    </Box>
                </Box>
            </PageContentContainer>
        </>
    )
}

export default PlansPage