import { Box, Typography } from '@mui/material'
import React from 'react'

interface IIntelligenceOptionsMenuProps {
    options: string[];
    optionSelected: string;
    setOptionSelected: (optionSelected: string) => void;
    sx?: any;
}

const SimpleMenu: React.FC<IIntelligenceOptionsMenuProps> = ({
    options,
    optionSelected,
    setOptionSelected,
    sx
}) => {
    return (
            <Box
                sx={{
                    backgroundColor: 'white',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-end',
                    borderRadius: '5px',
                    padding: '0px 50px',
                    boxShadow: '0px 5px 5px 0px #c0c7d0',
                    overflow: 'auto',
                    ...sx
                }}
            >
                {options.map((option, index) => (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            cursor: 'pointer',
                            marginRight: '30px'
                        }}
                        onClick={() => setOptionSelected(option)}
                    >
                        <Typography
                            sx={{
                                color: optionSelected === option ? '#005fdb' : '#88929d',
                            }}
                        >{option}</Typography>
                        <Box
                            sx={{
                                width: '50%',
                                height: '3px',
                                backgroundColor: optionSelected === option ? '#005fdb' : 'white',
                                marginTop: '5px',
                                borderRadius: '20px'
                            }}
                        />
                    </Box>
                ))}
            </Box>
    )
}

export default SimpleMenu;