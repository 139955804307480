import './LandingPageFooter.css';
import { BsChatText, BsTwitter, BsInstagram, BsFacebook } from "react-icons/bs";
import LogoFooter from '../../../assets/images/logo_footer_landing_page.svg';
const LandingPageFooter: React.FC = () => {
  return (
    <>
      <footer id='footer_landing_page'>
        <nav>
          <img src={LogoFooter} alt="Logo" />
          <div className='actions'>
            <p><a href=''>Funcionalidades</a></p>
            <p><a href=''>Preço</a></p>
            <p><a href=''>Documentação</a></p>
            <p><a href=''>Contato</a></p>
          </div>
        </nav>
        <hr id='footer_hr' />
        <div>
          <div id='terms'>
            <p>
              Todos os direitos reservados. Veja nossa <a href=''>Política de Privacidade</a> e <a href=''>Termos de Uso</a>
            </p>
          </div>
          <div id='utils'>
            <div>
              <div><BsChatText className='icons' /></div>
              <p><a href=''>Ajuda e Suporte</a></p>
            </div>
            <div>
              <p>Siga em</p>
              <a href='' className='icons'><BsTwitter /></a>
              <a href='' className='icons'><BsInstagram /></a>
              <a href='' className='icons'><BsFacebook /></a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default LandingPageFooter;
