export const formatCellPhone = (value) => {
    value = value.replace(/\D/g, '');
    if (value.length > 11) {
        value = value.slice(0, 11);
    }
    value = value.replace(/^(\d{2})(\d)/g, "($1) $2");
    if (value.length > 9) {
        value = value.replace(/(\d{5})(\d)/, "$1-$2");
    } else {
        value = value.replace(/(\d{4})(\d)/, "$1-$2");
    }
    return value;
}

export const sanitizeCellPhone = (value) => {
    return value.replace(/\D/g, '').slice(0, 11);
}

export const formatPhone = (value) => {
    value = value.replace(/\D/g, '');
    if (value.length > 10) {
        value = value.slice(0, 10);
    }
    value = value.replace(/^(\d{2})(\d)/g, "($1) $2");
    value = value.replace(/(\d{4})(\d)/, "$1-$2");
    return value;
}

export const sanitizePhone = (value) => {
    return value.replace(/\D/g, '').slice(0, 10);
}

export const formatCNPJ = (value) => {
    value = value.replace(/\D/g, '');
    if (value.length > 14) {
        value = value.slice(0, 14);
    }
    value = value.replace(/^(\d{2})(\d)/, '$1.$2');
    value = value.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
    value = value.replace(/\.(\d{3})(\d)/, '.$1/$2');
    value = value.replace(/(\d{4})(\d)/, '$1-$2');
    return value;
};

export const sanitizeCNPJ = (value) => {
    return value.replace(/\D/g, '');
  };
  

export const formatCPF = (value) => {
    value = value.replace(/\D/g, '');
    value = value.replace(/^(\d{3})(\d)/, '$1.$2');
    value = value.replace(/^(\d{3})\.(\d{3})(\d)/, '$1.$2.$3');
    value = value.replace(/\.(\d{3})(\d)/, '.$1-$2');
    return value;
};
  
export const sanitizeCPF = (value) => {
    return value.replace(/\D/g, '');
};
  

export const formatCEP = (value) => {
    value = value.replace(/\D/g, '');
    if (value.length > 8) {
        value = value.slice(0, 8);
    }
    value = value.replace(/(\d{5})(\d)/, "$1-$2");
    return value;
}
